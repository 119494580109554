import { Badge, Image, Popover } from "antd";
import jwt_decode from "jwt-decode";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";
import { HyperlinkType } from "../../constants/hyperlink-type.constants";
import { store } from "../../modules";
import { setSelectedSubMenuId, setUserInfo } from "../../modules/session/session.actions";
import { storeConfigSelector } from "../../modules/session/session.reducers";
import { notificationActions } from "../../modules/notification/notification.reducer";
import { firebase } from "../../utils/firebase";
import { colorToRgba, handleHyperlinkValue, isNonEmptyArray, truncateText } from "../../utils/helpers";
import { getStorage, localStorageKeys, removeStorage, setStorage } from "../../utils/localStorage.helpers";
import "../assets/css/home-page.style.scss";
import { ArrowDownIcon, HeaderViewMoreMenuIcon, UserIconUrl } from "../assets/icons.constants";
import closeNavbarIcon from "../assets/icons/close-nav-bar.svg";
import profileCricle from "../assets/icons/profile-circle.svg";
import menuPointUrl from "../assets/images/menu_point.svg";
import DefaultLogo from "../assets/images/pho-viet-logo.png";
import { Hyperlink } from "../constants/hyperlink.constants";
import { profileTab } from "../constants/string.constant";
import SelectLanguageContainer from "../containers/SelectLanguageContainer";
import ShoppingCartPage from "../pages/shopping-cart/shopping-cart.page";
import ChangeLanguageDesktop from "./ChangeLanguageDesktop/ChangeLanguageDesktop";
import CartComponent from "./cart/cart.component";
import { DeliveryAddressSelectorComponent } from "./delivery-address-selector/delivery-address-selector.component";
import {
  StyledDeliveryAddressSelector,
  StyledIconStroke,
  StyledLanguage,
  StyledMenuItems,
  StyledPopoverLoginHeader,
  StyledSubMenuItem,
} from "./header/StyledComponents";
import { UserIcon } from "./header/UserIcon";
import LoginPopover from "./login-popover.component";
import FnbNotification from "./fnb-notification/fnb-notification";

function Theme2OriginalHeader(props) {
  // Listener theme config
  //const headerConfig = useSelector((state) => state?.themeConfig?.data?.general?.header);
  const headerConfig = useSelector((state) => state?.session?.themeConfig?.general?.header);
  const headerMenuOptions = useSelector((state) => state?.themeConfig?.headerMenuOptions);
  const isAllowReserveTable = !!useSelector(storeConfigSelector)?.isAllowReserveTable;
  const dispatch = useDispatch();
  const { logo, menuItem, colorGroups, config, isCustomize, isDefault, stateConfig, fontFamily } = props;
  const isMaxWidth600 = useMediaQuery({ maxWidth: 600 });
  const isDevicesTablet = useMediaQuery({ maxWidth: 1336, minWidth: 600 });
  const isMaxWidth640 = useMediaQuery({ maxWidth: 640 });
  const [t] = useTranslation();
  const history = useHistory();
  const selectedSubMenuId = useSelector((state) => state.session.selectedSubMenuID);
  const [headerMenus, setHeaderMenus] = useState(menuItem);
  const [__menus, setMenus] = useState([]);
  const [activeMenu, setActiveMenu] = useState();
  const [moreMenuItems, setMoreMenuItems] = useState([]);
  const [moreProfileInfo, setMoreProfileInfo] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [isCalculate, setIsCalculate] = useState(true);
  const [isRefreshShowCart, setIsRefreshShowCart] = useState(false);
  const groupColorConfig = stateConfig?.general?.color?.colorGroups?.find((c) => c.id === headerConfig?.colorGroupId);
  const [maxMenuSupport, setMaxMenuSupport] = useState(isCustomize ? 10 : 0);
  const [calculateMaxMenuDone, setCalculateMaxMenuDone] = useState(false);
  const token = getStorage("token");
  const decoded = token && jwt_decode(token);
  const [isShowLanguageOnMobile, setIsShowLanguageOnMobile] = useState(false);
  const changeSizeScreenRef = useRef(null);

  const onResize = () => {
    if (isCustomize) {
      if (changeSizeScreenRef.current) {
        clearTimeout(changeSizeScreenRef.current);
      }
      changeSizeScreenRef.current = setTimeout(() => {
        const headerWidth = document.getElementById("right-content").offsetWidth;
        const navTopRightElement = document.getElementById("nav-top-right-id");
        const offsetRight = headerWidth - (navTopRightElement?.offsetLeft + navTopRightElement?.offsetWidth) + 20;
        if (offsetRight > 0) {
          document.getElementById("nav-menu-customize").style.paddingLeft = `${offsetRight}px`;
        }
      }, 100);
    }
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ScrollHeaderType = {
    SCROLL: 1,
    FIXED: 2,
  };
  const PREFIX_MENU_ITEM_ID = "custom-menu-header-item__";

  const pageData = {
    logOut: t("loginPage.logOut", "Log Out"),
    myAccount: t("storeWebPage.header.myAccount", "My Account"),
    order: t("storeWebPage.header.order", "Order"),
    myReservation: t("reservation.myReservation", "My Reservations"),
  };
  const customerInfo = JSON.parse(getStorage(localStorageKeys.CUSTOMER_INFO));
  const accessToken = getStorage(localStorageKeys.TOKEN);
  const isLogin = customerInfo && accessToken;

  useEffect(() => {
    if (!headerConfig?.menuId || headerMenuOptions?.length < 1) return;
    const headerMenuOption = headerMenuOptions?.find((x) => x?.id === headerConfig?.menuId);
    const menuItems = headerMenuOption?.onlineStoreMultiLevelMenus ?? [];
    setHeaderMenus(menuItems);
    setMaxMenuSupport(10);
  }, [headerConfig?.menuId, headerMenuOptions]);

  const setMenuHeader = useCallback(() => {
    if (maxMenuSupport > 0) {
      const menuItemSlice = headerMenus?.slice(0, maxMenuSupport);
      setMenus(menuItemSlice);
      /// If number of menu item > 5 item => Add menu item "..."
      if (Array.isArray(headerMenus)) {
        const menuLength = headerMenus?.length - maxMenuSupport;
        const moreMenuItems = menuLength <= 0 ? [] : headerMenus?.slice(-menuLength);
        setMoreMenuItems(moreMenuItems);
      }
    } else {
      setMenus(headerMenus);
    }
  }, [headerMenus, maxMenuSupport]);

  const handleItemMenu = (index) => {
    const menuArrowCollapseElement = document.getElementById("menuArrowCollapse-" + index);
    if (menuArrowCollapseElement) {
      menuArrowCollapseElement.classList.toggle("menu_arrow_right");
    }

    const menuArrowExpandElement = document.getElementById("menuArrowExpand-" + index);
    if (menuArrowExpandElement) {
      menuArrowExpandElement.classList.toggle("menu_arrow_right");
    }
  };
  const handleSubMenuEnter = (itemChildId) => {
    document.getElementById("subMenuHover-" + itemChildId).style.backgroundColor = colorToRgba(
      groupColorConfig?.buttonBackgroundColor,
      0.2,
    );
    document.getElementById("subMenuHover-" + itemChildId).style.borderRadius = "12px";
  };

  const handleSubMenuLeave = (itemChildId) => {
    document.getElementById("subMenuHover-" + itemChildId).style.backgroundColor =
      itemChildId === selectedSubMenuId ? groupColorConfig.buttonBackgroundColor : "";
    document.getElementById("subMenuHover-" + itemChildId).style.borderRadius = "12px";
  };

  const changeVisiblePopover = () => {
    if (!clicked) {
      setIsCalculate(false);
    }
    setClicked(!clicked);
  };

  const onLogout = async () => {
    try {
      await firebase.auth().signOut();
      await Promise.all([
        store.dispatch(setUserInfo({})),
        store.dispatch(notificationActions.resetNotifications()),
        setMoreProfileInfo(false),
        removeStorage(localStorageKeys.LOGIN),
        removeStorage(localStorageKeys.TOKEN),
        removeStorage(localStorageKeys.CUSTOMER_INFO),
      ]);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  // Handle Render Header Component
  const contentRef = useRef(null);

  useEffect(() => {
    if (window.innerWidth > 1200) {
      const listWrapperMenuElement = document.getElementById("nav-menu");
      if (listWrapperMenuElement) {
        listWrapperMenuElement.style.visibility = "hidden";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById("nav-menu")]);

  useEffect(() => {
    if (calculateMaxMenuDone) {
      setTimeout(() => {
        const listWrapperMenuElement = document.getElementById("nav-menu");
        if (listWrapperMenuElement) {
          listWrapperMenuElement.style.visibility = "visible";
        }
      }, 20);
    }
  }, [calculateMaxMenuDone]);

  useEffect(() => {
    setCalculateMaxMenuDone(false);
    setTimeout(() => {
      const branchLogoElement = document.getElementById("id-brand-logo-header");
      const listWrapperMenuElement = isCustomize
        ? document.getElementById("nav-menu-customize")
        : document.getElementById("nav-menu");

      let maxItem = 0;

      if (branchLogoElement && listWrapperMenuElement) {
        const spacingBetweenLogoAndMenu = 24;
        let sumListMenuItem = 0;
        const startPointBranchLogo = branchLogoElement.getBoundingClientRect().left;
        const startPointListMenu = listWrapperMenuElement.getBoundingClientRect().left;
        for (let i = 0; i < headerMenus?.length; i++) {
          if (headerMenus[i]?.hyperlinkOption === Hyperlink?.RESERVATION && !isAllowReserveTable) {
            maxItem++;
            continue;
          }
          const menuItemElement = document.getElementById(`${PREFIX_MENU_ITEM_ID}${headerMenus[i]?.id}`);
          if (menuItemElement) {
            sumListMenuItem += menuItemElement.offsetWidth;
            if (sumListMenuItem + startPointListMenu + spacingBetweenLogoAndMenu > startPointBranchLogo) {
              break;
            }
            maxItem++;
          }
        }
      }
      setMaxMenuSupport(maxItem > 0 ? maxItem : 10);
      setCalculateMaxMenuDone(true);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerMenus, isAllowReserveTable]);

  useEffect(() => {
    setMenuHeader();
  }, [maxMenuSupport, headerConfig, setMenuHeader]);

  useEffect(() => {
    setActiveMenu(localStorage.getItem(localStorageKeys.ACTIVE_MENU));
  }, [activeMenu]);

  function handleMenuMobileClick(event) {
    event.target.classList.toggle("menu_arrow_rotate");
    event.target.classList.toggle("menu_arrow_rotate_reset");
    event.target.parentElement.nextSibling.classList.toggle("menu_arrow_hide");
  }

  function handleNavbarActive() {
    document.body.classList.toggle("disable-scroll");
    document.removeEventListener("touchmove", preventDefault, { passive: false });
  }

  function handleChangeMenuProfile() {
    setStorage(localStorageKeys.ACTIVE_MENU, null);
  }

  function preventDefault(e) {
    e.preventDefault();
  }

  const handleSubMenuClick = (subMenuId) => {
    // Call action creator selectSubMenu when clicking on the sub menu
    dispatch(setSelectedSubMenuId(subMenuId));
  };

  const handleSelectedMenuItem = (menu) => {
    setActiveMenu(menu);
    setStorage(localStorageKeys.ACTIVE_MENU, menu);
  };

  const handleClearSubMenuClick = () => {
    dispatch(setSelectedSubMenuId(""));
  };

  const removeScrollDisable = () => {
    document.getElementById("nav-mobile-input").click();
    document.body.classList.remove("disable-scroll");
  };

  useEffect(() => {
    const level2ItemsElement = document.querySelectorAll(".sub-nav-menu-header-theme2");
    if (!level2ItemsElement) return;
    const handleMouseEnter = (item) => {
      item.style.display = "block !important";
      const parentElement = item?.closest("li").querySelector(".sub-nav-menu-header-theme2-parent");
      const linkElement = parentElement?.querySelector("a");
      const subMenuHoverId = linkElement?.id;
      const regex = /subMenuHover-(.*)/;
      const splitSubMenuHoverId = subMenuHoverId?.match(regex);
      const subMenuId = splitSubMenuHoverId?.length < 1 ? "" : splitSubMenuHoverId[1];
      handleSubMenuEnter(subMenuId);
    };
    const handleMouseLeave = (item) => {
      item.style.display = "none";
    };
    level2ItemsElement?.forEach((item) => {
      item.addEventListener("mouseenter", () => handleMouseEnter(item));
      item.addEventListener("mouseleave", () => handleMouseLeave(item));
    });

    return () => {
      level2ItemsElement?.forEach((item) => {
        item.removeEventListener("mouseenter", () => handleMouseEnter(item));
        item.removeEventListener("mouseleave", () => handleMouseLeave(item));
      });
    };
  });

  function handleBackToHome() {
    if (!isNonEmptyArray(__menus)) return;
    const homeMenuItemId = __menus?.find((menu) => menu?.hyperlinkOption === HyperlinkType.HOME_PAGE)?.id;
    const firstMenuItemId = __menus[0]?.id;
    const initialMenuItemId = homeMenuItemId ? homeMenuItemId : firstMenuItemId;
    if (!initialMenuItemId) return;
    const menuItemActive = `${PREFIX_MENU_ITEM_ID}${initialMenuItemId}`;
    handleSelectedMenuItem(menuItemActive);
  }

  const ChangeLanguageMemoried = useMemo(
    () => (
      <ChangeLanguageDesktop
        isCustomize={isCustomize}
        fontFamily={fontFamily}
        overlayClassName="change-language-header-theme2"
      />
    ),
    [fontFamily],
  );

  return (
    <div
      style={{
        background:
          headerConfig?.backgroundType === 1
            ? headerConfig?.backgroundColor
            : "url(" + headerConfig?.backgroundImage + ") no-repeat top",
      }}
    >
      <div
        style={{
          background:
            headerConfig?.backgroundType === 1
              ? headerConfig?.backgroundColor
              : "url(" + headerConfig?.backgroundImage + ") no-repeat top",
        }}
        className={`navigation theme2-header header-theme2-new ${
          headerConfig?.scrollType !== ScrollHeaderType.FIXED ? "scroll-header-theme2" : "fixed-header-theme2"
        }`}
        id="header-theme2"
      >
        <div
          className={`page-container ${
            isCustomize ? "page-container-customize-header-theme2" : "page-container-header-theme2"
          }`}
          style={{
            maxWidth: isCustomize ? "70vw" : "1360px",
            position: "relative",
          }}
        >
          {isDevicesTablet && (
            <div className="delivery-address-tablet-theme2">
              <StyledDeliveryAddressSelector groupColorConfig={groupColorConfig}>
                <DeliveryAddressSelectorComponent groupColorConfig={groupColorConfig} isCustomize={isCustomize} />
              </StyledDeliveryAddressSelector>
            </div>
          )}
          <div className="brand" id="id-brand-logo-header">
            <Link to="/" onClick={handleBackToHome}>
              <Image preview={false} src={headerConfig?.logoUrl ?? logo?.url} alt="logo" fallback={DefaultLogo}></Image>
            </Link>
          </div>
          <nav>
            <div className="nav-mobile">
              <label id="nav-toggle" htmlFor="nav-mobile-input" className="nav-toggle">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2 3L18 3"
                    stroke={groupColorConfig?.titleColor ?? "white"}
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M2 9H10"
                    stroke={groupColorConfig?.titleColor ?? "white"}
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M2 16L14 16"
                    stroke={groupColorConfig?.titleColor ?? "white"}
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </label>
              <input
                hidden
                type="checkbox"
                className="nav-input-header"
                id="nav-mobile-input"
                onClick={handleNavbarActive}
              />
              <label htmlFor="nav-mobile-input" className="overlay"></label>
              <div className="nav-menu-mobile">
                {moreProfileInfo ? (
                  <>
                    <div className="profile-mobile profile-mobile-top">
                      <ArrowDownIcon className="menu_arrow_rotate_left" onClick={() => setMoreProfileInfo(false)} />
                      <img
                        src={JSON.parse(getStorage(localStorageKeys.CUSTOMER_INFO))?.thumbnail ?? profileCricle}
                        alt=""
                        height="24"
                        width="24"
                      ></img>
                      <span>
                        <div>{decoded?.FULL_NAME}</div>
                      </span>
                    </div>
                    <ul>
                      <li>
                        <a href={`/my-profile/${profileTab.myAccount}`}>Personal Profile</a>
                      </li>
                      <li>
                        <a href={`/my-profile/${profileTab.myAddress}`}>Address & Payment</a>
                      </li>
                      <li>
                        <a href={`/my-profile/${profileTab.myOrder}`}>Order History</a>
                      </li>
                      <li>
                        <a>Booking History</a>
                      </li>
                      <li>
                        <a>Wish List</a>
                      </li>
                      <li>
                        <a onClick={onLogout}>Sign Out</a>
                      </li>
                    </ul>
                  </>
                ) : (
                  <>
                    <label>
                      <img className="close-nav-bar" src={closeNavbarIcon} alt="Close" onClick={removeScrollDisable} />
                    </label>

                    <img
                      className="brand-mobile"
                      src={headerConfig?.logoUrl ?? logo?.url}
                      width="156"
                      height="138"
                      alt="logo"
                    />

                    <ul id="nav-menu-mobile-list">
                      {headerMenus?.map((item, index) => {
                        return (
                          <li key={index} className={isShowLanguageOnMobile ? "d-none" : "d-block"}>
                            {item?.subMenu ? (
                              <a>
                                {truncateText(t(item?.name), 20)}
                                <ArrowDownIcon
                                  className="arrow-right-mobile menu_arrow_rotate_reset"
                                  onClick={handleMenuMobileClick}
                                />
                              </a>
                            ) : (
                              <a href={`${handleHyperlinkValue(item?.hyperlinkOption, item?.url)}`}>
                                {truncateText(t(item?.name), 20)}
                              </a>
                            )}
                            {item?.children && (
                              <>
                                <ul className="sub-nav-menu-mobile">
                                  {item?.children
                                    .sort((a, b) => a?.position - b?.position)
                                    .map((itemSubMenu, indexSubMenu) => {
                                      return (
                                        <li key={indexSubMenu}>
                                          <a
                                            href={`${handleHyperlinkValue(
                                              itemSubMenu?.hyperlinkOption,
                                              itemSubMenu?.url,
                                            )}`}
                                          >
                                            {truncateText(itemSubMenu?.name, 20)}
                                          </a>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                    <SelectLanguageContainer className="custom-language" />
                    <div
                      className="profile-mobile"
                      onClick={() => {
                        if (isLogin) {
                          setMoreProfileInfo(true);
                        }
                      }}
                    >
                      <img
                        src={JSON.parse(getStorage(localStorageKeys.CUSTOMER_INFO))?.thumbnail ?? profileCricle}
                        alt=""
                      />

                      <div>
                        {isLogin ? (
                          decoded?.FULL_NAME
                        ) : (
                          <span
                            onClick={() => {
                              history.push("/login");
                              document.body.classList.remove("disable-scroll");
                            }}
                          >
                            Sign In/ Sign Up
                          </span>
                        )}
                      </div>

                      {isLogin && (
                        <ArrowDownIcon
                          className="arrow-right-mobile menu_arrow_rotate"
                          onClick={() => setMoreProfileInfo(true)}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <ul
              className="nav-list"
              ref={contentRef}
              id={isCustomize ? "nav-menu-customize" : "nav-menu"}
              style={isCustomize ? { visibility: calculateMaxMenuDone ? "visible" : "hidden" } : {}}
            >
              {__menus?.map((item, index) => {
                const className = "active-item-menu-theme2";
                const selectedMenuItemClassName = activeMenu === `${PREFIX_MENU_ITEM_ID}${item?.id}` ? className : "";
                if (item?.hyperlinkOption == Hyperlink?.RESERVATION && isAllowReserveTable == false) {
                  return null;
                } else {
                  if (item?.children?.length > 0) {
                    return (
                      <li
                        key={index}
                        className="custom-sub-menu-header-item menu-header-trigger"
                        onClick={() => handleSelectedMenuItem(`${PREFIX_MENU_ITEM_ID}${item?.id}`)}
                        style={{ position: "relative" }}
                        id={`${PREFIX_MENU_ITEM_ID}${item?.id}`}
                      >
                        <Link
                          to={`${handleHyperlinkValue(item?.hyperlinkOption, item?.url)}`}
                          onMouseEnter={() => handleItemMenu(index)}
                          onMouseLeave={() => handleItemMenu(index)}
                        >
                          <div style={{ color: groupColorConfig?.titleColor }} className={selectedMenuItemClassName}>
                            {truncateText(t(item?.name), 20)}
                          </div>
                          <svg
                            id={`menuArrowCollapse-${index}`}
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.9465 5.45312H7.79316H4.05317C3.41317 5.45312 3.09317 6.22646 3.5465 6.67979L6.99983 10.1331C7.55317 10.6865 8.45317 10.6865 9.0065 10.1331L10.3198 8.81979L12.4598 6.67979C12.9065 6.22646 12.5865 5.45312 11.9465 5.45312Z"
                              fill={groupColorConfig?.titleColor}
                            />
                          </svg>
                          <svg
                            id={`menuArrowExpand-${index}`}
                            className="menu_arrow_right"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.0535 10.5469L8.20684 10.5469L11.9468 10.5469C12.5868 10.5469 12.9068 9.77354 12.4535 9.32021L9.00017 5.86687C8.44684 5.31354 7.54683 5.31354 6.9935 5.86687L5.68017 7.18021L3.54017 9.32021C3.0935 9.77354 3.4135 10.5469 4.0535 10.5469Z"
                              fill={groupColorConfig?.titleColor}
                            />
                          </svg>
                        </Link>
                        <ul className="nav-dropdown">
                          <div className="scroll-border-radius">
                            {item?.children
                              ?.sort((a, b) => a.position - b.position)
                              .map((itemChild, index) => {
                                return (
                                  <li
                                    key={index}
                                    onClick={() => handleSelectedMenuItem(`${PREFIX_MENU_ITEM_ID}${itemChild?.id}`)}
                                  >
                                    <StyledSubMenuItem groupColorConfig={groupColorConfig}>
                                      <a
                                        href={`${handleHyperlinkValue(itemChild?.hyperlinkOption, itemChild?.url)}`}
                                        id={`subMenuHover-${itemChild?.id}`}
                                        className="subMenuHover"
                                        style={{
                                          borderRadius: "12px",
                                          backgroundColor:
                                            selectedSubMenuId === itemChild?.id
                                              ? groupColorConfig?.buttonBackgroundColor
                                              : "#FFFFFF",
                                        }}
                                        onClick={() => handleSubMenuClick(itemChild?.id)}
                                        onMouseEnter={() => handleSubMenuEnter(itemChild?.id)}
                                        onMouseLeave={() => handleSubMenuLeave(itemChild?.id)}
                                      >
                                        <img alt="menu_point" className="menu_point" src={menuPointUrl} />
                                        <div
                                          style={
                                            selectedSubMenuId === itemChild?.id
                                              ? {
                                                  color: groupColorConfig?.buttonTextColor,
                                                  opacity: 0.8,
                                                }
                                              : { color: "#282828", opacity: 1 }
                                          }
                                          className="child-menu-item-header"
                                        >
                                          {truncateText(t(itemChild?.name), 20)}
                                        </div>
                                      </a>
                                    </StyledSubMenuItem>
                                  </li>
                                );
                              })}
                          </div>
                        </ul>
                      </li>
                    );
                  } else {
                    return (
                      <li
                        key={index}
                        className="custom-menu-header-item menu-header-trigger"
                        onClick={() => handleSelectedMenuItem(`${PREFIX_MENU_ITEM_ID}${item?.id}`)}
                        id={`${PREFIX_MENU_ITEM_ID}${item?.id}`}
                      >
                        <a
                          onClick={() => handleClearSubMenuClick()}
                          href={`${handleHyperlinkValue(item?.hyperlinkOption, item?.url)}`}
                        >
                          <div style={{ color: groupColorConfig?.titleColor }} className={selectedMenuItemClassName}>
                            {truncateText(t(item?.name), 20)}
                          </div>
                        </a>
                      </li>
                    );
                  }
                }
              })}

              {__menus?.length >= maxMenuSupport && moreMenuItems?.length > 0 && (
                <>
                  <li key="999" style={{ marginLeft: "-12px", position: "relative" }}>
                    <button
                      href="#!"
                      className="btn-view-more"
                      onMouseEnter={() => handleItemMenu(999)}
                      onMouseLeave={() => handleItemMenu(999)}
                    >
                      <div style={{ color: groupColorConfig?.titleColor }}>
                        <StyledMenuItems groupColorConfig={groupColorConfig}>
                          <span className="view-more-icon">
                            <HeaderViewMoreMenuIcon />
                          </span>
                        </StyledMenuItems>
                      </div>
                    </button>
                    <ul className="nav-dropdown nav-dropdown-header-theme2">
                      <div className="scroll-border-radius">
                        {moreMenuItems?.map((itemChild, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() => handleSelectedMenuItem(`${PREFIX_MENU_ITEM_ID}${itemChild?.id}`)}
                              className="nav-menu-li-dropdown-children"
                              style={{ position: "relative" }}
                            >
                              <StyledSubMenuItem
                                groupColorConfig={groupColorConfig}
                                className={`submenu-item-parent-them2 ${
                                  itemChild?.children?.length > 0 ? "sub-nav-menu-header-theme2-parent" : ""
                                }`}
                              >
                                <a
                                  href={`${handleHyperlinkValue(itemChild?.hyperlinkOption, itemChild?.url)}`}
                                  id={`subMenuHover-${itemChild?.id}`}
                                  className="subMenuHover"
                                  style={{
                                    borderRadius: "12px",
                                    backgroundColor:
                                      selectedSubMenuId === itemChild?.id
                                        ? groupColorConfig?.buttonBackgroundColor
                                        : "#FFFFFF",
                                  }}
                                  onClick={() => handleSubMenuClick(itemChild?.id)}
                                  onMouseEnter={() => handleSubMenuEnter(itemChild?.id)}
                                  onMouseLeave={() => handleSubMenuLeave(itemChild?.id)}
                                >
                                  <img alt="menu_point" className="menu_point" src={menuPointUrl} />
                                  <div
                                    style={
                                      selectedSubMenuId === itemChild?.id
                                        ? {
                                            color: groupColorConfig?.buttonTextColor,
                                            opacity: 0.8,
                                          }
                                        : { color: "#282828", opacity: 1 }
                                    }
                                    className="sub-more-menu-items-theme2"
                                  >
                                    {truncateText(t(itemChild?.name), 20)}
                                  </div>
                                </a>
                              </StyledSubMenuItem>

                              {itemChild?.children?.length > 0 && (
                                <ul className="sub-nav-menu-header-theme2">
                                  {itemChild?.children
                                    ?.sort((a, b) => a.position - b.position)
                                    .map((itemChildSubMenu, index) => {
                                      return (
                                        <li
                                          key={index}
                                          onClick={() =>
                                            handleSelectedMenuItem(`${PREFIX_MENU_ITEM_ID}${itemChildSubMenu?.id}`)
                                          }
                                        >
                                          <StyledSubMenuItem groupColorConfig={groupColorConfig}>
                                            <a
                                              href={`${handleHyperlinkValue(
                                                itemChildSubMenu?.hyperlinkOption,
                                                itemChildSubMenu?.url,
                                              )}`}
                                              id={`subMenuHover-${itemChildSubMenu?.id}`}
                                              className="subMenuHover"
                                              style={{
                                                borderRadius: "12px",
                                                backgroundColor:
                                                  selectedSubMenuId === itemChildSubMenu?.id
                                                    ? groupColorConfig?.buttonBackgroundColor
                                                    : "#FFFFFF",
                                              }}
                                              onClick={() => handleSubMenuClick(itemChildSubMenu?.id)}
                                              onMouseEnter={() => handleSubMenuEnter(itemChildSubMenu?.id)}
                                              onMouseLeave={() => handleSubMenuLeave(itemChildSubMenu?.id)}
                                            >
                                              <img alt="menu_point" className="menu_point" src={menuPointUrl} />
                                              <div
                                                style={
                                                  selectedSubMenuId === itemChildSubMenu?.id
                                                    ? {
                                                        color: groupColorConfig?.buttonTextColor,
                                                        opacity: 0.8,
                                                      }
                                                    : { color: "#282828", opacity: 1 }
                                                }
                                                className="child-menu-item-header"
                                              >
                                                {truncateText(t(itemChildSubMenu?.name), 20)}
                                              </div>
                                            </a>
                                          </StyledSubMenuItem>
                                        </li>
                                      );
                                    })}
                                </ul>
                              )}
                            </li>
                          );
                        })}
                      </div>
                    </ul>
                  </li>
                </>
              )}
            </ul>
          </nav>
          <div className="nav-top-right" id="nav-top-right-id">
            {!isMaxWidth600 && !isDevicesTablet && (
              <StyledDeliveryAddressSelector groupColorConfig={groupColorConfig}>
                <DeliveryAddressSelectorComponent groupColorConfig={groupColorConfig} isCustomize={isCustomize} />
              </StyledDeliveryAddressSelector>
            )}

            {!isMaxWidth640 && (
              <div
                className={`header-language ${
                  isCustomize ? "header-language-customize-theme2" : "header-language-theme2"
                }`}
              >
                <StyledLanguage groupColorConfig={groupColorConfig}>{ChangeLanguageMemoried}</StyledLanguage>
              </div>
            )}

            <FnbNotification groupColorConfig={groupColorConfig} />

            <Popover
              placement="bottom"
              showArrow={false}
              trigger="click"
              content={
                <StyledPopoverLoginHeader groupColorConfig={groupColorConfig}>
                  {isLogin ? (
                    <UserIcon
                      isLogin={isLogin}
                      translateData={pageData}
                      groupColorConfig={groupColorConfig}
                      profileTab={profileTab}
                      isAllowReserveTable={isAllowReserveTable}
                      onLogout={() => {
                        onLogout();
                      }}
                    />
                  ) : (
                    <LoginPopover isAllowReserveTable={isAllowReserveTable} groupColorConfig={groupColorConfig ?? {}} />
                  )}
                </StyledPopoverLoginHeader>
              }
              getPopupContainer={(trigger) => trigger.parentElement}
              overlayClassName="popover-header-theme2"
            >
              <a className="user-icon" href>
                <Badge color="#ffffff" style={{ color: "#000000" }}>
                  <div className="userNameLogin" onClick={handleChangeMenuProfile}>
                    <StyledIconStroke groupColorConfig={groupColorConfig}>
                      <span className="icon-stroke-color">
                        <UserIconUrl alt="cart-icon"></UserIconUrl>
                      </span>
                    </StyledIconStroke>
                  </div>
                </Badge>
              </a>
            </Popover>

            <CartComponent
              className="cart-header-theme-2-popover"
              groupColorConfig={groupColorConfig}
              content={(_props) => {
                const { open } = _props;
                return (
                  <ShoppingCartPage
                    open={open}
                    changeVisiblePopover={changeVisiblePopover}
                    isDefault={isDefault}
                    stateConfig={stateConfig}
                    isCalculate={isCalculate}
                    setIsCalculate={setIsCalculate}
                    isRefresh={isRefreshShowCart}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>

      {!isCustomize && isMaxWidth600 && (
        <div
          className={`delivery-address-selector-mobile-mode ${
            headerConfig?.scrollType !== ScrollHeaderType.FIXED
              ? "scroll-header-delivery-theme2"
              : "fixed-header-delivery-theme2"
          }`}
          style={{
            background:
              headerConfig?.backgroundType === 1
                ? headerConfig?.backgroundColor
                : "url(" + headerConfig?.backgroundImage + ") no-repeat top",
          }}
          id="header-mobile-theme2"
        >
          <StyledDeliveryAddressSelector groupColorConfig={groupColorConfig}>
            <DeliveryAddressSelectorComponent groupColorConfig={groupColorConfig} isCustomize={isCustomize} />
          </StyledDeliveryAddressSelector>
        </div>
      )}
    </div>
  );
}

export default Theme2OriginalHeader;
