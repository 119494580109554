import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { paymentVisaStatus } from "../../../constants/visa-constants";

const PaymentStatusPage = () => {
  const [countDown, setCountDown] = useState(3);
  const [t] = useTranslation();
  const [status, setStatus] = useState("");

  useEffect(() => {
    checkPayment();
  }, []);

  const translateData = {
    paymentSuccess: t("checkOutPage.payment.paymentSuccessful"),
    paymentFailed: t("checkOutPage.payment.paymentFailed"),
    pagePaymentNotify: t("checkOutPage.payment.pagePaymentNotify"),
    pageCloseInSeconds: t("checkOutPage.payment.pageCloseInSeconds"),
  };

  const checkPayment = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const resultCode = urlParams?.get("resultCode");
    const partnerCode = urlParams?.get("partnerCode");
    const orderId = urlParams?.get("orderId");
    const amount = urlParams?.get("amount");

    const executeRequestModel = {
      resultCode: resultCode ?? "",
      partnerCode: partnerCode ?? "",
      orderId: orderId ?? "",
      amount: amount ?? "",
    };
    executePayment(executeRequestModel);
  };

  const executePayment = async (data) => {
    try {
      if (data?.resultCode == paymentVisaStatus?.successPayment) {
        setStatus(true);
        setInterval(() => {
          setCountDown((prevCount) => prevCount - 1);
        }, 1000);
      } else {
        setStatus(false);
        setInterval(() => {
          setCountDown((prevCount) => prevCount - 1);
        }, 1000);
      }
    } catch (ex) {
      console.log("melog ex: ", ex);
    }
  };

  useEffect(() => {
    if (countDown === 0) {
      window.close();
    }
  }, [countDown]);

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1 }}>
      {status !== "" ? (
        <div
          style={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1, flexDirection: "column" }}
        >
          <h1 style={{ color: status ? "green" : "red" }}>
            {status ? translateData.paymentSuccess : translateData.paymentFailed}
          </h1>
          <div>
            {translateData.pageCloseInSeconds}, {countDown}
          </div>
        </div>
      ) : (
        <>{translateData.pagePaymentNotify}</>
      )}
    </div>
  );
};

export default PaymentStatusPage;
