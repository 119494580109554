import { Col, ConfigProvider, Radio, Row } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setDeliveryAddress } from "../../../modules/session/session.actions";
import { getInitialSelectedBranchInfo } from "../../../utils/helpers";
import { IconLocationCustomize } from "../../assets/icons/LocationIcon";
import "./store-branch-address-selector.component.scss";

export const StoreBranchAddressSelector = forwardRef((props, ref) => {
  const [t] = useTranslation();
  const { onSelected, initStoreBranchData, customerAddress } = props;
  const nearestStoreBranches = useSelector((state) => state?.session?.nearestStoreBranches);
  const [storeBranchAddresses, setStoreBranchAddresses] = useState([]);
  const themeConfigReduxState = useSelector((state) => state?.session?.themeConfig);
  const config = themeConfigReduxState;
  const colorGroupFirstDefault = config?.general?.color?.colorGroups[0];
  const deliveryAddress = useSelector((state) => state?.session?.deliveryAddress);
  const dispatch = useDispatch();
  const translateData = {
    storeBranch: t("storeWebPage.profilePage.storeBranch", "Chi nhánh cửa hàng"),
    toTickup: t("checkOutPage.toPickup", "Tự lấy hàng"),
    delivery: t("checkOutPage.delivery", "Giao hàng"),
    defaultBranch: t("storeBranch.defaultBranch", "Chi nhánh mặc định"),
    nearestBranch: t("storeBranch.nearestBranch", "Chi nhánh gần nhất"),
    otherBranch: t("storeBranch.otherBranch", "Chi nhánh khác"),
    deliveryFrom: t("storeWebPage.profilePage.deliveryFrom", "Được giao từ"),
  };

  useEffect(() => {
    let timeoutId;
    const callFetchData = () => {
      if (nearestStoreBranches) {
        getStoreBranchAddresses();
      }
    };
    timeoutId = setTimeout(() => {
      callFetchData();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [nearestStoreBranches]);

  useImperativeHandle(ref, () => ({
    setAutoPickStoreBranchAddress(branches) {
      autoPickStoreBranchAddress(branches);
    },
  }));

  const autoPickStoreBranchAddress = (nearestStoreBranches) => {
    if (!nearestStoreBranches || nearestStoreBranches?.length === 0) {
      return;
    }
    const defaultStoreBranchAddressInfo = getInitialSelectedBranchInfo(nearestStoreBranches);
    onSelectBranchAddress(defaultStoreBranchAddressInfo);
  };

  const onSelectBranchAddress = (storeBranch, isSelectNewBranch) => {
    if (onSelected) onSelected(storeBranch, isSelectNewBranch);
  };

  const getStoreBranchAddresses = () => {
    // TODO: mapping nearestStoreBranches => storeBranchAddresses and update storeBranchAddresses
    const storeBranchAddresses = nearestStoreBranches?.map((item) => {
      const convertDistanceToKm = item?.distance / 1000;
      const distance = convertDistanceToKm?.toFixed(1)?.toString()?.replace(".", ",") + " km";

      return {
        id: item?.branchId,
        title: item?.branchName,
        addressDetail: item?.branchAddress,
        distance: distance,
        lat: item?.lat,
        lng: item?.lng,
        isDefault: item?.isDefault,
      };
    });

    setStoreBranchAddresses(storeBranchAddresses);
    ///If delete selected branch in admin, auto select first branch
    const selectedBranch = storeBranchAddresses?.find((x) => x.id === initStoreBranchData?.id);
    if (!selectedBranch && storeBranchAddresses?.[0]) {
      onSelectBranchAddress(storeBranchAddresses?.[0] ?? null);
    }
  };

  const StoreBranchDetail = (props) => {
    const { position, storeBranchName, distance, addressDetail } = props;
    return (
      <Row className="store-branch-address-item">
        <Col span={24} className="d-flex">
          <div className="address-icon">
            <IconLocationCustomize color={colorGroupFirstDefault?.buttonBackgroundColor} />
            <span className="outline">
              <span className="address-index">{position + 1}</span>
            </span>
          </div>
          <div className="address-detail">
            <p className="address-label">{storeBranchName}</p>
            <p className="address">{addressDetail}</p>
            <p className="distance">{distance}</p>
          </div>
        </Col>
      </Row>
    );
  };

  const sortedStoreBranchAddresses = storeBranchAddresses?.slice();
  const initialSelectedValue = initStoreBranchData?.id;

  if (initialSelectedValue) {
    const selectedAddressIndex = sortedStoreBranchAddresses?.findIndex(
      (storeBranch) => storeBranch.id === initialSelectedValue,
    );

    if (selectedAddressIndex > -1) {
      const selectedAddress = sortedStoreBranchAddresses[selectedAddressIndex];
      sortedStoreBranchAddresses.splice(selectedAddressIndex, 1);
      sortedStoreBranchAddresses.unshift(selectedAddress);
    }
  }

  sortedStoreBranchAddresses?.sort((a, b) => {
    if (a.id === initialSelectedValue) {
      return -1;
    } else if (b.id === initialSelectedValue) {
      return 1;
    } else {
      return a.distance - b.distance;
    }
  });

  const StyledStoreBranchAddressSection = styled.div`
    border-radius: 0 0 20px 20px;
    .store-branch-addresses-parent {
      .store-branch-addresses {
        .ant-radio-wrapper-checked {
          .ant-radio-checked {
            .ant-radio-inner {
              border-color: ${colorGroupFirstDefault?.buttonBorderColor};
            }
            .ant-radio-inner::after {
              background-color: ${colorGroupFirstDefault?.buttonBackgroundColor};
            }
          }
        }
      }
    }

    .store-branch-address-item {
      .ant-col {
        .address-detail {
          .distance {
            color: ${colorGroupFirstDefault?.buttonBackgroundColor};
          }
          .address {
            color: #282828;
          }
        }
        .address-icon {
          .outline {
            background: ${colorGroupFirstDefault?.buttonBackgroundColor};
            .address-index {
              color: ${colorGroupFirstDefault?.buttonTextColor};
            }
          }
        }
      }
    }
    .store-branch-address-selector ::-webkit-scrollbar-thumb {
      background: ${colorGroupFirstDefault?.buttonBackgroundColor};
    }
  `;

  function saveOrderTypeToRedux(type) {
    const currentDeliveryAddress = {
      ...deliveryAddress,
      orderType: type,
    };
    dispatch(setDeliveryAddress(currentDeliveryAddress));
  }

  return (
    <div className="store-branch-address-parent">
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              colorBgElevated: colorGroupFirstDefault?.buttonBackgroundColor ?? "#db4d29",
            },
          },
        }}
      >
        <div className={"store-branch-address-selector"}>
          <StyledStoreBranchAddressSection>
            <div className="store-branch-addresses-parent">
              <Radio.Group
                value={initStoreBranchData?.id}
                defaultValue={initStoreBranchData?.id}
                className="store-branch-addresses"
              >
                {sortedStoreBranchAddresses?.map((storeBranch, index) => {
                  return (
                    <Radio key={index} value={storeBranch?.id} onClick={() => onSelectBranchAddress(storeBranch, true)}>
                      {(index === 0 || index === 1) && (
                        <div className="text-branch-info">
                          {index === 0
                            ? !customerAddress && storeBranch?.isDefault
                              ? translateData.defaultBranch
                              : translateData.deliveryFrom
                            : translateData.otherBranch}
                        </div>
                      )}
                      <StoreBranchDetail
                        position={index}
                        storeBranchName={storeBranch.title}
                        distance={storeBranch.distance}
                        addressDetail={storeBranch.addressDetail}
                      />
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
          </StyledStoreBranchAddressSection>
        </div>
      </ConfigProvider>
    </div>
  );
});
