import { useEffect, useState } from "react";
import { EnumActionTypeOrderHistoryLocal as actionType } from "../../../../../constants/enums";
import orderService from "../../../../../services/orders/order-service";
import { convertUtcToLocalTime, isNonEmptyArray } from "../../../../../utils/helpers";
import { getStorage, localStorageKeys } from "../../../../../utils/localStorage.helpers";
import { actionBgColor, actionIcon, actionName } from "../ActionButtons/ActionButton";
import HistoriesNotFound from "./HistoriesNotFound";
import HistoryCardItem from "./HistoryCardItem";
import "./HistoryCardItem.style.scss";

function OrderActionHistories(props) {
  const { open, fontFamily, setIsScrollHistoryNew } = props;
  const [groupedHistories, setGroupedHistories] = useState([]);
  const customerInfo = JSON.parse(getStorage(localStorageKeys.CUSTOMER_INFO));
  const historyContentId = "historyContentId";

  async function fetchData(customerInfo) {
    let _histories = [];
    if (customerInfo) {
      const orderActionHistories = await orderService.getOrderActionHistoriesDatabase(customerInfo?.customerId);
      const historiesDatabase = orderActionHistories?.map((i) => mappingDataOrderActionHistoriesToLocalStorage(i));
      _histories.push(...historiesDatabase);
    } else {
      const orderActionHistories = orderService.getOrderActionToHistoriesLocalStorage();
      const historiesLocalStorage = orderActionHistories?.map((i) => dataMapper(i)) || [];
      _histories.push(...historiesLocalStorage);
    }
    if (isNonEmptyArray(_histories)) {
      const sortedHistories = [..._histories].sort((a, b) => new Date(a.time) - new Date(b.time));
      const _groupedHistories = orderService.groupedOrderActionHistories(sortedHistories);
      setGroupedHistories(_groupedHistories);
    }
  }

  useEffect(() => {
    if (open) {
      fetchData(customerInfo);
    }
  }, [open]);

  useEffect(() => {
    // check to get to the latest history at the bottom
    if (isNonEmptyArray(groupedHistories)) {
      const historyElement = document.getElementById(historyContentId);
      if (historyElement != null) {
        if (historyElement?.scrollHeight > window?.innerHeight) {
          setIsScrollHistoryNew(true);
        } else {
          setIsScrollHistoryNew(false);
        }
      }
    }
  }, [groupedHistories]);

  const mappingDataOrderActionHistoriesToLocalStorage = (data) => {
    const _actionType = data?.action;
    const _actionName = actionName(_actionType);
    const _icon = actionIcon(_actionType);
    const _background = actionBgColor(_actionType);
    const _time = convertUtcToLocalTime(data?.actionDate);
    const _message =
      _actionType === actionType.CALL_WAITER
        ? data.content
        : _actionType === actionType.CALL_PAYMENT
        ? "order.calledPaymentSuccessfully"
        : "";
    const _orderInfo =
      _actionType === actionType.ORDER || _actionType === actionType.ADD_ITEM
        ? orderService.mappingOrderInfoHistories(data.orderItems)
        : [];
    return {
      ...data,
      actionName: _actionName,
      action: _actionType,
      icon: _icon,
      background: _background,
      time: _time,
      orderInfo: _orderInfo,
      message: _message,
      areaName: data?.areaTableName,
    };
  };

  const dataMapper = (data) => {
    const _actionName = actionName(data.action);
    const _icon = actionIcon(data.action);
    const _background = actionBgColor(data.action);
    const _time = data.time;
    const _message = data.action === actionType.ORDER || data.action === actionType.ADD_ITEM ? "" : data.content;
    const _orderInfo = data.action === actionType.ORDER || data.action === actionType.ADD_ITEM ? data.content : [];
    return {
      ...data,
      actionName: _actionName,
      icon: _icon,
      background: _background,
      time: _time,
      orderInfo: _orderInfo,
      message: _message,
    };
  };
  return (
    <div
      className={`history-content ${!isNonEmptyArray(groupedHistories) ? "history-box-not-found" : ""}`}
      style={{ fontFamily: fontFamily }}
      id={historyContentId}
    >
      {!isNonEmptyArray(groupedHistories) && <HistoriesNotFound />}
      {groupedHistories?.map((history, indexGroup) => {
        const { dataList, timeTitle, orderCode } = history;
        return (
          <div>
            <div className="history-title">
              {timeTitle}
              {orderCode && <span>&nbsp;{`- ${orderCode}`} </span>}
            </div>
            {dataList &&
              dataList?.map((data, index) => {
                return <HistoryCardItem key={index} data={data} />;
              })}
          </div>
        );
      })}
    </div>
  );
}
export default OrderActionHistories;
