import React from "react";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import "./fnb-flash-sale-banner-zero.component.scss";

export default function FnbFlashSaleBannerZeroComponent(props) {
  const { className } = props;
  return (
    <div className={className ? `_qWTcH flash-sale-banner-zero ${className}` : `_qWTcH flash-sale-banner-zero`}>
      <div className="_3cpN7">
        <div className="_2EBbg">
          <div className="_SKh-V">0</div>
          <div className="_106d2">0</div>
          <div className="_3luxx">
            <div className="_2v-d4 _1GZQ7">0</div>
            <div className="_2v-d4 _3AVXa">0</div>
          </div>
        </div>
        <div className="_2EBbg">
          <div className="_SKh-V">0</div>
          <div className="_106d2">0</div>
          <div className="_3luxx">
            <div className="_2v-d4 _1GZQ7">0</div>
            <div className="_2v-d4 _3AVXa">0</div>
          </div>
        </div>
      </div>
      <div className="_2hRXr"></div>
      <div className="_3cpN7">
        <div className="_2EBbg">
          <div className="_SKh-V">0</div>
          <div className="_106d2">0</div>
          <div className="_3luxx">
            <div className="_2v-d4 _1GZQ7">0</div>
            <div className="_2v-d4 _3AVXa">0</div>
          </div>
        </div>
        <div className="_2EBbg">
          <div className="_SKh-V">0</div>
          <div className="_106d2">0</div>
          <div className="_3luxx">
            <div className="_2v-d4 _1GZQ7">0</div>
            <div className="_2v-d4 _3AVXa">0</div>
          </div>
        </div>
      </div>
      <div className="_2hRXr"></div>
      <div className="_3cpN7">
        <div className="_2EBbg">
          <div className="_SKh-V">0</div>
          <div className="_106d2">0</div>
          <div className="_3luxx">
            <div className="_2v-d4 _1GZQ7">0</div>
            <div className="_2v-d4 _3AVXa">0</div>
          </div>
        </div>
        <div className="_2EBbg">
          <div className="_SKh-V">0</div>
          <div className="_106d2">0</div>
          <div className="_3luxx _2kgZC">
            <div className="_2v-d4 _1GZQ7">0</div>
            <div className="_2v-d4 _3AVXa">0</div>
          </div>
        </div>
      </div>
    </div>
  );
}
