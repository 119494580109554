import { ProductDetailTitleIcon } from "../../assets/icons.constants";
import ContactCustomize from "./ContactCustomize";

export const ContactCustomizePage = [
  {
    icon: <ProductDetailTitleIcon />,
    title: "contact.contactPage",
    isNormal: true,
    defaultActiveKey: 0,
    iconRight: null,
    collapsible: false,
    name: "contact",
    content: (props) => {
      return <ContactCustomize {...props} />;
    },
  },
];
