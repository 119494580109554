function EyeCloseOutline({ width = 25, height = 25, color = "#B8B8B8" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 25" fill="none">
      <g clipPath="url(#clip0_1133_4104)">
        <path
          d="M3.36883 16.2183C4.09617 16.9064 4.88855 17.5223 5.73483 18.0573L3.48783 20.3043C3.30567 20.4929 3.20488 20.7455 3.20716 21.0077C3.20943 21.2699 3.3146 21.5207 3.50001 21.7061C3.68542 21.8915 3.93623 21.9967 4.19843 21.999C4.46062 22.0013 4.71323 21.9005 4.90183 21.7183L11.3298 15.2903L21.9018 4.71831C21.9973 4.62606 22.0735 4.51572 22.1259 4.39372C22.1783 4.27171 22.2059 4.14049 22.2071 4.00771C22.2082 3.87493 22.1829 3.74325 22.1327 3.62036C22.0824 3.49746 22.0081 3.38581 21.9142 3.29192C21.8203 3.19802 21.7087 3.12377 21.5858 3.07349C21.4629 3.02321 21.3312 2.99791 21.1984 2.99906C21.0656 3.00022 20.9344 3.0278 20.8124 3.08021C20.6904 3.13262 20.5801 3.2088 20.4878 3.30431L18.1348 5.65731C17.0115 5.00053 15.7909 4.52629 14.5188 4.25231C13.7545 4.0926 12.9757 4.01184 12.1948 4.01131C4.69483 4.01131 0.50083 11.2113 0.32583 11.5163C0.24009 11.667 0.195007 11.8374 0.195007 12.0108C0.195007 12.1842 0.24009 12.3546 0.32583 12.5053C1.16345 13.8778 2.18756 15.1274 3.36883 16.2183ZM10.1948 12.0113C10.1948 11.4809 10.4055 10.9722 10.7806 10.5971C11.1557 10.222 11.6644 10.0113 12.1948 10.0113C12.6221 10.0127 13.0374 10.1531 13.3778 10.4113L10.5948 13.1943C10.3366 12.8539 10.1962 12.4386 10.1948 12.0113ZM12.1948 6.01131C12.8397 6.01185 13.4827 6.07887 14.1138 6.21131C15.0009 6.40176 15.8583 6.71088 16.6628 7.13031L14.8068 8.98531C14.0834 8.35415 13.1549 8.00793 12.1948 8.01131C11.134 8.01131 10.1165 8.43274 9.3664 9.18288C8.61626 9.93303 8.19483 10.9504 8.19483 12.0113C8.19145 12.9714 8.53767 13.8999 9.16883 14.6233L7.18883 16.6033C6.30274 16.0858 5.47987 15.467 4.73683 14.7593C3.85274 13.9331 3.06292 13.0115 2.38183 12.0113C3.41383 10.4943 6.91083 6.01131 12.1948 6.01131Z"
          fill={color}
        />
        <path
          d="M12.1948 18.0112C12.1118 18.0112 12.0328 18.0112 11.9528 18.0052C11.691 17.9861 11.4321 18.0708 11.2322 18.2409C11.0323 18.4111 10.9072 18.653 10.8841 18.9145C10.861 19.176 10.9417 19.4361 11.1087 19.6387C11.2757 19.8413 11.5157 19.97 11.7768 19.9972C11.9168 20.0102 12.0548 20.0112 12.1948 20.0112C19.6778 20.0112 23.8868 12.8112 24.0628 12.5112C24.1493 12.36 24.1948 12.1888 24.1948 12.0147C24.1948 11.8405 24.1493 11.6693 24.0628 11.5182C23.4872 10.5703 22.8171 9.68308 22.0628 8.87016C21.8799 8.69546 21.6376 8.59655 21.3847 8.59334C21.1319 8.59014 20.8871 8.68288 20.6999 8.85289C20.5126 9.0229 20.3967 9.25755 20.3756 9.50958C20.3544 9.76161 20.4295 10.0123 20.5858 10.2112C21.1008 10.7774 21.5756 11.3788 22.0068 12.0112C20.9698 13.5302 17.4638 18.0112 12.1948 18.0112Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1133_4104">
          <rect width="24" height="24" fill="white" transform="translate(0.194824 0.0112305)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EyeCloseOutline;
