import { theme2ElementCustomize } from "../../../constants/store-web-page.constants";
import "./checkout.header.scss";
export default function CheckOutHeader(props) {
  const { configuration, colorGroups, clickToFocusCustomize } = props;
  const title = configuration?.title ?? "Checkout Order";
  const colorGroup = colorGroups?.find((c) => c.id === configuration?.colorGroupId);

  return (
    <>
      <div
        id="themeHeaderCheckout"
        onClick={() => {
          if (clickToFocusCustomize) clickToFocusCustomize(theme2ElementCustomize.HeaderCheckout);
        }}
      >
        <div className="check_out_header_theme2">
          <div className="header_title" style={{ color: colorGroup?.titleColor }}>
            {title}
          </div>
        </div>
      </div>
    </>
  );
}
