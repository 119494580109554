import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import historyIcon from "../../../../assets/icons/history-icon.svg";
import { useSearchParams } from "../../../../hooks";
import "./HistoryCardItem.style.scss";

function HistoriesNotFound(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useSearchParams();

  const handleOpenProductList = () => {
    history.push("/pos?" + query.toString()); // redirect to product list
  };

  return (
    <div className="history-content-not-found">
      <div className="content-center img-not-found">
        <img src={historyIcon} alt="" />
      </div>

      <div className="content-center text-not-found">{t("order.historyNotFound")}</div>
      <Button className="button-let-order" onClick={handleOpenProductList}>
        {t("order.letOrder")}
      </Button>
    </div>
  );
}
export default HistoriesNotFound;
