import { useTranslation } from "react-i18next";
import { EmptyOrderPosIcon, EmptyReceiptIcon } from "../../../../assets/icons.constants";
import { tabName } from "../TabsStatusOrder/TabsStatusOrder";
import "./EmptyOrder.scss";

const EmptyOrder = (props) => {
  const { statusTabsOrder, onClickEmptyOrderBtn, isDisabledAddItemBtn = false } = props;
  const { t } = useTranslation();

  return (
    <div className="empty-order">
      {statusTabsOrder === tabName.RECEIPT ? (
        <div className="empty-order-icon empty-order-icon-receipt">
          <EmptyReceiptIcon />
        </div>
      ) : (
        <>
          <div className="empty-order-icon">
            <EmptyOrderPosIcon />
          </div>
          <div className="empty-order-text">{t("orderDetail.noItem", "There is no item yet. Let's order!")}</div>
          <div
            className={`empty-order-btn ${isDisabledAddItemBtn ? "disable-add-item-btn" : ""}`}
            onClick={() => {
              if (onClickEmptyOrderBtn) onClickEmptyOrderBtn();
            }}
          >
            {statusTabsOrder === tabName.ORDERING
              ? t("orderDetail.addItemTitle", "Add item")
              : t("orderDetail.addItemOfEmptyOrder", "Create order")}
          </div>
        </>
      )}
    </div>
  );
};

export default EmptyOrder;
