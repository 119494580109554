import actionTypes from "./seo.type";

const initialState = {
  seoData: {
    title: '',
    description: '',
    keywords: ''
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SEO_DATA:
      return { ...state, seoData: action.payload };
    default:
      return state;
  }
};

export default reducer;
