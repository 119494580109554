import { useMediaQuery } from "react-responsive";
import { GridViewIcon, ListViewIcon } from "../../../../assets/icons.constants";
import "./mode-view-product-list.scss";

function ModeViewProductList(props) {
  const { isGridView, onChangeGridView } = props;
  const isMobileDevice = useMediaQuery({ maxWidth: 575 });

  function onChange(isGridViewMode) {
    onChangeGridView && onChangeGridView(isGridViewMode);
  }

  if (!isMobileDevice) return <></>;

  return (
    <div className="mode-view-product-list" id="mode-view-product-list">
      <div className={`grid ${isGridView ? "active-view" : "no-active-view"}`} onClick={() => onChange(true)}>
        <GridViewIcon />
      </div>
      <div className={`list ${!isGridView ? "active-view" : "no-active-view"}`} onClick={() => onChange(false)}>
        <ListViewIcon />
      </div>
    </div>
  );
}

export default ModeViewProductList;
