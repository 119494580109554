import { Button } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { EnumActionTypeOrderHistoryLocal } from "../../../../../constants/enums";
import { qrOrderSelector } from "../../../../../modules/order/order.reducers";
import { useAppCtx } from "../../../../../providers/app.provider";
import orderService from "../../../../../services/orders/order-service";
import posCartService from "../../../../../services/pos/pos-cart.services";
import { formatTextCurrency } from "../../../../../utils/helpers";
import { useSearchParams } from "../../../../hooks";
import CashIcon from "../../../order/components/ActionButtons/CashIcon";
import { tabName } from "../TabsStatusOrder/TabsStatusOrder";
import "./CreateOrderButton.scss";

const CreateOrderButton = (props) => {
  const {
    statusTabsOrder = tabName.ORDERING,
    orderPOSId = "",
    price = 0,
    handleCreateOrder,
    handleOnClickAddItem,
    isDisabledAddItemBtn,
    isLoadingCreateOrder = false,
    posOrderDetail,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const reduxStoreInfo = useSelector(qrOrderSelector);
  const [qrCodeOrder, setQrCodeOrder] = useState();
  const [isLoadingCallToPay, setIsLoadingCallToPay] = useState(false);
  const { Toast } = useAppCtx();
  const query = useSearchParams();

  useEffect(() => {
    if (reduxStoreInfo) {
      setQrCodeOrder(reduxStoreInfo);
    }
  }, [reduxStoreInfo]);

  async function handleCallPayment() {
    setIsLoadingCallToPay(true);
    await orderService.sendNotify2CallPaymentAsync(qrCodeOrder, (success) => {
      if (success) {
        Toast.success({
          message: t("order.calledPaymentSuccessfully"),
          icon: <CashIcon width={"45px"} hideBg={true} style={{ marginTop: "-10px", marginRight: "5px" }} />,
        });

        // Save history
        posCartService.handleSaveOrderActionHistory(
          posOrderDetail,
          reduxStoreInfo,
          EnumActionTypeOrderHistoryLocal.CALL_PAYMENT,
          "order.calledPaymentSuccessfully",
        );
        setIsLoadingCallToPay(false);
        history.push("/action-page?" + query.toString());
      }
    });
  }

  return (
    <div className="button-create-order-and-payment">
      {!Boolean(orderPOSId) ? (
        <Button
          className="create-order-button"
          onClick={() => handleCreateOrder && handleCreateOrder()}
          loading={isLoadingCreateOrder}
          disabled={isLoadingCreateOrder}
        >
          {t("orderDetail.createOrder", { price: formatTextCurrency(price) }, "Create Order")}
        </Button>
      ) : statusTabsOrder === tabName.ORDERING ? (
        <Button
          className="create-order-button"
          onClick={() => handleOnClickAddItem && handleOnClickAddItem()}
          disabled={isDisabledAddItemBtn || isLoadingCreateOrder}
          loading={isLoadingCreateOrder}
        >
          {t("orderDetail.addItem", { price: formatTextCurrency(price) }, "Add Item")}
        </Button>
      ) : statusTabsOrder === tabName.ORDERED ? (
        <Button
          className="create-order-button create-order-button-ordered"
          onClick={handleCallPayment}
          loading={isLoadingCallToPay}
          disabled={isLoadingCallToPay}
        >
          <div className="call-to-pay-btn">{t("orderDetail.callToPay", "Call to pay")}</div>
        </Button>
      ) : (
        <Button className="create-order-button" onClick={() => handleCallPayment()}>
          {t("orderDetail.callToPay")}
        </Button>
      )}
    </div>
  );
};

export default CreateOrderButton;
