import { UKFlagIcon, VNFlagIcon } from "../../assets/icons.constants";

export const listDefaultLanguage = [
  {
    name: "language.vietnamese",
    emoji: "VN",
    isPublish: false,
    isDefault: true,
    languageCode: "vi",
    countryCode: "VN",
    flag: <VNFlagIcon />,
  },
  {
    name: "language.english",
    emoji: "GB",
    isPublish: false,
    isDefault: true,
    languageCode: "en",
    countryCode: "US",
    flag: <UKFlagIcon />,
  },
];
