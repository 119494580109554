import { createHttp } from "../utils/http-common";
const controller = "orderActionHistory";

const getOrderActionHistoriesAsync = (customerId) => {
  const http = createHttp();
  return http.get(`/${controller}?customerId=${customerId}`);
};

const CreateOrderActionHistoriesAsync = (data) => {
  const http = createHttp();
  return http.post(`/${controller}`, data);
};

const orderActionHistoryDataService = {
  getOrderActionHistoriesAsync,
  CreateOrderActionHistoriesAsync,
};

export default orderActionHistoryDataService;
