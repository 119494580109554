import { useTranslation } from "react-i18next";
import flashSaleLogo from "../../assets/images/flash-sale-tag-theme2.png";
import ImgDefault from "../../assets/images/product-default-img-none-radius.png";
import ImageWithFallback from "../fnb-image-with-fallback/fnb-image-with-fallback.component";
import "./DisplayImageComponent.scss";

export default function DisplayImageComponent(props) {
  //Add className prop to modify css of this component. fnb-display-image is default class if not set
  const {
    src,
    isPromotion,
    promotionTitle,
    isFlashSale,
    className = "fnb-display-image",
    onClick,
    isOutOfStock = false,
  } = props;

  const [t] = useTranslation();
  const translatedData = {
    outOfStock: t("storeWebPage.productDetailPage.labelOutOfStock", "outOfStock"),
  };

  function handleOnClick() {
    if (onClick) {
      onClick();
    }
  }

  return (
    <div className={className} onClick={handleOnClick}>
      <ImageWithFallback src={src} fallbackSrc={ImgDefault} className="display-image" />
      {isOutOfStock && (
        <div className="out-of-stock-badge">
          <span>{translatedData.outOfStock}</span>
        </div>
      )}
      {isPromotion && promotionTitle && !isOutOfStock && (
        <div className="promotion-label">
          <span>{promotionTitle}</span>
        </div>
      )}
      {isFlashSale && (
        <>
          <div className="flash-sale-border"></div>
          <div className="flash-sale">
            <img src={flashSaleLogo} className="flash-sale-logo" />
          </div>
        </>
      )}
    </div>
  );
}
