import { Modal } from "antd";
import styled from "styled-components";
import "./confirmation-dialog.component.scss";

function ConfirmationDialog(props) {
  const {
    confirmLoading,
    open,
    className,
    title,
    content,
    onCancel,
    onConfirm,
    cancelText,
    okText,
    footer,
    afterClose,
    closable,
    maskClosable,
    fontFamily,
    colorGroup,
  } = props;

  const StyledModal = styled(Modal)`
    .ant-modal-header {
      background-color: ${colorGroup?.buttonBackgroundColor} ${colorGroup ? "!important" : ""};
      color: ${colorGroup?.buttonTextColor} ${colorGroup ? "!important" : ""};
    }
  `;

  return (
    <div className={`confirmation-dialog${className ? ` ${className}` : ""}`}>
      {!colorGroup ? (
        <Modal
          className="confirmation-modal-theme2"
          title={<span style={{ fontFamily: fontFamily }}>{title ?? "Confirmation"}</span>}
          open={open}
          onOk={onConfirm}
          onCancel={onCancel}
          okText={<span style={{ fontFamily: fontFamily }}>{okText ?? "Confirm cancel"}</span>}
          cancelText={<span style={{ fontFamily: fontFamily }}>{cancelText ?? "Ignore"}</span>}
          closable={closable ?? false}
          confirmLoading={confirmLoading ?? false}
          wrapClassName={className}
          footer={footer}
          style={{ fontFamily: fontFamily }}
          afterClose={afterClose}
          maskClosable={maskClosable ?? true} //maskClosable default is true
        >
          <div className="confirmation-dialog-content">{content ?? "Do you really want to cancel this order?"}</div>
        </Modal>
      ) : (
        <StyledModal
          className="confirmation-modal-theme2"
          title={<span style={{ fontFamily: fontFamily }}>{title ?? "Confirmation"}</span>}
          open={open}
          onOk={onConfirm}
          onCancel={onCancel}
          okText={<span style={{ fontFamily: fontFamily }}>{okText ?? "Confirm cancel"}</span>}
          cancelText={<span style={{ fontFamily: fontFamily }}>{cancelText ?? "Ignore"}</span>}
          closable={closable ?? false}
          confirmLoading={confirmLoading ?? false}
          wrapClassName={className}
          footer={footer}
          style={{ fontFamily: fontFamily }}
          afterClose={afterClose}
          maskClosable={maskClosable ?? true} //maskClosable default is true
        >
          <div className="confirmation-dialog-content">{content ?? "Do you really want to cancel this order?"}</div>
        </StyledModal>
      )}
    </div>
  );
}

export default ConfirmationDialog;
