export const IconAddressDeliveryCustomize = (props) => {
  const { width = 44, height = 44, color } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} rx="22" fill="#F9F1DF" />
      <path
        d="M27.5374 30.3387C26.8736 30.8157 26.0767 31.0718 25.2594 31.0708C24.7126 31.0704 24.1718 30.9578 23.6703 30.74L23.2581 29.1566L22.4713 26.1247L22.3032 25.4739C22.3686 25.3325 22.4055 25.1795 22.4117 25.0237C22.413 24.8523 22.368 24.6837 22.2815 24.5357C22.2037 24.396 22.0938 24.277 21.9609 24.1883C21.828 24.0996 21.6758 24.0439 21.517 24.0258C21.376 24.0149 21.1862 24.0204 19.2445 24.0204C18.3814 24.0204 17.5533 23.6787 16.9415 23.0698C16.3296 22.461 15.9838 21.6347 15.9795 20.7716V19.6812L21.4248 20.2561L22.7265 20.3916L24.2183 20.5489C24.7745 20.608 25.2864 20.8796 25.6472 21.3071C26.0079 21.7346 26.1896 22.2849 26.1543 22.8431L25.8343 28.0119C25.8152 28.3246 25.864 28.6377 25.9772 28.9298C26.0905 29.2219 26.2656 29.486 26.4906 29.7041C26.6474 29.8594 26.8288 29.9876 27.0275 30.0837L27.5374 30.3387Z"
        fill="#E0925B"
      />
      <path
        d="M10.3337 29.677L9.53095 31.8194C9.20159 31.8552 8.87118 31.7618 8.60923 31.559H8.60381L8.50614 31.4774C8.38697 31.3649 8.29151 31.2296 8.22538 31.0797C8.15925 30.9297 8.1238 30.7681 8.12109 30.6042C8.12113 30.5371 8.12657 30.47 8.13735 30.4038C8.18347 30.1279 8.32278 29.8762 8.53203 29.6907C8.74128 29.5051 9.00783 29.3969 9.28722 29.3841C9.46281 29.3744 9.63846 29.4022 9.80245 29.4657L9.9923 29.5417L10.3337 29.677Z"
        fill="#FED673"
      />
      <path
        d="M16.9501 35.057C16.9487 35.3599 16.9049 35.6612 16.82 35.9519C16.6269 36.5846 16.2342 37.1378 15.7007 37.5289C15.1673 37.9201 14.5216 38.1281 13.8601 38.122C13.1986 38.1159 12.5569 37.896 12.0307 37.4951C11.5045 37.0942 11.1222 36.5338 10.9407 35.8977C10.8594 35.6249 10.8192 35.3416 10.8214 35.057C10.8204 34.78 10.8588 34.5043 10.9353 34.238C11.118 33.5791 11.5159 33.0002 12.0656 32.5935C12.6154 32.1868 13.2853 31.9757 13.9689 31.9937C14.6525 32.0118 15.3104 32.2581 15.8378 32.6933C16.3653 33.1285 16.732 33.7276 16.8796 34.3953C16.9254 34.6129 16.949 34.8346 16.9501 35.057Z"
        fill="#FED673"
      />
      <path
        d="M35.858 34.8675C35.8586 35.2383 35.7906 35.6061 35.6576 35.9522C35.4378 36.5341 35.0461 37.0351 34.5345 37.3888C34.0229 37.7424 33.4158 37.9319 32.7938 37.9319C32.1719 37.9319 31.5647 37.7424 31.0531 37.3888C30.5416 37.0351 30.1498 36.5341 29.9301 35.9522C29.7971 35.6061 29.7291 35.2383 29.7297 34.8675C29.7275 34.7037 29.742 34.5401 29.7731 34.3793C29.8879 33.6608 30.2552 33.0067 30.8089 32.5345C31.3625 32.0623 32.0664 31.803 32.7941 31.803C33.5217 31.803 34.2256 32.0623 34.7792 32.5345C35.3329 33.0067 35.7002 33.6608 35.8151 34.3793C35.8459 34.5402 35.8603 34.7037 35.858 34.8675Z"
        fill="#FED673"
      />
      <path
        d="M17.683 34.8674C17.683 35.8743 17.283 36.84 16.571 37.552C15.859 38.264 14.8933 38.664 13.8864 38.664C12.8795 38.664 11.9138 38.264 11.2018 37.552C10.4898 36.84 10.0898 35.8743 10.0898 34.8674C10.0898 34.3033 10.3936 34.0972 10.7355 34.1569C10.8081 34.1669 10.877 34.195 10.9359 34.2385C11.0241 34.296 11.0936 34.3779 11.136 34.4742C11.1784 34.5705 11.1918 34.6771 11.1746 34.7809V34.8677C11.1728 35.225 11.2415 35.5792 11.377 35.9098C11.5124 36.2405 11.7119 36.5411 11.9639 36.7944C12.2159 37.0478 12.5155 37.2488 12.8454 37.386C13.1753 37.5231 13.5291 37.5938 13.8865 37.5938C14.2438 37.5938 14.5976 37.5231 14.9275 37.386C15.2575 37.2488 15.5571 37.0478 15.8091 36.7944C16.0611 36.5411 16.2605 36.2405 16.396 35.9098C16.5314 35.5792 16.6002 35.225 16.5983 34.8677C16.5975 34.7707 16.6234 34.6754 16.6731 34.5922C16.7229 34.509 16.7946 34.441 16.8803 34.3958C16.9587 34.348 17.049 34.3236 17.1407 34.3253C17.2844 34.3257 17.422 34.383 17.5236 34.4846C17.6252 34.5861 17.6825 34.7237 17.683 34.8674Z"
        fill="#7C737D"
      />
      <path
        d="M36.5902 34.8675C36.5902 35.366 36.492 35.8597 36.3012 36.3204C36.1104 36.781 35.8308 37.1995 35.4782 37.5521C35.1257 37.9046 34.7072 38.1843 34.2465 38.3751C33.7859 38.5658 33.2922 38.6641 32.7937 38.6641C32.2951 38.6641 31.8014 38.5658 31.3408 38.3751C30.8801 38.1843 30.4616 37.9046 30.1091 37.5521C29.7565 37.1995 29.4769 36.781 29.2861 36.3204C29.0953 35.8597 28.9971 35.366 28.9971 34.8675C28.9975 34.7237 29.0548 34.586 29.1564 34.4844C29.258 34.3828 29.3957 34.3255 29.5395 34.3251C29.6202 34.3263 29.6997 34.3448 29.7727 34.3793C29.8655 34.4226 29.9439 34.4916 29.9987 34.5782C30.0535 34.6647 30.0823 34.7651 30.0819 34.8675C30.0823 35.2404 30.158 35.6093 30.3045 35.9522C30.5165 36.436 30.8649 36.8475 31.307 37.1365C31.7491 37.4254 32.2658 37.5793 32.794 37.5793C33.3222 37.5793 33.8389 37.4254 34.281 37.1365C34.7231 36.8475 35.0715 36.436 35.2835 35.9522C35.4299 35.6093 35.5057 35.2404 35.5061 34.8675C35.5056 34.7651 35.5345 34.6647 35.5893 34.5782C35.644 34.4916 35.7225 34.4226 35.8153 34.3793C35.8882 34.3448 35.9677 34.3263 36.0484 34.3251C36.1921 34.3257 36.3296 34.383 36.4311 34.4846C36.5326 34.5862 36.5898 34.7238 36.5902 34.8675Z"
        fill="#7C737D"
      />
      <path
        d="M16.8207 35.9522C16.6276 36.5849 16.2349 37.1381 15.7014 37.5292C15.168 37.9203 14.5223 38.1284 13.8608 38.1223C13.1993 38.1162 12.5576 37.8962 12.0314 37.4953C11.5052 37.0944 11.1229 36.5341 10.9414 35.8979C11.091 35.9308 11.2438 35.9472 11.397 35.9467C11.4366 35.9513 11.4765 35.9531 11.5164 35.9522H16.8207Z"
        fill="#FED673"
      />
      <path
        d="M35.6571 35.9523C35.4374 36.5341 35.0457 37.0352 34.5341 37.3888C34.0225 37.7425 33.4153 37.9319 32.7934 37.9319C32.1715 37.9319 31.5643 37.7425 31.0527 37.3888C30.5412 37.0352 30.1494 36.5341 29.9297 35.9523H35.6571Z"
        fill="#FED673"
      />
      <path
        d="M17.5254 35.9525C17.284 36.7609 16.7804 37.4659 16.094 37.9563C15.4075 38.4468 14.5774 38.6948 13.7344 38.6612C12.8914 38.6276 12.0836 38.3144 11.4384 37.7709C10.7932 37.2274 10.3472 36.4846 10.1709 35.6595C10.4152 35.7747 10.6745 35.855 10.9411 35.8982C11.0907 35.9311 11.2435 35.9474 11.3967 35.947C11.6076 36.4315 11.9553 36.844 12.3972 37.134C12.839 37.4239 13.3559 37.5786 13.8843 37.5792C14.4128 37.5798 14.93 37.4262 15.3725 37.1372C15.815 36.8483 16.1636 36.4366 16.3756 35.9525H17.5254Z"
        fill="#7C737D"
      />
      <path
        d="M36.4329 35.9523C36.1991 36.7358 35.7186 37.423 35.0629 37.9116C34.4072 38.4002 33.6113 38.6641 32.7936 38.6641C31.9759 38.6641 31.18 38.4002 30.5243 37.9116C29.8686 37.423 29.3881 36.7358 29.1543 35.9523H30.3039C30.5159 36.436 30.8642 36.8475 31.3063 37.1365C31.7485 37.4255 32.2652 37.5793 32.7934 37.5793C33.3215 37.5793 33.8383 37.4255 34.2804 37.1365C34.7225 36.8475 35.0708 36.436 35.2828 35.9523H36.4329Z"
        fill="#7C737D"
      />
      <path
        d="M11.3973 35.9467C11.2441 35.9471 11.0913 35.9308 10.9417 35.8979C10.675 35.8547 10.4158 35.7744 10.1715 35.6592C10.1167 35.3989 10.0893 35.1335 10.0899 34.8674C10.0899 34.3033 10.3936 34.0972 10.7356 34.1569C10.8081 34.1669 10.877 34.195 10.9359 34.2385C11.0241 34.296 11.0936 34.3779 11.136 34.4742C11.1784 34.5705 11.1918 34.6771 11.1746 34.7809V34.8677C11.1751 35.2387 11.2508 35.6058 11.3973 35.9467Z"
        fill="#6C646D"
      />
      <path
        d="M16.8802 34.3956C16.7944 34.4409 16.7227 34.5088 16.673 34.592C16.6232 34.6753 16.5973 34.7706 16.5981 34.8675C16.5977 35.2404 16.522 35.6094 16.3755 35.9523H11.5162C11.4764 35.9532 11.4365 35.9514 11.3969 35.9468C11.2504 35.6058 11.1747 35.2387 11.1742 34.8675V34.7808C11.1914 34.6769 11.178 34.5704 11.1356 34.4741C11.0932 34.3777 11.0237 34.2958 10.9355 34.2384C11.1183 33.5794 11.5161 33.0005 12.0659 32.5938C12.6156 32.1871 13.2856 31.976 13.9692 31.9941C14.6527 32.0122 15.3106 32.2584 15.8381 32.6936C16.3656 33.1288 16.7323 33.728 16.8799 34.3956H16.8802Z"
        fill="#FEC845"
      />
      <path
        d="M17.6831 34.8676C17.6837 35.2349 17.6307 35.6003 17.5258 35.9523H16.376C16.5225 35.6094 16.5982 35.2404 16.5986 34.8676C16.5978 34.7706 16.6237 34.6753 16.6735 34.5921C16.7232 34.5088 16.7949 34.4409 16.8807 34.3957C16.959 34.3479 17.0493 34.3234 17.141 34.3252C17.2847 34.3257 17.4223 34.383 17.5239 34.4846C17.6254 34.5862 17.6827 34.7239 17.6831 34.8676Z"
        fill="#6C646D"
      />
      <path
        d="M30.3039 35.9522H29.1543C29.0494 35.6002 28.9965 35.2348 28.9971 34.8675C28.9975 34.7237 29.0548 34.586 29.1564 34.4844C29.258 34.3828 29.3957 34.3255 29.5395 34.3251C29.6202 34.3263 29.6997 34.3448 29.7727 34.3793C29.8655 34.4226 29.9439 34.4916 29.9987 34.5782C30.0535 34.6647 30.0824 34.7651 30.0819 34.8675C30.0821 35.2403 30.1576 35.6093 30.3039 35.9522Z"
        fill="#6C646D"
      />
      <path
        d="M35.8141 34.3793C35.7213 34.4226 35.6429 34.4916 35.5881 34.5782C35.5333 34.6647 35.5045 34.7651 35.505 34.8675C35.5045 35.2404 35.4287 35.6093 35.2823 35.9522H30.3033C30.1569 35.6093 30.0811 35.2404 30.0807 34.8675C30.0812 34.7651 30.0523 34.6647 29.9975 34.5782C29.9427 34.4916 29.8643 34.4226 29.7715 34.3793C29.8864 33.6608 30.2536 33.0067 30.8073 32.5345C31.361 32.0623 32.0648 31.803 32.7925 31.803C33.5202 31.803 34.224 32.0623 34.7777 32.5345C35.3314 33.0067 35.6986 33.6608 35.8135 34.3793H35.8141Z"
        fill="#FEC845"
      />
      <path
        d="M36.5893 34.8675C36.5899 35.2348 36.5369 35.6002 36.432 35.9522H35.2822C35.4287 35.6093 35.5044 35.2404 35.5049 34.8675C35.5044 34.7651 35.5332 34.6647 35.588 34.5782C35.6428 34.4916 35.7212 34.4226 35.814 34.3793C35.887 34.3448 35.9665 34.3263 36.0472 34.3251C36.1909 34.3256 36.3285 34.3829 36.4301 34.4845C36.5316 34.5861 36.5889 34.7238 36.5893 34.8675Z"
        fill="#6C646D"
      />
      <path
        d="M27.5381 30.3387C26.8744 30.8157 26.0774 31.0718 25.2601 31.0709C24.7134 31.0704 24.1725 30.9578 23.671 30.7401L23.2588 29.1566C23.6739 29.5724 24.2083 29.8485 24.7876 29.9467C25.3669 30.0449 25.9624 29.9602 26.4913 29.7044C26.6481 29.8597 26.8296 29.988 27.0282 30.0841L27.5381 30.3387Z"
        fill="#DB7A35"
      />
      <path
        d="M36.59 34.8675H11.5164C11.1681 34.8675 10.825 34.7835 10.5161 34.6228C10.2072 34.462 9.94155 34.2291 9.74174 33.9439C9.54193 33.6587 9.41381 33.3295 9.36824 32.9843C9.32267 32.639 9.36099 32.2878 9.47996 31.9605L9.53124 31.8195L10.334 29.6771L11.1313 27.5456L11.5271 26.4826C12.1174 26.9939 12.8724 27.275 13.6533 27.2745H19.3915C20.1008 27.2753 20.7907 27.0428 21.3549 26.6128C21.3277 27.0281 21.3514 27.4452 21.4254 27.8548C21.9895 30.8324 25.2922 31.9443 27.5379 30.3389C28.0512 29.9792 28.4701 29.501 28.7591 28.9448C29.048 28.3886 29.1985 27.771 29.1978 27.1442C29.1978 26.7482 28.5307 20.1205 28.3409 18.0541C28.3192 17.7937 28.3029 17.6093 28.2975 17.5117H31.6493L32.7189 30.1843C32.7366 30.4141 32.828 30.6321 32.9796 30.8058C33.1312 30.9795 33.3349 31.0995 33.5602 31.1481C34.4164 31.3243 35.1854 31.7906 35.7374 32.4683C36.2895 33.1459 36.5906 33.9934 36.59 34.8675Z"
        fill="#F16069"
      />
      <path
        d="M31.6487 17.5117H28.2969C28.3022 17.6093 28.3185 17.7937 28.3402 18.0541C28.3674 18.3361 28.3996 18.7104 28.4432 19.1388H28.9965C29.1645 19.1381 29.3319 19.1182 29.4954 19.0794C29.7459 19.4333 30.0775 19.7219 30.4626 19.921C30.8476 20.1201 31.2748 20.224 31.7083 20.2238H31.8767L31.6487 17.5117Z"
        fill="#EF444E"
      />
      <path
        d="M30.0811 16.9695C30.0808 17.16 30.0303 17.3471 29.9345 17.5119C29.8878 17.594 29.8295 17.669 29.7613 17.7345C29.6619 17.8365 29.543 17.9173 29.4116 17.9723C29.2803 18.0273 29.1392 18.0553 28.9969 18.0546H22.8516C22.6673 18.4683 22.4515 18.8672 22.2059 19.2478C21.9775 19.6076 21.716 19.9453 21.4248 20.2566L15.9795 19.6817C15.9822 19.319 16.0203 18.9576 16.0933 18.6024C16.6737 15.8525 19.2879 13.2925 20.9747 11.8878C21.3001 11.6112 21.5875 11.3831 21.8262 11.2045C22.3099 11.6811 22.9073 12.0265 23.5618 12.2078C23.8725 12.5363 24.0734 12.9533 24.1367 13.401C24.2831 14.3231 23.9363 15.3318 23.5781 15.8851H28.9961C29.2835 15.8859 29.5589 16.0003 29.7622 16.2035C29.9655 16.4067 30.0801 16.6821 30.0811 16.9695Z"
        fill="#FED673"
      />
      <path
        d="M22.2059 19.2473C21.9775 19.6072 21.716 19.9449 21.4248 20.2561L15.9795 19.6812C15.9822 19.3186 16.0203 18.9571 16.0933 18.6019L22.2059 19.2473Z"
        fill="#FEC845"
      />
      <path
        d="M13.6157 9.10504C13.6157 6.10957 10.9446 3.41016 7.64967 3.41016C4.35473 3.41016 1.68359 6.10957 1.68359 9.10504C1.68359 12.1005 4.35473 14.7999 7.64967 14.7999C7.82111 14.7999 7.98974 14.7883 8.15755 14.774L10.3618 16.427V14.1454C12.2918 13.1675 13.6157 11.2117 13.6157 9.10504Z"
        fill="#F16069"
      />
      <path
        d="M9.24426 13.6879L10.3613 14.5286V16.4268L8.15948 14.7729C7.99137 14.7891 7.82319 14.8 7.64967 14.8C4.35206 14.8 1.68359 12.099 1.68359 9.10516C1.68359 7.38581 2.56227 5.76413 3.93984 4.69568C3.19231 5.64366 2.78043 6.81288 2.76875 8.02008C2.76875 11.014 5.43713 13.715 8.73475 13.715C8.90797 13.715 9.07608 13.7041 9.24426 13.6879Z"
        fill="#EF353F"
      />
      <path
        d="M7.6497 6.12195C9.5402 6.12195 10.9039 7.48757 10.9039 9.37622V11.0033C10.9038 11.0746 10.8897 11.1452 10.8623 11.2111C10.835 11.2769 10.7949 11.3367 10.7444 11.3871C10.6939 11.4375 10.634 11.4774 10.5681 11.5046C10.5022 11.5318 10.4316 11.5457 10.3602 11.5456H4.93909C4.86778 11.5457 4.79716 11.5318 4.73126 11.5046C4.66535 11.4773 4.60545 11.4374 4.55497 11.3871C4.50449 11.3367 4.46443 11.2769 4.43707 11.2111C4.40971 11.1452 4.39559 11.0746 4.39551 11.0033V9.37622C4.39551 7.48757 5.75913 6.12195 7.6497 6.12195Z"
        fill="#E69B6C"
      />
      <path
        d="M10.9388 10.4608C10.4026 10.4608 10.1144 10.271 9.88281 10.1184C9.70016 9.99794 9.57948 9.91845 9.29262 9.91845C9.00577 9.91845 8.88509 9.99794 8.70244 10.1184C8.47087 10.271 8.18291 10.4608 7.64645 10.4608C7.11 10.4608 6.82262 10.2707 6.59114 10.1182C6.40915 9.99794 6.28869 9.91845 6.0028 9.91845C5.71691 9.91845 5.59638 9.99794 5.4144 10.1182C5.18313 10.2707 4.89487 10.4608 4.3596 10.4608C4.09984 10.4608 3.88965 10.218 3.88965 9.91845C3.88965 9.6189 4.10006 9.37613 4.3596 9.37613C4.64549 9.37613 4.76573 9.29664 4.94771 9.17641C5.17898 9.02389 5.46724 8.83374 6.0028 8.83374C6.53837 8.83374 6.82663 9.02389 7.05782 9.17641C7.24003 9.29664 7.36027 9.37613 7.64645 9.37613C7.93264 9.37613 8.05399 9.29664 8.23664 9.17619C8.46813 9.02359 8.75617 8.83374 9.29262 8.83374C9.82908 8.83374 10.1171 9.02359 10.3486 9.17619C10.5313 9.29664 10.652 9.37613 10.9388 9.37613C11.1986 9.37613 11.4087 9.61897 11.4087 9.91845C11.4087 10.2179 11.1983 10.4608 10.9388 10.4608Z"
        fill="#85CCAB"
      />
      <path
        d="M7.6497 6.12195C9.5402 6.12195 10.9039 7.48757 10.9039 9.37622V11.0033C10.9038 11.0746 10.8897 11.1452 10.8623 11.2111C10.835 11.2769 10.7949 11.3367 10.7444 11.3871C10.6939 11.4375 10.634 11.4774 10.5681 11.5046C10.5022 11.5318 10.4316 11.5457 10.3602 11.5456H4.93909C4.86778 11.5457 4.79716 11.5318 4.73126 11.5046C4.66535 11.4773 4.60545 11.4374 4.55497 11.3871C4.50449 11.3367 4.46443 11.2769 4.43707 11.2111C4.40971 11.1452 4.39559 11.0746 4.39551 11.0033V9.37622C4.39551 7.48757 5.75913 6.12195 7.6497 6.12195Z"
        fill="#E69B6C"
      />
      <path
        d="M10.9039 11.0034C10.9035 11.1471 10.8462 11.2848 10.7446 11.3864C10.6429 11.488 10.5052 11.5453 10.3615 11.5457H4.93782C4.79412 11.5453 4.65642 11.488 4.5548 11.3864C4.45319 11.2848 4.39592 11.1471 4.39551 11.0034V9.3763C4.39551 8.2861 4.85106 7.36408 5.60527 6.78918C5.18223 7.3315 4.93812 8.03658 4.93812 8.83391C4.93887 8.94473 4.94795 9.05533 4.96529 9.16478C5.01769 9.52083 5.16159 9.85719 5.38291 10.141C5.44211 10.2201 5.50737 10.2946 5.57811 10.3636C5.77777 10.5665 6.01586 10.7276 6.27847 10.8375C6.54107 10.9474 6.82294 11.0039 7.10761 11.0036L10.9039 11.0034Z"
        fill="#E28044"
      />
      <path
        d="M10.9388 10.4608C10.4026 10.4608 10.1144 10.271 9.88281 10.1184C9.70016 9.99794 9.57948 9.91845 9.29262 9.91845C9.00577 9.91845 8.88509 9.99794 8.70244 10.1184C8.47087 10.271 8.18291 10.4608 7.64645 10.4608C7.11 10.4608 6.82262 10.2707 6.59114 10.1182C6.40915 9.99794 6.28869 9.91845 6.0028 9.91845C5.71691 9.91845 5.59638 9.99794 5.4144 10.1182C5.18313 10.2707 4.89487 10.4608 4.3596 10.4608C4.09984 10.4608 3.88965 10.218 3.88965 9.91845C3.88965 9.6189 4.10006 9.37613 4.3596 9.37613C4.64549 9.37613 4.76573 9.29664 4.94771 9.17641C5.17898 9.02389 5.46724 8.83374 6.0028 8.83374C6.53837 8.83374 6.82663 9.02389 7.05782 9.17641C7.24003 9.29664 7.36027 9.37613 7.64645 9.37613C7.93264 9.37613 8.05399 9.29664 8.23664 9.17619C8.46813 9.02359 8.75617 8.83374 9.29262 8.83374C9.82908 8.83374 10.1171 9.02359 10.3486 9.17619C10.5313 9.29664 10.652 9.37613 10.9388 9.37613C11.1986 9.37613 11.4087 9.61897 11.4087 9.91845C11.4087 10.2179 11.1983 10.4608 10.9388 10.4608Z"
        fill="#85CCAB"
      />
      <path
        d="M5.38212 10.1409C5.0932 10.3453 4.74891 10.457 4.39502 10.461H4.35702C4.10215 10.461 3.89062 10.2169 3.89062 9.91858C3.89062 9.6203 4.10215 9.37627 4.35702 9.37627H4.39502C4.6553 9.37077 4.77464 9.28943 4.94824 9.17587C4.95366 9.17046 4.95908 9.17046 4.9645 9.16504C5.01695 9.52099 5.16085 9.85724 5.38212 10.1409Z"
        fill="#63C397"
      />
      <path
        d="M21.425 27.8547C20.7996 28.1894 20.1007 28.3629 19.3914 28.3594H13.6529C12.7484 28.355 11.8674 28.0708 11.1309 27.5458L11.5267 26.4828C12.1171 26.994 12.872 27.2752 13.6529 27.2746H19.3911C20.1004 27.2755 20.7903 27.043 21.3545 26.613C21.3273 27.0282 21.351 27.4452 21.425 27.8547Z"
        fill="#EF444E"
      />
      <path
        d="M22.3037 25.4736C22.0329 26.0143 21.6172 26.469 21.1028 26.787C20.5885 27.105 19.9959 27.2737 19.3912 27.2742H13.653C13.0483 27.2734 12.4558 27.1046 11.9415 26.7866C11.4273 26.4687 11.0114 26.0141 10.7405 25.4736C10.6637 25.3208 10.6273 25.1509 10.6347 24.98C10.6421 24.8091 10.6931 24.643 10.7827 24.4974C10.8724 24.3517 10.9978 24.2314 11.147 24.1479C11.2962 24.0644 11.4643 24.0203 11.6353 24.02L21.5173 24.0254C21.678 24.0435 21.8321 24.1001 21.9663 24.1905C22.1005 24.2808 22.2109 24.4023 22.2881 24.5444C22.3653 24.6866 22.4071 24.8453 22.4098 25.0071C22.4125 25.1689 22.3761 25.3289 22.3037 25.4736Z"
        fill="#7C737D"
      />
      <path
        d="M13.8105 24.02H7.30216V18.2024C7.30216 18.163 7.3178 18.1253 7.34563 18.0974C7.37347 18.0696 7.41123 18.0539 7.45059 18.0539H13.6621C13.7015 18.0539 13.7392 18.0696 13.7671 18.0974C13.7949 18.1253 13.8105 18.163 13.8105 18.2024V24.02Z"
        fill="#E69B6C"
      />
      <path
        d="M13.8101 22.3931V24.0202H7.30176V19.1389C7.3026 18.8515 7.41715 18.5761 7.62039 18.3728C7.82363 18.1696 8.09904 18.055 8.38646 18.0542C8.38646 18.624 8.49869 19.1882 8.71674 19.7146C8.93479 20.241 9.2544 20.7194 9.6573 21.1223C10.471 21.936 11.5746 22.3931 12.7254 22.3931H13.8101Z"
        fill="#E28044"
      />
      <path
        d="M27.9124 8.02039C27.9149 8.38454 27.8803 8.748 27.8093 9.10517C27.5707 10.3526 26.8765 11.302 25.7429 11.6597C25.3915 11.7673 25.0257 11.8204 24.6582 11.817V8.02039H27.9124Z"
        fill="#FCD7CA"
      />
      <path
        d="M34.9623 17.5116C34.9626 17.7254 34.9207 17.9371 34.8391 18.1346C34.7574 18.3322 34.6376 18.5116 34.4864 18.6628C34.3353 18.8139 34.1558 18.9338 33.9582 19.0154C33.7607 19.0971 33.549 19.139 33.3352 19.1387H31.7082C31.2766 19.1387 30.8628 18.9673 30.5576 18.6622C30.2525 18.357 30.0811 17.9432 30.0811 17.5116C30.0811 17.0801 30.2525 16.6662 30.5576 16.3611C30.8628 16.0559 31.2766 15.8845 31.7082 15.8845H33.3352C33.7666 15.8852 34.18 16.0569 34.485 16.3618C34.79 16.6668 34.9617 17.0803 34.9623 17.5116Z"
        fill="#2FD9E7"
      />
      <path
        d="M34.7237 18.3579C34.58 18.5974 34.3764 18.7954 34.1329 18.9324C33.8894 19.0693 33.6145 19.1405 33.3352 19.1389H31.7081C31.3532 19.139 31.008 19.0231 30.7252 18.8088C30.4424 18.5945 30.2374 18.2936 30.1415 17.9519C30.0457 17.6103 30.0642 17.2466 30.1943 16.9165C30.3243 16.5863 30.5588 16.3077 30.862 16.1233C30.7113 16.3698 30.629 16.652 30.6236 16.9409C30.6183 17.2297 30.69 17.5148 30.8314 17.7668C30.9729 18.0187 31.1789 18.2284 31.4283 18.3742C31.6777 18.52 31.9615 18.5968 32.2504 18.5965H33.8776C34.1763 18.5973 34.4694 18.5147 34.7237 18.3579Z"
        fill="#0DCEDE"
      />
      <path
        d="M5.13276 22.9353H3.73819C3.66643 22.9362 3.59522 22.9228 3.52868 22.8959C3.46215 22.869 3.4016 22.8292 3.35057 22.7787C3.29953 22.7283 3.25901 22.6682 3.23135 22.602C3.20369 22.5358 3.18945 22.4647 3.18945 22.393C3.18945 22.3212 3.20369 22.2502 3.23135 22.1839C3.25901 22.1177 3.29953 22.0577 3.35057 22.0072C3.4016 21.9568 3.46215 21.9169 3.52868 21.8901C3.59522 21.8632 3.66643 21.8498 3.73819 21.8506H5.13276C5.20452 21.8498 5.27573 21.8632 5.34226 21.8901C5.4088 21.9169 5.46934 21.9568 5.52038 22.0072C5.57142 22.0577 5.61194 22.1177 5.6396 22.1839C5.66725 22.2502 5.68149 22.3212 5.68149 22.393C5.68149 22.4647 5.66725 22.5358 5.6396 22.602C5.61194 22.6682 5.57142 22.7283 5.52038 22.7787C5.46934 22.8292 5.4088 22.869 5.34226 22.8959C5.27573 22.9228 5.20452 22.9362 5.13276 22.9353Z"
        fill="#E69B6C"
      />
      <path
        d="M5.13288 20.7659H2.8085C2.73674 20.7667 2.66553 20.7533 2.599 20.7264C2.53246 20.6996 2.47192 20.6598 2.42088 20.6093C2.36984 20.5589 2.32932 20.4988 2.30166 20.4326C2.27401 20.3664 2.25977 20.2953 2.25977 20.2235C2.25977 20.1518 2.27401 20.0807 2.30166 20.0145C2.32932 19.9483 2.36984 19.8882 2.42088 19.8378C2.47192 19.7873 2.53246 19.7475 2.599 19.7206C2.66553 19.6938 2.73674 19.6804 2.8085 19.6812H5.13288C5.20464 19.6804 5.27585 19.6938 5.34239 19.7206C5.40893 19.7475 5.46947 19.7873 5.52051 19.8378C5.57155 19.8882 5.61207 19.9483 5.63972 20.0145C5.66738 20.0807 5.68162 20.1518 5.68162 20.2235C5.68162 20.2953 5.66738 20.3664 5.63972 20.4326C5.61207 20.4988 5.57155 20.5589 5.52051 20.6093C5.46947 20.6598 5.40893 20.6996 5.34239 20.7264C5.27585 20.7533 5.20464 20.7667 5.13288 20.7659Z"
        fill="#E69B6C"
      />
      <path
        d="M5.13301 18.5965H1.87881C1.80706 18.5973 1.73585 18.5839 1.66931 18.557C1.60277 18.5301 1.54223 18.4903 1.49119 18.4399C1.44015 18.3894 1.39963 18.3294 1.37197 18.2631C1.34432 18.1969 1.33008 18.1259 1.33008 18.0541C1.33008 17.9823 1.34432 17.9113 1.37197 17.8451C1.39963 17.7789 1.44015 17.7188 1.49119 17.6683C1.54223 17.6179 1.60277 17.5781 1.66931 17.5512C1.73585 17.5243 1.80706 17.5109 1.87881 17.5118H5.13301C5.20477 17.5109 5.27598 17.5243 5.34251 17.5512C5.40905 17.5781 5.46959 17.6179 5.52063 17.6683C5.57167 17.7188 5.61219 17.7789 5.63985 17.8451C5.6675 17.9113 5.68174 17.9823 5.68174 18.0541C5.68174 18.1259 5.6675 18.1969 5.63985 18.2631C5.61219 18.3294 5.57167 18.3894 5.52063 18.4399C5.46959 18.4903 5.40905 18.5301 5.34251 18.557C5.27598 18.5839 5.20477 18.5973 5.13301 18.5965Z"
        fill="#E69B6C"
      />
      <path
        d="M23.5623 12.2073C23.8731 12.5358 24.074 12.9528 24.1372 13.4006H24.1213C24.0379 13.4005 23.9545 13.3951 23.8718 13.3843C23.6683 13.3526 23.4672 13.3073 23.2698 13.2487C22.4377 13.018 21.6781 12.5794 21.0623 11.9741C21.0352 11.947 21.003 11.9199 20.9756 11.8873C21.301 11.6107 21.5884 11.3826 21.8271 11.204C22.3107 11.6806 22.908 12.0259 23.5623 12.2073Z"
        fill="#FECD55"
      />
      <path
        d="M27.9124 8.02039C27.9149 8.38454 27.8803 8.748 27.8093 9.10517C27.2613 9.10517 26.7357 9.32288 26.3481 9.7104C25.9606 10.0979 25.7429 10.6235 25.7429 11.1716V11.6597C25.3915 11.7673 25.0257 11.8204 24.6582 11.817V8.02039H27.9124Z"
        fill="#FAC1AD"
      />
      <path
        d="M28.7097 8.02031H24.6582V11.7705C24.6585 11.8485 24.6421 11.9256 24.61 11.9966C24.5779 12.0677 24.5309 12.131 24.4722 12.1823C24.4135 12.2335 24.3444 12.2716 24.2697 12.2938C24.195 12.3161 24.1164 12.322 24.0391 12.3112C23.8784 12.2861 23.7192 12.2514 23.5626 12.2073C22.9081 12.0259 22.3108 11.6806 21.8271 11.2039C21.3826 10.7768 21.042 10.2535 20.8314 9.67419C20.6208 9.09486 20.5459 8.47496 20.6124 7.86215C20.7188 6.84971 21.2013 5.91408 21.9644 5.2403C22.7275 4.56653 23.7158 4.20369 24.7336 4.22355C25.7514 4.24341 26.7247 4.64453 27.461 5.34756C28.1972 6.0506 28.6428 7.00435 28.7097 8.02016V8.02031Z"
        fill="#F16069"
      />
      <path
        d="M24.6466 11.1226C24.6521 11.1226 24.6521 11.1226 24.6575 11.128V11.768C24.6579 11.8459 24.6416 11.9231 24.6096 11.9942C24.5776 12.0653 24.5307 12.1287 24.4721 12.1801C24.4134 12.2316 24.3445 12.2698 24.2698 12.2923C24.1951 12.3147 24.1165 12.3209 24.0392 12.3104C23.8784 12.2844 23.7191 12.25 23.5619 12.2073C22.9075 12.026 22.3101 11.6806 21.8264 11.204C21.3918 10.7846 21.0565 10.2733 20.8451 9.70752C20.6337 9.14177 20.5515 8.53589 20.6045 7.93426C20.6575 7.33263 20.8445 6.75047 21.1516 6.23043C21.4587 5.71039 21.8783 5.26562 22.3796 4.92871C21.8395 5.7169 21.5966 6.67107 21.6939 7.62153C21.7913 8.57199 22.2226 9.45712 22.9111 10.1195C23.3948 10.5961 23.9922 10.9413 24.6466 11.1226Z"
        fill="#EF444E"
      />
      <path
        d="M18.6914 32.1558H14.3524V29.5924C14.3524 29.553 14.3681 29.5153 14.3959 29.4874C14.4237 29.4596 14.4615 29.444 14.5009 29.444H18.543C18.5823 29.444 18.6201 29.4596 18.6479 29.4874C18.6758 29.5153 18.6914 29.553 18.6914 29.5924V32.1558Z"
        fill="#2FD9E7"
      />
      <path
        d="M18.6914 31.6133V32.1557H14.3525V30.5286C14.3531 30.32 14.4137 30.1159 14.5272 29.9409C14.6407 29.7658 14.8023 29.6272 14.9925 29.5415C14.927 29.6805 14.8937 29.8325 14.8949 29.9862C14.8949 30.4178 15.0663 30.8316 15.3714 31.1368C15.6766 31.4419 16.0904 31.6133 16.522 31.6133H18.6914Z"
        fill="#0DCEDE"
      />
      <path
        d="M28.997 8.56253H24.6581C24.5864 8.56337 24.5151 8.54996 24.4486 8.52308C24.3821 8.4962 24.3215 8.45638 24.2705 8.40594C24.2194 8.35549 24.1789 8.29542 24.1513 8.2292C24.1236 8.16298 24.1094 8.09194 24.1094 8.02017C24.1094 7.94841 24.1236 7.87736 24.1513 7.81115C24.1789 7.74493 24.2194 7.68485 24.2705 7.63441C24.3215 7.58396 24.3821 7.54415 24.4486 7.51727C24.5151 7.49039 24.5864 7.47698 24.6581 7.47782H28.997C29.0688 7.47698 29.14 7.49039 29.2065 7.51727C29.2731 7.54415 29.3336 7.58396 29.3846 7.63441C29.4357 7.68485 29.4762 7.74493 29.5039 7.81115C29.5315 7.87736 29.5457 7.94841 29.5457 8.02017C29.5457 8.09194 29.5315 8.16298 29.5039 8.2292C29.4762 8.29542 29.4357 8.35549 29.3846 8.40594C29.3336 8.45638 29.2731 8.4962 29.2065 8.52308C29.14 8.54996 29.0688 8.56337 28.997 8.56253Z"
        fill="#FFE589"
      />
      <path
        d="M13.8105 22.9353H6.75967C6.68792 22.9345 6.61671 22.9479 6.55017 22.9748C6.48363 23.0017 6.42309 23.0415 6.37205 23.0919C6.32101 23.1424 6.28049 23.2024 6.25283 23.2687C6.22518 23.3349 6.21094 23.4059 6.21094 23.4777C6.21094 23.5495 6.22518 23.6205 6.25283 23.6867C6.28049 23.7529 6.32101 23.813 6.37205 23.8635C6.42309 23.9139 6.48363 23.9537 6.55017 23.9806C6.61671 24.0075 6.68792 24.0209 6.75967 24.02H13.8105V22.9353Z"
        fill="#2FD9E7"
      />
      <path
        d="M28.997 8.56253H24.6581C24.5864 8.56337 24.5151 8.54996 24.4486 8.52308C24.3821 8.4962 24.3215 8.45638 24.2705 8.40594C24.2194 8.35549 24.1789 8.29542 24.1513 8.2292C24.1236 8.16298 24.1094 8.09194 24.1094 8.02017C24.1094 7.94841 24.1236 7.87736 24.1513 7.81115C24.1789 7.74493 24.2194 7.68485 24.2705 7.63441C24.3215 7.58396 24.3821 7.54415 24.4486 7.51727C24.5151 7.49039 24.5864 7.47698 24.6581 7.47782H28.997C29.0688 7.47698 29.14 7.49039 29.2065 7.51727C29.2731 7.54415 29.3336 7.58396 29.3846 7.63441C29.4357 7.68485 29.4762 7.74493 29.5039 7.81115C29.5315 7.87736 29.5457 7.94841 29.5457 8.02017C29.5457 8.09194 29.5315 8.16298 29.5039 8.2292C29.4762 8.29542 29.4357 8.35549 29.3846 8.40594C29.3336 8.45638 29.2731 8.4962 29.2065 8.52308C29.14 8.54996 29.0688 8.56337 28.997 8.56253Z"
        fill="#FFE589"
      />
      <path
        d="M10.3338 29.677L9.5311 31.8194C9.20174 31.8552 8.87133 31.7619 8.60938 31.5591C9.5043 31.5482 9.99244 29.5415 9.99244 29.5415L10.3338 29.677Z"
        fill="#FEC845"
      />
    </svg>
  );
};
