import React from "react";

const OptionItem = (props) => {
  const { cartItem } = props;
  const options = cartItem?.options?.map((itemOption) => {
    return `${itemOption?.name} (${itemOption?.optionLevelName})`;
  });

  return <>{options?.join(" , ")}</>;
};

export default OptionItem;
