export const EnumVerifyProductPromotionType = {
  /**
   *  Check the product and do not apply the promotion.
   */
  NotPromotion: 0,

  /**
   *  Verify Flash Sale.
   */
  FlashSale: 1,

  /**
   *   Verify discount product category, discount product.
   */
  Discount: 2,
};

export const EnumAddToCartType = {
  ComboPricing: 0,
  ComboProductPrice: 1,
  Product: 2,
};

export const EnumOrderMessageCode = {
  success: 1,
  FlashSaleIsApplied: 100,
  FlashSaleInactive: 101,
  FlashSaleMinimumPurchaseValue: 102,
  FlashSaleOverLimited: 103,
  FlashSaleNotFound: 104,
  FlashSaleNoApplicableProducts: 105,
  FlashSaleHasBeenChanged: 106,
  DiscountCodeExpired: 201,
  DiscountCodeMinimumPurchaseValue: 202,
  DiscountCodeOverLimited: 203,
  DiscountCodeOverLimitedPerCustomer: 204,
  DiscountCodeNotFound: 205,
  DiscountCodeBranchNotApplicable: 206,
  DiscountCodePlatformNotApplicable: 207,
  DiscountCodeCannotAppliedWithFlashSale: 208,
  HigherDiscountAmountBeingApplied: 209,
  ProductIsBeingAppliedFlashSale: 210,
  CanNotAppliedAnyItem: 211,
  AppliedSuccessfully: 213,
  DiscountProductsNotAvailable: 213,
  DiscountOrderNotAvailable: 214,
  DiscountOrderServiceTypeNotAvailable: 215,
  OrderCancelCode: 310,
};

export const EnumDiscountCodeResponseCode = {
  Success: 0,
  Expired: 1,
  MinimumPurchaseValue: 2,
  OverLimited: 3,
  OverLimitedPerCustomer: 4,
  NotFound: 5,
  BranchNotApplicable: 6,
  PlatformNotApplicable: 7,
  Existed: 8,
  NoProductsMatch: 9,
  HigherDiscountAmountBeingApplied: 10,
  ProductIsBeingAppliedFlashSale: 11,
  CanNotAppliedAnyItem: 12,
  AppliedSuccessfully: 13,
  DiscountProductsNotAvailable: 14,
  DiscountOrderNotAvailable: 15,
  DiscountOrderServiceTypeNotAvailable: 16,
};

export const DiscountCodeResponseMessage = [
  {
    key: 0,
    message: "promotion.discountCode.redeemSuccessfully",
  },
  {
    key: 1,
    message: "promotion.discountCode.description.expired",
  },
  {
    key: 2,
    message: "promotion.discountCode.description.minimumPurchaseValue",
  },
  {
    key: 3,
    message: "promotion.discountCode.description.overLimited",
  },
  {
    key: 4,
    message: "promotion.discountCode.description.overLimitedPerCustomer",
  },
  {
    key: 5,
    message: "promotion.discountCode.description.notFound",
  },
  {
    key: 6,
    message: "promotion.discountCode.description.branchNotApplicable",
  },
  {
    key: 7,
    message: "promotion.discountCode.description.platformNotApplicable",
  },
  {
    key: 8,
    message: "promotion.discountCode.description.existed",
  },
  {
    key: 10,
    message: "promotion.discountCode.description.higherDiscountAmountBeingApplied",
  },
  {
    key: 11,
    message: "promotion.discountCode.description.productIsBeingAppliedFlashSale",
  },
  {
    key: 12,
    message: "promotion.discountCode.description.canNotAppliedAnyItem",
  },
  {
    key: 13,
    message: "promotion.discountCode.description.appliedSuccessfully",
  },
  {
    key: 14,
    message: "promotion.discountCode.description.discountProductsNotAvailable",
  },
  {
    key: 15,
    message: "romotion.discountCode.description.discountOrderNotAvailable",
  },
  {
    key: 16,
    message: "promotion.discountCode.description.discountOrderServiceTypeNotAvailable",
  },
];

export const EnumFlashSaleResponseCode = {
  Success: 200,
  Inactive: 1,
  MinimumPurchaseValue: 2,
  OverLimited: 3,
  NotFound: 4,
};

export const EnumQRCodeStatus = {
  Scheduled: 1,
  Active: 2,
  Finished: 3,
};

export const EnumOrderType = {
  Instore: 0,
  Delivery: 1,
  TakeAway: 2,
  Online: 3,
  PickUp: 4,
};

export const EnumTargetQRCode = {
  ShopMenu: 0,
  AddProductToCart: 1,
};

export const enumCheckInactiveProduct = {
  NOT_FOUND: 1,
};

export const ScrollHeaderType = {
  SCROLL: 1,
  FIXED: 2,
};

export const ListEnumDiscountCodeError = [
  EnumOrderMessageCode.DiscountCodeExpired,
  EnumOrderMessageCode.DiscountCodeMinimumPurchaseValue,
  EnumOrderMessageCode.DiscountCodeOverLimited,
  EnumOrderMessageCode.DiscountCodeOverLimitedPerCustomer,
  EnumOrderMessageCode.DiscountCodeNotFound,
  EnumOrderMessageCode.DiscountCodeBranchNotApplicable,
  EnumOrderMessageCode.DiscountCodePlatformNotApplicable,
  EnumOrderMessageCode.DiscountCodeCannotAppliedWithFlashSale,
  EnumOrderMessageCode.HigherDiscountAmountBeingApplied,
  EnumOrderMessageCode.ProductIsBeingAppliedFlashSale,
  EnumOrderMessageCode.CanNotAppliedAnyItem,
  EnumOrderMessageCode.AppliedSuccessfully,
  EnumOrderMessageCode.DiscountProductsNotAvailable,
  EnumOrderMessageCode.DiscountOrderNotAvailable,
  EnumOrderMessageCode.DiscountOrderServiceTypeNotAvailable,
];

export const EnumOrderStatus = {
  New: 0,
  Returned: 1,
  Canceled: 2,
  ToConfirm: 3,
  Processing: 4,
  Delivering: 5,
  Completed: 6,
  Draft: 7,
};

export const OrderPaymentStatus = {
  Unpaid: 0,
  Paid: 1,
};

export const EnumOrderItemStatus = {
  New: 1,
  Completed: 2,
  Canceled: 3,
};

export const EnumOrderActionHistory = {
  ORDER: 1,
  ADD_ITEM: 2,
  CALL_WAITER: 3,
  CALL_PAYMENT: 4,
};

export const EnumActionTypeOrderHistoryLocal = {
  ORDER: "ORDER",
  ADD_ITEM: "ADD_ITEM",
  CALL_WAITER: "CALL_WAITER",
  CALL_PAYMENT: "CALL_PAYMENT",
};

export const EnumPromotionSummary = {
  Discount: 1,
  DiscountCode: 2,
  LoyaltyPointDiscount: 3,
  CustomerMembershipDiscount: 4,
  ManualDiscount: 5,
};

export const EnumDepositStatus = {
  NotReceived: 1,
  Received: 2,
  WaitToRefund: 3,
  Refunded: 4,
  NoRefund: 5,
};

export const EnumReviewStatus = {
  Hidden: 0,
  Show: 1,
};
