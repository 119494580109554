export const CURRENCY_CODE = {
  VND: "VND",
};

export const currency = {
  vnd: "VND",
  d: "đ",
};

export const DateFormat = {
  DD_MM_YYYY: "DD/MM/YYYY",
};

export const DefaultShippingName = {
  Home: "Home",
  Work: "Work",
};

export const paramIsLoadData = "isLoadData"; //data field to identify first scan Qr Order

export const monthLocalize = {
  1: "optionDatetime.january",
  2: "optionDatetime.february",
  3: "optionDatetime.march",
  4: "optionDatetime.april",
  5: "optionDatetime.may",
  6: "optionDatetime.june",
  7: "optionDatetime.july",
  8: "optionDatetime.august",
  9: "optionDatetime.september",
  10: "optionDatetime.october",
  11: "optionDatetime.november",
  12: "optionDatetime.december"
}