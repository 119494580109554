import { Radio, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatTextCurrency } from "../../../../../utils/helpers";
import { InfoIcon } from "../../../../assets/icons.constants";
import BankAccountDetailComponent from "../../../../components/bank-account-detail-component/BankAccountDetailComponent";

const DepositPaymentMethod = (props) => {
  const {
    paymentMethods,
    handleSelectPaymentMethod,
    selectedPaymentMethod,
    selectedBankAccountInfo,
    isCustomize,
    handleCopyText,
  } = props;
  const { t } = useTranslation();
  const imgSlash = isCustomize ? "../../.." : "";
  const storeConfigData = useSelector((state) => state?.session?.storeConfig);
  console.log(storeConfigData);
  const tooltipOverlayStyle = {
    maxWidth: "none",
  };

  const tooltipInnerOverlayStyle = {
    color: "black",
    fontSize: "16px",
    whiteSpace: "nowrap",
    padding: "6px, 20px",
  };
  return (
    <>
      <div className="deposit-method-container">
        <h1>{t("reserveTable.depositMethod")}</h1>
        <Tooltip
          title={t("reserveTable.youNeedToDepositToKeepThisPlace")}
          color="white"
          overlayStyle={tooltipOverlayStyle}
          overlayInnerStyle={tooltipInnerOverlayStyle}
        >
          <InfoIcon />
        </Tooltip>
      </div>
      {paymentMethods?.length > 0 ? (
        <div className="content-reserve-table-payment-method-section">
          <Radio.Group
            className="reserve-table-payment-method payment-method-radio-group radio-style"
            onChange={(e) => {
              handleSelectPaymentMethod(e.target.value);
            }}
            value={selectedPaymentMethod}
          >
            {paymentMethods.map((paymentMethod) => {
              return (
                <Radio className="payment-method-radio" value={paymentMethod}>
                  <div className="radio-button-container">
                    <div className="payment-method-left-content">
                      <div className="method-img-container">
                        <img
                          className="payment-method-radio-image"
                          src={`${imgSlash}${paymentMethod?.icon}`}
                          alt={paymentMethod.paymentMethodName}
                        />
                      </div>
                      <div className="payment-method-left-content-name">{paymentMethod.paymentMethodName}</div>
                    </div>
                    <div className="payment-method-right-content-price">
                      {formatTextCurrency(storeConfigData.depositAmount)}
                    </div>
                  </div>
                </Radio>
              );
            })}
          </Radio.Group>
          {selectedBankAccountInfo && (
            <BankAccountDetailComponent
              selectedBankAccountInfo={selectedBankAccountInfo}
              handleCopyText={handleCopyText}
            />
          )}
        </div>
      ) : (
        <span className="warning-no-payment-method">{t("reserveTable.currentlyThereIsNoSuitablePaymentMethod")}</span>
      )}
    </>
  );
};

export default DepositPaymentMethod;
