import styled from "styled-components";

export const StyleFormResetPassword = styled.div`
  .reset-password__title {
    color: ${(props) => props?.colorGroup?.titleColor};
  }

  .btn-password {
    button {
      background-color: ${(props) => props?.colorGroup?.buttonBackgroundColor};
      span {
        color: ${(props) => props?.colorGroup?.buttonTextColor};
      }
    }
  }
`;

export const StyledLogin = styled.div`
  .tab-login-account-theme2 {
    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab-active {
            color: ${(props) => props?.colorGroup?.buttonBackgroundColor};
            .ant-tabs-tab-btn {
              color: ${(props) => props?.colorGroup?.buttonBackgroundColor};
            }
          }
          .ant-tabs-tab {
            .ant-tabs-tab-btn:hover {
              color: ${(props) => props?.colorGroup?.buttonBackgroundColor};
            }
          }
          .ant-tabs-ink-bar {
            border-bottom: 3px solid ${(props) => props?.colorGroup?.buttonBackgroundColor};
          }
        }
      }
    }
  }
`;

export const StyledForgotPassword = styled.div`
  .forgot-password__title {
    color: ${(props) => props?.colorGroup?.titleColor};
  }

  .btn-send-otp {
    button {
      background: ${(props) => props?.colorGroup?.buttonBackgroundColor};
      span {
        color: ${(props) => props?.colorGroup?.buttonTextColor};
      }
    }
  }
`;
