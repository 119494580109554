export const IconPickupCustomize = (props) => {
  const { width = 44, height = 44, color } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} rx="22" fill="white" />
      <g clipPath="url(#clip0_260_12090)">
        <path
          d="M37.2959 21.1717C37.2959 29.6243 30.4437 36.4766 21.991 36.4766C13.5383 36.4766 6.68604 29.6243 6.68604 21.1717C6.68604 12.719 13.5383 5.8667 21.991 5.8667C30.4437 5.8667 37.2959 12.719 37.2959 21.1717Z"
          fill="#F5585C"
        />
        <path
          d="M29.8024 25.7321L27.9349 21.1946C27.2744 21.1946 16.7074 21.1946 16.0469 21.1946L14.1792 25.7321C13.4805 27.4295 13.1211 29.2475 13.1211 31.0832V35.7487C13.1211 37.0657 14.1888 38.1334 15.5058 38.1334H28.4758C29.7928 38.1334 30.8605 37.0657 30.8605 35.7487V31.0832C30.8607 29.2475 30.5011 27.4295 29.8024 25.7321Z"
          fill="#E2E9F2"
        />
        <path
          d="M13.1211 31.0832V35.7487C13.1211 37.0657 14.1885 38.1334 15.5055 38.1334H19.258C18.2861 38.1334 17.4983 37.0657 17.4983 35.7487V31.0832C17.4983 29.2475 17.7637 27.4295 18.2792 25.7321L19.6573 21.1946C17.7901 21.1946 16.2835 21.1946 16.0469 21.1946L14.1792 25.7321C13.4805 27.4295 13.1211 29.2475 13.1211 31.0832Z"
          fill="#C7D6E5"
        />
        <path
          d="M27.4015 18.2996H16.5801C16.2854 18.2996 16.0469 18.5384 16.0469 18.8328V21.1946H27.9349V18.8328C27.9349 18.5384 27.6961 18.2996 27.4015 18.2996Z"
          fill="#494970"
        />
        <path
          d="M20.1905 18.2996H16.5801C16.2854 18.2996 16.0469 18.5384 16.0469 18.8328V21.1946H19.6573V18.8328C19.6573 18.5384 19.8958 18.2996 20.1905 18.2996Z"
          fill="#3A3A60"
        />
        <path
          d="M25.1135 10.4929H18.7698L18.7823 11.6674C18.7838 11.8215 18.739 11.9727 18.6533 12.1012C18.0674 12.9788 17.76 14.0127 17.7713 15.0681L17.8119 19.3013C17.8173 19.8033 18.212 20.2279 18.7139 20.2392C19.2387 20.2513 19.6651 19.8266 19.6595 19.306C19.6649 19.8163 20.0829 20.2255 20.593 20.22C21.1033 20.2146 21.5124 19.7966 21.507 19.2865C21.5124 19.7966 21.9304 20.2058 22.4405 20.2003C22.9506 20.1949 23.3597 19.7772 23.3543 19.2671H23.4053C23.4107 19.769 23.8055 20.189 24.3072 20.2003C24.8321 20.2124 25.2585 19.7875 25.2531 19.2671L25.1802 16.8233C25.1765 16.4614 25.6341 16.3014 25.8567 16.5867L27.474 18.66C27.8376 19.0157 28.4277 19.0369 28.7901 18.68C29.1594 18.3164 29.1571 17.7223 28.7886 17.3612C27.7894 16.0493 26.9167 15.0422 25.7597 13.2661C25.35 12.6374 25.1282 11.9047 25.1204 11.1544L25.1135 10.4929Z"
          fill="#F9D6BF"
        />
        <path
          d="M25.6083 6.298C24.4484 6.01687 23.2374 5.8667 21.991 5.8667C20.7673 5.8667 19.5778 6.0112 18.437 6.28249V8.04535H25.6083V6.298Z"
          fill="#E2E9F2"
        />
        <path
          d="M21.8168 5.86914C20.6546 5.88243 19.5234 6.02398 18.437 6.28247V8.04533H21.8168V5.86914Z"
          fill="#C7D6E5"
        />
        <path
          d="M18.1538 13.0492C20.203 11.1763 23.2964 11.0591 25.4797 12.7565C25.2501 12.2555 25.1267 11.71 25.1208 11.1544L25.1139 10.4929H18.7702L18.7828 11.6674C18.7843 11.8215 18.7395 11.9727 18.6538 12.1012C18.4539 12.4005 18.2872 12.7186 18.1538 13.0492Z"
          fill="#EFC1A8"
        />
        <path
          d="M18.3095 10.6257H25.6721C25.7649 10.6257 25.84 10.5506 25.84 10.458V8.08024C25.84 7.98768 25.7649 7.9126 25.6721 7.9126H18.3444C18.2324 7.9126 18.1416 8.00344 18.1416 8.11545V10.458C18.1416 10.5506 18.2167 10.6257 18.3095 10.6257Z"
          fill="#F6A221"
        />
        <path
          d="M20.8685 9.28675C20.8685 8.52779 21.4837 7.9126 22.2426 7.9126H18.3444C18.2324 7.9126 18.1416 8.00344 18.1416 8.11545V10.458C18.1416 10.5506 18.2167 10.6257 18.3095 10.6257H22.2074C21.4679 10.6257 20.8685 10.0263 20.8685 9.28675Z"
          fill="#F28A00"
        />
        <path
          d="M20.8527 24.6924C20.431 24.6924 20.0894 25.0343 20.0894 25.4558V26.1625H21.6159V25.4558C21.6159 25.0341 21.2742 24.6924 20.8527 24.6924Z"
          fill="#75BDEF"
        />
        <path
          d="M16.7926 31.2C17.4609 31.2 17.4609 30.8364 18.1291 30.8364C18.7974 30.8364 18.7974 31.2 19.4658 31.2C20.1339 31.2 20.1339 30.8364 20.8023 30.8364C21.4706 30.8364 21.4706 31.2 22.139 31.2C22.7109 31.2 22.7943 30.9342 23.2249 30.8574L23.4208 28.9453C23.1658 28.9453 15.7658 28.9453 15.5107 28.9453L15.7069 30.8574C16.1375 30.9342 16.221 31.2 16.7926 31.2Z"
          fill="#A0D5FF"
        />
        <path
          d="M16.7928 31.2C17.3492 31.2 17.4427 30.9482 17.844 30.8638L17.6473 28.9453C16.49 28.9453 15.5969 28.9453 15.5112 28.9453L15.7074 30.8574C16.1377 30.9342 16.2212 31.2 16.7928 31.2Z"
          fill="#75BDEF"
        />
        <path
          d="M21.8946 26.0273H17.0378C16.1946 26.0273 15.5112 26.7107 15.5112 27.5539H23.4213C23.4213 26.7107 22.7377 26.0273 21.8946 26.0273Z"
          fill="#A0D5FF"
        />
        <path
          d="M19.1879 26.0273H17.0378C16.1946 26.0273 15.5112 26.7107 15.5112 27.5539H17.6611C17.6611 26.7107 18.3447 26.0273 19.1879 26.0273Z"
          fill="#75BDEF"
        />
        <path
          d="M16.3703 37.3223C16.4176 37.7831 16.8056 38.1335 17.2689 38.1335H21.6634C22.1267 38.1335 22.5149 37.7831 22.5621 37.3223L23.2394 30.7184C22.7958 30.7918 22.7177 31.064 22.1392 31.064C21.4708 31.064 21.4708 30.7002 20.8025 30.7002C20.1341 30.7002 20.1341 31.064 19.466 31.064C18.7976 31.064 18.7976 30.7002 18.1293 30.7002C17.4611 30.7002 17.4611 31.064 16.7928 31.064C16.2145 31.064 16.1365 30.7918 15.6929 30.7184L16.3703 37.3223Z"
          fill="#FC757E"
        />
        <path
          d="M17.2686 38.1333H19.4047C18.9414 38.1333 18.5532 37.783 18.5059 37.3222L17.8299 30.7303C17.4402 30.8175 17.3427 31.0636 16.7925 31.0636C16.2143 31.0636 16.1362 30.7916 15.6929 30.718L16.3701 37.3222C16.4174 37.783 16.8056 38.1333 17.2686 38.1333Z"
          fill="#F5585C"
        />
        <path
          d="M23.6879 29.0131H15.2443C14.8229 29.0131 14.481 28.6712 14.481 28.2497C14.481 27.828 14.8229 27.4863 15.2443 27.4863H23.6879C24.1096 27.4863 24.4513 27.828 24.4513 28.2497C24.4513 28.6712 24.1096 29.0131 23.6879 29.0131Z"
          fill="#75BDEF"
        />
        <path
          d="M16.9939 28.2497C16.9939 27.828 17.3359 27.4863 17.7573 27.4863H15.2441C14.8226 27.4863 14.481 27.828 14.481 28.2497C14.481 28.6712 14.8226 29.0131 15.2441 29.0131H17.7573C17.3356 29.0131 16.9939 28.6712 16.9939 28.2497Z"
          fill="#4DADE2"
        />
        <path
          d="M25.8108 24.6924C25.3891 24.6924 25.0474 25.0343 25.0474 25.4558V26.1625H26.5741V25.4558C26.5741 25.0341 26.2322 24.6924 25.8108 24.6924Z"
          fill="#75BDEF"
        />
        <path
          d="M21.7511 31.2C22.4194 31.2 22.4194 30.8364 23.0876 30.8364C23.7559 30.8364 23.7559 31.2 24.424 31.2C25.0924 31.2 25.0924 30.8364 25.7605 30.8364C26.4289 30.8364 26.4289 31.2 27.0973 31.2C27.6691 31.2 27.7526 30.9342 28.1831 30.8574L28.3793 28.9453C28.1243 28.9453 20.7243 28.9453 20.4692 28.9453L20.6654 30.8574C21.0958 30.9342 21.1792 31.2 21.7511 31.2Z"
          fill="#A0D5FF"
        />
        <path
          d="M21.7513 31.2C22.3077 31.2 22.4012 30.9482 22.8022 30.8638L22.6055 28.9453C21.4483 28.9453 20.5551 28.9453 20.4697 28.9453L20.6657 30.8574C21.096 30.9342 21.1794 31.2 21.7513 31.2Z"
          fill="#75BDEF"
        />
        <path
          d="M26.8528 26.0273H21.996C21.1529 26.0273 20.4692 26.7107 20.4692 27.5539H28.3793C28.3793 26.7107 27.696 26.0273 26.8528 26.0273Z"
          fill="#A0D5FF"
        />
        <path
          d="M24.1459 26.0273H21.996C21.1529 26.0273 20.4692 26.7107 20.4692 27.5539H22.6193C22.6193 26.7107 23.3027 26.0273 24.1459 26.0273Z"
          fill="#75BDEF"
        />
        <path
          d="M21.3286 37.3223C21.3759 37.7831 21.7641 38.1335 22.2274 38.1335H26.6219C27.0849 38.1335 27.4731 37.7831 27.5204 37.3223L28.1979 30.7184C27.7543 30.7918 27.676 31.064 27.0977 31.064C26.4293 31.064 26.4293 30.7002 25.761 30.7002C25.0926 30.7002 25.0926 31.064 24.4242 31.064C23.7561 31.064 23.7561 30.7002 23.0878 30.7002C22.4194 30.7002 22.4194 31.064 21.7513 31.064C21.173 31.064 21.095 30.7918 20.6514 30.7184L21.3286 37.3223Z"
          fill="#F6A221"
        />
        <path
          d="M22.2274 38.1333H24.3632C23.8999 38.1333 23.5119 37.783 23.4647 37.3222L22.7887 30.7303C22.3987 30.8175 22.3012 31.0636 21.7513 31.0636C21.173 31.0636 21.0947 30.7916 20.6514 30.718L21.3286 37.3222C21.3759 37.783 21.7641 38.1333 22.2274 38.1333Z"
          fill="#F28A00"
        />
        <path
          d="M28.6464 29.0131H20.2028C19.7811 29.0131 19.4395 28.6712 19.4395 28.2497C19.4395 27.828 19.7811 27.4863 20.2028 27.4863H28.6464C29.0679 27.4863 29.4095 27.828 29.4095 28.2497C29.4095 28.6712 29.0679 29.0131 28.6464 29.0131Z"
          fill="#75BDEF"
        />
        <path
          d="M21.9527 28.2497C21.9527 27.828 22.2943 27.4863 22.7158 27.4863H20.2028C19.7811 27.4863 19.4395 27.828 19.4395 28.2497C19.4395 28.6712 19.7811 29.0131 20.2028 29.0131H22.7158C22.2943 29.0131 21.9527 28.6712 21.9527 28.2497Z"
          fill="#4DADE2"
        />
      </g>
      <defs>
        <clipPath id="clip0_260_12090">
          <rect width="32.2667" height="32.2667" fill="white" transform="translate(5.8667 5.8667)" />
        </clipPath>
      </defs>
    </svg>
  );
};
