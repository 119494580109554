export const IconButtonClose = (props) => {
  const { color, className } = props;
  return (
    <svg
      fill={color}
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 8.40005L2.1 13.3C1.91667 13.4834 1.68334 13.575 1.4 13.575C1.11667 13.575 0.883336 13.4834 0.700003 13.3C0.516669 13.1167 0.425003 12.8834 0.425003 12.6C0.425003 12.3167 0.516669 12.0834 0.700003 11.9L5.6 7.00005L0.700003 2.10005C0.516669 1.91672 0.425003 1.68338 0.425003 1.40005C0.425003 1.11672 0.516669 0.883382 0.700003 0.700048C0.883336 0.516715 1.11667 0.425049 1.4 0.425049C1.68334 0.425049 1.91667 0.516715 2.1 0.700048L7 5.60005L11.9 0.700048C12.0833 0.516715 12.3167 0.425049 12.6 0.425049C12.8833 0.425049 13.1167 0.516715 13.3 0.700048C13.4833 0.883382 13.575 1.11672 13.575 1.40005C13.575 1.68338 13.4833 1.91672 13.3 2.10005L8.4 7.00005L13.3 11.9C13.4833 12.0834 13.575 12.3167 13.575 12.6C13.575 12.8834 13.4833 13.1167 13.3 13.3C13.1167 13.4834 12.8833 13.575 12.6 13.575C12.3167 13.575 12.0833 13.4834 11.9 13.3L7 8.40005Z"
        fill={color}
      />
    </svg>
  );
};
