import { Hyperlink } from "./constants/hyperlink.constants";
import { getInternationalRegion } from "../utils/helpers";
import defaultConfigInternational from "./default-store-config/en";
import defaultConfigLocal from "./default-store-config/vi";

const { default: PageType } = require("./constants/page-type.constants");

const isInternationalRegion = getInternationalRegion();
const defaultConfig = isInternationalRegion ? defaultConfigInternational : defaultConfigLocal;

export default defaultConfig;
