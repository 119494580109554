import React from "react";
function OrderFoodIcon({ className, ...props }) {
  return (
    <div className={className}>
      <svg width="235" height="303" viewBox="0 0 235 303" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_24176_33789)">
          <path
            d="M162.04 244.297L12.0002 230.759C12.0002 230.759 100.123 289.206 142.543 290.969C179.924 292.522 208.135 236.027 208.135 236.027C192.897 233.09 177.279 247.234 162.04 244.297Z"
            fill="#D1D6F2"
          />
        </g>
        <path
          d="M179.469 146.744C177.315 147.201 175.161 145.765 174.77 143.611L154.145 38.856C153.688 36.7021 155.124 34.5483 157.278 34.1567C159.432 33.6998 161.586 35.1357 161.977 37.2895L182.602 142.045C183.059 144.199 181.688 146.287 179.469 146.744Z"
          fill="#292D32"
        />
        <path
          opacity="0.8"
          d="M214.648 101.97H122.098C124.447 84.5435 136.457 70.1193 152.578 64.3757H184.168C190.694 66.7254 196.568 70.5109 201.398 75.3408C208.447 82.3244 213.277 91.5925 214.648 101.97Z"
          fill="#F8DE8A"
        />
        <path
          opacity="0.3"
          d="M171.506 64.3103C155.384 70.1192 143.375 84.4781 141.025 101.905H122.098C124.447 84.4781 136.457 70.0539 152.578 64.3103H171.506Z"
          fill="#F8DE8A"
        />
        <g opacity="0.8">
          <path
            opacity="0.8"
            d="M200.942 94.0726C200.159 94.0726 199.376 93.5504 199.115 92.7672C198.658 91.2661 198.07 89.8302 197.352 88.4595C196.895 87.5458 197.287 86.371 198.201 85.9141C199.115 85.4572 200.289 85.8488 200.746 86.7626C201.529 88.329 202.182 89.9607 202.704 91.5924C203.031 92.5714 202.508 93.681 201.464 93.942C201.334 94.0073 201.073 94.0726 200.942 94.0726Z"
            fill="white"
          />
          <path
            opacity="0.8"
            d="M195.198 83.3679C194.611 83.3679 194.089 83.1068 193.697 82.6499C192.914 81.6709 192.065 80.7572 191.217 79.8434C189.063 77.6896 186.648 75.8621 184.037 74.4262C183.124 73.904 182.797 72.7945 183.32 71.8807C183.842 70.967 184.951 70.6406 185.865 71.1628C188.802 72.7945 191.543 74.8178 193.893 77.2327C194.872 78.2117 195.786 79.256 196.634 80.3656C197.287 81.214 197.156 82.3889 196.308 83.0415C195.982 83.2373 195.59 83.3679 195.198 83.3679Z"
            fill="white"
          />
        </g>
        <path
          d="M213.604 148.049L204.14 219.583L200.289 248.693H136.457L132.606 219.583L123.143 148.049H213.604Z"
          fill="#FBA625"
        />
        <path
          d="M199.875 197.722C201.317 197.7 202.468 196.514 202.446 195.072C202.425 193.63 201.238 192.479 199.797 192.501C198.355 192.522 197.204 193.709 197.225 195.15C197.247 196.592 198.433 197.743 199.875 197.722Z"
          fill="#CE7406"
        />
        <path
          d="M200.484 212.404C200.484 213.513 199.636 214.362 198.526 214.362C197.417 214.362 196.568 213.513 196.568 212.404C196.568 211.294 197.417 210.446 198.526 210.446C199.636 210.446 200.484 211.36 200.484 212.404Z"
          fill="#CE7406"
        />
        <path
          d="M189.65 200.133C189.65 201.112 188.867 201.896 187.888 201.896C186.909 201.896 186.126 201.112 186.126 200.133C186.126 199.154 186.909 198.371 187.888 198.371C188.867 198.371 189.65 199.154 189.65 200.133Z"
          fill="#CE7406"
        />
        <path
          d="M201.594 203.984C201.594 204.702 201.007 205.29 200.289 205.29C199.571 205.29 198.984 204.702 198.984 203.984C198.984 203.266 199.571 202.679 200.289 202.679C201.007 202.679 201.594 203.266 201.594 203.984Z"
          fill="#CE7406"
        />
        <path
          d="M192.522 211.099C192.522 211.817 191.935 212.404 191.217 212.404C190.499 212.404 189.912 211.817 189.912 211.099C189.912 210.381 190.499 209.793 191.217 209.793C191.935 209.793 192.522 210.381 192.522 211.099Z"
          fill="#CE7406"
        />
        <path
          d="M194.545 197.849C194.545 198.501 194.023 199.024 193.436 199.024C192.783 199.024 192.261 198.501 192.261 197.849C192.261 197.196 192.783 196.739 193.436 196.739C194.023 196.674 194.545 197.196 194.545 197.849Z"
          fill="#CE7406"
        />
        <path
          d="M187.04 216.516C187.04 217.169 186.518 217.691 185.93 217.691C185.278 217.691 184.755 217.169 184.755 216.516C184.755 215.863 185.278 215.406 185.93 215.406C186.518 215.341 187.04 215.863 187.04 216.516Z"
          fill="#CE7406"
        />
        <path
          d="M186.19 206.53C186.19 207.182 185.668 207.705 185.081 207.705C184.428 207.705 183.906 207.182 183.906 206.53C183.906 205.877 184.428 205.355 185.081 205.355C185.668 205.42 186.19 205.942 186.19 206.53Z"
          fill="#CE7406"
        />
        <path
          d="M190.63 206.595C190.954 206.595 191.217 206.332 191.217 206.008C191.217 205.683 190.954 205.42 190.63 205.42C190.305 205.42 190.042 205.683 190.042 206.008C190.042 206.332 190.305 206.595 190.63 206.595Z"
          fill="#CE7406"
        />
        <path
          d="M195.068 217.625C195.392 217.625 195.655 217.362 195.655 217.038C195.655 216.713 195.392 216.45 195.068 216.45C194.743 216.45 194.48 216.713 194.48 217.038C194.48 217.362 194.743 217.625 195.068 217.625Z"
          fill="#CE7406"
        />
        <path
          d="M181.622 201.569C181.622 202.157 181.1 202.679 180.513 202.679C179.925 202.679 179.403 202.157 179.403 201.569C179.403 200.982 179.925 200.46 180.513 200.46C181.1 200.46 181.622 200.917 181.622 201.569Z"
          fill="#CE7406"
        />
        <path
          d="M187.888 193.933C187.888 194.39 187.497 194.782 187.04 194.782C186.583 194.782 186.191 194.39 186.191 193.933C186.191 193.476 186.583 193.085 187.04 193.085C187.497 193.085 187.888 193.476 187.888 193.933Z"
          fill="#CE7406"
        />
        <path
          d="M179.697 198.174C181.139 198.154 182.292 196.97 182.272 195.528C182.253 194.086 181.069 192.933 179.627 192.953C178.185 192.972 177.032 194.157 177.052 195.598C177.071 197.04 178.255 198.193 179.697 198.174Z"
          fill="#CE7406"
        />
        <path
          d="M178.36 211.947C178.36 213.057 177.512 213.905 176.402 213.905C175.293 213.905 174.444 213.057 174.444 211.947C174.444 210.838 175.293 209.989 176.402 209.989C177.512 209.989 178.36 210.838 178.36 211.947Z"
          fill="#CE7406"
        />
        <path
          d="M168.112 196.413C168.112 197.392 167.329 198.175 166.35 198.175C165.371 198.175 164.588 197.392 164.588 196.413C164.588 195.434 165.371 194.651 166.35 194.651C167.329 194.585 168.112 195.434 168.112 196.413Z"
          fill="#CE7406"
        />
        <path
          d="M181.035 206.856C181.035 207.574 180.448 208.161 179.73 208.161C179.012 208.161 178.425 207.574 178.425 206.856C178.425 206.138 179.012 205.551 179.73 205.551C180.448 205.551 181.035 206.138 181.035 206.856Z"
          fill="#CE7406"
        />
        <path
          d="M169.418 211.099C169.418 211.817 168.831 212.404 168.113 212.404C167.395 212.404 166.807 211.817 166.807 211.099C166.807 210.381 167.395 209.793 168.113 209.793C168.831 209.793 169.418 210.381 169.418 211.099Z"
          fill="#CE7406"
        />
        <path
          d="M173.659 202.091C173.659 202.744 173.137 203.201 172.484 203.201C171.831 203.201 171.374 202.679 171.374 202.091C171.374 201.439 171.896 200.917 172.484 200.917C173.137 200.917 173.659 201.439 173.659 202.091Z"
          fill="#CE7406"
        />
        <path
          d="M168.374 216.516C168.374 217.168 167.852 217.691 167.264 217.691C166.612 217.691 166.09 217.168 166.09 216.516C166.09 215.863 166.612 215.406 167.264 215.406C167.852 215.341 168.374 215.863 168.374 216.516Z"
          fill="#CE7406"
        />
        <path
          d="M162.956 214.101C162.956 214.754 162.433 215.276 161.781 215.276C161.128 215.276 160.671 214.754 160.671 214.101C160.671 213.448 161.193 212.991 161.781 212.991C162.433 212.926 162.956 213.448 162.956 214.101Z"
          fill="#CE7406"
        />
        <path
          d="M170.201 206.008C170.201 206.334 169.94 206.595 169.613 206.595C169.287 206.595 169.026 206.334 169.026 206.008C169.026 205.681 169.287 205.42 169.613 205.42C169.94 205.42 170.201 205.681 170.201 206.008Z"
          fill="#CE7406"
        />
        <path
          d="M176.402 217.625C176.727 217.625 176.99 217.362 176.99 217.038C176.99 216.713 176.727 216.45 176.402 216.45C176.078 216.45 175.815 216.713 175.815 217.038C175.815 217.362 176.078 217.625 176.402 217.625Z"
          fill="#CE7406"
        />
        <path
          d="M163.544 204.311C163.544 204.898 163.021 205.42 162.434 205.42C161.847 205.42 161.324 204.898 161.324 204.311C161.324 203.723 161.847 203.201 162.434 203.201C163.021 203.201 163.544 203.723 163.544 204.311Z"
          fill="#CE7406"
        />
        <path
          d="M174.574 195.434C174.574 195.891 174.182 196.283 173.725 196.283C173.268 196.283 172.877 195.891 172.877 195.434C172.877 194.977 173.268 194.586 173.725 194.586C174.182 194.586 174.574 194.977 174.574 195.434Z"
          fill="#CE7406"
        />
        <path
          d="M159.432 198.175C159.432 199.611 158.257 200.786 156.821 200.786C155.385 200.786 154.21 199.611 154.21 198.175C154.21 196.74 155.385 195.565 156.821 195.565C158.257 195.565 159.432 196.74 159.432 198.175Z"
          fill="#CE7406"
        />
        <path
          d="M151.337 206.334C151.337 207.443 150.489 208.292 149.379 208.292C148.27 208.292 147.421 207.443 147.421 206.334C147.421 205.224 148.27 204.376 149.379 204.376C150.489 204.376 151.337 205.224 151.337 206.334Z"
          fill="#CE7406"
        />
        <path
          d="M135.414 191.257C135.414 192.236 134.631 193.019 133.652 193.019C132.673 193.019 131.889 192.236 131.889 191.257C131.889 190.278 132.673 189.495 133.652 189.495C134.631 189.495 135.414 190.278 135.414 191.257Z"
          fill="#CE7406"
        />
        <path
          d="M159.497 209.989C159.497 210.707 158.909 211.294 158.191 211.294C157.473 211.294 156.886 210.707 156.886 209.989C156.886 209.271 157.473 208.684 158.191 208.684C158.909 208.684 159.497 209.271 159.497 209.989Z"
          fill="#CE7406"
        />
        <path
          d="M148.532 214.818C149.253 214.818 149.837 214.234 149.837 213.513C149.837 212.792 149.253 212.208 148.532 212.208C147.811 212.208 147.227 212.792 147.227 213.513C147.227 214.234 147.811 214.818 148.532 214.818Z"
          fill="#CE7406"
        />
        <path
          d="M150.816 198.959C150.816 199.611 150.293 200.134 149.641 200.134C148.988 200.134 148.531 199.611 148.531 198.959C148.531 198.306 149.053 197.784 149.641 197.784C150.359 197.849 150.816 198.371 150.816 198.959Z"
          fill="#CE7406"
        />
        <path
          d="M139.46 216.32C139.46 216.973 138.938 217.429 138.285 217.429C137.632 217.429 137.175 216.907 137.175 216.32C137.175 215.667 137.697 215.145 138.285 215.145C139.003 215.21 139.46 215.732 139.46 216.32Z"
          fill="#CE7406"
        />
        <path
          d="M136.587 207.443C136.587 208.096 136.065 208.553 135.478 208.553C134.825 208.553 134.303 208.031 134.303 207.443C134.303 206.79 134.825 206.268 135.478 206.268C136.065 206.334 136.587 206.79 136.587 207.443Z"
          fill="#CE7406"
        />
        <path
          d="M142.397 209.793C142.397 210.12 142.136 210.381 141.81 210.381C141.483 210.381 141.222 210.12 141.222 209.793C141.222 209.467 141.483 209.206 141.81 209.206C142.136 209.206 142.397 209.467 142.397 209.793Z"
          fill="#CE7406"
        />
        <path
          d="M156.036 217.625C156.36 217.625 156.623 217.362 156.623 217.038C156.623 216.713 156.36 216.45 156.036 216.45C155.711 216.45 155.448 216.713 155.448 217.038C155.448 217.362 155.711 217.625 156.036 217.625Z"
          fill="#CE7406"
        />
        <path
          d="M142.331 203.462C142.331 204.049 141.809 204.571 141.222 204.571C140.634 204.571 140.112 204.049 140.112 203.462C140.112 202.874 140.634 202.352 141.222 202.352C141.874 202.352 142.331 202.874 142.331 203.462Z"
          fill="#CE7406"
        />
        <path
          d="M146.9 195.108C146.9 195.564 146.508 195.956 146.051 195.956C145.594 195.956 145.203 195.564 145.203 195.108C145.203 194.651 145.594 194.259 146.051 194.259C146.508 194.259 146.9 194.651 146.9 195.108Z"
          fill="#CE7406"
        />
        <path
          d="M207.861 167.891C207.861 169.327 206.686 170.502 205.25 170.502C203.814 170.502 202.64 169.327 202.64 167.891C202.64 166.455 203.814 165.28 205.25 165.28C206.686 165.28 207.861 166.455 207.861 167.891Z"
          fill="#CE7406"
        />
        <path
          d="M204.794 185.513C204.794 186.623 203.945 187.471 202.835 187.471C201.726 187.471 200.877 186.623 200.877 185.513C200.877 184.404 201.726 183.555 202.835 183.555C203.945 183.555 204.794 184.404 204.794 185.513Z"
          fill="#CE7406"
        />
        <path
          d="M189.063 175.136C189.063 176.115 188.28 176.898 187.301 176.898C186.322 176.898 185.539 176.115 185.539 175.136C185.539 174.156 186.322 173.373 187.301 173.373C188.28 173.373 189.063 174.156 189.063 175.136Z"
          fill="#CE7406"
        />
        <path
          d="M205.445 176.898C205.445 177.616 204.857 178.203 204.139 178.203C203.421 178.203 202.834 177.616 202.834 176.898C202.834 176.18 203.421 175.593 204.139 175.593C204.857 175.527 205.445 176.18 205.445 176.898Z"
          fill="#CE7406"
        />
        <path
          d="M195.199 186.819C195.199 187.536 194.611 188.124 193.893 188.124C193.176 188.124 192.588 187.536 192.588 186.819C192.588 186.101 193.176 185.513 193.893 185.513C194.611 185.513 195.199 186.101 195.199 186.819Z"
          fill="#CE7406"
        />
        <path
          d="M197.417 174.287C197.417 174.94 196.895 175.462 196.243 175.462C195.59 175.462 195.133 174.94 195.133 174.287C195.133 173.634 195.655 173.177 196.243 173.177C196.895 173.177 197.417 173.7 197.417 174.287Z"
          fill="#CE7406"
        />
        <path
          d="M185.865 189.495C186.514 189.495 187.04 188.969 187.04 188.32C187.04 187.671 186.514 187.145 185.865 187.145C185.216 187.145 184.69 187.671 184.69 188.32C184.69 188.969 185.216 189.495 185.865 189.495Z"
          fill="#CE7406"
        />
        <path
          d="M187.04 181.858C187.04 182.511 186.518 183.033 185.931 183.033C185.278 183.033 184.756 182.511 184.756 181.858C184.756 181.205 185.278 180.749 185.931 180.749C186.518 180.749 187.04 181.271 187.04 181.858Z"
          fill="#CE7406"
        />
        <path
          d="M195.655 182.837C195.979 182.837 196.242 182.574 196.242 182.25C196.242 181.925 195.979 181.662 195.655 181.662C195.33 181.662 195.067 181.925 195.067 182.25C195.067 182.574 195.33 182.837 195.655 182.837Z"
          fill="#CE7406"
        />
        <path
          d="M195.656 191.257C195.656 191.583 195.395 191.844 195.068 191.844C194.742 191.844 194.481 191.583 194.481 191.257C194.481 190.931 194.742 190.669 195.068 190.669C195.395 190.669 195.656 190.931 195.656 191.257Z"
          fill="#CE7406"
        />
        <path
          d="M181.623 175.722C181.623 176.31 181.101 176.832 180.513 176.832C179.926 176.832 179.404 176.31 179.404 175.722C179.404 175.135 179.926 174.613 180.513 174.613C181.101 174.613 181.623 175.135 181.623 175.722Z"
          fill="#CE7406"
        />
        <path
          d="M189.911 166.586C189.911 167.042 189.519 167.434 189.062 167.434C188.605 167.434 188.214 167.042 188.214 166.586C188.214 166.129 188.605 165.737 189.062 165.737C189.584 165.737 189.911 166.129 189.911 166.586Z"
          fill="#CE7406"
        />
        <path
          d="M184.168 169.784C184.168 171.219 182.994 172.394 181.558 172.394C180.122 172.394 178.947 171.219 178.947 169.784C178.947 168.348 180.122 167.173 181.558 167.173C182.994 167.173 184.168 168.348 184.168 169.784Z"
          fill="#CE7406"
        />
        <path
          d="M177.054 182.772C177.054 183.881 176.206 184.73 175.096 184.73C173.986 184.73 173.138 183.881 173.138 182.772C173.138 181.662 173.986 180.814 175.096 180.814C176.206 180.814 177.054 181.727 177.054 182.772Z"
          fill="#CE7406"
        />
        <path
          d="M168.112 170.567C168.112 171.546 167.329 172.329 166.35 172.329C165.371 172.329 164.588 171.546 164.588 170.567C164.588 169.588 165.371 168.805 166.35 168.805C167.329 168.805 168.112 169.588 168.112 170.567Z"
          fill="#CE7406"
        />
        <path
          d="M169.482 185.513C169.482 186.165 168.96 186.688 168.308 186.688C167.655 186.688 167.198 186.165 167.198 185.513C167.198 184.86 167.72 184.338 168.308 184.338C169.026 184.338 169.482 184.86 169.482 185.513Z"
          fill="#CE7406"
        />
        <path
          d="M162.956 188.32C162.956 188.973 162.434 189.495 161.781 189.495C161.128 189.495 160.671 188.973 160.671 188.32C160.671 187.667 161.194 187.145 161.781 187.145C162.434 187.145 162.956 187.667 162.956 188.32Z"
          fill="#CE7406"
        />
        <path
          d="M170.2 180.16C170.2 180.487 169.939 180.748 169.613 180.748C169.286 180.748 169.025 180.487 169.025 180.16C169.025 179.834 169.286 179.573 169.613 179.573C169.939 179.638 170.2 179.899 170.2 180.16Z"
          fill="#CE7406"
        />
        <path
          d="M176.989 189.429C176.989 189.755 176.728 190.016 176.401 190.016C176.075 190.016 175.814 189.755 175.814 189.429C175.814 189.103 176.075 188.842 176.401 188.842C176.728 188.842 176.989 189.103 176.989 189.429Z"
          fill="#CE7406"
        />
        <path
          d="M163.544 178.529C163.544 179.117 163.022 179.639 162.434 179.639C161.847 179.639 161.325 179.117 161.325 178.529C161.325 177.942 161.847 177.42 162.434 177.42C163.022 177.42 163.544 177.942 163.544 178.529Z"
          fill="#CE7406"
        />
        <path
          d="M174.573 169.653C174.573 170.11 174.181 170.501 173.724 170.501C173.268 170.501 172.876 170.11 172.876 169.653C172.876 169.196 173.268 168.804 173.724 168.804C174.181 168.739 174.573 169.131 174.573 169.653Z"
          fill="#CE7406"
        />
        <path
          d="M159.432 172.329C159.432 173.765 158.257 174.939 156.821 174.939C155.385 174.939 154.21 173.765 154.21 172.329C154.21 170.893 155.385 169.718 156.821 169.718C158.257 169.718 159.432 170.958 159.432 172.329Z"
          fill="#CE7406"
        />
        <path
          d="M153.297 179.639C153.297 180.748 152.449 181.597 151.339 181.597C150.23 181.597 149.381 180.748 149.381 179.639C149.381 178.529 150.23 177.681 151.339 177.681C152.384 177.681 153.297 178.529 153.297 179.639Z"
          fill="#CE7406"
        />
        <path
          d="M133.651 168.152C133.651 169.131 132.867 169.914 131.888 169.914C130.909 169.914 130.126 169.131 130.126 168.152C130.126 167.173 130.909 166.389 131.888 166.389C132.867 166.389 133.651 167.173 133.651 168.152Z"
          fill="#CE7406"
        />
        <path
          d="M154.603 185.513C154.603 186.231 154.015 186.818 153.297 186.818C152.579 186.818 151.992 186.231 151.992 185.513C151.992 184.795 152.579 184.208 153.297 184.208C154.015 184.142 154.603 184.73 154.603 185.513Z"
          fill="#CE7406"
        />
        <path
          d="M145.921 188.319C145.921 189.037 145.333 189.624 144.615 189.624C143.897 189.624 143.31 189.037 143.31 188.319C143.31 187.601 143.897 187.014 144.615 187.014C145.333 186.948 145.921 187.536 145.921 188.319Z"
          fill="#CE7406"
        />
        <path
          d="M150.816 173.177C150.816 173.83 150.294 174.287 149.641 174.287C148.989 174.287 148.532 173.764 148.532 173.177C148.532 172.524 149.054 172.002 149.641 172.002C150.359 172.002 150.816 172.524 150.816 173.177Z"
          fill="#CE7406"
        />
        <path
          d="M139.459 198.175C139.459 198.828 138.937 199.35 138.285 199.35C137.632 199.35 137.175 198.828 137.175 198.175C137.175 197.522 137.697 197.066 138.285 197.066C139.003 197 139.459 197.522 139.459 198.175Z"
          fill="#CE7406"
        />
        <path
          d="M134.826 181.597C134.826 182.249 134.304 182.772 133.717 182.772C133.064 182.772 132.542 182.249 132.542 181.597C132.542 180.944 133.064 180.487 133.717 180.487C134.304 180.422 134.826 180.944 134.826 181.597Z"
          fill="#CE7406"
        />
        <path
          d="M145.725 182.249C145.725 182.576 145.464 182.837 145.137 182.837C144.811 182.837 144.55 182.576 144.55 182.249C144.55 181.923 144.811 181.662 145.137 181.662C145.464 181.662 145.725 181.923 145.725 182.249Z"
          fill="#CE7406"
        />
        <path
          d="M156.624 191.257C156.624 191.583 156.363 191.844 156.036 191.844C155.71 191.844 155.449 191.583 155.449 191.257C155.449 190.93 155.71 190.669 156.036 190.669C156.363 190.669 156.624 190.93 156.624 191.257Z"
          fill="#CE7406"
        />
        <path
          d="M142.332 177.681C142.332 178.268 141.81 178.79 141.222 178.79C140.635 178.79 140.113 178.268 140.113 177.681C140.113 177.093 140.635 176.571 141.222 176.571C141.875 176.571 142.332 177.028 142.332 177.681Z"
          fill="#CE7406"
        />
        <path
          d="M146.9 169.326C146.9 169.783 146.508 170.175 146.052 170.175C145.595 170.175 145.203 169.783 145.203 169.326C145.203 168.869 145.595 168.478 146.052 168.478C146.508 168.413 146.9 168.804 146.9 169.326Z"
          fill="#CE7406"
        />
        <path
          d="M205.903 151.835C205.903 152.945 205.055 153.793 203.945 153.793C202.836 153.793 201.987 152.945 201.987 151.835C201.987 150.725 202.836 149.877 203.945 149.877C205.055 149.942 205.903 150.791 205.903 151.835Z"
          fill="#CE7406"
        />
        <path
          d="M195.72 149.355C195.72 150.334 194.937 151.117 193.958 151.117C192.979 151.117 192.196 150.334 192.196 149.355C192.196 148.376 192.979 147.592 193.958 147.592C194.937 147.527 195.72 148.376 195.72 149.355Z"
          fill="#CE7406"
        />
        <path
          d="M208.515 157.057C208.515 157.775 207.927 158.362 207.209 158.362C206.491 158.362 205.904 157.775 205.904 157.057C205.904 156.339 206.491 155.751 207.209 155.751C207.927 155.751 208.515 156.339 208.515 157.057Z"
          fill="#CE7406"
        />
        <path
          d="M195.851 156.991C195.851 157.709 195.263 158.297 194.545 158.297C193.827 158.297 193.24 157.709 193.24 156.991C193.24 156.273 193.827 155.686 194.545 155.686C195.263 155.686 195.851 156.273 195.851 156.991Z"
          fill="#CE7406"
        />
        <path
          d="M202.052 161.364C202.052 162.017 201.53 162.539 200.877 162.539C200.224 162.539 199.768 162.017 199.768 161.364C199.768 160.711 200.29 160.254 200.877 160.254C201.53 160.189 202.052 160.711 202.052 161.364Z"
          fill="#CE7406"
        />
        <path
          d="M188.476 153.989C188.476 154.641 187.954 155.098 187.301 155.098C186.648 155.098 186.191 154.576 186.191 153.989C186.191 153.336 186.714 152.814 187.301 152.814C187.954 152.814 188.476 153.336 188.476 153.989Z"
          fill="#CE7406"
        />
        <path
          d="M196.829 166.063C196.829 166.39 196.568 166.651 196.242 166.651C195.916 166.651 195.655 166.39 195.655 166.063C195.655 165.737 195.916 165.476 196.242 165.476C196.568 165.476 196.829 165.737 196.829 166.063Z"
          fill="#CE7406"
        />
        <path
          d="M181.623 151.835C181.623 152.422 181.101 152.944 180.513 152.944C179.926 152.944 179.404 152.422 179.404 151.835C179.404 151.247 179.926 150.725 180.513 150.725C181.101 150.725 181.623 151.247 181.623 151.835Z"
          fill="#CE7406"
        />
        <path
          d="M185.865 162.147C185.865 163.257 185.017 164.105 183.907 164.105C182.798 164.105 181.949 163.257 181.949 162.147C181.949 161.038 182.798 160.189 183.907 160.189C184.952 160.189 185.865 161.103 185.865 162.147Z"
          fill="#CE7406"
        />
        <path
          d="M177.772 157.709C177.772 158.427 177.185 159.015 176.467 159.015C175.749 159.015 175.162 158.427 175.162 157.709C175.162 156.991 175.749 156.404 176.467 156.404C177.185 156.404 177.772 156.991 177.772 157.709Z"
          fill="#CE7406"
        />
        <path
          d="M169.418 159.536C169.418 160.254 168.831 160.842 168.113 160.842C167.395 160.842 166.807 160.254 166.807 159.536C166.807 158.818 167.395 158.231 168.113 158.231C168.831 158.166 169.418 158.753 169.418 159.536Z"
          fill="#CE7406"
        />
        <path
          d="M174.835 149.877C174.835 150.53 174.313 151.052 173.726 151.052C173.073 151.052 172.551 150.53 172.551 149.877C172.551 149.224 173.073 148.702 173.726 148.702C174.313 148.767 174.835 149.29 174.835 149.877Z"
          fill="#CE7406"
        />
        <path
          d="M168.374 164.889C168.374 165.542 167.852 165.999 167.264 165.999C166.612 165.999 166.09 165.477 166.09 164.889C166.09 164.237 166.612 163.714 167.264 163.714C167.852 163.78 168.374 164.237 168.374 164.889Z"
          fill="#CE7406"
        />
        <path
          d="M170.201 154.381C170.201 154.707 169.94 154.968 169.613 154.968C169.287 154.968 169.026 154.707 169.026 154.381C169.026 154.054 169.287 153.793 169.613 153.793C169.94 153.793 170.201 154.054 170.201 154.381Z"
          fill="#CE7406"
        />
        <path
          d="M175.096 164.693C175.421 164.693 175.684 164.43 175.684 164.105C175.684 163.781 175.421 163.518 175.096 163.518C174.772 163.518 174.509 163.781 174.509 164.105C174.509 164.43 174.772 164.693 175.096 164.693Z"
          fill="#CE7406"
        />
        <path
          d="M163.544 152.749C163.544 153.336 163.021 153.859 162.434 153.859C161.847 153.859 161.324 153.336 161.324 152.749C161.324 152.162 161.847 151.639 162.434 151.639C163.021 151.639 163.544 152.096 163.544 152.749Z"
          fill="#CE7406"
        />
        <path
          d="M153.297 153.793C153.297 154.903 152.448 155.751 151.339 155.751C150.229 155.751 149.381 154.903 149.381 153.793C149.381 152.683 150.229 151.835 151.339 151.835C152.383 151.835 153.297 152.749 153.297 153.793Z"
          fill="#CE7406"
        />
        <path
          d="M159.497 158.362C159.497 159.08 158.909 159.667 158.191 159.667C157.473 159.667 156.886 159.08 156.886 158.362C156.886 157.644 157.473 157.057 158.191 157.057C158.909 157.057 159.497 157.644 159.497 158.362Z"
          fill="#CE7406"
        />
        <path
          d="M149.38 162.474C149.38 163.192 148.793 163.779 148.075 163.779C147.357 163.779 146.77 163.192 146.77 162.474C146.77 161.756 147.357 161.168 148.075 161.168C148.793 161.168 149.38 161.756 149.38 162.474Z"
          fill="#CE7406"
        />
        <path
          d="M139.46 151.835C139.46 152.488 138.938 152.945 138.285 152.945C137.632 152.945 137.175 152.423 137.175 151.835C137.175 151.183 137.697 150.66 138.285 150.66C139.003 150.726 139.46 151.248 139.46 151.835Z"
          fill="#CE7406"
        />
        <path
          d="M142.396 164.889C142.396 165.542 141.874 165.999 141.287 165.999C140.634 165.999 140.112 165.477 140.112 164.889C140.112 164.237 140.634 163.714 141.287 163.714C141.874 163.78 142.396 164.237 142.396 164.889Z"
          fill="#CE7406"
        />
        <path
          d="M132.41 158.362C132.41 159.014 131.888 159.537 131.236 159.537C130.583 159.537 130.061 159.014 130.061 158.362C130.061 157.709 130.583 157.252 131.236 157.252C131.888 157.252 132.41 157.709 132.41 158.362Z"
          fill="#CE7406"
        />
        <path
          d="M145.724 156.404C145.724 156.73 145.463 156.991 145.137 156.991C144.811 156.991 144.55 156.73 144.55 156.404C144.55 156.077 144.811 155.816 145.137 155.816C145.463 155.816 145.724 156.077 145.724 156.404Z"
          fill="#CE7406"
        />
        <path
          d="M156.623 165.476C156.623 165.802 156.362 166.063 156.036 166.063C155.71 166.063 155.448 165.802 155.448 165.476C155.448 165.149 155.71 164.888 156.036 164.888C156.362 164.888 156.623 165.149 156.623 165.476Z"
          fill="#CE7406"
        />
        <path
          d="M128.886 150.465C128.886 151.052 128.364 151.574 127.777 151.574C127.189 151.574 126.667 151.052 126.667 150.465C126.667 149.877 127.189 149.355 127.777 149.355C128.364 149.355 128.886 149.877 128.886 150.465Z"
          fill="#CE7406"
        />
        <path d="M204.139 219.583L200.289 248.692H136.457L132.606 219.583H204.139Z" fill="#C8721B" />
        <path opacity="0.8" d="M217.977 115.024L213.604 148.049H123.142L118.769 115.024H217.977Z" fill="#F8DE8A" />
        <path
          d="M217.977 115.024C192.914 121.942 176.401 133.951 165.697 148.049C148.662 170.502 146.378 198.24 148.009 219.583C148.988 232.637 151.534 243.275 152.97 248.693H136.392L132.541 219.583L123.142 148.049L118.769 115.024H217.977Z"
          fill="#F8DE8A"
        />
        <path
          d="M200.289 223.303C200.289 224.739 199.114 225.914 197.678 225.914C196.242 225.914 195.067 224.739 195.067 223.303C195.067 221.867 196.242 220.693 197.678 220.693C199.114 220.693 200.289 221.867 200.289 223.303Z"
          fill="#EE9833"
        />
        <path
          d="M198.397 237.075C198.397 238.185 197.548 239.033 196.439 239.033C195.329 239.033 194.481 238.185 194.481 237.075C194.481 235.965 195.329 235.117 196.439 235.117C197.548 235.117 198.397 235.965 198.397 237.075Z"
          fill="#EE9833"
        />
        <path
          d="M187.039 228.46C187.039 229.439 186.256 230.222 185.277 230.222C184.298 230.222 183.515 229.439 183.515 228.46C183.515 227.481 184.298 226.698 185.277 226.698C186.256 226.698 187.039 227.546 187.039 228.46Z"
          fill="#EE9833"
        />
        <path
          d="M199.833 230.418C199.833 231.136 199.245 231.723 198.527 231.723C197.809 231.723 197.222 231.136 197.222 230.418C197.222 229.7 197.809 229.112 198.527 229.112C199.245 229.112 199.833 229.7 199.833 230.418Z"
          fill="#EE9833"
        />
        <path
          d="M190.824 239.033C190.824 239.751 190.237 240.338 189.519 240.338C188.801 240.338 188.214 239.751 188.214 239.033C188.214 238.315 188.801 237.728 189.519 237.728C190.237 237.728 190.824 238.315 190.824 239.033Z"
          fill="#EE9833"
        />
        <path
          d="M191.74 226.698C191.74 227.35 191.218 227.872 190.565 227.872C189.913 227.872 189.456 227.35 189.456 226.698C189.456 226.045 189.978 225.523 190.565 225.523C191.283 225.588 191.74 226.11 191.74 226.698Z"
          fill="#EE9833"
        />
        <path
          d="M187.04 244.255C187.04 244.907 186.518 245.364 185.93 245.364C185.278 245.364 184.756 244.842 184.756 244.255C184.756 243.602 185.278 243.08 185.93 243.08C186.518 243.08 187.04 243.602 187.04 244.255Z"
          fill="#EE9833"
        />
        <path
          d="M185.018 236.357C185.018 237.01 184.495 237.532 183.843 237.532C183.19 237.532 182.668 237.01 182.668 236.357C182.668 235.704 183.19 235.247 183.843 235.247C184.495 235.182 185.018 235.704 185.018 236.357Z"
          fill="#EE9833"
        />
        <path
          d="M191.218 233.746C191.218 234.072 190.956 234.334 190.63 234.334C190.304 234.334 190.043 234.072 190.043 233.746C190.043 233.42 190.304 233.159 190.63 233.159C190.956 233.159 191.218 233.42 191.218 233.746Z"
          fill="#EE9833"
        />
        <path
          d="M195.656 244.841C195.656 245.168 195.394 245.429 195.068 245.429C194.742 245.429 194.481 245.168 194.481 244.841C194.481 244.515 194.742 244.254 195.068 244.254C195.394 244.254 195.656 244.515 195.656 244.841Z"
          fill="#EE9833"
        />
        <path
          d="M179.468 232.441C179.468 233.029 178.946 233.551 178.359 233.551C177.771 233.551 177.249 233.029 177.249 232.441C177.249 231.854 177.771 231.332 178.359 231.332C179.012 231.332 179.468 231.788 179.468 232.441Z"
          fill="#EE9833"
        />
        <path
          d="M187.888 221.671C187.888 222.128 187.497 222.52 187.04 222.52C186.583 222.52 186.191 222.128 186.191 221.671C186.191 221.215 186.583 220.823 187.04 220.823C187.497 220.823 187.888 221.215 187.888 221.671Z"
          fill="#EE9833"
        />
        <path
          d="M182.275 223.303C182.275 224.739 181.1 225.914 179.664 225.914C178.228 225.914 177.053 224.739 177.053 223.303C177.053 221.867 178.228 220.693 179.664 220.693C181.1 220.693 182.275 221.867 182.275 223.303Z"
          fill="#EE9833"
        />
        <path
          d="M178.36 239.686C178.36 240.795 177.512 241.644 176.402 241.644C175.293 241.644 174.444 240.795 174.444 239.686C174.444 238.576 175.293 237.728 176.402 237.728C177.512 237.728 178.36 238.576 178.36 239.686Z"
          fill="#EE9833"
        />
        <path
          d="M168.112 224.151C168.112 225.13 167.329 225.914 166.35 225.914C165.371 225.914 164.588 225.13 164.588 224.151C164.588 223.172 165.371 222.389 166.35 222.389C167.329 222.389 168.112 223.172 168.112 224.151Z"
          fill="#EE9833"
        />
        <path
          d="M167.394 237.466C167.394 238.184 166.807 238.772 166.089 238.772C165.371 238.772 164.784 238.184 164.784 237.466C164.784 236.748 165.371 236.161 166.089 236.161C166.807 236.161 167.394 236.748 167.394 237.466Z"
          fill="#EE9833"
        />
        <path
          d="M173.659 229.83C173.659 230.483 173.137 230.94 172.484 230.94C171.831 230.94 171.374 230.418 171.374 229.83C171.374 229.178 171.896 228.656 172.484 228.656C173.137 228.656 173.659 229.178 173.659 229.83Z"
          fill="#EE9833"
        />
        <path
          d="M168.374 244.255C168.374 244.907 167.852 245.364 167.264 245.364C166.612 245.364 166.09 244.842 166.09 244.255C166.09 243.602 166.612 243.08 167.264 243.08C167.852 243.08 168.374 243.602 168.374 244.255Z"
          fill="#EE9833"
        />
        <path
          d="M162.956 241.839C162.956 242.492 162.433 242.949 161.781 242.949C161.128 242.949 160.671 242.427 160.671 241.839C160.671 241.187 161.193 240.665 161.781 240.665C162.433 240.73 162.956 241.187 162.956 241.839Z"
          fill="#EE9833"
        />
        <path
          d="M170.201 233.746C170.201 234.072 169.94 234.334 169.613 234.334C169.287 234.334 169.026 234.072 169.026 233.746C169.026 233.42 169.287 233.159 169.613 233.159C169.94 233.159 170.201 233.42 170.201 233.746Z"
          fill="#EE9833"
        />
        <path
          d="M176.99 244.841C176.99 245.168 176.728 245.429 176.402 245.429C176.076 245.429 175.815 245.168 175.815 244.841C175.815 244.515 176.076 244.254 176.402 244.254C176.728 244.254 176.99 244.515 176.99 244.841Z"
          fill="#EE9833"
        />
        <path
          d="M174.574 223.173C174.574 223.63 174.182 224.021 173.725 224.021C173.268 224.021 172.877 223.63 172.877 223.173C172.877 222.716 173.268 222.324 173.725 222.324C174.182 222.324 174.574 222.716 174.574 223.173Z"
          fill="#EE9833"
        />
        <path
          d="M159.432 225.914C159.432 227.35 158.257 228.525 156.821 228.525C155.385 228.525 154.21 227.35 154.21 225.914C154.21 224.478 155.385 223.303 156.821 223.303C158.257 223.303 159.432 224.478 159.432 225.914Z"
          fill="#EE9833"
        />
        <path
          d="M153.297 233.159C153.297 234.268 152.448 235.117 151.339 235.117C150.229 235.117 149.381 234.268 149.381 233.159C149.381 232.049 150.229 231.201 151.339 231.201C152.383 231.201 153.297 232.114 153.297 233.159Z"
          fill="#EE9833"
        />
        <path
          d="M140.112 223.173C140.112 224.152 139.328 224.935 138.349 224.935C137.37 224.935 136.587 224.152 136.587 223.173C136.587 222.194 137.37 221.411 138.349 221.411C139.328 221.411 140.112 222.194 140.112 223.173Z"
          fill="#EE9833"
        />
        <path
          d="M159.497 235.052C160.218 235.052 160.803 234.468 160.803 233.747C160.803 233.026 160.218 232.441 159.497 232.441C158.776 232.441 158.192 233.026 158.192 233.747C158.192 234.468 158.776 235.052 159.497 235.052Z"
          fill="#EE9833"
        />
        <path
          d="M151.207 239.686C151.207 240.404 150.62 240.991 149.902 240.991C149.184 240.991 148.597 240.404 148.597 239.686C148.597 238.968 149.184 238.381 149.902 238.381C150.62 238.381 151.207 238.968 151.207 239.686Z"
          fill="#EE9833"
        />
        <path
          d="M150.816 226.698C150.816 227.35 150.293 227.872 149.641 227.872C148.988 227.872 148.531 227.35 148.531 226.698C148.531 226.045 149.053 225.523 149.641 225.523C150.359 225.588 150.816 226.11 150.816 226.698Z"
          fill="#EE9833"
        />
        <path
          d="M144.42 244.843C144.42 245.495 143.897 246.017 143.31 246.017C142.657 246.017 142.135 245.495 142.135 244.843C142.135 244.19 142.657 243.733 143.31 243.733C143.897 243.668 144.42 244.19 144.42 244.843Z"
          fill="#EE9833"
        />
        <path
          d="M139.46 237.728C139.46 238.381 138.938 238.903 138.285 238.903C137.632 238.903 137.175 238.381 137.175 237.728C137.175 237.075 137.697 236.618 138.285 236.618C139.003 236.553 139.46 237.075 139.46 237.728Z"
          fill="#EE9833"
        />
        <path
          d="M145.725 235.77C145.725 236.096 145.464 236.357 145.138 236.357C144.812 236.357 144.551 236.096 144.551 235.77C144.551 235.443 144.812 235.182 145.138 235.182C145.464 235.182 145.725 235.443 145.725 235.77Z"
          fill="#EE9833"
        />
        <path
          d="M156.623 244.842C156.623 245.168 156.362 245.429 156.036 245.429C155.71 245.429 155.448 245.168 155.448 244.842C155.448 244.515 155.71 244.254 156.036 244.254C156.362 244.254 156.623 244.515 156.623 244.842Z"
          fill="#EE9833"
        />
        <path
          d="M142.331 231.201C142.331 231.788 141.809 232.31 141.222 232.31C140.634 232.31 140.112 231.788 140.112 231.201C140.112 230.613 140.634 230.091 141.222 230.091C141.874 230.091 142.331 230.613 142.331 231.201Z"
          fill="#EE9833"
        />
        <path
          d="M146.9 222.846C146.9 223.303 146.508 223.695 146.051 223.695C145.594 223.695 145.203 223.303 145.203 222.846C145.203 222.389 145.594 221.998 146.051 221.998C146.508 221.998 146.9 222.389 146.9 222.846Z"
          fill="#EE9833"
        />
        <path opacity="0.8" d="M224.177 108.301H112.634V114.958H224.177V108.301Z" fill="#DFC262" />
        <path
          opacity="0.3"
          d="M142.201 113.979C142.788 114.566 143.637 114.958 144.55 114.958H112.634V108.301H144.55C142.723 108.301 141.222 109.802 141.222 111.629C141.222 112.543 141.613 113.391 142.201 113.979Z"
          fill="#DFC262"
        />
        <path opacity="0.8" d="M219.343 101.169H117.073V108.174H219.343V101.169Z" fill="#DFC262" />
        <path
          opacity="0.3"
          d="M140.96 107.126C141.482 107.649 142.265 108.04 143.114 108.04H117.268V101.97H143.114C141.417 101.97 140.046 103.341 140.046 105.038C140.111 105.821 140.438 106.604 140.96 107.126Z"
          fill="#DFC262"
        />
        <path
          opacity="0.8"
          d="M200.486 190.278C200.421 190.278 200.29 190.278 200.225 190.278C199.181 190.147 198.463 189.168 198.593 188.124L202.575 158.035C202.705 156.991 203.684 156.273 204.728 156.403C205.773 156.534 206.491 157.513 206.36 158.557L202.379 188.646C202.183 189.56 201.4 190.278 200.486 190.278Z"
          fill="white"
        />
        <path
          opacity="0.8"
          d="M206.882 136.497C206.816 136.497 206.686 136.497 206.621 136.497C205.576 136.366 204.858 135.387 204.989 134.343L206.033 126.641C206.164 125.597 207.143 124.879 208.187 125.009C209.231 125.14 209.949 126.119 209.819 127.163L208.774 134.865C208.644 135.844 207.795 136.497 206.882 136.497Z"
          fill="white"
        />
        <path
          d="M145.594 179.704H46.9092C41.2308 179.704 37.2495 174.352 38.5549 169.065C38.6201 168.673 38.7507 168.347 38.8812 167.955C45.9301 149.876 68.9698 136.627 96.2518 136.627C123.534 136.627 146.573 149.876 153.622 167.955C153.753 168.347 153.883 168.673 153.949 169.065C155.319 174.417 151.273 179.704 145.594 179.704Z"
          fill="#F4AC40"
        />
        <path
          d="M154.209 231.071V239.36C154.209 244.516 150.031 248.693 144.875 248.693H45.9942C40.838 248.693 36.6609 244.516 36.6609 239.36V231.071C36.6609 230.483 36.7262 229.896 36.8567 229.308C37.6399 224.936 41.4907 221.672 46.0595 221.672H144.875C149.444 221.672 153.295 224.936 154.078 229.308C154.143 229.896 154.209 230.418 154.209 231.071Z"
          fill="#F4AC40"
        />
        <path
          d="M154.013 229.308H36.8572C37.6404 224.936 41.4912 221.672 46.06 221.672H144.876C149.379 221.672 153.23 224.936 154.013 229.308Z"
          fill="#F0B865"
        />
        <path
          opacity="0.4"
          d="M91.8124 248.693H45.9942C40.838 248.693 36.6609 244.516 36.6609 239.36V231.071C36.6609 230.483 36.7262 229.896 36.8567 229.308C37.6399 224.936 41.4907 221.672 46.0595 221.672H91.8124C88.0921 221.672 84.6981 223.173 82.2832 225.653C79.803 228.068 78.3019 231.462 78.3019 235.183C78.3019 242.688 84.3718 248.693 91.8124 248.693Z"
          fill="#F4AC40"
        />
        <path
          opacity="0.2"
          d="M79.8042 228.199C79.739 228.264 79.6737 228.33 79.6737 228.395C78.4989 230.157 76.1492 232.115 71.711 232.18C66.9464 232.311 65.1842 230.092 64.5968 228.199C64.401 227.546 63.4872 227.351 63.0956 227.938C62.0513 229.505 60.0933 231.332 56.6994 232.05C51.6737 233.029 51.4779 228.199 51.6085 226.045C51.2821 228.264 50.042 233.421 45.6691 231.724C42.6667 230.549 42.6015 228.787 43.1236 227.416C43.3847 226.698 42.7973 226.045 42.0793 226.241C39.3381 227.155 35.2915 227.938 33.8556 225.327C31.8323 221.477 40.1866 220.041 40.9698 219.91C41.035 219.91 41.035 219.91 41.1003 219.91L80.9138 219.192C79.1516 222.912 79.0863 225.849 79.8042 228.199Z"
          fill="#3C8939"
        />
        <path
          d="M157.799 225.327C153.752 229.243 149.64 227.22 148.074 226.175C147.682 225.914 147.16 226.045 146.899 226.437C146.181 227.416 144.615 228.982 141.286 229.112C138.153 229.243 136.13 227.677 135.151 226.502C134.759 226.11 134.107 226.175 133.78 226.632C132.801 228.133 130.582 229.7 127.058 230.092C124.316 230.353 122.554 228.656 121.51 227.22C121.118 226.632 120.27 226.763 120.009 227.35C119.095 229.569 116.354 232.637 108.391 232.245C101.212 231.854 98.4703 229.504 97.426 227.677C97.0997 227.089 96.2512 227.089 95.9249 227.677C94.9459 229.504 92.792 231.788 88.2885 232.18C84.1114 232.572 82.2839 230.483 81.5006 228.525C81.2396 227.807 80.3258 227.677 79.8689 228.199C79.8037 228.264 79.7384 228.329 79.7384 228.395C79.2163 229.178 78.433 230.026 77.3888 230.679C76.0834 231.527 74.2559 232.115 71.7757 232.18C67.0111 232.311 65.2489 230.092 64.6615 228.199C64.4657 227.546 63.5519 227.35 63.1603 227.938C62.116 229.504 60.158 231.332 56.7641 232.05C51.7384 233.029 51.5426 228.199 51.6732 226.045C51.3468 228.264 50.1067 233.42 45.7338 231.723C42.7314 230.548 42.6662 228.786 43.1883 227.416C43.4494 226.698 42.862 226.045 42.144 226.241C39.4028 227.154 35.3562 227.938 33.9203 225.327C31.897 221.476 40.2513 220.04 41.0345 219.91C41.0997 219.91 41.0997 219.91 41.165 219.91L77.9109 219.257L81.0438 219.192L151.076 217.952C151.142 217.952 151.207 217.952 151.272 217.952C152.121 218.213 162.759 220.562 157.799 225.327Z"
          fill="#3C8939"
        />
        <path
          opacity="0.4"
          d="M77.3241 230.679C76.0187 231.527 74.1912 232.115 71.711 232.18C66.9464 232.311 65.1842 230.092 64.5968 228.199C64.401 227.546 63.4872 227.35 63.0956 227.938C62.0514 229.504 60.0933 231.332 56.6994 232.05C51.6737 233.029 51.4779 228.199 51.6085 226.045C51.2821 228.264 50.042 233.42 45.6691 231.723C42.6667 230.548 42.6015 228.786 43.1236 227.416C43.3847 226.698 42.7973 226.045 42.0793 226.241C39.3381 227.155 35.2915 227.938 33.8556 225.327C31.8323 221.476 40.1866 220.04 40.9698 219.91C41.035 219.91 41.035 219.91 41.1003 219.91L77.8462 219.257C75.4966 224.544 75.9534 228.199 77.3241 230.679Z"
          fill="#3C8939"
        />
        <path
          d="M148.597 209.206H44.0375C40.5783 209.206 37.7717 212.012 37.7717 215.471C37.7717 218.931 40.5783 221.737 44.0375 221.737H148.597C152.056 221.737 154.863 218.931 154.863 215.471C154.797 212.012 152.056 209.206 148.597 209.206Z"
          fill="#603A15"
        />
        <path
          d="M45.9301 212.208C46.2185 212.208 46.4523 211.974 46.4523 211.686C46.4523 211.397 46.2185 211.164 45.9301 211.164C45.6417 211.164 45.408 211.397 45.408 211.686C45.408 211.974 45.6417 212.208 45.9301 212.208Z"
          fill="#57330F"
        />
        <path
          d="M55.5904 211.686C55.5904 211.947 55.3294 212.208 55.0683 212.208C54.8072 212.208 54.5461 211.947 54.5461 211.686C54.5461 211.425 54.8072 211.164 55.0683 211.164C55.3946 211.164 55.5904 211.359 55.5904 211.686Z"
          fill="#57330F"
        />
        <path
          d="M64.7931 211.686C64.7931 211.947 64.532 212.208 64.2709 212.208C64.0099 212.208 63.7488 211.947 63.7488 211.686C63.7488 211.425 64.0099 211.164 64.2709 211.164C64.532 211.164 64.7931 211.359 64.7931 211.686Z"
          fill="#57330F"
        />
        <path
          d="M73.9303 211.686C73.9303 211.947 73.6692 212.208 73.4081 212.208C73.1471 212.208 72.886 211.947 72.886 211.686C72.886 211.425 73.1471 211.164 73.4081 211.164C73.6692 211.164 73.9303 211.359 73.9303 211.686Z"
          fill="#57330F"
        />
        <path
          d="M83.0675 211.686C83.0675 211.947 82.8064 212.208 82.5453 212.208C82.2843 212.208 82.0232 211.947 82.0232 211.686C82.0232 211.425 82.2843 211.164 82.5453 211.164C82.8717 211.164 83.0675 211.359 83.0675 211.686Z"
          fill="#57330F"
        />
        <path
          d="M91.7497 212.208C92.038 212.208 92.2718 211.974 92.2718 211.686C92.2718 211.397 92.038 211.164 91.7497 211.164C91.4613 211.164 91.2275 211.397 91.2275 211.686C91.2275 211.974 91.4613 212.208 91.7497 212.208Z"
          fill="#57330F"
        />
        <path
          d="M101.409 211.686C101.409 211.947 101.147 212.208 100.886 212.208C100.625 212.208 100.364 211.947 100.364 211.686C100.364 211.425 100.625 211.164 100.886 211.164C101.213 211.164 101.409 211.359 101.409 211.686Z"
          fill="#57330F"
        />
        <path
          d="M110.611 211.686C110.611 211.947 110.35 212.208 110.089 212.208C109.828 212.208 109.567 211.947 109.567 211.686C109.567 211.425 109.828 211.164 110.089 211.164C110.35 211.164 110.611 211.359 110.611 211.686Z"
          fill="#57330F"
        />
        <path
          d="M119.749 211.686C119.749 211.947 119.488 212.208 119.227 212.208C118.966 212.208 118.705 211.947 118.705 211.686C118.705 211.425 118.966 211.164 119.227 211.164C119.553 211.164 119.749 211.359 119.749 211.686Z"
          fill="#57330F"
        />
        <path
          d="M128.886 211.686C128.886 211.947 128.625 212.208 128.364 212.208C128.103 212.208 127.842 211.947 127.842 211.686C127.842 211.425 128.103 211.164 128.364 211.164C128.69 211.164 128.886 211.359 128.886 211.686Z"
          fill="#57330F"
        />
        <path
          d="M137.566 212.208C137.854 212.208 138.088 211.974 138.088 211.686C138.088 211.397 137.854 211.164 137.566 211.164C137.277 211.164 137.044 211.397 137.044 211.686C137.044 211.974 137.277 212.208 137.566 212.208Z"
          fill="#57330F"
        />
        <path
          d="M146.704 212.208C146.992 212.208 147.226 211.974 147.226 211.686C147.226 211.397 146.992 211.164 146.704 211.164C146.415 211.164 146.181 211.397 146.181 211.686C146.181 211.974 146.415 212.208 146.704 212.208Z"
          fill="#57330F"
        />
        <path
          d="M41.817 214.427C41.817 214.688 41.5559 214.949 41.2948 214.949C41.0338 214.949 40.7727 214.688 40.7727 214.427C40.7727 214.166 41.0338 213.905 41.2948 213.905C41.6212 213.905 41.817 214.101 41.817 214.427Z"
          fill="#57330F"
        />
        <path
          d="M51.0211 214.427C51.0211 214.688 50.76 214.949 50.499 214.949C50.2379 214.949 49.9768 214.688 49.9768 214.427C49.9768 214.166 50.2379 213.905 50.499 213.905C50.76 213.905 51.0211 214.101 51.0211 214.427Z"
          fill="#57330F"
        />
        <path
          d="M60.1593 214.427C60.1593 214.688 59.8982 214.949 59.6371 214.949C59.3761 214.949 59.115 214.688 59.115 214.427C59.115 214.166 59.3761 213.905 59.6371 213.905C59.9635 213.905 60.1593 214.101 60.1593 214.427Z"
          fill="#57330F"
        />
        <path
          d="M69.3624 214.427C69.3624 214.688 69.1013 214.949 68.8403 214.949C68.5792 214.949 68.3181 214.688 68.3181 214.427C68.3181 214.166 68.5792 213.905 68.8403 213.905C69.1013 213.905 69.3624 214.101 69.3624 214.427Z"
          fill="#57330F"
        />
        <path
          d="M78.4991 214.427C78.4991 214.688 78.2381 214.949 77.977 214.949C77.7159 214.949 77.4548 214.688 77.4548 214.427C77.4548 214.166 77.7159 213.905 77.977 213.905C78.3033 213.905 78.4991 214.101 78.4991 214.427Z"
          fill="#57330F"
        />
        <path
          d="M87.7015 214.427C87.7015 214.688 87.4404 214.949 87.1794 214.949C86.9183 214.949 86.6572 214.688 86.6572 214.427C86.6572 214.166 86.9183 213.905 87.1794 213.905C87.4404 213.905 87.7015 214.101 87.7015 214.427Z"
          fill="#57330F"
        />
        <path
          d="M96.8382 214.427C96.8382 214.688 96.5772 214.949 96.3161 214.949C96.055 214.949 95.7939 214.688 95.7939 214.427C95.7939 214.166 96.055 213.905 96.3161 213.905C96.5772 213.905 96.8382 214.101 96.8382 214.427Z"
          fill="#57330F"
        />
        <path
          d="M105.978 214.427C105.978 214.688 105.717 214.949 105.455 214.949C105.194 214.949 104.933 214.688 104.933 214.427C104.933 214.166 105.194 213.905 105.455 213.905C105.782 213.905 105.978 214.101 105.978 214.427Z"
          fill="#57330F"
        />
        <path
          d="M115.18 214.427C115.18 214.688 114.918 214.949 114.657 214.949C114.396 214.949 114.135 214.688 114.135 214.427C114.135 214.166 114.396 213.905 114.657 213.905C114.918 213.905 115.18 214.101 115.18 214.427Z"
          fill="#57330F"
        />
        <path
          d="M124.317 214.427C124.317 214.688 124.056 214.949 123.795 214.949C123.534 214.949 123.273 214.688 123.273 214.427C123.273 214.166 123.534 213.905 123.795 213.905C124.121 213.905 124.317 214.101 124.317 214.427Z"
          fill="#57330F"
        />
        <path
          d="M133.52 214.427C133.52 214.688 133.259 214.949 132.997 214.949C132.736 214.949 132.475 214.688 132.475 214.427C132.475 214.166 132.736 213.905 132.997 213.905C133.259 213.905 133.52 214.101 133.52 214.427Z"
          fill="#57330F"
        />
        <path
          d="M142.659 214.427C142.659 214.688 142.397 214.949 142.136 214.949C141.875 214.949 141.614 214.688 141.614 214.427C141.614 214.166 141.875 213.905 142.136 213.905C142.463 213.905 142.659 214.101 142.659 214.427Z"
          fill="#57330F"
        />
        <path
          d="M151.86 214.427C151.86 214.688 151.599 214.949 151.338 214.949C151.077 214.949 150.816 214.688 150.816 214.427C150.816 214.166 151.077 213.905 151.338 213.905C151.599 213.905 151.86 214.101 151.86 214.427Z"
          fill="#57330F"
        />
        <path
          d="M45.9301 216.842C46.2185 216.842 46.4523 216.609 46.4523 216.32C46.4523 216.032 46.2185 215.798 45.9301 215.798C45.6417 215.798 45.408 216.032 45.408 216.32C45.408 216.609 45.6417 216.842 45.9301 216.842Z"
          fill="#57330F"
        />
        <path
          d="M55.5904 216.32C55.5904 216.581 55.3294 216.842 55.0683 216.842C54.8072 216.842 54.5461 216.581 54.5461 216.32C54.5461 216.059 54.8072 215.798 55.0683 215.798C55.3946 215.798 55.5904 216.059 55.5904 216.32Z"
          fill="#57330F"
        />
        <path
          d="M64.7931 216.32C64.7931 216.581 64.532 216.842 64.2709 216.842C64.0099 216.842 63.7488 216.581 63.7488 216.32C63.7488 216.059 64.0099 215.798 64.2709 215.798C64.532 215.798 64.7931 216.059 64.7931 216.32Z"
          fill="#57330F"
        />
        <path
          d="M73.9303 216.32C73.9303 216.581 73.6692 216.842 73.4081 216.842C73.1471 216.842 72.886 216.581 72.886 216.32C72.886 216.059 73.1471 215.798 73.4081 215.798C73.6692 215.798 73.9303 216.059 73.9303 216.32Z"
          fill="#57330F"
        />
        <path
          d="M83.0675 216.32C83.0675 216.581 82.8064 216.842 82.5453 216.842C82.2843 216.842 82.0232 216.581 82.0232 216.32C82.0232 216.059 82.2843 215.798 82.5453 215.798C82.8717 215.798 83.0675 216.059 83.0675 216.32Z"
          fill="#57330F"
        />
        <path
          d="M91.7497 216.842C92.038 216.842 92.2718 216.609 92.2718 216.32C92.2718 216.032 92.038 215.798 91.7497 215.798C91.4613 215.798 91.2275 216.032 91.2275 216.32C91.2275 216.609 91.4613 216.842 91.7497 216.842Z"
          fill="#57330F"
        />
        <path
          d="M101.409 216.32C101.409 216.581 101.147 216.842 100.886 216.842C100.625 216.842 100.364 216.581 100.364 216.32C100.364 216.059 100.625 215.798 100.886 215.798C101.213 215.798 101.409 216.059 101.409 216.32Z"
          fill="#57330F"
        />
        <path
          d="M110.611 216.32C110.611 216.581 110.35 216.842 110.089 216.842C109.828 216.842 109.567 216.581 109.567 216.32C109.567 216.059 109.828 215.798 110.089 215.798C110.35 215.798 110.611 216.059 110.611 216.32Z"
          fill="#57330F"
        />
        <path
          d="M119.749 216.32C119.749 216.581 119.488 216.842 119.227 216.842C118.966 216.842 118.705 216.581 118.705 216.32C118.705 216.059 118.966 215.798 119.227 215.798C119.553 215.798 119.749 216.059 119.749 216.32Z"
          fill="#57330F"
        />
        <path
          d="M128.886 216.32C128.886 216.581 128.625 216.842 128.364 216.842C128.103 216.842 127.842 216.581 127.842 216.32C127.842 216.059 128.103 215.798 128.364 215.798C128.69 215.798 128.886 216.059 128.886 216.32Z"
          fill="#57330F"
        />
        <path
          d="M137.566 216.842C137.854 216.842 138.088 216.609 138.088 216.32C138.088 216.032 137.854 215.798 137.566 215.798C137.277 215.798 137.044 216.032 137.044 216.32C137.044 216.609 137.277 216.842 137.566 216.842Z"
          fill="#57330F"
        />
        <path
          d="M146.704 216.842C146.992 216.842 147.226 216.609 147.226 216.32C147.226 216.032 146.992 215.798 146.704 215.798C146.415 215.798 146.181 216.032 146.181 216.32C146.181 216.609 146.415 216.842 146.704 216.842Z"
          fill="#57330F"
        />
        <path
          d="M41.817 219.061C41.817 219.322 41.5559 219.583 41.2948 219.583C41.0338 219.583 40.7727 219.322 40.7727 219.061C40.7727 218.8 41.0338 218.539 41.2948 218.539C41.6212 218.539 41.817 218.8 41.817 219.061Z"
          fill="#57330F"
        />
        <path
          d="M51.0211 219.061C51.0211 219.322 50.76 219.583 50.499 219.583C50.2379 219.583 49.9768 219.322 49.9768 219.061C49.9768 218.8 50.2379 218.539 50.499 218.539C50.76 218.539 51.0211 218.8 51.0211 219.061Z"
          fill="#57330F"
        />
        <path
          d="M60.1593 219.061C60.1593 219.322 59.8982 219.583 59.6371 219.583C59.3761 219.583 59.115 219.322 59.115 219.061C59.115 218.8 59.3761 218.539 59.6371 218.539C59.9635 218.539 60.1593 218.8 60.1593 219.061Z"
          fill="#57330F"
        />
        <path
          d="M69.3624 219.061C69.3624 219.322 69.1013 219.583 68.8403 219.583C68.5792 219.583 68.3181 219.322 68.3181 219.061C68.3181 218.8 68.5792 218.539 68.8403 218.539C69.1013 218.539 69.3624 218.8 69.3624 219.061Z"
          fill="#57330F"
        />
        <path
          d="M77.977 219.583C78.2654 219.583 78.4991 219.35 78.4991 219.061C78.4991 218.773 78.2654 218.539 77.977 218.539C77.6886 218.539 77.4548 218.773 77.4548 219.061C77.4548 219.35 77.6886 219.583 77.977 219.583Z"
          fill="#57330F"
        />
        <path
          d="M87.7015 219.061C87.7015 219.322 87.4404 219.583 87.1794 219.583C86.9183 219.583 86.6572 219.322 86.6572 219.061C86.6572 218.8 86.9183 218.539 87.1794 218.539C87.4404 218.539 87.7015 218.8 87.7015 219.061Z"
          fill="#57330F"
        />
        <path
          d="M96.8382 219.061C96.8382 219.322 96.5772 219.583 96.3161 219.583C96.055 219.583 95.7939 219.322 95.7939 219.061C95.7939 218.8 96.055 218.539 96.3161 218.539C96.5772 218.539 96.8382 218.8 96.8382 219.061Z"
          fill="#57330F"
        />
        <path
          d="M105.978 219.061C105.978 219.322 105.717 219.583 105.455 219.583C105.194 219.583 104.933 219.322 104.933 219.061C104.933 218.8 105.194 218.539 105.455 218.539C105.782 218.539 105.978 218.8 105.978 219.061Z"
          fill="#57330F"
        />
        <path
          d="M115.18 219.061C115.18 219.322 114.918 219.583 114.657 219.583C114.396 219.583 114.135 219.322 114.135 219.061C114.135 218.8 114.396 218.539 114.657 218.539C114.918 218.539 115.18 218.8 115.18 219.061Z"
          fill="#57330F"
        />
        <path
          d="M124.317 219.061C124.317 219.322 124.056 219.583 123.795 219.583C123.534 219.583 123.273 219.322 123.273 219.061C123.273 218.8 123.534 218.539 123.795 218.539C124.121 218.539 124.317 218.8 124.317 219.061Z"
          fill="#57330F"
        />
        <path
          d="M132.997 219.583C133.286 219.583 133.52 219.35 133.52 219.061C133.52 218.773 133.286 218.539 132.997 218.539C132.709 218.539 132.475 218.773 132.475 219.061C132.475 219.35 132.709 219.583 132.997 219.583Z"
          fill="#57330F"
        />
        <path
          d="M142.659 219.061C142.659 219.322 142.397 219.583 142.136 219.583C141.875 219.583 141.614 219.322 141.614 219.061C141.614 218.8 141.875 218.539 142.136 218.539C142.463 218.539 142.659 218.8 142.659 219.061Z"
          fill="#57330F"
        />
        <path
          d="M151.86 219.061C151.86 219.322 151.599 219.583 151.338 219.583C151.077 219.583 150.816 219.322 150.816 219.061C150.816 218.8 151.077 218.539 151.338 218.539C151.599 218.539 151.86 218.8 151.86 219.061Z"
          fill="#57330F"
        />
        <path
          opacity="0.4"
          d="M81.5019 219.91C82.6114 221.019 84.1779 221.737 85.9401 221.737H43.9728C42.2758 221.737 40.7094 221.019 39.5345 219.91C38.425 218.8 37.707 217.234 37.707 215.471C37.707 212.012 40.6441 209.206 44.1686 209.206H85.9401C82.4809 209.206 79.6744 212.012 79.6744 215.471C79.6744 217.234 80.3923 218.8 81.5019 219.91Z"
          fill="#603A15"
        />
        <path
          d="M148.661 192.823H44.1673C40.7081 192.823 37.9016 195.63 37.9016 199.089C37.9016 202.548 40.7081 205.355 44.1673 205.355H148.661C152.121 205.355 154.927 202.548 154.927 199.089C154.927 195.63 152.121 192.823 148.661 192.823Z"
          fill="#4E2B0D"
        />
        <path
          d="M46.5169 195.303C46.5169 195.564 46.2559 195.826 45.9948 195.826C45.7337 195.826 45.4727 195.564 45.4727 195.303C45.4727 195.042 45.7337 194.781 45.9948 194.781C46.3211 194.781 46.5169 195.042 46.5169 195.303Z"
          fill="#45270F"
        />
        <path
          d="M55.7196 195.303C55.7196 195.564 55.4585 195.826 55.1974 195.826C54.9364 195.826 54.6753 195.564 54.6753 195.303C54.6753 195.042 54.9364 194.781 55.1974 194.781C55.4585 194.781 55.7196 195.042 55.7196 195.303Z"
          fill="#45270F"
        />
        <path
          d="M64.8575 195.303C64.8575 195.564 64.5964 195.826 64.3354 195.826C64.0743 195.826 63.8132 195.564 63.8132 195.303C63.8132 195.042 64.0743 194.781 64.3354 194.781C64.5964 194.781 64.8575 195.042 64.8575 195.303Z"
          fill="#45270F"
        />
        <path
          d="M73.9942 195.303C73.9942 195.564 73.7332 195.826 73.4721 195.826C73.211 195.826 72.95 195.564 72.95 195.303C72.95 195.042 73.211 194.781 73.4721 194.781C73.7984 194.781 73.9942 195.042 73.9942 195.303Z"
          fill="#45270F"
        />
        <path
          d="M83.1976 195.303C83.1976 195.564 82.9365 195.826 82.6755 195.826C82.4144 195.826 82.1533 195.564 82.1533 195.303C82.1533 195.042 82.4144 194.781 82.6755 194.781C82.9365 194.781 83.1976 195.042 83.1976 195.303Z"
          fill="#45270F"
        />
        <path
          d="M92.3358 195.303C92.3358 195.564 92.0747 195.826 91.8137 195.826C91.5526 195.826 91.2915 195.564 91.2915 195.303C91.2915 195.042 91.5526 194.781 91.8137 194.781C92.0747 194.781 92.3358 195.042 92.3358 195.303Z"
          fill="#45270F"
        />
        <path
          d="M101.538 195.303C101.538 195.564 101.277 195.826 101.016 195.826C100.755 195.826 100.494 195.564 100.494 195.303C100.494 195.042 100.755 194.781 101.016 194.781C101.277 194.781 101.538 195.042 101.538 195.303Z"
          fill="#45270F"
        />
        <path
          d="M110.676 195.303C110.676 195.564 110.415 195.826 110.153 195.826C109.892 195.826 109.631 195.564 109.631 195.303C109.631 195.042 109.892 194.781 110.153 194.781C110.415 194.781 110.676 195.042 110.676 195.303Z"
          fill="#45270F"
        />
        <path
          d="M119.812 195.303C119.812 195.564 119.551 195.826 119.29 195.826C119.029 195.826 118.768 195.564 118.768 195.303C118.768 195.042 119.029 194.781 119.29 194.781C119.617 194.781 119.812 195.042 119.812 195.303Z"
          fill="#45270F"
        />
        <path
          d="M129.015 195.303C129.015 195.564 128.754 195.826 128.493 195.826C128.232 195.826 127.97 195.564 127.97 195.303C127.97 195.042 128.232 194.781 128.493 194.781C128.754 194.781 129.015 195.042 129.015 195.303Z"
          fill="#45270F"
        />
        <path
          d="M138.152 195.303C138.152 195.564 137.891 195.826 137.63 195.826C137.369 195.826 137.108 195.564 137.108 195.303C137.108 195.042 137.369 194.781 137.63 194.781C137.957 194.781 138.152 195.042 138.152 195.303Z"
          fill="#45270F"
        />
        <path
          d="M147.356 195.303C147.356 195.564 147.094 195.826 146.833 195.826C146.572 195.826 146.311 195.564 146.311 195.303C146.311 195.042 146.572 194.781 146.833 194.781C147.094 194.781 147.356 195.042 147.356 195.303Z"
          fill="#45270F"
        />
        <path
          d="M41.9476 198.045C41.9476 198.306 41.6865 198.567 41.4255 198.567C41.1644 198.567 40.9033 198.306 40.9033 198.045C40.9033 197.784 41.1644 197.523 41.4255 197.523C41.6865 197.523 41.9476 197.719 41.9476 198.045Z"
          fill="#45270F"
        />
        <path
          d="M51.086 198.045C51.086 198.306 50.825 198.567 50.5639 198.567C50.3028 198.567 50.0417 198.306 50.0417 198.045C50.0417 197.784 50.3028 197.523 50.5639 197.523C50.8902 197.523 51.086 197.719 51.086 198.045Z"
          fill="#45270F"
        />
        <path
          d="M60.2896 198.045C60.2896 198.306 60.0286 198.567 59.7675 198.567C59.5064 198.567 59.2454 198.306 59.2454 198.045C59.2454 197.784 59.5064 197.523 59.7675 197.523C60.0286 197.523 60.2896 197.719 60.2896 198.045Z"
          fill="#45270F"
        />
        <path
          d="M69.4273 198.045C69.4273 198.306 69.1663 198.567 68.9052 198.567C68.6441 198.567 68.3831 198.306 68.3831 198.045C68.3831 197.784 68.6441 197.523 68.9052 197.523C69.2315 197.523 69.4273 197.719 69.4273 198.045Z"
          fill="#45270F"
        />
        <path
          d="M78.6297 198.045C78.6297 198.306 78.3687 198.567 78.1076 198.567C77.8465 198.567 77.5854 198.306 77.5854 198.045C77.5854 197.784 77.8465 197.523 78.1076 197.523C78.3687 197.523 78.6297 197.719 78.6297 198.045Z"
          fill="#45270F"
        />
        <path
          d="M87.7665 198.045C87.7665 198.306 87.5054 198.567 87.2443 198.567C86.9832 198.567 86.7222 198.306 86.7222 198.045C86.7222 197.784 86.9832 197.523 87.2443 197.523C87.5054 197.523 87.7665 197.719 87.7665 198.045Z"
          fill="#45270F"
        />
        <path
          d="M96.9054 198.045C96.9054 198.306 96.6443 198.567 96.3832 198.567C96.1222 198.567 95.8611 198.306 95.8611 198.045C95.8611 197.784 96.1222 197.523 96.3832 197.523C96.7096 197.523 96.9054 197.719 96.9054 198.045Z"
          fill="#45270F"
        />
        <path
          d="M106.106 198.045C106.106 198.306 105.844 198.567 105.583 198.567C105.322 198.567 105.061 198.306 105.061 198.045C105.061 197.784 105.322 197.523 105.583 197.523C105.844 197.523 106.106 197.719 106.106 198.045Z"
          fill="#45270F"
        />
        <path
          d="M115.245 198.045C115.245 198.306 114.984 198.567 114.723 198.567C114.462 198.567 114.201 198.306 114.201 198.045C114.201 197.784 114.462 197.523 114.723 197.523C115.049 197.523 115.245 197.719 115.245 198.045Z"
          fill="#45270F"
        />
        <path
          d="M124.447 198.045C124.447 198.306 124.186 198.567 123.925 198.567C123.664 198.567 123.403 198.306 123.403 198.045C123.403 197.784 123.664 197.523 123.925 197.523C124.186 197.523 124.447 197.719 124.447 198.045Z"
          fill="#45270F"
        />
        <path
          d="M133.585 198.045C133.585 198.306 133.324 198.567 133.063 198.567C132.802 198.567 132.541 198.306 132.541 198.045C132.541 197.784 132.802 197.523 133.063 197.523C133.389 197.523 133.585 197.719 133.585 198.045Z"
          fill="#45270F"
        />
        <path
          d="M142.266 198.567C142.554 198.567 142.788 198.333 142.788 198.045C142.788 197.757 142.554 197.523 142.266 197.523C141.977 197.523 141.743 197.757 141.743 198.045C141.743 198.333 141.977 198.567 142.266 198.567Z"
          fill="#45270F"
        />
        <path
          d="M151.926 198.045C151.926 198.306 151.665 198.567 151.404 198.567C151.143 198.567 150.882 198.306 150.882 198.045C150.882 197.784 151.143 197.523 151.404 197.523C151.665 197.523 151.926 197.719 151.926 198.045Z"
          fill="#45270F"
        />
        <path
          d="M46.0283 200.532C46.3163 200.516 46.5368 200.269 46.5208 199.981C46.5049 199.693 46.2585 199.473 45.9706 199.489C45.6826 199.505 45.4621 199.751 45.4781 200.039C45.494 200.327 45.7404 200.548 46.0283 200.532Z"
          fill="#45270F"
        />
        <path
          d="M55.7201 200.003C55.7201 200.264 55.459 200.525 55.1979 200.525C54.9368 200.525 54.6758 200.264 54.6758 200.003C54.6758 199.742 54.9368 199.481 55.1979 199.481C55.459 199.481 55.7201 199.677 55.7201 200.003Z"
          fill="#45270F"
        />
        <path
          d="M64.858 200.003C64.858 200.264 64.5969 200.525 64.3359 200.525C64.0748 200.525 63.8137 200.264 63.8137 200.003C63.8137 199.742 64.0748 199.481 64.3359 199.481C64.5969 199.481 64.858 199.677 64.858 200.003Z"
          fill="#45270F"
        />
        <path
          d="M73.9947 200.003C73.9947 200.264 73.7336 200.525 73.4726 200.525C73.2115 200.525 72.9504 200.264 72.9504 200.003C72.9504 199.742 73.2115 199.481 73.4726 199.481C73.7989 199.481 73.9947 199.677 73.9947 200.003Z"
          fill="#45270F"
        />
        <path
          d="M83.1981 200.003C83.1981 200.264 82.937 200.525 82.676 200.525C82.4149 200.525 82.1538 200.264 82.1538 200.003C82.1538 199.742 82.4149 199.481 82.676 199.481C82.937 199.481 83.1981 199.677 83.1981 200.003Z"
          fill="#45270F"
        />
        <path
          d="M92.3358 200.003C92.3358 200.264 92.0747 200.525 91.8137 200.525C91.5526 200.525 91.2915 200.264 91.2915 200.003C91.2915 199.742 91.5526 199.481 91.8137 199.481C92.0747 199.481 92.3358 199.677 92.3358 200.003Z"
          fill="#45270F"
        />
        <path
          d="M101.051 200.531C101.339 200.515 101.56 200.268 101.544 199.98C101.528 199.692 101.281 199.472 100.994 199.488C100.706 199.504 100.485 199.75 100.501 200.038C100.517 200.326 100.763 200.547 101.051 200.531Z"
          fill="#45270F"
        />
        <path
          d="M110.676 200.003C110.676 200.264 110.415 200.525 110.154 200.525C109.893 200.525 109.632 200.264 109.632 200.003C109.632 199.742 109.893 199.481 110.154 199.481C110.415 199.481 110.676 199.677 110.676 200.003Z"
          fill="#45270F"
        />
        <path
          d="M119.813 200.003C119.813 200.264 119.552 200.525 119.291 200.525C119.03 200.525 118.769 200.264 118.769 200.003C118.769 199.742 119.03 199.481 119.291 199.481C119.617 199.481 119.813 199.677 119.813 200.003Z"
          fill="#45270F"
        />
        <path
          d="M129.015 200.003C129.015 200.264 128.754 200.525 128.493 200.525C128.232 200.525 127.971 200.264 127.971 200.003C127.971 199.742 128.232 199.481 128.493 199.481C128.754 199.481 129.015 199.677 129.015 200.003Z"
          fill="#45270F"
        />
        <path
          d="M137.667 200.533C137.955 200.517 138.175 200.271 138.16 199.983C138.144 199.695 137.897 199.475 137.609 199.491C137.321 199.507 137.101 199.753 137.117 200.041C137.133 200.329 137.379 200.549 137.667 200.533Z"
          fill="#45270F"
        />
        <path
          d="M146.87 200.531C147.158 200.515 147.378 200.269 147.362 199.981C147.346 199.693 147.1 199.472 146.812 199.488C146.524 199.504 146.304 199.751 146.32 200.039C146.336 200.327 146.582 200.547 146.87 200.531Z"
          fill="#45270F"
        />
        <path
          d="M41.9476 202.679C41.9476 202.94 41.6865 203.201 41.4255 203.201C41.1644 203.201 40.9033 202.94 40.9033 202.679C40.9033 202.418 41.1644 202.156 41.4255 202.156C41.6865 202.222 41.9476 202.418 41.9476 202.679Z"
          fill="#45270F"
        />
        <path
          d="M51.086 202.679C51.086 202.94 50.825 203.201 50.5639 203.201C50.3028 203.201 50.0417 202.94 50.0417 202.679C50.0417 202.418 50.3028 202.156 50.5639 202.156C50.8902 202.222 51.086 202.418 51.086 202.679Z"
          fill="#45270F"
        />
        <path
          d="M60.2896 202.679C60.2896 202.94 60.0286 203.201 59.7675 203.201C59.5064 203.201 59.2454 202.94 59.2454 202.679C59.2454 202.418 59.5064 202.156 59.7675 202.156C60.0286 202.222 60.2896 202.418 60.2896 202.679Z"
          fill="#45270F"
        />
        <path
          d="M69.4273 202.679C69.4273 202.94 69.1663 203.201 68.9052 203.201C68.6441 203.201 68.3831 202.94 68.3831 202.679C68.3831 202.418 68.6441 202.156 68.9052 202.156C69.2315 202.222 69.4273 202.418 69.4273 202.679Z"
          fill="#45270F"
        />
        <path
          d="M78.6297 202.679C78.6297 202.94 78.3687 203.201 78.1076 203.201C77.8465 203.201 77.5854 202.94 77.5854 202.679C77.5854 202.418 77.8465 202.156 78.1076 202.156C78.3687 202.222 78.6297 202.418 78.6297 202.679Z"
          fill="#45270F"
        />
        <path
          d="M87.7665 202.679C87.7665 202.94 87.5054 203.201 87.2443 203.201C86.9832 203.201 86.7222 202.94 86.7222 202.679C86.7222 202.418 86.9832 202.156 87.2443 202.156C87.5054 202.222 87.7665 202.418 87.7665 202.679Z"
          fill="#45270F"
        />
        <path
          d="M96.9054 202.679C96.9054 202.94 96.6443 203.201 96.3832 203.201C96.1222 203.201 95.8611 202.94 95.8611 202.679C95.8611 202.418 96.1222 202.156 96.3832 202.156C96.7096 202.222 96.9054 202.418 96.9054 202.679Z"
          fill="#45270F"
        />
        <path
          d="M106.106 202.679C106.106 202.94 105.844 203.201 105.583 203.201C105.322 203.201 105.061 202.94 105.061 202.679C105.061 202.418 105.322 202.156 105.583 202.156C105.844 202.222 106.106 202.418 106.106 202.679Z"
          fill="#45270F"
        />
        <path
          d="M115.245 202.679C115.245 202.94 114.984 203.201 114.723 203.201C114.462 203.201 114.201 202.94 114.201 202.679C114.201 202.418 114.462 202.156 114.723 202.156C115.049 202.222 115.245 202.418 115.245 202.679Z"
          fill="#45270F"
        />
        <path
          d="M124.447 202.679C124.447 202.94 124.186 203.201 123.925 203.201C123.664 203.201 123.403 202.94 123.403 202.679C123.403 202.418 123.664 202.156 123.925 202.156C124.186 202.222 124.447 202.418 124.447 202.679Z"
          fill="#45270F"
        />
        <path
          d="M133.585 202.679C133.585 202.94 133.324 203.201 133.063 203.201C132.802 203.201 132.541 202.94 132.541 202.679C132.541 202.418 132.802 202.156 133.063 202.156C133.389 202.222 133.585 202.418 133.585 202.679Z"
          fill="#45270F"
        />
        <path
          d="M142.788 202.679C142.788 202.94 142.527 203.201 142.266 203.201C142.004 203.201 141.743 202.94 141.743 202.679C141.743 202.418 142.004 202.156 142.266 202.156C142.527 202.222 142.788 202.418 142.788 202.679Z"
          fill="#45270F"
        />
        <path
          d="M151.926 202.679C151.926 202.94 151.665 203.201 151.404 203.201C151.143 203.201 150.882 202.94 150.882 202.679C150.882 202.418 151.143 202.156 151.404 202.156C151.665 202.222 151.926 202.418 151.926 202.679Z"
          fill="#45270F"
        />
        <path
          opacity="0.4"
          d="M81.4347 203.527C82.5443 204.637 84.1107 205.355 85.873 205.355H43.9056C42.2087 205.355 40.6422 204.637 39.4674 203.527C38.3578 202.418 37.6399 200.851 37.6399 199.089C37.6399 195.63 40.5769 192.823 44.1014 192.823H85.873C82.4137 192.823 79.6072 195.63 79.6072 199.089C79.6072 200.851 80.3252 202.418 81.4347 203.527Z"
          fill="#603A15"
        />
        <path
          d="M143.376 207.509C143.245 208.292 142.462 208.814 141.679 208.814H133.455C132.803 208.814 132.15 208.879 131.497 208.944L113.026 212.73C111.786 212.991 110.938 214.035 110.938 215.275V216.189C110.938 216.45 110.742 216.646 110.481 216.646C110.22 216.646 110.024 216.45 110.024 216.189V215.667C110.024 214.427 108.915 213.513 107.74 213.774L105.455 214.231C104.542 214.427 103.954 215.21 103.954 216.124V218.996C103.954 219.257 103.824 219.518 103.693 219.714C103.497 219.91 103.301 219.975 103.04 219.975C102.518 219.975 102.061 219.518 102.061 218.996V217.951C102.061 216.385 100.625 215.21 99.1243 215.537L84.6348 218.474L82.6115 218.865C78.3691 219.714 73.9308 218.669 70.5369 215.928L68.9052 214.623C68.3831 214.166 67.5998 214.558 67.5998 215.275V216.646C67.5998 216.907 67.404 217.103 67.143 217.103C66.8819 217.103 66.6861 216.907 66.6861 216.646C66.6861 214.231 65.5765 212.012 63.749 210.511L63.1616 210.054C61.1383 209.206 58.9192 208.749 56.7001 208.749H50.7607C49.7817 208.749 48.9985 207.835 49.129 206.856C49.2595 206.073 50.0428 205.551 50.826 205.551H141.679C142.789 205.551 143.572 206.464 143.376 207.509Z"
          fill="#E5CB43"
        />
        <path
          opacity="0.4"
          d="M84.7 218.539L82.6767 218.931C78.4343 219.779 73.996 218.735 70.6021 215.994L68.9704 214.688C68.4483 214.231 67.665 214.623 67.665 215.341V216.712C67.665 216.973 67.4692 217.168 67.2082 217.168C66.9471 217.168 66.7513 216.973 66.7513 216.712C66.7513 214.297 65.6417 212.078 63.8142 210.576L63.2268 210.119C61.2035 209.271 58.9844 208.814 56.7653 208.814H50.8259C49.8469 208.814 49.0637 207.9 49.1942 206.921C49.3247 206.138 50.1079 205.616 50.8912 205.616H83.9168C78.3037 212.404 81.6977 216.516 84.7 218.539Z"
          fill="#E5CB43"
        />
        <path
          d="M156.821 193.41C152.839 197.261 148.858 195.303 147.226 194.193C146.835 193.932 146.313 194.063 146.051 194.455C145.399 195.434 143.767 196.935 140.504 197.065C137.436 197.196 135.478 195.629 134.434 194.52C134.042 194.128 133.389 194.193 133.128 194.65C132.215 196.086 129.996 197.718 126.471 198.044C123.795 198.305 122.033 196.608 121.054 195.238C120.662 194.716 119.814 194.781 119.618 195.368C118.704 197.522 116.028 200.59 108.196 200.198C101.147 199.807 98.4058 197.522 97.3615 195.695C97.0352 195.107 96.1867 195.172 95.8603 195.695C94.8813 197.457 92.7927 199.741 88.3545 200.133C84.2426 200.524 82.4804 198.501 81.6972 196.543C81.4361 195.825 80.5876 195.695 80.0655 196.217C80.0002 196.282 79.9349 196.347 79.9349 196.413C79.8044 196.543 79.7391 196.739 79.6086 196.869C78.3685 198.501 76.0841 200.002 72.1028 200.133C67.4035 200.263 65.7065 198.11 65.1191 196.217C64.9233 195.564 64.0095 195.368 63.6179 195.956C62.5736 197.522 60.6809 199.284 57.3522 200.002C52.3918 200.981 52.196 196.217 52.3266 194.128C52.0002 196.347 50.8254 201.373 46.4524 199.676C43.5154 198.566 43.3848 196.804 43.9722 195.434C44.2333 194.781 43.6459 194.063 42.928 194.324C40.252 195.173 36.2054 195.956 34.8347 193.41C32.8114 189.625 41.1005 188.254 41.8837 188.124C41.9489 188.124 41.9489 188.124 42.0142 188.124L79.0212 187.471L81.175 187.406L150.033 186.166C150.098 186.166 150.163 186.166 150.229 186.166C151.208 186.427 161.716 188.711 156.821 193.41Z"
          fill="#3C8939"
        />
        <path
          opacity="0.4"
          d="M79.5444 196.935C78.3043 198.567 76.0199 200.068 72.0386 200.199C67.3393 200.329 65.6423 198.175 65.0549 196.283C64.8591 195.63 63.9453 195.434 63.5537 196.021C62.5094 197.588 60.6167 199.35 57.288 200.068C52.3276 201.047 52.1318 196.283 52.2623 194.194C51.936 196.413 50.7612 201.439 46.3882 199.742C43.4512 198.632 43.3206 196.87 43.908 195.499C44.1691 194.847 43.5817 194.129 42.8638 194.39C40.1878 195.238 36.1411 196.021 34.7705 193.476C32.7472 189.69 41.0363 188.32 41.8195 188.189C41.8847 188.189 41.8847 188.189 41.95 188.189L78.957 187.537C78.1085 191.257 78.6306 194.455 79.5444 196.935Z"
          fill="#3C8939"
        />
        <path
          d="M91.4218 179.835H53.4358C50.7598 179.835 48.606 181.989 48.606 184.665C48.606 187.341 50.7598 189.494 53.4358 189.494H91.4218C94.0978 189.494 96.2516 187.341 96.2516 184.665C96.3169 181.989 94.0978 179.835 91.4218 179.835Z"
          fill="#EB3E37"
        />
        <path
          opacity="0.4"
          d="M76.9975 188.124C77.846 188.972 79.0861 189.56 80.4568 189.56H53.5011C52.1304 189.56 50.9556 189.038 50.0419 188.124C49.1934 187.275 48.606 186.035 48.606 184.665C48.606 181.989 50.7598 179.835 53.4358 179.835H80.3915C77.7155 179.835 75.5616 181.989 75.5616 184.665C75.5616 186.035 76.1491 187.275 76.9975 188.124Z"
          fill="#EB3E37"
        />
        <path
          d="M139.132 179.835H101.146C98.4698 179.835 96.3159 181.989 96.3159 184.665C96.3159 187.341 98.4698 189.494 101.146 189.494H139.132C141.808 189.494 143.962 187.341 143.962 184.665C143.962 181.989 141.808 179.835 139.132 179.835Z"
          fill="#EB3E37"
        />
        <path
          opacity="0.4"
          d="M124.708 188.124C125.556 188.972 126.796 189.56 128.167 189.56H101.211C99.8404 189.56 98.6656 189.038 97.7518 188.124C96.9033 187.275 96.3159 186.035 96.3159 184.665C96.3159 181.989 98.4698 179.835 101.146 179.835H128.101C125.425 179.835 123.272 181.989 123.272 184.665C123.272 186.035 123.794 187.275 124.708 188.124Z"
          fill="#EB3E37"
        />
        <path
          d="M143.376 181.793C143.245 182.576 142.462 183.098 141.679 183.098H133.455C132.803 183.098 132.15 183.163 131.497 183.229L113.026 187.014C111.786 187.275 110.938 188.32 110.938 189.56V190.473C110.938 190.734 110.742 190.93 110.481 190.93C110.22 190.93 110.024 190.734 110.024 190.473V189.951C110.024 188.711 108.915 187.797 107.74 188.058L105.455 188.515C104.542 188.711 103.954 189.494 103.954 190.408V193.28C103.954 193.541 103.824 193.802 103.693 193.998C103.497 194.194 103.301 194.259 103.04 194.259C102.518 194.259 102.061 193.802 102.061 193.28V192.236C102.061 190.669 100.625 189.494 99.1243 189.821L84.6348 192.758L82.6115 193.149C78.3691 193.998 73.9308 192.954 70.5369 190.212L68.9052 188.907C68.3831 188.45 67.5998 188.842 67.5998 189.56V190.93C67.5998 191.191 67.404 191.387 67.143 191.387C66.8819 191.387 66.6861 191.191 66.6861 190.93C66.6861 188.515 65.5765 186.296 63.749 184.795L63.1616 184.338C61.1383 183.49 58.9192 183.033 56.7001 183.033H50.7607C49.7817 183.033 48.9985 182.119 49.129 181.14C49.2595 180.357 50.0428 179.835 50.826 179.835H141.679C142.789 179.835 143.572 180.748 143.376 181.793Z"
          fill="#E5CB43"
        />
        <path
          opacity="0.4"
          d="M84.7 192.823L82.6767 193.215C78.4343 194.063 73.996 193.019 70.6021 190.278L68.9704 188.972C68.4483 188.516 67.665 188.907 67.665 189.625V190.996C67.665 191.257 67.4692 191.453 67.2082 191.453C66.9471 191.453 66.7513 191.257 66.7513 190.996C66.7513 188.581 65.6417 186.362 63.8142 184.861L63.2268 184.404C61.2035 183.555 58.9844 183.098 56.7653 183.098H50.8259C49.8469 183.098 49.0637 182.185 49.1942 181.206C49.3247 180.422 50.1079 179.9 50.8912 179.9H83.9168C78.3037 186.688 81.6977 190.8 84.7 192.823Z"
          fill="#E5CB43"
        />
        <path
          d="M142.135 244.777C141.091 244.777 140.243 243.928 140.243 242.884V233.029C140.243 231.984 141.091 231.136 142.135 231.136C143.18 231.136 144.028 231.984 144.028 233.029V242.949C144.028 243.928 143.18 244.777 142.135 244.777Z"
          fill="#F6DDB4"
        />
        <path
          d="M145.594 179.704H46.9092C41.2308 179.704 37.2495 174.352 38.5549 169.065H154.014C155.319 174.417 151.273 179.704 145.594 179.704Z"
          fill="#F2BA65"
        />
        <path
          d="M84.243 143.676C83.9819 143.937 83.5903 143.937 83.3292 143.676L81.3059 141.653C81.0448 141.392 81.0448 141 81.3059 140.739C81.567 140.478 81.9586 140.478 82.2197 140.739L84.243 142.762C84.504 143.023 84.504 143.415 84.243 143.676Z"
          fill="#FED9A5"
        />
        <path
          d="M93.6421 143.676C93.3811 143.937 92.9895 143.937 92.7284 143.676L90.7051 141.653C90.444 141.392 90.444 141 90.7051 140.739C90.9662 140.478 91.3578 140.478 91.6188 140.739L93.6421 142.762C93.9032 143.023 93.9032 143.415 93.6421 143.676Z"
          fill="#FED9A5"
        />
        <path
          d="M103.106 143.676C102.845 143.937 102.454 143.937 102.193 143.676L100.169 141.653C99.9084 141.392 99.9084 141 100.169 140.739C100.431 140.478 100.822 140.478 101.083 140.739L103.106 142.762C103.368 143.023 103.368 143.415 103.106 143.676Z"
          fill="#FED9A5"
        />
        <path
          d="M112.57 143.676C112.309 143.937 111.917 143.937 111.656 143.676L109.633 141.653C109.371 141.392 109.371 141 109.633 140.739C109.894 140.478 110.285 140.478 110.546 140.739L112.57 142.762C112.831 143.023 112.831 143.415 112.57 143.676Z"
          fill="#FED9A5"
        />
        <path
          d="M113.679 149.55C113.94 149.811 114.332 149.811 114.593 149.55L116.616 147.527C116.877 147.266 116.877 146.874 116.616 146.613C116.355 146.352 115.963 146.352 115.702 146.613L113.679 148.637C113.483 148.898 113.483 149.289 113.679 149.55Z"
          fill="#FED9A5"
        />
        <path
          d="M122.098 149.55C122.359 149.811 122.751 149.811 123.012 149.55L125.035 147.527C125.296 147.266 125.296 146.874 125.035 146.613C124.774 146.352 124.383 146.352 124.121 146.613L122.098 148.637C121.837 148.898 121.837 149.289 122.098 149.55Z"
          fill="#FED9A5"
        />
        <path
          d="M130.453 149.55C130.714 149.811 131.105 149.811 131.366 149.55L133.39 147.527C133.651 147.266 133.651 146.874 133.39 146.613C133.129 146.352 132.737 146.352 132.476 146.613L130.453 148.637C130.192 148.898 130.192 149.289 130.453 149.55Z"
          fill="#FED9A5"
        />
        <path
          d="M104.28 149.55C104.541 149.811 104.932 149.811 105.193 149.55L107.217 147.527C107.478 147.266 107.478 146.874 107.217 146.613C106.956 146.352 106.564 146.352 106.303 146.613L104.28 148.637C104.018 148.898 104.018 149.289 104.28 149.55Z"
          fill="#FED9A5"
        />
        <path
          d="M94.8164 149.55C95.0775 149.811 95.4691 149.811 95.7302 149.55L97.7535 147.527C98.0145 147.266 98.0145 146.874 97.7535 146.613C97.4924 146.352 97.1008 146.352 96.8397 146.613L94.8164 148.637C94.5553 148.898 94.5553 149.289 94.8164 149.55Z"
          fill="#FED9A5"
        />
        <path
          d="M85.3521 149.55C85.6131 149.811 86.0047 149.811 86.2658 149.55L88.2891 147.527C88.5502 147.266 88.5502 146.874 88.2891 146.613C88.028 146.352 87.6364 146.352 87.3754 146.613L85.3521 148.637C85.091 148.898 85.091 149.289 85.3521 149.55Z"
          fill="#FED9A5"
        />
        <path
          d="M76.5403 149.55C76.8014 149.811 77.193 149.811 77.454 149.55L79.4773 147.527C79.7384 147.266 79.7384 146.874 79.4773 146.613C79.2163 146.352 78.8247 146.352 78.5636 146.613L76.5403 148.637C76.2792 148.898 76.2792 149.289 76.5403 149.55Z"
          fill="#FED9A5"
        />
        <path
          d="M69.2954 149.55C69.5565 149.811 69.9481 149.811 70.2092 149.55L72.2325 147.527C72.4935 147.266 72.4935 146.874 72.2325 146.613C71.9714 146.352 71.5798 146.352 71.3187 146.613L69.2954 148.637C69.0343 148.898 69.0343 149.289 69.2954 149.55Z"
          fill="#FED9A5"
        />
        <path
          d="M60.5501 149.55C60.8111 149.811 61.2027 149.811 61.4638 149.55L63.4871 147.527C63.7482 147.266 63.7482 146.874 63.4871 146.613C63.226 146.352 62.8344 146.352 62.5734 146.613L60.5501 148.637C60.289 148.898 60.289 149.289 60.5501 149.55Z"
          fill="#FED9A5"
        />
        <path
          d="M109.305 152.814C109.566 152.553 109.958 152.553 110.219 152.814L112.242 154.837C112.503 155.098 112.503 155.49 112.242 155.751C111.981 156.012 111.589 156.012 111.328 155.751L109.305 153.727C109.044 153.466 109.044 153.075 109.305 152.814Z"
          fill="#FED9A5"
        />
        <path
          d="M117.66 152.814C117.921 152.553 118.312 152.553 118.573 152.814L120.597 154.837C120.858 155.098 120.858 155.49 120.597 155.751C120.336 156.012 119.944 156.012 119.683 155.751L117.66 153.727C117.399 153.466 117.399 153.075 117.66 152.814Z"
          fill="#FED9A5"
        />
        <path
          d="M126.08 152.814C126.341 152.553 126.733 152.553 126.994 152.814L129.017 154.837C129.278 155.098 129.278 155.49 129.017 155.751C128.756 156.012 128.364 156.012 128.103 155.751L126.08 153.727C125.819 153.466 125.819 153.075 126.08 152.814Z"
          fill="#FED9A5"
        />
        <path
          d="M137.044 152.814C137.306 152.553 137.697 152.553 137.958 152.814L139.981 154.837C140.243 155.098 140.243 155.49 139.981 155.751C139.72 156.012 139.329 156.012 139.068 155.751L137.044 153.727C136.783 153.466 136.783 153.075 137.044 152.814Z"
          fill="#FED9A5"
        />
        <path
          d="M99.8411 152.814C100.102 152.553 100.494 152.553 100.755 152.814L102.778 154.837C103.039 155.098 103.039 155.49 102.778 155.751C102.517 156.012 102.125 156.012 101.864 155.751L99.8411 153.727C99.58 153.466 99.58 153.075 99.8411 152.814Z"
          fill="#FED9A5"
        />
        <path
          d="M90.3779 152.814C90.639 152.553 91.0306 152.553 91.2917 152.814L93.315 154.837C93.5761 155.098 93.5761 155.49 93.315 155.751C93.0539 156.012 92.6623 156.012 92.4012 155.751L90.3779 153.727C90.1169 153.466 90.1169 153.075 90.3779 152.814Z"
          fill="#FED9A5"
        />
        <path
          d="M80.9137 152.814C81.1748 152.553 81.5664 152.553 81.8274 152.814L83.8508 154.837C84.1118 155.098 84.1118 155.49 83.8508 155.751C83.5897 156.012 83.1981 156.012 82.937 155.751L80.9137 153.727C80.7179 153.466 80.7179 153.075 80.9137 152.814Z"
          fill="#FED9A5"
        />
        <path
          d="M72.1023 152.814C72.3634 152.553 72.755 152.553 73.016 152.814L75.0394 154.837C75.3004 155.098 75.3004 155.49 75.0394 155.751C74.7783 156.012 74.3867 156.012 74.1256 155.751L72.1023 153.727C71.8412 153.466 71.8412 153.075 72.1023 152.814Z"
          fill="#FED9A5"
        />
        <path
          d="M64.8571 152.814C65.1181 152.553 65.5097 152.553 65.7708 152.814L67.7941 154.837C68.0552 155.098 68.0552 155.49 67.7941 155.751C67.533 156.012 67.1414 156.012 66.8804 155.751L64.8571 153.727C64.6613 153.466 64.6613 153.075 64.8571 152.814Z"
          fill="#FED9A5"
        />
        <path
          d="M53.8937 152.814C54.1548 152.553 54.5464 152.553 54.8074 152.814L56.8307 154.837C57.0918 155.098 57.0918 155.49 56.8307 155.751C56.5697 156.012 56.1781 156.012 55.917 155.751L53.8937 153.727C53.6979 153.466 53.6979 153.075 53.8937 152.814Z"
          fill="#FED9A5"
        />
        <g opacity="0.8">
          <path
            d="M137.632 161.168C137.175 161.168 136.653 160.972 136.262 160.58C135.674 159.993 135.022 159.405 134.369 158.818C133.586 158.1 133.52 156.925 134.238 156.142C134.956 155.359 136.131 155.294 136.914 156.012C137.632 156.664 138.35 157.317 139.003 157.97C139.721 158.688 139.721 159.928 139.003 160.646C138.611 160.972 138.154 161.168 137.632 161.168Z"
            fill="#F6DDB4"
          />
          <path
            d="M130.192 154.249C129.8 154.249 129.409 154.119 129.017 153.858C125.036 150.855 120.402 148.31 115.18 146.482C114.201 146.156 113.679 145.047 114.071 144.067C114.397 143.088 115.507 142.566 116.486 142.958C122.033 144.981 126.994 147.657 131.301 150.921C132.15 151.573 132.28 152.748 131.693 153.597C131.301 153.988 130.779 154.249 130.192 154.249Z"
            fill="#F6DDB4"
          />
        </g>
        <path
          d="M131.629 55.7599C131.629 57.4569 130.258 58.8275 128.561 58.8275C126.864 58.8275 125.493 57.4569 125.493 55.7599C125.493 54.063 126.864 52.6923 128.561 52.6923C130.258 52.6271 131.629 54.063 131.629 55.7599Z"
          stroke="#292D32"
          strokeWidth="1.95804"
          stroke-miterlimit="10"
        />
        <path
          d="M147.488 22.5385C147.488 24.2355 146.117 25.6061 144.42 25.6061C142.723 25.6061 141.353 24.2355 141.353 22.5385C141.353 20.8416 142.723 19.4709 144.42 19.4709C146.117 19.4709 147.488 20.8416 147.488 22.5385Z"
          stroke="#FFAA2D"
          strokeWidth="1.95804"
          stroke-miterlimit="10"
        />
        <path
          d="M29.2221 128.273C29.2221 129.97 27.8515 131.34 26.1545 131.34C24.4575 131.34 23.0869 129.97 23.0869 128.273C23.0869 126.576 24.4575 125.205 26.1545 125.205C27.7862 125.205 29.2221 126.576 29.2221 128.273Z"
          stroke="#292D32"
          strokeWidth="1.95804"
          stroke-miterlimit="10"
        />
        <path
          d="M62.8342 70.9026C62.8342 72.5995 61.4635 73.9702 59.7666 73.9702C58.0696 73.9702 56.699 72.5995 56.699 70.9026C56.699 69.2056 58.0696 67.835 59.7666 67.835C61.4635 67.835 62.8342 69.2056 62.8342 70.9026Z"
          stroke="#FFAA2D"
          strokeWidth="1.95804"
          stroke-miterlimit="10"
        />
        <path
          d="M218.826 17.7744H188.28C185.213 17.7744 182.733 20.2546 182.733 23.3222V42.5763C182.733 45.6439 185.213 48.124 188.28 48.124H197.222C199.18 48.124 201.008 49.1683 201.987 50.8653L202.835 52.3012C203.162 52.8233 203.945 52.8233 204.206 52.3012L205.054 50.8653C206.033 49.1683 207.861 48.124 209.819 48.124H218.761C221.828 48.124 224.308 45.6439 224.308 42.5763V23.3222C224.308 20.2546 221.828 17.7744 218.826 17.7744Z"
          fill="#FFAA2D"
        />
        <path
          d="M211.123 26.0637C210.078 25.0195 208.642 24.4973 207.272 24.4973C205.901 24.4973 204.596 25.0195 203.486 25.9985C202.442 25.0195 201.071 24.4973 199.701 24.4973C198.33 24.4973 196.894 25.0195 195.85 26.0637C193.761 28.1523 193.696 31.5463 195.785 33.6348L195.85 33.7001L203.356 41.2059L203.421 41.2712L203.486 41.2059L210.992 33.7001L211.057 33.6348C213.277 31.5463 213.211 28.1523 211.123 26.0637Z"
          fill="white"
        />
        <path
          d="M221.762 14.315V8.7019"
          stroke="#292D32"
          strokeWidth="1.95804"
          stroke-miterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M233.184 22.0168H227.571"
          stroke="#292D32"
          strokeWidth="1.95804"
          stroke-miterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M226.463 16.3382L229.987 12.8137"
          stroke="#292D32"
          strokeWidth="1.95804"
          stroke-miterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M74.6482 92.3755H47.1051C44.3639 92.3755 42.1448 94.5946 42.1448 97.3359V114.632C42.1448 117.373 44.3639 119.592 47.1051 119.592H55.1984C56.9606 119.592 58.5923 120.506 59.5061 122.072L60.2893 123.378C60.5504 123.835 61.2683 123.835 61.5294 123.378L62.3126 122.072C63.2263 120.506 64.858 119.592 66.6203 119.592H74.7135C77.4548 119.592 79.6739 117.373 79.6739 114.632V97.3359C79.6086 94.5946 77.3895 92.3755 74.6482 92.3755Z"
          fill="#292D32"
        />
        <path
          d="M67.7284 99.881C66.7493 98.9019 65.5093 98.4451 64.2692 98.4451C63.0291 98.4451 61.8542 98.9019 60.8752 99.8157C59.8962 98.9019 58.7214 98.4451 57.4813 98.4451C56.2412 98.4451 55.0011 98.9019 54.0221 99.881C52.1293 101.774 52.1293 104.841 53.9568 106.734L54.0221 106.799L60.81 113.587L60.8752 113.653L60.9405 113.587L67.7284 106.799L67.7936 106.734C69.6864 104.776 69.6211 101.774 67.7284 99.881Z"
          fill="white"
        />
        <path
          d="M100.168 123.769C100.168 125.205 98.9936 126.38 97.5577 126.38C96.1218 126.38 94.947 125.205 94.947 123.769C94.947 122.333 96.1218 121.158 97.5577 121.158C98.9936 121.158 100.168 122.333 100.168 123.769Z"
          stroke="#FFAA2D"
          strokeWidth="1.95804"
          stroke-miterlimit="10"
        />
        <path
          d="M44.4285 88.5899V83.499"
          stroke="#FFAA2D"
          strokeWidth="1.95804"
          stroke-miterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M34.1824 95.5083H39.208"
          stroke="#FFAA2D"
          strokeWidth="1.95804"
          stroke-miterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M40.1869 90.4175L36.9888 87.2847"
          stroke="#FFAA2D"
          strokeWidth="1.95804"
          stroke-miterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M206.555 3.02331C206.555 4.13286 205.641 5.04662 204.532 5.04662C203.422 5.04662 202.508 4.13286 202.508 3.02331C202.508 1.91375 203.422 1 204.532 1C205.641 1 206.555 1.91375 206.555 3.02331Z"
          stroke="#FFAA2D"
          strokeWidth="1.95804"
          stroke-miterlimit="10"
        />
        <path
          d="M219.219 60.786C219.219 61.8956 218.305 62.8093 217.196 62.8093C216.086 62.8093 215.172 61.8956 215.172 60.786C215.172 59.6765 216.086 58.7627 217.196 58.7627C218.305 58.828 219.219 59.6765 219.219 60.786Z"
          stroke="#FFAA2D"
          strokeWidth="1.95804"
          stroke-miterlimit="10"
        />
        <defs>
          <filter
            id="filter0_f_24176_33789"
            x="0.0345936"
            y="218.793"
            width="220.066"
            height="84.1728"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="5.98283" result="effect1_foregroundBlur_24176_33789" />
          </filter>
        </defs>
      </svg>
    </div>
  );
}

export default OrderFoodIcon;
