export const customizationElementPageEnum = {
  Banner: 1,
  Advertisement: 2,
  Introduction: 3,
  ProductSlide: 4,
  Categories: 5,
  Testimonials: 6,
  Blogs: 7,
  Booking: 8,
  TodayMenu: 9,
  Checkout: 10,
  SlideShowTheme1: 11,
  CategoryTheme1: 12,
  BestSellingProductTheme1: 13,
  SignatureProductTheme1: 14,
  BookingTheme1: 15,
  BlogsTheme1: 16,
  BranchTheme1: 17,
};

export const backgroundTypeEnum = {
  Color: 1,
  Image: 2,
};

export const GeneralCustomizationEnum = {
  ColorGroup: 1,
  Image: 2,
  Color: 3,
};

export const amountMaximumOfBanner = 5;

export const theme2ElementRightId = {
  Header: "themeHeader",
  Footer: "themeFooter",
  Banner: "themeBanner",
  FlashSale: "themeFlashSale",
  Advertisement: "themeAdvertisement",
  Introduction: "themeIntroduction",
  ProductSlide: "",
  Categories: "",
  Testimonials: "",
  Blogs: "themeBlogs",
  Booking: "",
  TodayMenu: "themeTodayMenu",
  Checkout: "",
  HeaderProductList: "header-product-list",
  ProductProductList: "product-product-list",
  PromotionSection: "themePromotionSection",
};

export const theme1ElementRightId = {
  Header: "theme1Header",
  Footer: "theme1Footer",
  Banner: "",
  Advertisement: "",
  Introduction: "",
  ProductSlide: "",
  Categories: "",
  Testimonials: "",
  Blogs: "",
  Booking: "",
  TodayMenu: "",
  Checkout: "",
  BestSellingProduct: "theme1-best-selling-product-id",
  PromotionSection: "themePromotionSection",
};

export const theme2IdScrollView = {
  Banner: "#themeBanner",
  FlashSale: "#themeFlashSale",
  Advertisement: "#themeAdvertisement",
  Introduction: "#themeIntroduction",
  ProductSlide: "",
  Categories: "",
  Testimonials: "",
  Blogs: "#themeBlogs",
  Booking: "",
  TodayMenu: "#themeTodayMenu",
  Checkout: "",
  BestSellingProduct: "#themeBestSellingProduct",
  ProductDetail: "",
  SignatureProduct: "",
  HeaderProductList: "#themeHeaderProductList",
  ProductProductList: "#themeProductProductList",
  HeaderCheckout: "#themeHeaderCheckout",
  CheckoutCheckout: "#themeCheckoutCheckout",
  RelatedProductsCheckout: "#themeRelatedProductsCheckout",
  PromotionSection: "#themePromotionSection",
  BlogHeader: "#blogHeader",
  BlogList: "#blogList",
  Reservation: "#reservation",
  HeaderReservation: "#themeHeaderReservation",
  ReservationReservation: "#themeReservationReservation",
};

export const theme2ElementCustomize = {
  Banner: "customizeBanner",
  FlashSale: "customizeFlashSale",
  Advertisement: "customizeAdvertisement",
  Introduction: "themeIntroduction",
  ProductSlide: "",
  Categories: "",
  Testimonials: "",
  Blogs: "customizeBlogs",
  BlogsDetail: "customizeBlogDetail",
  Booking: "",
  TodayMenu: "customizeTodayMenu",
  Checkout: "",
  BestSellingProduct: "customizeBestSellingProduct",
  ProductDetail: "",
  SignatureProduct: "",
  HeaderProductList: "customizeHeaderProductList",
  ProductProductList: "customizeProductProductList",
  Header: "customizeHeader",
  Footer: "customizeFooter",
  HeaderCheckout: "customizeHeaderCheckout",
  CheckoutCheckout: "customizeCheckoutCheckout",
  RelatedProductsCheckout: "customizeRelatedProductCheckout",
  LoginPage: "customizeLogin",
  HeaderProductDetail: "product-detail-header",
  MainProductDetail: "productDetailBodyAndChat",
  RelatedProductDetail: "relatedProductsDetail",
  PromotionSection: "customizePromotionSection",
  Reservation: "customizeReservation",
  HeaderReservation: "customizeHeaderReservation",
  ReservationReservation: "customizeReservationReservation",
};

export const theme2PageConfigName = {
  Banner: "banner",
  FlashSale: "flashSale",
  Advertisement: "advertisement",
  Introduction: "introduction",
  TodayMenu: "todayMenu",
  PromotionSection: "promotionSection",
  Blogs: "blogs",
};

export const comboTypeEnum = {
  //Combo Flexible => comboPricingId
  comboPricing: { id: 0, path: "combo-pricing" },
  //Combo Specific => comboId
  comboProductPrice: { id: 1, path: "combo-product-price" },
};

export const EnumInternationalRegion = {
  VIETNAM: 0,
  INTERNATIONAL: 1,
};
