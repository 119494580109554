const actionTypes = {
  SET_TOAST_MESSAGE_MAX_DISCOUNT: "SET_TOAST_MESSAGE_MAX_DISCOUNT",
  SET_TOAST_MESSAGE_ADD_UPDATE_PRODUCT_CART_ITEM: "SET_TOAST_MESSAGE_ADD_UPDATE_PRODUCT_CART_ITEM",
  SET_TOAST_MESSAGE_ADD_TO_CART: "SET_TOAST_MESSAGE_ADD_TO_CART",
  SET_TOAST_MESSAGE_UPDATE_TO_CART: "SET_TOAST_MESSAGE_UPDATE_TO_CART",
  SET_TOAST_MESSAGE_CANCEL_ORDER: "SET_TOAST_MESSAGE_CANCEL_ORDER",
  SET_TOAST_MESSAGE_DISCOUNT_CODE_CHECKOUT: "SET_TOAST_MESSAGE_DISCOUNT_CODE_CHECKOUT",
  SET_RESET_TOAST_MESSAGE: "SET_RESET_TOAST_MESSAGE",
};

export default actionTypes;
