import { Button, Form, Image } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { EnumDiscountCodeResponseCode } from "../../../constants/enums";
import { ToastMessageType } from "../../../constants/toast-message.constants";
import { store } from "../../../modules";
import { discountCodeCollectionSelector } from "../../../modules/collection/collection.reducers";
import { setToastMessage } from "../../../modules/session/session.actions";
import posCartService from "../../../services/pos/pos-cart.services";
import reduxService from "../../../services/redux.services";
import { formatDate, formatTextNumber } from "../../../utils/helpers";
import { ListPromotionType } from "../../constants/enums";
import { DateFormat } from "../../constants/string.constant";
import MyVoucherCard from "../my-voucher-card/my-voucher-card";
import SearchInputComponent from "../search-input/search-input.component";
import "./pos-order-promotion-list.component.scss";
import DiscountImage from "../../assets/images/discount-icon.png";

function PosOrderPromotionListComponent(props) {
  const { callBack, hiddenPromotion, colorConfig, isPos, fontFamily, posDiscountCodes, applyDiscountCodes } = props;
  const discountCodeCollectionInRedux = useSelector(discountCodeCollectionSelector);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const promotionListRef = useRef(null);
  const [discountCodeValue, setDiscountCodeValue] = useState("");
  const discountCodeFieldName = "discountCode";

  const translateData = {
    placeholderPromotion: t("checkOutPage.placeHolderPromotion", "Enter your discount code"),
    changeAddress: t("checkOutPage.changeAddress", "Change another address"),
    validatedDiscountCode: t(
      "promotion.discountCode.description.notFound",
      "Mã giảm giá không hợp lệ. Vui lòng thử lại.",
    ),
    titleDefault: t("promotion.titleDefault", "Bạn không có mã khuyến mãi nào"),
    redeem: t("checkOutPage.redeem", "Lấy mã"),
    storePromotion: t("checkOutPage.storePromotion", "Khuyến mãi cửa hàng"),
    myVoucher: t("checkOutPage.myVoucher", "Voucher của tôi"),
    pleaseEnterDiscountCode: t(
      "promotion.discountCode.pleaseEnterDiscountCode",
      "Vui lòng nhập mã giảm giá để sử dụng trong đơn hàng của bạn.",
    ),
  };

  const mappingDiscountCode = (data) => {
    let mappedDiscountCode = {
      id: data?.id,
      title: data?.name,
      code: data?.code,
      content: t(
        ListPromotionType.find((promotionType) => promotionType?.key === data?.discountCodeTypeId)?.name ?? "",
      ),
      endDate: data?.endDate ? formatDate(data?.endDate, DateFormat.DD_MM_YYYY_HH_MM_2) : null,
      isApply: data?.isApply ?? true,
    };
    return mappedDiscountCode;
  };

  const handleSortDiscountCodeList = (discountCodesCollection = [], discountCodes = []) => {
    const dataSort = discountCodesCollection;
    let data = [];
    if (dataSort) {
      let discountCodesUnSelected =
        dataSort?.filter((item) => !discountCodes?.some((discountCode) => discountCode === item?.code)) ?? [];
      let discountCodesSelected =
        dataSort?.filter((item) => discountCodes?.some((discountCode) => discountCode === item?.code)) ?? [];
      data = [...discountCodesSelected, ...discountCodesUnSelected];
    }
    return data;
  };

  const renderTitle = (title) => {
    return (
      <div className="title">
        <div className="title-text">{title}</div>
        <div className="title-line"></div>
      </div>
    );
  };

  function onChangeInput(e) {
    if (window?.changeInput) {
      clearTimeout(window.changeInput);
    }
    window.changeInput = setTimeout(() => {
      setDiscountCodeValue(e?.target?.value);
    }, 300);
  }

  const applyDiscountCode = async (discountCode) => {
    await applyDiscountCodes([...posDiscountCodes, discountCode], true);
  };

  const onClickRedeem = (code, isClickRedeem) => {
    let POSDiscountCodes = reduxService.getPOSDiscountCodes();
    const indexExist = POSDiscountCodes?.findIndex((discountCode) => discountCode === code);
    if (indexExist === -1) {
      POSDiscountCodes = [...POSDiscountCodes, code];
    } else {
      POSDiscountCodes.splice(indexExist, 1);
    }
    applyDiscountCodes(POSDiscountCodes, isClickRedeem);
    callBack(code);
  };

  const onApply = async () => {
    const code = form.getFieldValue(discountCodeFieldName)?.toUpperCase()?.trim();
    if (code) {
      try {
        const response = await posCartService.verifyDisCountCodeAsync(code);
        const responseCode = response?.discountCodeResult?.responseCode;
        const indexExist = discountCodeCollectionInRedux?.findIndex((item) => item?.code === code);
        if (responseCode === EnumDiscountCodeResponseCode.NotFound) {
          form.setFields([
            {
              name: discountCodeFieldName,
              errors: [t("promotion.discountCode.description.notFound") ?? ""],
            },
          ]);
          return;
        }
        if (
          responseCode === EnumDiscountCodeResponseCode.Success ||
          responseCode === EnumDiscountCodeResponseCode.MinimumPurchaseValue ||
          responseCode === EnumDiscountCodeResponseCode.Existed
        ) {
          if (indexExist !== -1 && posDiscountCodes?.includes(code)) {
            form.setFields([
              {
                name: discountCodeFieldName,
                errors: [t("promotion.discountCode.description.existed", "Mã giảm giá đã tồn tại.")],
              },
            ]);
          } else {
            const mappingResponse = mappingDiscountCode(response?.discountCodeResult?.result);
            posCartService.addDiscountCodeCollection(mappingResponse);
            applyDiscountCode(code);
            form.setFieldValue(discountCodeFieldName, "");
            const data = {
              isShow: false,
              message: t("messages.appliedDiscountCodeSuccessfully", "Áp dụng mã giảm giá thành công"),
              type: ToastMessageType.SUCCESS,
              duration: 3,
              key: moment.now(),
            };
            store?.dispatch(setToastMessage(data));
          }
        } else {
          form.setFields([
            {
              name: discountCodeFieldName,
              errors: [t(response?.discountCodeResult?.responseMessage) ?? ""],
            },
          ]);
          return;
        }
      } catch (err) {}
    } else {
      form.setFields([
        {
          name: discountCodeFieldName,
          errors: [t("promotion.discountCode.description.notFound") ?? ""],
        },
      ]);
      return;
    }
  };

  return (
    <div style={{ fontFamily: fontFamily }}>
      <Form form={form}>
        <div className="search-discount-code">
          <Form.Item
            name={discountCodeFieldName}
            rules={[{ required: true, message: translateData.validatedDiscountCode }]}
            style={{ fontFamily: fontFamily }}
          >
            <SearchInputComponent
              {...props}
              suffix={
                <Button className="btn-apply" onClick={discountCodeValue?.length > 0 ? onApply : () => {}}>
                  {translateData.redeem}
                </Button>
              }
              maxLength={20}
              placeholder={translateData.placeholderPromotion}
              isInstore={isPos}
              pleaseEnterDiscountCode={!isPos ? translateData.pleaseEnterDiscountCode : ""}
              onChange={onChangeInput}
            />
          </Form.Item>
        </div>
      </Form>

      <div className="promotion-list" ref={promotionListRef}>
        {discountCodeCollectionInRedux?.length > 0 ? (
          <>
            {renderTitle(translateData.myVoucher)}
            <div>
              {handleSortDiscountCodeList(discountCodeCollectionInRedux, posDiscountCodes)?.map((item) => {
                return (
                  <MyVoucherCard
                    idName={item.code}
                    className="my-voucher-item"
                    data={item}
                    onClickRedeem={(isClickRedeem) => {
                      onClickRedeem(item?.code, isClickRedeem);
                    }}
                    hiddenPromotion={(value) => hiddenPromotion(value)}
                    isSelected={posDiscountCodes?.some((discountCode) => discountCode === item?.code)}
                    colorConfig={colorConfig}
                    isApply={item?.isApply ?? true}
                  />
                );
              })}
            </div>
          </>
        ) : (
          <div className="discount-default">
            <Image src={DiscountImage} preview={false} />
            <span className="discount-default-title">{translateData.titleDefault}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(PosOrderPromotionListComponent, (prevProps, nextProps) => prevProps === nextProps);
