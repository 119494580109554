import React from "react";
import { formatTextCurrency } from "../../../../../../utils/helpers";

const ToppingItem = (props) => {
  const { cartItem } = props;

  return (
    cartItem?.toppings?.reduce((total, topping) => {
      return total + topping.quantity;
    }, 0) > 0 && (
      <div className="toppings">
        {cartItem?.toppings?.map((itemTopping) => {
          return (
            itemTopping?.quantity > 0 && (
              <div className="topping-item">
                <div className="name text-line-clamp-1">{itemTopping?.name}</div>
                <div>x {itemTopping?.quantity}</div>
                <div className="price-value">
                  <div className="topping-price-col">
                    <span>{formatTextCurrency(itemTopping?.priceValue)}</span>
                    {itemTopping?.priceValue < itemTopping?.originalPrice && (
                      <span className="topping-original-price">{formatTextCurrency(itemTopping?.originalPrice)}</span>
                    )}
                  </div>
                </div>
              </div>
            )
          );
        })}
      </div>
    )
  );
};

export default ToppingItem;
