import React from "react";
import { EnumOrderItemStatus } from "../../../../../../../constants/enums";
import { useTranslation } from "react-i18next";
import "./StatusOrderedItem.scss";

const StatusOrderedItem = (props) => {
  const { statusId } = props;
  const { t } = useTranslation();
  const getStatusOrdered = (statusId) => {
    switch (statusId) {
      case EnumOrderItemStatus.Completed:
        return (
          <div className="status-ordered status-ordered-completed">
            {t("reservation.reserveTableStatus.completed")}
          </div>
        );
      case EnumOrderItemStatus.Canceled:
        return (
          <div className="status-ordered status-ordered-cancelled">
            {t("reservation.reserveTableStatus.cancelled")}
          </div>
        );
      default:
        return <></>;
    }
  };

  return <>{getStatusOrdered(statusId)}</>;
};

export default StatusOrderedItem;
