export const BackToTopIcon = (props) => {
  const { color = "white", backgroundColor = "#DB4D29" } = props;
  return (
    <svg opacity={0.8} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.9169 2.62346C28.6941 1.11118 31.3059 1.11118 33.0831 2.62346C34.3391 3.69213 36.0588 4.03421 37.6281 3.52751C39.8488 2.81047 42.2617 3.80995 43.325 5.88724C44.0764 7.35519 45.5343 8.32935 47.178 8.46176C49.5041 8.64913 51.3509 10.4959 51.5382 12.822C51.6706 14.4657 52.6448 15.9236 54.1128 16.675C56.1901 17.7383 57.1895 20.1512 56.4725 22.3719C55.9658 23.9412 56.3079 25.6609 57.3765 26.9169C58.8888 28.6941 58.8888 31.3059 57.3765 33.0831C56.3079 34.3391 55.9658 36.0588 56.4725 37.6281C57.1895 39.8488 56.1901 42.2617 54.1128 43.325C52.6448 44.0764 51.6706 45.5343 51.5382 47.178C51.3509 49.5041 49.5041 51.3509 47.178 51.5382C45.5343 51.6706 44.0764 52.6448 43.325 54.1128C42.2617 56.1901 39.8488 57.1895 37.6281 56.4725C36.0588 55.9658 34.3391 56.3079 33.0831 57.3765C31.3059 58.8888 28.6941 58.8888 26.9169 57.3765C25.6609 56.3079 23.9412 55.9658 22.3719 56.4725C20.1512 57.1895 17.7383 56.1901 16.675 54.1128C15.9237 52.6448 14.4657 51.6706 12.822 51.5382C10.4959 51.3509 8.64913 49.5041 8.46176 47.178C8.32935 45.5343 7.35519 44.0764 5.88724 43.325C3.80995 42.2617 2.81047 39.8488 3.52751 37.6281C4.03421 36.0588 3.69213 34.3391 2.62346 33.0831C1.11118 31.3059 1.11118 28.6941 2.62346 26.9169C3.69213 25.6609 4.03421 23.9412 3.52751 22.3719C2.81047 20.1512 3.80995 17.7383 5.88724 16.675C7.35519 15.9237 8.32935 14.4657 8.46176 12.822C8.64913 10.4959 10.4959 8.64913 12.822 8.46176C14.4657 8.32935 15.9237 7.35519 16.675 5.88724C17.7383 3.80995 20.1512 2.81047 22.3719 3.52751C23.9412 4.03421 25.6609 3.69213 26.9169 2.62346Z"
        fill={backgroundColor}
      />
      <path
        d="M39.2398 33.5582L31.6331 25.9516C30.7348 25.0532 29.2648 25.0532 28.3664 25.9516L20.7598 33.5582"
        stroke={color}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
