import { Badge, Button, Col, Row } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import posCartService from "../../../services/pos/pos-cart.services";
import themeConfigServices from "../../../services/theme-config.services";
import { ArrowBackIcon, CartStoreIcon, CloseHeaderIcon, DefaultStoreIcon } from "../../assets/icons.constants";
import defaultConfig from "../../default-store.config";
import { useSearchParams } from "../../hooks";
import ImageWithFallback from "../fnb-image-with-fallback/fnb-image-with-fallback.component";
import NotificationDialog from "../notification-dialog/notification-dialog.component";
import "./MinHeader.scss";

export default function MinHeader(props) {
  const themeConfig = useSelector((state) => state.session?.themeConfig);
  const fontFamily = themeConfig?.general?.font?.name;
  const groupColorConfig = themeConfig.general?.color?.colorGroups;
  const posCartItems = useSelector((state) => state.session?.posCartItems);
  themeConfigServices.setGlobalCSSPropertyByColorGroupDefault();
  const history = useHistory();
  const [t] = useTranslation();
  const [isShowModalConfirmLeave, setIsShowModalConfirmLeave] = useState(false);
  const query = useSearchParams();

  const countCartItems = () => {
    let countItem = 0;
    Array.isArray(posCartItems) &&
      posCartItems?.forEach((e) => {
        countItem = countItem + e.quantity;
      });

    return countItem;
  };

  const StyledIconFill = styled.div`
    .icon-fill-color {
      path {
        fill: ${groupColorConfig?.titleColor};
      }
    }
  `;

  const {
    className = "",
    addonBefore = (
      <ArrowBackIcon
        className="arrow-left-icon cursor-pointer"
        onClick={() => {
          history.goBack();
        }}
      />
    ),
    addonBetween = (
      <ImageWithFallback
        src={themeConfig?.general?.header?.logoUrl ?? defaultConfig?.general?.header?.logoUrl}
        alt="icon"
        fallbackSrc={DefaultStoreIcon}
        className="logo-original-theme"
        style={{ width: "auto", height: "100%", maxWidth: "100%" }}
      />
    ),
    addonAfter = (
      <>
        <a className="pointer" role="button" style={{ marginRight: 20 }} href={"/pos-order?" + query.toString()}>
          <Badge count={countCartItems()} color="#ffffff" style={{ color: "#000000" }}>
            <StyledIconFill>
              <span className="icon-fill-color">
                <CartStoreIcon alt="cart-icon"></CartStoreIcon>
              </span>
            </StyledIconFill>
          </Badge>
        </a>
        <CloseHeaderIcon className="close-icon" onClick={closePage} />
      </>
    ),
  } = props;

  function closePage() {
    setIsShowModalConfirmLeave(true);
  }

  function handleOnClickIgnore() {
    setIsShowModalConfirmLeave(false);
  }

  function handleOnClickConfirmLeave() {
    setIsShowModalConfirmLeave(false);
    const pathName = query.get("backToPath");
    if (pathName) {
      posCartService.closeQrCodePage(
        history.push({
          pathname: pathName,
          state: { orderId: props?.orderId ?? "" },
        }),
      );
    } else {
      posCartService.closeQrCodePage(history.push("/")); // back to home page
    }
  }

  return (
    <>
      <Row className={`min-header ${className}`}>
        <Col xs={8} className="addon-before">
          {addonBefore}
        </Col>
        <Col xs={8} className="addon-between">
          {addonBetween}
        </Col>
        <Col xs={8} className="addon-after">
          {addonAfter}
        </Col>
      </Row>
      <NotificationDialog
        open={isShowModalConfirmLeave}
        className="confirm-leave-notify-dialog-instore"
        title={t("order.confirmation")}
        style={{ fontFamily }}
        content={
          <span
            dangerouslySetInnerHTML={{
              __html: t("orderDetail.confirmLeave"),
            }}
          ></span>
        }
        onCancel={() => setIsShowModalConfirmLeave(false)}
        footer={[
          <Button className="ignore-btn" onClick={handleOnClickIgnore} style={{ fontFamily }}>
            {t("button.ignore")}
          </Button>,
          <Button className="confirm-leave-btn" onClick={handleOnClickConfirmLeave} style={{ fontFamily }}>
            {t("button.confirmLeave")}
          </Button>,
        ]}
      />
    </>
  );
}
