import actionTypes from "./order.type";

export function setQrCodeOrder(data) {
  return { type: actionTypes.SET_QR_ORDER, payload: data };
}

export function setPOSDiscountCodes(data) {
  return { type: actionTypes.SET_POS_DISCOUNT_CODES, payload: data };
}

export function setPOSOrderDetail(data) {
  return { type: actionTypes.SET_POS_ORDER_DETAIL, payload: data };
}

export function resetPOSOrderDetail() {
  return { type: actionTypes.RESET_POS_ORDER_DETAIL };
}
