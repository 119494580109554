import { Col, Form, Input, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import branchDataService from "../../../data-services/branch-data.services";
import { getStorage, localStorageKeys } from "../../../utils/localStorage.helpers";
import {
  CloseWorkingHourIcon,
  ContactCallIcon,
  ContactNameLeftIcon,
  ContactNameRightIcon,
  ContactSmsIcon,
  ContactUserIcon,
  OpenWorkingHourIcon,
} from "../../assets/icons.constants";
import backgroundBottomDefault from "../../assets/images/contact-footer-default.png";
import backgroundTopDefault from "../../assets/images/product-detail-header-default.png";
import { GoogleMapComponent } from "../../components/fnb-google-map/google-map.component";
import { backgroundTypeEnum } from "../../constants/store-web-page.constants";
import Index from "../../index";
import "./contact.page.scss";
import MetaInfoMemoized from "../../components/MetaInfo";
import { getGeocode, getLatLng } from "use-places-autocomplete";

export default function ContactPage({ isCustomize, ...props }) {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const translateData = {
    contactUS: t("contact.contactUS", "CONTACT US"),
    contact: t("menu.contact", "Contact"),
    haveQuestion: t("contact.haveQuestion", "HAVE QUESTION?"),
    callUsOrVisitPlace: t("contact.callUsOrVisitPlace", "CALL US OR VISIT PLACE"),
    open: t("contact.open", "Open"),
    sendUSMessage: t("contact.sendUSMessage", "SEND US MESSAGE"),
    yourName: t("contact.yourName", "Your name"),
    enterYourPhoneNumber: t("contact.enterYourPhoneNumber", "Enter your phone number"),
    enterYourEmail: t("contact.enterYourEmail", "Enter your email"),
    subject: t("contact.subject", "Subject"),
    message: t("contact.message", "Message"),
    pleaseYourName: t("contact.pleaseYourName"),
    pleaseEnterYourPhoneNumber: t("contact.pleaseEnterYourPhoneNumber"),
    pleaseEnterYourEmail: t("contact.pleaseEnterYourEmail"),
    pleaseSubject: t("contact.pleaseSubject"),
    pleaseMessage: t("contact.pleaseMessage"),
    today: t("contact.today", "Today"),
    isOpen: t("contact.isOpen", "Is Open"),
  };
  const googleMapRef = useRef();
  const jsonConfig = getStorage(localStorageKeys.STORE_CONFIG);
  const storeConfig = JSON.parse(jsonConfig);
  const storeId = storeConfig?.storeId;
  const [branchesInfo, setBranchesInfo] = useState(null);
  const [branchOpenStates, setBranchOpenStates] = useState({});
  const [selectedColIndex, setSelectedColIndex] = useState(null);
  const [positionSelected, setPositionSelected] = useState({});

  const toggleOpen = (index) => {
    setBranchOpenStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
    document.title = translateData.contact;
    getBranches();
  }, []);

  async function getBranches() {
    try {
      const branches = await branchDataService.getBranchesInStoreAsync(storeId);
      if (branches) {
        const branchesByCustomerAddress = branches?.data?.storeBranches;
        setBranchesInfo(branchesByCustomerAddress);
        convertAddressFullToLatLng(branchesByCustomerAddress[0]?.fullAddress );
      }
    } catch (error) {}
  }

  const convertAddressFullToLatLng = async (fullAddress) => {
    const results = await getGeocode({ address: fullAddress });
    if(results?.length > 0) {
      const { lat, lng } = getLatLng(results[0]);
      setPositionSelected({lat,lng })
    }
  }

  async function handleSendMessage() {
    if (isCustomize) return;
    const values = await form.validateFields();
  }

  function getBackground(styles, type) {
    let style = {};
    const IMAGE_BG_DEFAULT = type == "header" ? backgroundTopDefault : backgroundBottomDefault;
    if (styles?.backgroundType === backgroundTypeEnum.Color) {
      style = {
        backgroundColor: styles?.backgroundColor,
      };
    } else {
      style = {
        background: `url(${
          styles?.backgroundImage ? styles?.backgroundImage : IMAGE_BG_DEFAULT
        }) no-repeat center center`,
      };
    }

    return style;
  }

  function getBackgroundInformation(information) {
    let styleInformation = {};
    if (information?.backgroundType === backgroundTypeEnum.Color) {
      styleInformation = {
        backgroundColor: information?.backgroundColor,
      };
    } else {
      styleInformation = {
        background: `url(${
          information?.backgroundImage
            ? information?.backgroundImage
            : "/images/default-theme/2/contact-information-default.png"
        }) no-repeat center center`,
        backgroundSize: "cover",
      };
    }

    return styleInformation;
  }

  function getColorGroup(colorGroup, general) {
    const selectedColorGroup =
      general?.color?.colorGroups?.find((a) => a?.id === colorGroup?.colorGroupId) ?? general?.color?.colorGroups?.[0];
    return selectedColorGroup;
  }

  const onClickBranchItem = (index, fullAddress) => {
    setSelectedColIndex(index);
    convertAddressFullToLatLng(fullAddress);
  }

  const RenderPreviewContent = (props) => {
    const { config, general } = props;
    const { header = {}, information = {}, contactUs = {} } = config;
    const styleHeader = getBackground(header, "header");
    const styleInformation = getBackgroundInformation(information);
    const styleContactUs = getBackground(contactUs, "contactUs");
    const colorGroupHeader = getColorGroup(header, general);
    const colorGroupInformation = getColorGroup(information, general);
    const colorGroupContactUs = getColorGroup(contactUs, general);

    const StyledIcon = styled.span`
      svg path {
        fill: ${colorGroupInformation?.buttonBackgroundColor};
      }
    `;
    const listBranch = information?.isAllBranches
      ? branchesInfo
      : information?.branchIds?.length > 0
      ? branchesInfo?.filter((item) => information?.branchIds?.includes(item.id))
      : branchesInfo;
    return (
      <div className="contact-wrapper">
        {/* Header */}
        <div className="contact-section container-fluid contact-header" style={styleHeader}>
          <h1 style={{ color: colorGroupHeader?.titleColor }}>
            {header?.title ? header.title : translateData.contact}
          </h1>
        </div>
        {/* Section */}
        <div className="contact-section container-fluid" style={styleInformation}>
          <div className={`contact-information-wrapper ${isCustomize ? "contact-information-wrapper-customize" : ""}`}>
            <div className="top-content">
              <div className="top-title">{information?.header ? information?.header : translateData.haveQuestion}</div>
              <div className="secondary-title" style={{ color: colorGroupInformation?.buttonBackgroundColor }}>
                {information?.title ? information?.title : translateData.callUsOrVisitPlace}
              </div>
              <div className="description">
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${information?.description ?? ""}`,
                  }}
                ></span>
              </div>
              <GoogleMapComponent ref={googleMapRef} positionSelected={positionSelected} className="google-map-box" />
            </div>
            <div className="bottom-content">
              <div className="branch-info-wrapper">
                <Row className="branch-row" gutter={[36, 48]}>
                  {listBranch?.map((branch, index) => {
                    const branchInfoClass = selectedColIndex === index ? "border-branch-info" : "";
                    return (
                      <Col key={index} span={12} xs={24} sm={24} md={12} onClick={() => {selectedColIndex != index && onClickBranchItem(index, branch?.fullAddress)}}>
                        <div
                          className={branchInfoClass}
                          style={
                            selectedColIndex === index
                              ? { borderColor: colorGroupInformation?.buttonBackgroundColor }
                              : {}
                          }
                        >
                          <div className="branch-title">
                            <StyledIcon>
                              <ContactNameLeftIcon />
                            </StyledIcon>
                            <div
                              className="name"
                              style={
                                selectedColIndex === index
                                  ? { color: colorGroupInformation?.buttonBackgroundColor }
                                  : {}
                              }
                            >
                              {branch?.branchName}
                            </div>
                            <div style={{ marginLeft: "auto" }}>
                              <ContactNameRightIcon />
                            </div>
                          </div>
                          <div className="branch-description">
                            <p className="branch-text">{branch?.fullAddress}</p>
                            <p className="branch-text mt-12 ">{branch?.phoneNumber}</p>
                            <p className="branch-text mt-12">{branch?.email}</p>
                            <p className="branch-text mt-12">
                              <span className="title-open">{translateData.open}</span>
                              <span
                                className="is-open"
                                style={{ background: colorGroupInformation?.buttonBackgroundColor }}
                                onClick={() => toggleOpen(index)}
                              >
                                <>{translateData.isOpen} </>
                                <>{branchOpenStates[index] ? <CloseWorkingHourIcon /> : <OpenWorkingHourIcon />}</>
                              </span>
                              {branchOpenStates[index] && (
                                <div className="open-time">
                                  {translateData.today}
                                  {branch?.storeBranchWorkingHours?.map((workingHour) => (
                                    <span className="working-hours" key={workingHour.id}>
                                      {workingHour.openTime} - {workingHour.closeTime}
                                    </span>
                                  ))}
                                </div>
                              )}
                            </p>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          </div>
        </div>
        {/* Form */}
        <div className="contact-section container-fluid contact-footer" style={styleContactUs}>
          <div className="contact-form-wrapper">
            <div className="info-box">
              <p className="top-title">{translateData.contactUS}</p>
              <p className="secondary-title" style={{ color: colorGroupContactUs?.buttonBackgroundColor }}>
                {translateData.sendUSMessage}
              </p>
              <p className="description">
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${contactUs?.description ?? ""}`,
                  }}
                ></span>
              </p>
            </div>
            <div className="form-box">
              <Form form={form}>
                <Row gutter={[16, 0]}>
                  <Col span={8} xs={24} sm={24} md={8}>
                    <Form.Item
                      className="form-item"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: translateData.pleaseYourName,
                        },
                      ]}
                    >
                      <Input
                        allowClear
                        maxLength={100}
                        placeholder={translateData.yourName}
                        prefix={<ContactUserIcon />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} xs={24} sm={24} md={8}>
                    <Form.Item
                      className="form-item"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: translateData.pleaseEnterYourPhoneNumber,
                        },
                      ]}
                    >
                      <Input
                        allowClear
                        maxLength={100}
                        placeholder={translateData.enterYourPhoneNumber}
                        prefix={<ContactCallIcon />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} xs={24} sm={24} md={8}>
                    <Form.Item
                      className="form-item"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: translateData.pleaseEnterYourEmail,
                        },
                      ]}
                    >
                      <Input
                        allowClear
                        maxLength={100}
                        placeholder={translateData.enterYourEmail}
                        prefix={<ContactSmsIcon />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      className="form-item"
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: translateData.pleaseSubject,
                        },
                      ]}
                    >
                      <Input allowClear maxLength={255} placeholder={translateData.subject} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="note"
                      className="form-item-note"
                      rules={[
                        {
                          required: true,
                          message: translateData.pleaseMessage,
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder={translateData.message}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        className="input-area-custom"
                        maxLength={255}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="button-box">
              <div
                className="send-message-btn"
                style={{ background: colorGroupContactUs?.buttonBackgroundColor }}
                onClick={handleSendMessage}
              >
                <span className="btn-text" style={{ color: colorGroupContactUs?.buttonTextColor }}>
                  {translateData.sendUSMessage}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <MetaInfoMemoized title={props?.pageName} />
      <Index
        {...props}
        isCustomize={isCustomize}
        contentPage={(_props) => {
          return <RenderPreviewContent {..._props} />;
        }}
      />
    </>
  );
}
