import { Space } from "antd";
import { CloseIcon } from "../../../assets/icons.constants";

function CloseButton(props) {
  return (
    <Space>
      <CloseIcon />
    </Space>
  );
}

export default CloseButton;
