import { isNonEmptyArray } from "../../utils/helpers";

const mapperProducts2ProductVariantsFromCategories = (productCategories) => {
  if (!isNonEmptyArray(productCategories)) return [];

  let productPricesData = [];
  let index = 0;
  let currentComboId = null;

  productCategories?.forEach((item) => {
    if (item?.isCombo) {
      item?.comboItems?.forEach((combo) => {
        combo?.productPrices?.forEach((comboProduct) => {
          if (currentComboId !== combo?.id) {
            index += 1;
          }

          const productPrice = {
            productPriceId: comboProduct?.productPriceId,
            comboId: combo?.id,
            index: index,
          };
          productPricesData.push(productPrice);

          currentComboId = combo?.id;
        });
      });
    } else {
      item?.products?.forEach((product) => {
        index += 1;

        const productPrice = {
          productPriceId: product?.productPriceDefault?.id,
          index: index,
        };
        productPricesData.push(productPrice);
      });
    }
  });

  return productPricesData;
};

const mapperProducts2Variants = (products) => {
  if (!isNonEmptyArray(products)) return [];
  const productPrices = products?.map((product, index) => ({
    productPriceId: product?.productPriceId ?? product?.productPriceDefault?.id,
    index: index,
  }));

  return productPrices;
};

const mapperOutOfStockServicesQROrder = {
  mapperProducts2ProductVariantsFromCategories,
  mapperProducts2Variants,
};

export default mapperOutOfStockServicesQROrder;
