import styled from "styled-components";

function OrderAreaInfo(props) {
  const { areaName } = props;
  const OrderAreaInfoStyled = styled.div`
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  `;
  const AreaNameStyled = styled.b`
    display: block;
    text-align: center;
  `;
  return (
    <OrderAreaInfoStyled>
      <AreaNameStyled>{areaName ?? ""}</AreaNameStyled>
    </OrderAreaInfoStyled>
  );
}

export default OrderAreaInfo;
