import { Radio, Space } from "antd";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatTextCurrency } from "../../../../../utils/helpers";
import CheckoutInfoCard from "../../../../components/checkout-info-cart/checkout-info-cart.component";
import { EnumDeliveryMethod } from "../../../../constants/enum";
import "./delivery-method.style.scss";

export const CheckoutDeliveryMethod = forwardRef((props, ref) => {
  const {
    onChange,
    value,
    isDefault,
    mockupData,
    isCustomize,
    configuration,
    colorGroups,
    colorConfig,
    isCashPaymentMethod = false,
  } = props;

  const [t] = useTranslation();
  const deliveryMethods = useSelector((state) => state.session.deliveryMethods ?? []);
  const deliveryAddress = useSelector((state) => state.session.deliveryAddress ?? null);
  const [disabled, setDisabled] = useState(false);
  const handleRadioChange = (e) => {
    if (!disabled) {
      setDisabled(true);
      const deliveryMethod = e.target.value;
      onChange && onChange(deliveryMethod);
      setTimeout(() => {
        setDisabled(false);
      }, 300); // Set the timeout - When changing the Radio button multiple times calls the api so I add a timeout for this event.
    }
  };
  useImperativeHandle(ref, () => ({}));

  const translatedData = {
    shippingMethod: t("checkOutPage.shippingMethod", "Shipping method"),
    justSupportOrderHasPaymentbyCashOnly: t(
      "checkOutPage.justSupportOrderHasPaymentbyCashOnly",
      "Just support order has payment by COD (cash) only",
    ),
    deliveryByShop: t("checkOutPage.deliveryByShop", "Delivery by shop"),
  };

  function getDeliveryMethodName(enumId, deliveryMethodName) {
    switch (enumId) {
      case EnumDeliveryMethod.SELF_DELIVERY:
        return translatedData.deliveryByShop;
      default:
        return deliveryMethodName;
    }
  }

  const validateMessage = () => {
    if (!deliveryMethods || deliveryMethods?.length <= 0) {
      if (!deliveryAddress?.receiverAddress || !deliveryAddress?.branchAddress)
        return <p>Vui lòng chọn địa chỉ giao - nhận hàng</p>;
    }
  };
  return (
    <CheckoutInfoCard
      className="theme2-checkout-delivery-method"
      title={translatedData.shippingMethod.toUpperCase()}
      configuration={configuration}
      colorGroups={colorGroups}
      colorConfig={colorConfig}
    >
      <Radio.Group className="delivery-method-radio-group radio-style" onChange={handleRadioChange} value={value}>
        <Space direction="vertical">
          {validateMessage()}
          {(isDefault || isCustomize ? mockupData : deliveryMethods)?.map((deliveryMethod, index) => {
            const deliveryMethodGrabExpress = deliveryMethod?.enumId === EnumDeliveryMethod.GrabExpress;

            const isDisabled = !isCashPaymentMethod && deliveryMethodGrabExpress;
            const isShowPromotionGrab = deliveryMethod?.discountInfo?.success;
            return (
              <div className="radio-button-wrapper">
                <Radio
                  key={deliveryMethod?.deliveryMethodId}
                  value={deliveryMethod}
                  className={`delivery-method-radio${
                    index + 1 === deliveryMethods?.length ? " delivery-method-radio-last-child" : ""
                  }`}
                  disabled={isDisabled}
                >
                  <>
                    <div
                      className={`delivery-method-radio-label ${
                        deliveryMethodGrabExpress ? "delivery-method-radio-label-grab-express" : ""
                      }`}
                    >
                      <div
                        className="delivery-method-radio-name"
                        style={{
                          color: isDisabled ? "#939393" : undefined,
                          textTransform:
                            deliveryMethod?.enumId !== EnumDeliveryMethod.SELF_DELIVERY ? "uppercase" : "none",
                        }}
                      >
                        {getDeliveryMethodName(deliveryMethod?.enumId, deliveryMethod?.deliveryMethodName)}
                      </div>

                      <div className="delivery-price">
                        {deliveryMethod.enumId === EnumDeliveryMethod.GrabExpress && isShowPromotionGrab && (
                          <div className="delivery-method-radio-price-original">
                            {formatTextCurrency(deliveryMethod?.originalFee)}
                          </div>
                        )}
                        <div className="delivery-method-radio-price">
                          {formatTextCurrency(deliveryMethod?.feeValue)}
                        </div>
                      </div>
                    </div>
                  </>
                </Radio>

                {deliveryMethodGrabExpress && (
                  <div className="delivery-method-grab-express" style={{ color: isDisabled ? "#939393" : undefined }}>
                    *{t(translatedData.justSupportOrderHasPaymentbyCashOnly)}
                  </div>
                )}
              </div>
            );
          })}
        </Space>
      </Radio.Group>
    </CheckoutInfoCard>
  );
});
