import { Col, Row } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { userInfoSelector } from "../../../../modules/session/session.reducers";
import { CloseCircleIcon, LocationOutlineIcon } from "../../../assets/icons.constants";
import { FnbInput } from "../../fnb-input/fnb-input.component";
import "./places-autocomplete.style.scss";
import { getInternationalRegion } from "../../../../utils/helpers";
import i18n from "i18next";

export const PlacesAutocompleteComponent = forwardRef((props, ref) => {
  const [t] = useTranslation();
  const { onSelectLocation, onEmptyLocation, placeholder, initLocation, initAddress, hasCustomerAddresses } = props;
  const [isError, setIsError] = useState(false);
  const isMaxWidth428 = useMediaQuery({ maxWidth: 428 });
  const addressList = useSelector(userInfoSelector)?.addressList;
  const [locations, setLocations] = useState([]);
  const isInternationalRegion = getInternationalRegion();

  useEffect(() => {
    if (initLocation) {
      handleSelect({ description: initLocation });
    }
    if (initAddress) {
      setValue(initAddress);
    }
  }, [initAddress]);

  useEffect(() => {
    clearSuggestions && clearSuggestions();
    clearCache && clearCache();
  }, [i18n.language]);

  useImperativeHandle(ref, () => ({
    setIsError(isError) {
      setIsError(isError);
    },
    clearCurrentLocation() {
      setValue(null);
      setIsError(false);
    },
    setAddress(address) {
      setValue(address);
    },
  }));

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
    clearCache,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      region: isInternationalRegion ? "" : "vn",
      language: i18n.language,
    },
    debounce: 500,
  });

  const refClickOutside = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    const value = e.target.value;
    setValue(value);
    if (!value) {
      onEmptyLocation();
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  const handleSelect = ({ description, lat, lng }) => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    onSelectLocation({
      center: { lat, lng },
      address: description,
    });
  };

  // Include lat, lng to the suggestions data
  const fetchLocations = async () => {
    const locationsData = await Promise.all(
      data.map(async (item) => {
        const { description } = item;
        const results = await getGeocode({ address: description });
        const { lat, lng } = getLatLng(results[0]);
        return { ...item, lat, lng };
      }),
    );
    setLocations(locationsData);
  };

  useEffect(() => {
    fetchLocations();
  }, [data]);

  const renderSuggestions = () =>
    locations.map((location) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
        lat,
        lng,
      } = location;
      const savedAddress = addressList?.find((address) => address.lat === lat && address.lng === lng);

      return (
        <div className="address-popover-item" key={place_id} onClick={() => handleSelect(location)}>
          <Row>
            <Col span={isMaxWidth428 ? 4 : 2}>
              <div className="d-flex icon-box">
                <span className="icon-location">
                  <LocationOutlineIcon />
                </span>
              </div>
            </Col>
            <Col span={isMaxWidth428 ? 20 : 22}>
              <div className="address-box">
                {savedAddress && <span className="address-name text-overflow">{savedAddress.name}</span>}
                {savedAddress && <br />}
                <span className="street-text text-overflow">{main_text}</span>
                <br />
                <span className="ward-text text-overflow">{secondary_text}</span>
              </div>
            </Col>
          </Row>
        </div>
      );
    });

  return (
    <div ref={refClickOutside} style={{ width: "100%" }}>
      <FnbInput
        className={`fnb-input delivery-select-address-input ${isError && "error-address-input"}`}
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder={placeholder}
        allowClear={{ clearIcon: <CloseCircleIcon /> }}
        prefix={<LocationOutlineIcon />}
        maxLength={255}
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && (
        <div className={`delivery-address-popover ${hasCustomerAddresses ? "has-customer-address" : ""}`}>
          <div className="delivery-address-popover-scroll">{renderSuggestions()}</div>
        </div>
      )}
    </div>
  );
});
