import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";
import "../change-language/change-language.scss";
import { setLanguageSession } from "../../../modules/session/session.actions";
import i18n from "../../../utils/i18n";
import { listDefaultLanguage } from "./list-language";
import { ArrowRightIcon, LanguageDropdownIcon } from "../../assets/icons.constants";
import { useMediaQuery } from "react-responsive";
import { storeConfigSelector } from "../../../modules/session/session.reducers";
import { EnumInternationalRegion } from "../../constants/store-web-page.constants";
import storeDataService from "../../../data-services/store-data.service";
import { localStorageKeys } from "../../../utils/localStorage.helpers";
import { getInternationalRegion } from "../../../utils/helpers";
import moment from "moment";

function ChangeLanguage(props) {
  const { onVisibleChange, visible, className, overlayClassName, fontFamily, isCustomize } = props;
  const { t } = useTranslation();
  const [defaultLanguage, setDefaultLanguage] = useState(i18n?.language);
  const [languageList, setLanguageList] = useState([]);
  const dispatch = useDispatch();
  const languageSession = useSelector((state) => state.session?.languageSession);
  const isMaxWidth640 = useMediaQuery({ maxWidth: 640 });
  const storeType = useSelector(storeConfigSelector)?.type;
  const themeConfig = useSelector((state) => state.session?.themeConfig);
  const [isGetStoreConfig, setIsGetStoreConfig] = useState(false);
  const isInternational = getInternationalRegion();
  useEffect(() => {
    if (storeType === undefined && !isGetStoreConfig) {
      setIsGetStoreConfig(true);
      getStoreConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetStoreConfig]);

  useEffect(() => {
    if (!languageSession) {
      loadLanguage(storeType);
      window.reloadLang = loadLanguage(storeType);
    } else {
      if (storeType !== undefined || isInternational) {
        let languages = listDefaultLanguage;
        let defaultLang = languageSession?.default;
        if (storeType === EnumInternationalRegion.INTERNATIONAL || isInternational) {
          languages = languages?.filter((language) => language?.languageCode !== "vi");
          let languageEN = languages?.filter((language) => language?.languageCode === "en");
          defaultLang = languageEN?.length > 0 ? languageEN[0] : defaultLang;
        }
        setDefaultLanguage(defaultLang ?? languages[0]);
        setLanguageList(languages);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const locale = defaultLanguage?.languageCode;
    moment.locale(locale);
  }, [defaultLanguage]);

  const getStoreConfig = async () => {
    if (isCustomize) {
      loadLanguage(0, false);
    } else {
      const currentStoreId =
        themeConfig?.storeId ?? JSON.parse(localStorage?.getItem(localStorageKeys.STORE_CONFIG))?.storeId;
      if (currentStoreId) {
        const resStoreConfig = await storeDataService.getStoreConfig(currentStoreId);
        if (resStoreConfig?.data) {
          let type = resStoreConfig?.data?.storeConfig?.type;
          loadLanguage(type, false);
        }
      }
    }
  };

  const loadLanguage = (storeType, isSetLanguageSession = true) => {
    let languages = listDefaultLanguage;
    let defaultLangCode = defaultLanguage;
    let defaultLang;
    if (storeType === EnumInternationalRegion.INTERNATIONAL) {
      languages = languages?.filter((language) => language?.languageCode !== "vi");
      let languageEN = languages?.filter((language) => language?.languageCode === "en");
      defaultLang = languageEN?.length > 0 ? languageEN[0] : defaultLang;
    }
    defaultLang = languages?.find((i) => i?.languageCode === defaultLangCode) || languages[0];
    setLanguageList(languages);
    setDefaultLanguage(defaultLang);
    if (isSetLanguageSession) dispatch(setLanguageSession({ default: defaultLang, list: languages }));
  };

  const onChangeLang = (selectedLang) => {
    i18n.changeLanguage(selectedLang);

    let language = languageList?.find((lang) => lang.languageCode === selectedLang);
    setDefaultLanguage(language);
    dispatch(setLanguageSession({ default: language, list: languageList }));
  };

  const jsUcfirst = (string) => {
    return string?.toUpperCase();
  };

  const contentLanguage = () => {
    return (
      <>
        {languageList?.map((item, index) => (
          <div
            key={index}
            onClick={() => onChangeLang(item.languageCode)}
            className="pointer"
            style={{ fontFamily: fontFamily }}
          >
            {getFlag(item.languageCode, item.name)}
            <a>{t(item.name)}</a>
          </div>
        ))}
      </>
    );
  };

  const getFlag = (languageCode) => {
    var Flag = languageList?.find((lang) => lang?.languageCode === languageCode);
    return <>{Flag?.flag}</>;
  };

  const getDefaultFlag = (languageCode, title) => {
    var Flag = languageList?.find((lang) => lang?.languageCode === languageCode);
    return (
      <>
        <div className="Flag-Default">{Flag?.flag}</div>
        <span className="language-text" style={{ minWidth: 30, textAlign: "end" }}>
          {t(title)}
        </span>
        {!isMaxWidth640 && <LanguageDropdownIcon className="link-language-icon-desktop" />}
        {isMaxWidth640 && <ArrowRightIcon className="link-language-icon-mobile" />}
      </>
    );
  };

  return (
    <Popover
      className={className}
      placement="bottom"
      overlayClassName={`language-top-bar ${overlayClassName}`}
      content={contentLanguage}
      trigger="click"
      open={visible}
      onOpenChange={onVisibleChange}
    >
      <a className="link-language">
        {defaultLanguage && getDefaultFlag(defaultLanguage.languageCode, jsUcfirst(defaultLanguage.languageCode))}
      </a>
    </Popover>
  );
}

export default ChangeLanguage;
