import CashPaymentIcon from "../../../../../assets/icons/cash-payment.svg";
import MoMoIcon from "../../../../../assets/icons/momo-icon.svg";
import VisaMasterJCBIcon from "../../../../../assets/icons/visa-master-jcb.svg";

export const mockupPaymentMethod = [
  {
    icon: CashPaymentIcon,
    paymentMethodEnumId: 3,
    paymentMethodId: "37ea8d80-d9b2-4208-85bb-8473cf771422",
    paymentMethodName: "Cash",
  },
  {
    icon: MoMoIcon,
    paymentMethodEnumId: 0,
    paymentMethodId: "b26d46a4-c3ab-4182-be94-81bf5c7554e5", 
    paymentMethodName: "MoMo",
  },
  {
    icon: VisaMasterJCBIcon,
    paymentMethodEnumId: 4,
    paymentMethodId: "bc3ec865-05a4-4e62-9b03-884a1360d5ca",
    paymentMethodName: "Visa, Master, JCB",
  },
];
