import { Button } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { EnumPromotionSummary } from "../../../constants/enums";
import { paramIsLoadData } from "../../../constants/string.constants";
import { ToastMessageType } from "../../../constants/toast-message.constants";
import orderDataService from "../../../data-services/order-data.service";
import { store } from "../../../modules";
import { setPOSDiscountCodes, setQrCodeOrder } from "../../../modules/order/order.actions";
import { posOrderDetailSelector, qrOrderSelector } from "../../../modules/order/order.reducers";
import {
  setNotificationDialog,
  setPOSCartItems,
  setPackageExpiredInfo,
} from "../../../modules/session/session.actions";
import { cartValidatedSelector } from "../../../modules/session/session.reducers";
import { setToastMessageMaxDiscount } from "../../../modules/toast-message/toast-message.actions";
import { useAppCtx } from "../../../providers/app.provider";
import { checkOutOfStockAllProductWhenUpdateCart } from "../../../services/material/check-out-of-stock.service";
import maxDiscountService from "../../../services/max-discount.services";
import orderService from "../../../services/orders/order-service";
import posCartService from "../../../services/pos/pos-cart.services";
import shoppingCartService from "../../../services/shopping-cart/shopping-cart.service";
import themeConfigServices from "../../../services/theme-config.services";
import { formatDate, isNonEmptyArray, isPastDate } from "../../../utils/helpers";
import { getStorage, localStorageKeys } from "../../../utils/localStorage.helpers";
import { CheckCircleIcon, QrNotAvailableIcon, WarningTriangle } from "../../assets/icons.constants";
import { UpdateToCartToastMessage } from "../../components/add-update-to-cart-toast-message/update-to-cart-toast.component";
import CartProductDetailComponent from "../../components/cart-checkout-scan-qrcode/cart-product-detail.component";
import NotificationContainer from "../../components/notification-container/notification-container.component";
import NotificationDialog from "../../components/notification-dialog/notification-dialog.component";
import { EnumPromotion, ListPromotionType } from "../../constants/enums";
import { DateFormat } from "../../constants/string.constant";
import { useSearchParams } from "../../hooks";
import "./POSOrder.style.scss";
import CreateOrderButton from "./components/CreateOrderButton/CreateOrderButton";
import EmptyOrder from "./components/EmptyOrder/EmptyOrder";
import HeaderOrder from "./components/HeaderOrder/HeaderOrder";
import TabsStatusOrder, { tabName } from "./components/TabsStatusOrder/TabsStatusOrder";

const POSOrderPage = (props) => {
  const { fontFamily } = props;
  const { Toast } = useAppCtx();
  const { t } = useTranslation();
  const [shoppingCart, setShoppingCart] = useState([]);
  const cartItems = useSelector((state) => state?.session?.posCartItems);
  const reduxQROrder = useSelector(qrOrderSelector);
  const query = useSearchParams();
  const qrCodeId = query.get("qrCodeId");
  const orderIdParam = query.get("orderId");
  const isLoadQRCodeData = query.get(paramIsLoadData);
  const customerInfo = JSON.parse(getStorage(localStorageKeys.CUSTOMER_INFO));
  const history = useHistory();
  const dispatch = useDispatch();
  const [statusTabsOrder, setStatusTabsOrder] = useState(tabName.ORDERING);
  const [orderedData, setOrderedData] = useState([]);
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);
  const [orderDetailByIdData, setOrderDetailByIdData] = useState({});
  const [isLoadingCreateOrder, setIsLoadingCreateOrder] = useState(false);
  const [isShowNotifyDialogLogin, setIsShowNotifyDialogLogin] = useState(false);
  const [isShowNotifyDialogOutOfStock, setIsShowNotifyDialogOutOfStock] = useState(false);
  const [isOrderFromPast, setIsOrderFromPast] = useState(false);
  const [isLoadingCallApi, setIsLoadingCallApi] = useState(true);
  const [isTableBeingUse, setIsTableBeingUse] = useState(false);
  const [messageError, setMessageError] = useState("");
  const cartValidated = useSelector(cartValidatedSelector);
  const posOrderDetail = useSelector(posOrderDetailSelector);
  themeConfigServices.setGlobalCSSPropertyByColorGroupDefault();
  const cartPageRef = useRef();

  const pageData = {
    backToTheActionPage: t("titles.backToTheActionPage", "Về trang Tác Vụ"),
    returnToHomepage: t("titles.returnToHomepage", "Về trang chủ"),
    someProductOutOfStock: t(
      "storeWebPage.productDetailPage.someProductOutOfStock",
      "Rất tiếc! Một số sản phẩm đã hết hàng.",
    ),
    willBeRemoveFromCart: t("storeWebPage.productDetailPage.willBeRemoveFromCart", "Chúng sẽ bị xóa khỏi giỏ hàng!"),
  };

  async function fetchQRCodeData(qrCodeId) {
    if (qrCodeId && isLoadQRCodeData) {
      await orderService.getQrCodeOrderAsync(qrCodeId, false, posOrderDetail, query, history);
    }
  }

  useEffect(() => {
    posCartService.handelRemoveOrderInQrCode(qrCodeId, posOrderDetail, customerInfo);

    if (Boolean(orderIdParam)) {
      posCartService.deleteUrlParam(query, history, paramIsLoadData);
    }

    fetchQRCodeData(qrCodeId);
  }, [qrCodeId]);

  useEffect(() => {
    if (reduxQROrder) {
      setQrCodeOrder(reduxQROrder);
      const statusQrOrder = posCartService.checkStatusQrOrder(reduxQROrder);
      const isSameQrCodeId = qrCodeId === reduxQROrder?.qrCodeId;
      if (statusQrOrder && isLoadQRCodeData && isSameQrCodeId) {
        handleShowToast(statusQrOrder);
      }
      setStoreCart(cartItems);
    }
  }, [reduxQROrder]);

  useEffect(() => {
    store?.dispatch(setPackageExpiredInfo(null));
    setStoreCartDataToLocalStore();
  }, [cartItems]);

  const fetchData = async (orderPOSId) => {
    if (orderPOSId) {
      try {
        const data = await orderDataService.getOrderDetailByIdAsync(orderPOSId, "", true);
        const order = data?.data?.order;
        const orderItems = order?.orderItems;
        if (orderItems) {
          const orderCartItems = orderItems?.map((o) => posCartService.mappingOrderDetailItem(o));
          setOrderedData(orderCartItems);
          setTotalDiscountAmount(data?.data?.order?.totalAmount);

          const listDiscountCode =
            data?.data?.order?.sumaryPromotionOrder
              ?.filter((p) => p.promotionType === EnumPromotionSummary.DiscountCode)
              ?.map((p) => {
                return {
                  id: p?.promotionId,
                  name: p?.promotionName,
                  title: p?.promotionName,
                  discountCodeTypeId: p?.discountCodeTypeId,
                  startDate: p?.startDate,
                  statusId: 0,
                  createTime: p?.createdDate,
                  isStopped: false,
                  percentNumber: p?.percentNumber,
                  maximumDiscountAmount: p?.maximunDiscountValue,
                  isPercentDiscount: p?.percentNumber > 0,
                  isLimitNumberCouponUse: false,
                  code: p?.promotionCode,
                  isApply: true,
                  content: t(
                    ListPromotionType.find((promotionType) => promotionType?.key === p?.discountCodeTypeId)?.name ?? "",
                  ),
                  endDate: p?.endDate ? formatDate(p?.endDate, DateFormat.DD_MM_YYYY_HH_MM_2) : null,
                };
              }) ?? [];
          posCartService.addDiscountCodesCollection(listDiscountCode);
          dispatch(setPOSDiscountCodes(listDiscountCode?.map((item) => item?.code)));
        } else {
          setOrderedData([]);
        }
        setIsLoadingCallApi(false);
        setOrderDetailByIdData(order);
      } catch (error) {}
    } else {
      setIsLoadingCallApi(false);
    }
  };

  useEffect(() => {
    fetchData(posOrderDetail?.orderId);
    checkNextPage();
  }, [posOrderDetail?.orderId]);

  const isDisabledAddItemBtn = useMemo(() => {
    const isPermissionToEditOrder = posCartService.getPermissionToEditOrder(orderDetailByIdData);
    return !isPermissionToEditOrder;
  }, [orderDetailByIdData, statusTabsOrder]);

  const isEmptyOrderPOS = useMemo(() => {
    if (shoppingCart?.length > 0 && orderedData?.length > 0) return false;
    return (
      (statusTabsOrder === tabName.ORDERING && shoppingCart?.length === 0) ||
      (statusTabsOrder === tabName.ORDERED && orderedData?.length === 0) ||
      (statusTabsOrder === tabName.RECEIPT && orderedData?.length === 0)
    );
  }, [statusTabsOrder, shoppingCart, orderedData]);

  const handleNavigationLogin = () => {
    history.push({ pathname: "/login", state: { redirectToAfterLogin: "/pos-order?" + query?.toString() } });
  };

  const handleRedirectToHomePage = () => {
    history.push("/");
  };

  function checkLogin() {
    const loginData = getStorage(localStorageKeys.LOGIN);
    return Boolean(loginData);
  }

  const setStoreCartDataToLocalStore = () => {
    setShoppingCart(JSON.parse(getStorage(localStorageKeys.POS_CART)));
  };

  const addMoreProducts = () => {
    //Check if Order is from the past - US 45123
    if (isPastDate(orderDetailByIdData?.createdTime)) {
      setIsOrderFromPast(true);
      return;
    }

    if (!isLoadingCallApi) {
      history.push("/pos?" + query.toString());
    }
  };

  function checkNextPage() {
    if (!isNonEmptyArray(cartItems) && posOrderDetail?.orderId) {
      setStatusTabsOrder(tabName.ORDERED);
    } else {
      setStatusTabsOrder(tabName.ORDERING);
    }
  }

  function handleShowToast(statusQrOrder) {
    const icon = statusQrOrder?.messageType === ToastMessageType.WARNING ? <WarningTriangle /> : <CheckCircleIcon />;
    Toast.show({
      messageType: statusQrOrder?.messageType,
      message: t(statusQrOrder?.message),
      icon: icon,
      placement: "bottom",
      duration: 3,
    });
    posCartService.deleteUrlParam(query, history, paramIsLoadData);
  }

  const onChangeIsStatusOrder = useCallback(async (status, orderId) => {
    await fetchData(posOrderDetail?.orderId || orderId);
    setStatusTabsOrder(status);
  }, []);

  const onDeleteProduct = (id, cartIndex) => {
    if (!shoppingCart || shoppingCart.length === 0) return;
    let data = [...shoppingCart];
    data.splice(cartIndex, 1);
    dispatch(setPOSCartItems(data));
    setStoreCart(data);
    setShoppingCart(data);
  };

  //#endregion

  const calculateDiscount = (price, promotion, currentDiscountValue = null) => {
    if (promotion && promotion?.isPercentDiscount) {
      let discountValue = (price * promotion?.percentNumber) / 100;
      if (promotion?.maximumDiscountAmount == 0) {
        return discountValue;
      }

      if (currentDiscountValue) {
        if (currentDiscountValue == promotion?.maximumDiscountAmount) {
          return 0;
        }

        return discountValue >= promotion?.maximumDiscountAmount
          ? promotion?.maximumDiscountAmount - currentDiscountValue
          : discountValue - currentDiscountValue;
      }
      if (promotion?.maximumDiscountAmount > 0) {
        return discountValue >= promotion?.maximumDiscountAmount ? promotion?.maximumDiscountAmount : discountValue;
      }

      return discountValue;
    } else {
      if (currentDiscountValue && currentDiscountValue <= promotion?.maximumDiscountAmount) {
        return promotion?.maximumDiscountAmount - currentDiscountValue;
      }

      return promotion.maximumDiscountAmount;
    }
  };

  const FindMaxPromotion = (promotions, price) => {
    let maxPromotion = null;
    let discountValue = 0;
    for (let i = 0; i < promotions.length; i++) {
      let promotion = promotions[i];
      let maxDiscount = calculateDiscount(price, promotion);
      if (maxDiscount >= discountValue) {
        discountValue = maxDiscount;
        maxPromotion = promotion;
      }
    }
    // Promotion value cannot be greater than product value
    if (discountValue > price) {
      discountValue = price;
    }

    return { maxPromotion, discountValue };
  };

  const onChangeProductQuantity = async (isCombo, quantity, index, isIncrease) => {
    if (isIncrease) {
      const cartData = [...shoppingCart];
      const outOfStockIndices = cartData?.reduce((acc, item, index) => {
        if (item.isOutOfStock) {
          acc.push(index);
        }
        return acc;
      }, []);

      const verifyOutOfStock = await checkOutOfStockAllProductWhenUpdateCart(
        reduxQROrder?.branchId,
        cartData,
        index,
        quantity,
        outOfStockIndices,
      );

      if (verifyOutOfStock && quantity > 0) {
        const notificationDialog = {
          isShow: true,
          content: t("storeWebPage.productDetailPage.textOutOfStock", "Rất tiếc! Sản phẩm không còn đủ hàng"),
        };
        store.dispatch(setNotificationDialog(notificationDialog));
        return;
      }
    }

    if (quantity <= 0) {
      shoppingCart?.splice(index, 1);
    } else {
      if (isIncrease) {
        if (isCombo) {
          shoppingCart[index].totalOfToppingPrice =
            (shoppingCart[index].totalOfToppingPrice / (quantity - 1)) * quantity;
        } else {
          shoppingCart[index].productPrice.totalOfToppingPrice =
            (shoppingCart[index].productPrice?.totalOfToppingPrice / (quantity - 1)) * quantity;
          shoppingCart[index].productPrice.totalOfToppingOriginalPrice =
            (shoppingCart[index].productPrice?.totalOfToppingOriginalPrice / (quantity - 1)) * quantity;
        }
      } else {
        if (isCombo) {
          shoppingCart[index].totalOfToppingPrice =
            (shoppingCart[index].totalOfToppingPrice / (quantity + 1)) * quantity;
        } else {
          shoppingCart[index].productPrice.totalOfToppingPrice =
            (shoppingCart[index].productPrice?.totalOfToppingPrice / (quantity + 1)) * quantity;
          shoppingCart[index].productPrice.totalOfToppingOriginalPrice =
            (shoppingCart[index].productPrice?.totalOfToppingOriginalPrice / (quantity + 1)) * quantity;
        }
      }
      shoppingCart[index].quantity = quantity;
    }

    setStoreCart(shoppingCart);

    /// Handle calculation max discount
    let maximumDiscountAmount = shoppingCart[index]?.productPrice?.maximumDiscountAmount;
    let totalPriceValue = shoppingCart[index]?.quantity * shoppingCart[index]?.productPrice?.priceValue;
    let isIncludedTopping = shoppingCart[index]?.productPrice?.isIncludedTopping;
    // Discount total bill
    if (shoppingCart[index]?.isFlashSale === false && shoppingCart[index]?.isPromotionTotalBill) {
      var totalAmountOriginalPrice = shoppingCart
        ?.filter((cart) => cart.isCombo === false)
        ?.reduce((amount, cartList) => {
          return (
            amount +
            (cartList?.productPrice?.originalPrice || 0) * cartList?.quantity +
            (cartList?.sellingPrice || 0) * cartList?.quantity
          );
        }, 0);
      const promotions = shoppingCart[index]?.dataDetails?.product?.promotions.filter(
        (p) => p.promotionTypeId === EnumPromotion.DiscountTotal,
      );
      const { maxPromotion } = FindMaxPromotion(promotions, totalAmountOriginalPrice);
      maximumDiscountAmount = maxPromotion?.maximumDiscountAmount;
      isIncludedTopping = maxPromotion?.isIncludedTopping;
      // IsIncludedTopping
      if (maxPromotion?.isIncludedTopping === true) {
        totalAmountOriginalPrice = shoppingCart
          ?.filter((cart) => cart.isCombo === false)
          ?.reduce((amount, cartList) => {
            return (
              amount +
              (cartList?.productPrice?.originalPrice || 0) * cartList?.quantity +
              (cartList?.sellingPrice || 0) * cartList?.quantity +
              (cartList?.productPrice?.totalOfToppingPrice || 0) * cartList?.quantity +
              (cartList?.totalOfToppingPrice || 0) * cartList?.quantity
            );
          }, 0);
      }
      //Total amount
      totalPriceValue = (totalAmountOriginalPrice * maxPromotion?.percentNumber) / 100;
    } else if (shoppingCart[index]?.isPromotionProductCategory) {
      // Discount product category
      const productCategoryId = shoppingCart[index]?.dataDetails?.product?.productDetail?.productCategoryId;
      let newCartItemsCategory = shoppingCart?.filter(
        (item) =>
          item?.dataDetails?.product?.productDetail?.productCategoryId === productCategoryId && item?.isCombo === false,
      );
      totalAmountOriginalPrice = newCartItemsCategory?.reduce((amount, cartList) => {
        return (
          amount +
          (cartList?.productPrice?.originalPrice || 0) * cartList?.quantity +
          (cartList?.sellingPrice || 0) * cartList?.quantity
        );
      }, 0);

      const promotionCategories = shoppingCart[index]?.dataDetails?.product?.promotions.filter(
        (p) =>
          p.promotionTypeId === EnumPromotion.DiscountProductCategory &&
          p.listPromotionProductCategory.find((cate) => cate === productCategoryId),
      );
      const { maxPromotion } = FindMaxPromotion(promotionCategories, totalAmountOriginalPrice);
      maximumDiscountAmount = maxPromotion?.maximumDiscountAmount;
      isIncludedTopping = maxPromotion?.isIncludedTopping;
      // IsIncludedTopping
      if (maxPromotion?.isIncludedTopping === true) {
        totalAmountOriginalPrice = newCartItemsCategory?.reduce((amount, cartList) => {
          return (
            amount +
            (cartList?.productPrice?.originalPrice || 0) * cartList?.quantity +
            (cartList?.sellingPrice || 0) * cartList?.quantity +
            (cartList?.productPrice?.totalOfToppingPrice || 0) * cartList?.quantity +
            (cartList?.totalOfToppingPrice || 0) * cartList?.quantity
          );
        }, 0);
      }
      //Total amount
      totalPriceValue = (totalAmountOriginalPrice * maxPromotion?.percentNumber) / 100;
    }
    const data = {
      isFlashSale: shoppingCart[index]?.isFlashSale,
      isApplyPromotion: shoppingCart[index]?.productPrice?.isApplyPromotion,
      isIncludedTopping: isIncludedTopping,
      isDiscountTotal: shoppingCart[index]?.isPromotionTotalBill,
      totalPriceValue: totalPriceValue,
      maximumDiscountAmount: maximumDiscountAmount,
      quantity: shoppingCart[index]?.quantity,
    };
    maxDiscountService.calculationMaxDiscountService(
      data,
      () => {
        dispatch(setToastMessageMaxDiscount(true));
      },
      () => {
        dispatch(setToastMessageMaxDiscount(false));
      },
    );
  };

  const setStoreCart = (cartItems) => {
    if (window.callApiValidateCartItems) {
      clearTimeout(window.callApiValidateCartItems);
    }
    window.callApiValidateCartItems = setTimeout(() => {
      posCartService?.setPOSCart(cartItems, true);
    }, 200);
  };

  function handleBackToTheActionPage() {
    setIsTableBeingUse(false);
    history.push("/action-page?" + query.toString());
  }

  function handleReturnToHomepage() {
    setIsTableBeingUse(false);
    posCartService.closeQrCodePage(history.push("/"));
  }

  const handleCreateOrder = async () => {
    setIsLoadingCreateOrder(true);
    const request = {
      notes: "",
    };
    var response = await posCartService.createOrderAsync(request);
    if (response?.isSuccess) {
      const emptyCart = [];
      posCartService.setStoreCartLocalStorage(emptyCart);
      setStoreCart(emptyCart);
      setShoppingCart([]);
      Toast.show({
        messageType: "success",
        message: t("order.orderIsCreated"),
        icon: <CheckCircleIcon />,
        placement: "bottom",
        duration: 3,
      });
      setStatusTabsOrder(tabName.ORDERED);
      fetchData(posOrderDetail?.orderId);
      posCartService.cleanPOSCartAsync();
    } else {
      setIsTableBeingUse(true);
      setMessageError(t(response?.message));
    }
    setIsLoadingCreateOrder(false);
  };

  async function handleLoginBeforeCreateOrder() {
    const isOutOfStock = await checkOutOfStock();
    if (isOutOfStock) {
      await posCartService.verifyAndUpdateCart();
      setIsShowNotifyDialogOutOfStock(true);
      return;
    }
    const isLogged = checkLogin();
    if (!isLogged) {
      setIsShowNotifyDialogLogin(true);
    } else {
      handleCreateOrder();
    }
  }

  const handleOnClickAddItem = async () => {
    //Check if Order is from the past - US 45123
    if (isPastDate(orderDetailByIdData?.createdTime)) {
      setIsOrderFromPast(true);
      return;
    }
    // Check out of stock when create new order session
    const isOutOfStock = await checkOutOfStock();
    if (isOutOfStock) {
      await posCartService.verifyAndUpdateCart();
      setIsShowNotifyDialogOutOfStock(true);
      return;
    }

    setIsLoadingCreateOrder(true);
    const response = await posCartService.createOrderSessionAsync({ notes: "" });
    if (response?.isSuccess) {
      const emptyCart = [];
      posCartService.setStoreCartLocalStorage(emptyCart);
      setStoreCart(emptyCart);
      setShoppingCart([]);
      Toast.show({
        messageType: "success",
        message: t(response.message, { stringCode: posOrderDetail?.stringCode }),
        icon: <CheckCircleIcon />,
        placement: "bottom",
        duration: 3,
      });
      setStatusTabsOrder(tabName.ORDERED);
      fetchData(posOrderDetail?.orderId);
      posCartService.cleanPOSCartAsync();
    } else {
      Toast.show({
        messageType: "error",
        message: t(response?.message, { stringCode: posOrderDetail?.stringCode }),
        icon: <QrNotAvailableIcon />,
        placement: "bottom",
        duration: 3,
      });
    }
    setIsLoadingCreateOrder(false);
  };

  function handleOnClickContinueLogin() {
    handleCreateOrder();
    setIsShowNotifyDialogLogin(false);
  }

  function handleClickEmptyOrderBtn() {
    //Check if Order is from the past - US 45123
    if (isPastDate(orderDetailByIdData?.createdTime)) {
      setIsOrderFromPast(true);
      return;
    }
    if (isDisabledAddItemBtn) return;
    history.push("/pos?" + query.toString());
  }

  function handleConfirmNotifyOutOfStock() {
    let newCartItems = [...shoppingCart];
    const newCarts = shoppingCartService.removeOutOfStockCartItem(newCartItems);
    posCartService.setStoreCartLocalStorage(newCarts);
    posCartService.setPOSCart(newCarts, false);
    setStoreCart(newCarts);
    setIsShowNotifyDialogOutOfStock(false);
    posCartService.verifyAndUpdateCart();
  }

  async function checkOutOfStock() {
    const indexCart = 0;
    const isOutOfStock = await checkOutOfStockAllProductWhenUpdateCart(
      reduxQROrder?.branchId,
      shoppingCart,
      indexCart,
      shoppingCart[indexCart]?.quantity,
      undefined,
      true,
    );
    return isOutOfStock;
  }

  return (
    <>
      <UpdateToCartToastMessage />
      <NotificationContainer style={{ fontFamily }} />
      <div style={{ fontFamily: fontFamily }}>
        <div className="cart-page order-detail-pos-page" ref={cartPageRef}>
          <HeaderOrder {...props} title={t("orderDetail.orderDetail")} stringCode={posOrderDetail?.stringCode} />
          <div className="container-cart">
            <TabsStatusOrder
              onChangeIsStatusOrder={onChangeIsStatusOrder}
              value={statusTabsOrder}
              orderId={posOrderDetail?.orderId}
            />
            {isEmptyOrderPOS || (posOrderDetail?.orderId == null && statusTabsOrder != tabName.ORDERING) ? (
              <EmptyOrder
                statusTabsOrder={statusTabsOrder}
                onClickEmptyOrderBtn={handleClickEmptyOrderBtn}
                isDisabledAddItemBtn={isDisabledAddItemBtn}
              />
            ) : (
              <CartProductDetailComponent
                {...props}
                shoppingCart={statusTabsOrder === tabName.ORDERING ? shoppingCart : orderedData}
                addMoreProducts={addMoreProducts}
                isCart={true}
                branchId={reduxQROrder?.branchId}
                statusTabsOrder={statusTabsOrder}
                onDeleteProduct={onDeleteProduct}
                onUpdateCartQuantity={onChangeProductQuantity}
                isDisabledAddItemBtn={isDisabledAddItemBtn}
                orderDetailByIdData={orderDetailByIdData}
                fetchData={fetchData}
                cartPageRef={cartPageRef}
              />
            )}

            {!isEmptyOrderPOS && (
              <CreateOrderButton
                statusTabsOrder={statusTabsOrder}
                orderPOSId={posOrderDetail?.orderId}
                subTotal={orderedData?.length}
                price={
                  statusTabsOrder === tabName.ORDERING
                    ? cartValidated?.totalPriceAfterDiscountInStoreOfCartItems
                    : totalDiscountAmount
                }
                handleCreateOrder={handleLoginBeforeCreateOrder}
                handleOnClickAddItem={handleOnClickAddItem}
                isDisabledAddItemBtn={isDisabledAddItemBtn}
                isLoadingCreateOrder={isLoadingCreateOrder}
                posOrderDetail={posOrderDetail}
              />
            )}
          </div>
        </div>

        <NotificationDialog
          title={t("loginPage.notification")}
          className={"notify-dialog-confirm-check-login"}
          style={{ fontFamily }}
          content={
            <span
              dangerouslySetInnerHTML={{
                __html: t("orderDetail.trackYourOrders"),
              }}
            ></span>
          }
          open={isShowNotifyDialogLogin}
          onCancel={() => setIsShowNotifyDialogLogin(false)}
          footer={[
            <Button className="login-btn" onClick={handleNavigationLogin} style={{ fontFamily }}>
              {t("loginPage.login")}
            </Button>,
            <Button className="continue-without-login-btn" onClick={handleOnClickContinueLogin} style={{ fontFamily }}>
              {t("loginPage.continueWithoutLogin")}
            </Button>,
          ]}
        />

        {/* Is Past Date - US 45123 */}
        <NotificationDialog
          title={t("loginPage.notification")}
          className={"notify-dialog-confirm-check-login"}
          style={{ fontFamily }}
          content={
            <span
              dangerouslySetInnerHTML={{
                __html: t("orderDetail.orderExceedProcessingTimeRedirect"),
              }}
            ></span>
          }
          open={isOrderFromPast}
          onCancel={handleRedirectToHomePage}
          footer={[
            <Button className="login-btn" onClick={handleRedirectToHomePage} style={{ fontFamily }}>
              {t("orderDetail.backToHomePage")}
            </Button>,
          ]}
        />

        <NotificationDialog
          title={t("loginPage.notification")}
          className={"confirm-leave-notify-dialog-instore"}
          style={{ fontFamily }}
          content={
            <span
              dangerouslySetInnerHTML={{
                __html: t(messageError, {
                  area: reduxQROrder?.areaName,
                }),
              }}
            ></span>
          }
          open={isTableBeingUse}
          onCancel={() => setIsTableBeingUse(false)}
          footer={[
            <>
              <Button onClick={handleBackToTheActionPage}>{pageData.backToTheActionPage}</Button>
              <Button onClick={handleReturnToHomepage}>{pageData.returnToHomepage}</Button>
            </>,
          ]}
        />
        {/* Out of stock */}
        <NotificationDialog
          title={t("loginPage.notification")}
          className={"notify-dialog-confirm-check-login notify-dialog-confirm-check-out-of-stock"}
          style={{ fontFamily }}
          content={
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(pageData.someProductOutOfStock),
                }}
              ></p>
              <p>{pageData.willBeRemoveFromCart}</p>
            </div>
          }
          open={isShowNotifyDialogOutOfStock}
          onCancel={() => setIsShowNotifyDialogOutOfStock(false)}
          footer={[
            <Button className="okay-btn" onClick={handleConfirmNotifyOutOfStock} style={{ fontFamily }}>
              {t("form.okay")}
            </Button>,
          ]}
        />
      </div>
    </>
  );
};

export default POSOrderPage;
