export const comboType = {
  comboPricing: { id: 0, path: "combo-pricing" },
  comboProductPrice: { id: 1, path: "combo-product-price" },
};

export const ComboType = {
  FLEXIBLE: 0,
  SPECIFIC: 1,
};

export const guidIdEmptyValue = "00000000-0000-0000-0000-000000000000";
