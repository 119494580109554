import React from "react";
import { formatTextCurrency } from "../../../../../../utils/helpers";

const ComboItem = (props) => {
  const { cartItem } = props;
  return (
    cartItem?.isCombo && (
      <div className="box-combo">
        {cartItem?.products?.map((product) => {
          const productOptions = product?.options?.map((itemOption) => {
            return `${itemOption?.name} (${itemOption?.optionLevelName})`;
          });
          return (
            <>
              <div className="box-combo-item">
                <div className="product-name text-line-clamp-1">{product.name}</div>
                <div className="product-option text-line-clamp-1">{productOptions?.join(" , ")}</div>
                <div className="product-topping">
                  {product?.toppings?.reduce((total, topping) => {
                    return total + topping.quantity;
                  }, 0) > 0 && (
                    <>
                      {product?.toppings?.map((itemTopping) => {
                        return (
                          itemTopping?.quantity > 0 && (
                            <div className="topping-item">
                              <div className="name text-line-clamp-1">{itemTopping?.name}</div>
                              <div>x {itemTopping?.quantity}</div>
                              <div className="price-value">{formatTextCurrency(itemTopping?.priceValue)}</div>
                            </div>
                          )
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </>
          );
        })}
      </div>
    )
  );
};

export default ComboItem;
