/**
 * CSS global
 */
export const GlobalCSSProperty = {
  GLOBAL_BUTTON_BACKGROUND_COLOR: "--global-button-background-color",
  GLOBAL_BUTTON_TEXT_COLOR: "--global-button-text-color",
  GLOBAL_BUTTON_BORDER_COLOR: "--global-button-border-color",
  GLOBAL_TEXT_COLOR: "--global-text-color",
  GLOBAL_TITLE_COLOR: "--global-title-color",
};

/**
 * CSS customize
 */
export const CSSProperty = {}; //Todo
