import { Modal } from "antd";
import "./fnb-modal.scss";

const FnbModal = (props) => {
  const { children, open, onOk, onCancel, centered, title, footer, okText, cancelText, className, width, ...rest } =
    props;

  return (
    <Modal
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      closable={false}
      centered={centered}
      transitionName={null}
      title={title}
      footer={footer}
      okText={okText}
      cancelText={cancelText}
      className={className}
      wrapClassName="popover-wrapper-overwrite"
      width={width || "50dvw"}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default FnbModal;
