import { Col, Form, Row } from "antd";
import { useTranslation } from "react-i18next";
import { FnbSelectSingle } from "../fnb-select-single/fnb-select-single";

import "./select-color-group.component.scss";
import { useEffect } from "react";

const SelectColorGroupComponent = (props) => {
  const { form, pageConfig, pageId, visible, formItemPreName } = props;
  const colorGroups = pageConfig?.general?.color?.colorGroups ?? [];
  const [t] = useTranslation();
  const translateData = {
    selectColorGroup: t("storeWebPage.header.selectColorGroup", "Select color group"),
  };
  const colorGroupIdName = [...formItemPreName, "colorGroupId"];

  useEffect(() => {
    const colorGroupId = form.getFieldValue(colorGroupIdName);
    if (!colorGroupId) {
      form.setFields([{ name: colorGroupIdName, value: colorGroups[0]?.id }]);
    }
  }, [])

  return (
    <>
      <Row className="select-color-group-component">
        <Col span={24}>
          <p>{translateData.selectColorGroup}</p>
        </Col>
        <Col span={24}>
          <Form.Item rules={[{ required: true }]} name={colorGroupIdName}>
            <FnbSelectSingle
              defaultValue={colorGroups[0]?.id}
              showSearch
              option={colorGroups?.map((b) => ({
                id: b.id,
                name: b?.name,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default SelectColorGroupComponent;
