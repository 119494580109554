import { HubConnectionState } from "@microsoft/signalr";
import { message } from "antd";
import parse from "html-react-parser";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { EnumOrderStatus } from "../../../constants/enums";
import { store } from "../../../modules";
import { notificationActions } from "../../../modules/notification/notification.reducer";
import { userInfoSelector } from "../../../modules/session/session.reducers";
import storeWebSocket from "../../../sockets/store-web-socket";
import { EnumNotification, SignalRListenerConstants } from "../../constants/signalR-listener.constants";
import "./signalr-listener.scss";

function SignalRListener() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const userInfo = useSelector(userInfoSelector);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (userInfo?.accountId) {
      initSocket();
      storeWebSocket.onreconnected(() => {
        joinListenerChannel();
      });
    }
  }, [userInfo?.accountId]);

  const getLoginAccount = () => {
    const reduxState = store.getState();
    const accountId = reduxState?.session?.userInfo?.accountId;
    return accountId;
  };

  const getNotificationDetail = () => {
    const reduxState = store.getState();
    const notificationDetail = reduxState?.notification?.notificationDetail;
    return notificationDetail;
  };

  const initSocket = async () => {
    const accountId = getLoginAccount();
    if (storeWebSocket.state !== HubConnectionState.Disconnected) return;
    if (!accountId) {
      if (window.socketLog) {
        console.error("Cannot find accountId");
      }
      retry();
      return;
    }

    try {
      await startSocketAsync();
    } catch (e) {
      if (window.socketLog) {
        console.log("Connection failed: ", e);
      }
      retry();
    }
  };

  const startSocketAsync = async (accountId) => {
    await storeWebSocket.start();
    iniDataAfterConnected(accountId);
  };

  const retry = () => {
    const accountId = getLoginAccount();
    if (window.socketLog) {
      console.log("Re-connecting... ", accountId);
    }
    setTimeout(async () => {
      iniDataAfterConnected(accountId);
    }, 1000);
  };

  const iniDataAfterConnected = (accountId) => {
    if (!accountId) {
      retry();
      return;
    }

    registerSocketFunctions();
    joinListenerChannel(accountId);
  };

  const joinListenerChannel = async () => {
    const accountId = getLoginAccount();
    let userConnection = {
      accountId: accountId,
    };

    if (window.socketLog) {
      console.log("JoinRoom with id: ", accountId);
    }
    await storeWebSocket.invoke("JoinRoom", userConnection);
  };

  const success = (content) => {
    messageApi.open({
      type: "success",
      className: "success-message",
      content,
    });
  };

  const crossNotifyUpdateOrderStatus = (response, statusId) => {
    const notificationDetail = getNotificationDetail();

    const notify = [
      {
        id: response?.notificationId,
        storeId: response?.storeId,
        referenceId: response?.id,
        type: EnumNotification.Order,
        statusId: statusId,
        title: response?.stringCode,
        isRead: false,
        notificationTime: moment.utc(),
      },
    ];

    dispatch(
      notificationActions.setNotification({
        ...notificationDetail,
        total: (notificationDetail?.total || 0) + 1,
        totalUnread: (notificationDetail?.totalUnread || 0) + 1,
        notifications: notify?.concat(notificationDetail?.notifications || []),
      }),
    );
  };

  const registerSocketFunctions = () => {
    storeWebSocket.on(SignalRListenerConstants.STORE_WEB_ORDER_COMPLETED, (response) => {
      if (process.env.NODE_ENV === "development" || window.socketLog) {
        console.log("response ", response);
      }

      if (response && response?.stringCode) {
        crossNotifyUpdateOrderStatus(response, EnumOrderStatus.Completed);
        success(parse(t("order.completedYourOrder", { stringCode: response?.stringCode })));
      }
    });
  };

  const cleanup = () => {
    storeWebSocket.off(SignalRListenerConstants.STORE_WEB_ORDER_COMPLETED);
  };

  return <>{contextHolder}</>;
}

export default SignalRListener;
