import { useEffect } from "react";

const FacebookPixel = ({ pixelId }) => {
  function initMetaPixel(pixelId) {
    if (!Boolean(pixelId)) {
      return;
    }

    // Initialize Facebook Pixel with your Pixel ID
    window.fbq("init", pixelId); // Replace with your actual Pixel ID

    // Track a page view event
    window.fbq("track", "PageView");
  }

  useEffect(() => {
    // Load Facebook Pixel script dynamically
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
  }, []); // Empty dependency array ensures useEffect runs only once after initial render

  useEffect(() => {
    initMetaPixel(pixelId);
  }, [pixelId]);

  return null; // This component doesn't render anything visible
};

export default FacebookPixel;
