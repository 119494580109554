import { Image } from "antd";
import flashSaleLogo from "../../assets/images/flash-sale-tag-theme2.png";
import OutOfStockLabelBoxComponent from "../out-of-stock-label-box/out-of-stock-label-box.component";
import "./fnb-display-image.component.scss";

export default function FnbDisplayImageComponent(props) {
  //Add className prop to modify css of this component. fnb-display-image is default class if not set
  const {
    src = "",
    srcFallback,
    isPromotion,
    promotionTitle,
    isFlashSale,
    className = "fnb-display-image",
    isOutOfStock,
    nameComponent,
  } = props;
  const isPromotionTitle = promotionTitle ?? false;
  return (
    <>
      {isPromotion && isPromotionTitle && (
        <div className="promotion-label">
          <span>{promotionTitle}</span>
        </div>
      )}
      <div className={className}>
        <Image
          src={src}
          fallback={srcFallback}
          preview={false}
          className={isOutOfStock ? "display-image out-of-stock" : "display-image"}
        />
        {isFlashSale && (
          <>
            <div className="flash-sale-border"></div>
            <div className="flash-sale">
              <img src={flashSaleLogo} className="flash-sale-logo" />
            </div>
          </>
        )}
        <OutOfStockLabelBoxComponent isShow={isOutOfStock} nameComponent={nameComponent} />
      </div>
    </>
  );
}
