import styled from "styled-components";

export const GroupProductOptionContentStyle = styled.div`
  .group-product-option-item {
    .ant-radio-group {
      .ant-radio-button-wrapper {
        .ant-radio-button-checked {
          background: ${(p) => p?.buttonBackgroundColor} !important;
          border: ${(p) => `1px solid ${p?.buttonBorderColor}`} !important;
          .container-radio-option {
            color: ${(p) => p?.buttonTextColor};
          }
        }
      }
    }
  }
`;

export const GroupProductToppingContentStyle = styled.div`
  .topping-change-quantity {
    .topping-quantity-btn-increase {
      background: unset !important;
    }
  }
`;
