import { Checkbox } from "antd";
import "./fnb-checkbox.scss";
import classNames from "classnames";
import React from "react";

const Group = ({
  options = undefined,
  onChange,
  direction = "horizontal", // horizontal | vertical
  children,
  className = "",
  style = {},
  columnGap = 12,
  rowGap = 12,
  ...rest
}) => {
  const styleFlex = {
    display: "flex",
    flexDirection: direction === "horizontal" ? "row" : "column",
    flexWrap: "wrap",
    columnGap: columnGap,
    rowGap: rowGap,
  };
  return (
    <>
      <Checkbox.Group onChange={onChange} className={`${className}`} style={{ ...styleFlex, ...style }} {...rest}>
        {options
          ? options?.map((opt) => {
              return <FnbCheckBox value={opt?.value}>{opt?.label}</FnbCheckBox>;
            })
          : children}
      </Checkbox.Group>
    </>
  );
};

const FnbCheckBox = ({
  onChange = undefined,
  children,
  defaultChecked = false,
  value,
  disabled = false,
  className = "",
  ...rest
}) => {
  const onChangCheckbox = (event) => {
    onChange && onChange(event);
  };

  const classNameCheckbox = classNames({
    "fnb-checkbox": true,
    [`${className}`]: true,
  });

  return (
    <Checkbox
      defaultChecked={defaultChecked}
      className={classNameCheckbox}
      onChange={onChangCheckbox}
      value={value}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Checkbox>
  );
};

FnbCheckBox.Group = Group;

export default FnbCheckBox;
