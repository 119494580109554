import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { EnumAddToCartType } from "../../../../constants/enums";
import productDataService from "../../../../data-services/product-data.service";
import { store } from "../../../../modules";
import { qrOrderSelector } from "../../../../modules/order/order.reducers";
import { setNotificationDialog } from "../../../../modules/session/session.actions";
import { setToastMessageAddToCart } from "../../../../modules/toast-message/toast-message.actions";
import { checkOutOfStockProductList } from "../../../../services/material/check-out-of-stock.service";
import posAddToCartServices from "../../../../services/pos/pos-add-to-cart.services";
import mapperOutOfStockServicesQROrder from "../../../../services/pos/pos-product-mapping.services";
import { isNonEmptyArray, isVisible, throttle } from "../../../../utils/helpers";
import { FnbLoadingSpinner } from "../../../components/fnb-loading-spinner/fnb-loading-spinner.component";
import ProductCartComponent from "../../../components/product-cart/ProductCartComponent";
import { ProductPlatform } from "../../../constants/product-platform.constants";
import { TIME_DELAY } from "../../../hooks/useDebounce";
import { classLoadMoreData } from "../POSProductListPageProvider";
import "./ProductCategoryComponent.scss";

export default function ProductCategoryComponent(props) {
  const { data, handleShowProductDetail, productPricesFirstTime, isGridView = true } = props;
  const pageSize = 20;
  const [dataProductCategory, setDataProductCategory] = useState(data);
  const [productPricesNew, setProductPricesNew] = useState([]);
  const [isAllowLoadData, setIsAllowLoadData] = useState(true);
  const reduxQROrder = useSelector(qrOrderSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const colXs = isGridView ? 12 : 24;

  function getProductByCategory(categoryId, pageNumber, elementLoadMoreData) {
    if (!isAllowLoadData) return;

    setIsAllowLoadData(false);
    if (elementLoadMoreData) {
      elementLoadMoreData?.children?.[0].classList.remove("d-none");
    }

    const request = {
      platformId: ProductPlatform.POSWebsite,
      categoryId: categoryId,
      branchId: reduxQROrder?.branchId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };

    productDataService
      .getProductsAsync(request)
      .then((response) => {
        const responseData = response?.data;
        if (responseData?.succeeded) {
          const productsOfCategory = responseData?.data?.productCategorys[0]?.products;
          if (productsOfCategory?.length > 0) {
            let dataProductCategoryNew = { ...dataProductCategory };
            if (request.pageNumber === 1) {
              dataProductCategoryNew.products = [...productsOfCategory];
            } else {
              dataProductCategoryNew.products = [...dataProductCategoryNew.products, ...productsOfCategory];
            }
            dataProductCategoryNew.currentPage = request.pageNumber;
            setDataProductCategory(dataProductCategoryNew);

            // Check out of stock when scroll by the categoryId
            const productPricesDataNew = mapperOutOfStockServicesQROrder.mapperProducts2Variants(
              dataProductCategoryNew?.products,
            );
            fetchOutOfStockData(productPricesDataNew);
          }
        }
      })
      .catch((errors) => {})
      .finally(() => {
        setIsAllowLoadData(true);
        if (elementLoadMoreData) {
          elementLoadMoreData?.children?.[0].classList.add("d-none");
        }
      });
  }

  // Out of stock

  async function fetchOutOfStockData(productPrices) {
    const outOfStockData = await checkOutOfStockProductList(reduxQROrder?.branchId, true, productPrices);
    setProductPricesNew(outOfStockData);
  }

  function checkProductPriceOutOfStock(productVariants, itemOfProduct, isCombo = false) {
    if (!productVariants || !itemOfProduct) return false;

    const productVariantOutOfStocks = productVariants?.filter((item) => {
      const hasOutOfStock =
        ((isCombo && item?.comboId === itemOfProduct?.id) ||
          (!isCombo && item?.productPriceId === itemOfProduct?.productPriceDefault?.id)) &&
        item?.outOfStock;

      return hasOutOfStock;
    });

    return isNonEmptyArray(productVariantOutOfStocks);
  }

  function getProductByCategoryDeplay(categoryId, pageNumber, elementLoadMoreData) {
    if (window.getProductByCategory) {
      clearTimeout(window.getProductByCategory);
    }
    window.getProductByCategory = setTimeout(() => {
      getProductByCategory(categoryId, pageNumber, elementLoadMoreData);
    }, TIME_DELAY);
  }

  function handleWindowScroll() {
    const elementLoadMoreData = document.getElementById(dataProductCategory?.id);
    if (elementLoadMoreData) {
      const elementIsVisible = isVisible(elementLoadMoreData);
      if (elementIsVisible) {
        const categoryIdIsVisible = elementLoadMoreData.id;
        let pageNumber = 1;
        const currentPage = dataProductCategory?.currentPage;
        if (currentPage > 0) {
          pageNumber = currentPage + 1;
        }
        getProductByCategoryDeplay(categoryIdIsVisible, pageNumber, elementLoadMoreData);
      }
    }

    // Handle mode view product list scroll
    const elementCategoryProductList = document.getElementById("nav-category-sticky");
    const modeViewProductListElement = document.getElementById("mode-view-product-list");
    const heightCategories = elementCategoryProductList?.offsetHeight ?? 0;
    if (elementCategoryProductList && modeViewProductListElement) {
      modeViewProductListElement.style.top = `${heightCategories}px`;
    }
  }

  useEffect(() => {
    const element = document.getElementById("listProductContainer");
    if (element) {
      window.addEventListener("scroll", throttle(handleWindowScroll, TIME_DELAY));
      return () => {
        window.removeEventListener("scroll", throttle(handleWindowScroll, TIME_DELAY));
      };
    }
  }, [handleWindowScroll]);

  function mappingProductToProductCartData(item) {
    return {
      name: Boolean(item?.productPriceDefault?.name)
        ? `${item?.name} (${item?.productPriceDefault?.name})`
        : item?.name,
      promotionTag: item?.productPriceDefault?.promotionTag,
      sellingPrice: item?.productPriceDefault?.sellingPrice,
      originalPrice: item?.productPriceDefault?.originalPrice,
      thumbnail: item?.thumbnail,
      description: item?.description,
    };
  }

  async function handleOnClickCombo(item) {
    let request = {
      id: item?.id,
    };
    const isSucess = await posAddToCartServices.quickAddToCartAsync(
      request,
      dataProductCategory?.comboTypeId,
      reduxQROrder?.branchId,
    );
    if (isSucess) {
      onShowToastMessage();
    } else {
      const notificationDialog = {
        isShow: true,
        content: t("storeWebPage.productDetailPage.textOutOfStock", "Rất tiếc! Sản phẩm không còn đủ hàng"),
      };
      store.dispatch(setNotificationDialog(notificationDialog));
      return;
    }
  }

  async function handleOnClickProduct(item) {
    const request = {
      id: item?.id, //productId
      productPriceId: item?.productPriceDefault?.id, //productPriceId
    };
    const isSucess = await posAddToCartServices.quickAddToCartAsync(
      request,
      EnumAddToCartType.Product,
      reduxQROrder?.branchId,
    );
    if (isSucess) {
      onShowToastMessage();
    } else {
      const notificationDialog = {
        isShow: true,
        content: t("storeWebPage.productDetailPage.textOutOfStock", "Rất tiếc! Sản phẩm không còn đủ hàng"),
      };
      store.dispatch(setNotificationDialog(notificationDialog));
      return;
    }
  }

  const onShowToastMessage = () => {
    dispatch(setToastMessageAddToCart(true));
    setTimeout(() => {
      dispatch(setToastMessageAddToCart(false));
    }, 3000);
  };

  return (
    <div
      className="product-category"
      id={`product-category-${dataProductCategory?.id}`}
      data-id={dataProductCategory?.id}
    >
      <Row className="product-category-header">
        <div className="text-line-clamp-1 product-category-name">{dataProductCategory?.name} </div>
        <span className="product-quantity">({dataProductCategory?.totalQuantity} món)</span>
      </Row>
      <div className="product-category-body">
        <Row
          className="product-list"
          gutter={[
            { xs: 16, sm: 24 },
            { xs: 16, sm: 24 },
          ]}
        >
          {dataProductCategory?.isCombo
            ? dataProductCategory?.comboItems.map((item) => {
                const _data = {
                  ...item,
                  thumbnail: dataProductCategory?.thumbnail,
                  comboTypeId: dataProductCategory?.comboTypeId,
                  isCombo: true,
                  description: dataProductCategory?.description,
                };
                const isOutOfStock = checkProductPriceOutOfStock(productPricesFirstTime, item, true);
                return (
                  <Col className="product-detail" xs={colXs} sm={8} md={8} key={item?.id}>
                    <ProductCartComponent
                      data={_data}
                      onClick={() => handleOnClickCombo(item)}
                      onClickTitle={() => handleShowProductDetail(_data)}
                      isOutOfStock={isOutOfStock}
                    />
                  </Col>
                );
              })
            : dataProductCategory?.products.map((item) => {
                const _data = mappingProductToProductCartData(item);
                const isOutOfStock = checkProductPriceOutOfStock(
                  [...productPricesFirstTime, ...productPricesNew],
                  item,
                  false,
                );
                return (
                  <Col className="product-detail" xs={colXs} sm={8} md={8} key={item?.id}>
                    <ProductCartComponent
                      data={_data}
                      onClick={() => handleOnClickProduct(item)}
                      onClickTitle={() => handleShowProductDetail(item)}
                      isOutOfStock={isOutOfStock}
                    />
                  </Col>
                );
              })}
          {dataProductCategory?.totalQuantity > dataProductCategory?.products?.length && (
            <div className={`${classLoadMoreData}`} id={dataProductCategory?.id}>
              <div className="d-none center">
                <FnbLoadingSpinner />
              </div>
            </div>
          )}
        </Row>
      </div>
    </div>
  );
}
