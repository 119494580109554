import { Button, Form, Input, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { themeConfigSelector } from "../../../modules/session/session.reducers";
import { handleMaxLength } from "../../../utils/helpers";
import productDefaultImage from "../../assets/images/product-default.png";
import FnbModal from "../fnb-modal/fnb-modal";
import FnbRate from "../fnb-rate/fnb-rate";
import NotificationDialog from "../notification-dialog/notification-dialog.component";
import "./review-order.component.scss";

const { TextArea } = Input;
const { Paragraph } = Typography;

const DEFAULT_MAX_COUNT = 255;

const ReviewOrder = (props) => {
  const { open, onOk, onCancel, loading, data } = props;
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [comment, setComment] = useState("");
  const [rate, setRate] = useState();
  const [valuesChange, setValuesChange] = useState(false);
  const [isShowConfirmLeave, setIsShowConfirmLeave] = useState(false);
  const themeConfig = useSelector(themeConfigSelector);
  const colorGroup = themeConfig?.general?.color?.colorGroups?.find(
    (group) => group?.id === themeConfig?.general?.header?.colorGroupId,
  );

  const pageData = {
    productReviews: t("storeWebPage.generalUse.productReviews"),
    review: t("storeWebPage.generalUse.review"),
    close: t("form.close"),
    howDoYouRate: t("storeWebPage.generalUse.howDoYouRate"),
    shareYourReview: t("storeWebPage.generalUse.shareYourReview"),
    selectRating: t("storeWebPage.generalUse.selectRating"),
    commentMax: t("storeWebPage.generalUse.commentMax"),
    notification: t("storeWebPage.generalUse.notification"),
    confirmToProceed: t("storeWebPage.generalUse.confirmToProceed"),
    ignore: t("button.ignore"),
    confirmLeave: t("button.confirmLeave"),
  };

  const getComboName = (comboItem) => {
    if (!comboItem) return;
    if (comboItem?.comboName) {
      return comboItem?.comboName;
    }
    const comboItemName = comboItem?.combo?.name;
    const productNameItem = [];
    comboItem?.orderComboProductPriceItems?.forEach((item) => {
      productNameItem.push(item?.itemName);
    });

    return `${comboItemName} [${productNameItem.join(" + ")}]`;
  };

  const renderOrderItems = (orderItems) => {
    return orderItems?.map((item, index) => (
      <>
        {!item?.isCombo ? (
          <div key={index} className="order-item">
            <div className="thumbnail">
              <img src={item?.productPrice?.product?.thumbnail || productDefaultImage} alt="thumbnail" />
            </div>
            <div className="product-price">
              <div>
                <div>
                  <Paragraph
                    ellipsis={{
                      rows: 2,
                      tooltip: `${item?.productPrice?.product?.name} ${
                        item?.productPrice?.priceName ? `(${item?.productPrice?.priceName})` : ""
                      }`,
                    }}
                    className="price-name"
                  >
                    {`${item?.productPrice?.product?.name} ${
                      item?.productPrice?.priceName ? `(${item?.productPrice?.priceName})` : ""
                    }`}
                  </Paragraph>
                  <div className="options">
                    {item?.orderItemOptions?.map((itemComboOption, indexComboOption, { length }) => {
                      if (itemComboOption.isSetDefault) return <></>;
                      if (length - 1 === indexComboOption) {
                        return (
                          <>
                            {itemComboOption?.optionName} ({itemComboOption.optionLevelName})
                          </>
                        );
                      } else {
                        return (
                          <>
                            {itemComboOption?.optionName} ({itemComboOption.optionLevelName}), &nbsp;
                          </>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
              <div className="toppings">
                {item?.orderItemToppings?.map((itemComboTopping, indexItemComboTopping, { length }) => {
                  if (length - 1 === indexItemComboTopping) {
                    return (
                      <>
                        {itemComboTopping?.quantity > 0 && (
                          <span>
                            {itemComboTopping?.quantity}x {itemComboTopping?.toppingName}
                          </span>
                        )}
                      </>
                    );
                  } else {
                    return (
                      <>
                        {itemComboTopping?.quantity > 0 && (
                          <div>
                            {itemComboTopping?.quantity}x {itemComboTopping?.toppingName}
                          </div>
                        )}
                      </>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        ) : (
          <div key={index} className="order-item">
            <div className="thumbnail">
              <img src={item?.orderComboItem?.combo?.thumbnail || productDefaultImage} alt="thumbnail" />
            </div>
            <div className="product-price">
              <div>
                <div>
                  <Paragraph
                    ellipsis={{
                      rows: 2,
                      tooltip: item?.productPriceName ? item?.productPriceName : getComboName(item?.orderComboItem),
                    }}
                    className="price-name"
                  >
                    {item?.productPriceName ? item?.productPriceName : getComboName(item?.orderComboItem)}
                  </Paragraph>
                </div>
              </div>
              <div>
                {item?.orderComboItem?.orderComboProductPriceItems?.map((itemCombo, indexCombo) => {
                  return (
                    <div key={indexCombo}>
                      <div>
                        <div className="combo-price-name">
                          <span>{itemCombo?.productPrice?.product?.name}</span>
                          {itemCombo?.productPrice?.priceName && <span>({itemCombo?.productPrice?.priceName})</span>}
                        </div>
                        <div className="options">
                          {itemCombo?.orderItemOptions?.map((itemComboOption, indexComboOption, { length }) => {
                            if (itemComboOption.isSetDefault) return <></>;
                            if (length - 1 === indexComboOption) {
                              return (
                                <>
                                  {itemComboOption?.optionName} ({itemComboOption.optionLevelName})
                                </>
                              );
                            } else {
                              return (
                                <>
                                  {itemComboOption?.optionName} ({itemComboOption.optionLevelName}), &nbsp;
                                </>
                              );
                            }
                          })}
                        </div>
                        <div className="toppings">
                          {itemCombo?.orderItemToppings?.map((itemComboTopping) => {
                            return (
                              <>
                                {itemComboTopping?.quantity > 0 && (
                                  <div>
                                    {itemComboTopping?.quantity}x {itemComboTopping?.toppingName}
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </>
    ));
  };

  const onReset = async () => {
    form.resetFields();
    setComment("");
    setRate(undefined);
    setValuesChange(false);
    onCancel && onCancel();
  };

  const onClose = async () => {
    if (valuesChange) {
      setIsShowConfirmLeave(true);
    } else {
      onReset();
    }
  };

  const onProceed = async () => {
    setIsShowConfirmLeave(false);
    onReset();
  };

  const onFinish = async () => {
    form.validateFields().then((values) => {
      const { rating } = values;
      const payload = {
        rating,
        comment: comment,
        orderId: data?.id,
      };
      setValuesChange(false);
      setComment("");
      onOk && onOk(payload);
    });
  };

  /**
   * use maxLength then emoij will be counted as 1 character.
   * But in reality emoij will have > 1 character
   * This function will handle the emoji length
   * @param {*} event
   */
  const handleChange = (event) => {
    const value = event.target.value;
    let adjustedValue = handleMaxLength(value, DEFAULT_MAX_COUNT);
    setComment(adjustedValue);
  };

  return (
    <>
      <Form form={form} onValuesChange={() => setValuesChange(true)}>
        <FnbModal
          open={open}
          onCancel={onClose}
          centered
          title={pageData.productReviews}
          footer={
            <>
              <Button
                type="default"
                style={{
                  borderColor: colorGroup?.buttonBackgroundColor,
                  color: colorGroup?.buttonBackgroundColor,
                }}
                onClick={onClose}
              >
                {pageData.close}
              </Button>
              <Button
                type="primary"
                style={{
                  background: colorGroup?.buttonBackgroundColor,
                  borderColor: colorGroup?.buttonBackgroundColor,
                  color: colorGroup?.buttonTextColor,
                }}
                onClick={onFinish}
                disabled={!rate || !data}
                loading={loading}
              >
                {pageData.review}
              </Button>
            </>
          }
          className="popover-overwrite"
          width={860}
        >
          <div className="review-completed-order">
            <div className="wrapper-order-items">
              <div className="rated-order">
                <span>{pageData.howDoYouRate}</span>
                <Form.Item
                  name={"rating"}
                  rules={[
                    {
                      required: true,
                      message: pageData.selectRating,
                    },
                  ]}
                >
                  <FnbRate onChange={setRate} />
                </Form.Item>
              </div>
              <div className="order-items">{renderOrderItems(data?.orderItems)}</div>
            </div>
            <Form.Item
              name={"comment"}
              rules={[
                {
                  max: DEFAULT_MAX_COUNT,
                  message: pageData.commentMax,
                },
              ]}
            >
              <div className="wrapper-comment">
                <TextArea
                  rows={4}
                  maxLength={DEFAULT_MAX_COUNT}
                  placeholder={pageData.shareYourReview}
                  onChange={handleChange}
                  value={comment}
                />
                <span className="count">
                  {comment.length}/{DEFAULT_MAX_COUNT}
                </span>
              </div>
            </Form.Item>
          </div>
        </FnbModal>
      </Form>
      <NotificationDialog
        open={isShowConfirmLeave}
        className="confirm-leave-notify-dialog-instore"
        title={pageData.notification}
        content={pageData.confirmToProceed}
        onCancel={() => setIsShowConfirmLeave(false)}
        footer={[
          <Button className="ignore-btn" onClick={() => setIsShowConfirmLeave(false)}>
            {pageData.ignore}
          </Button>,
          <Button className="confirm-leave-btn" onClick={onProceed}>
            {pageData.confirmLeave}
          </Button>,
        ]}
      />
    </>
  );
};

export default ReviewOrder;
