import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Platform } from "../../../constants/platform.constants";
import { setDiscountCodeCollection } from "../../../modules/collection/collection.actions";
import { discountCodeCollectionSelector } from "../../../modules/collection/collection.reducers";
import { setPOSDiscountCodes } from "../../../modules/order/order.actions";
import { posDiscountCodesSelector, qrOrderSelector } from "../../../modules/order/order.reducers";
import { useAppCtx } from "../../../providers/app.provider";
import posCartService from "../../../services/pos/pos-cart.services";
import reduxService from "../../../services/redux.services";
import { HttpStatusCode } from "../../../utils/http-common";
import { getStorage } from "../../../utils/localStorage.helpers";
import { CheckCircleIcon, CheckoutAddIcon, WarningTriangle } from "../../assets/icons.constants";
import { BackIcon } from "../../assets/icons/BackIcon";
import noProductInCart from "../../assets/images/no-product-in-cart.png";
import EditOrderItem from "../../components/edit-order-item/edit-order-item.component";
import CheckoutOrderItems from "../../pages/checkout/components/checkout-order-items";
import BoxDrawer from "../../pages/order/components/BoxDrawer";
import OrderedOrderDetailItem from "../../pages/pos-order/components/OrderedOrderDetailItem/OrderedOrderDetailItem";
import { tabName } from "../../pages/pos-order/components/TabsStatusOrder/TabsStatusOrder";
import BtnSelectDiscountCode from "../btn-select-discount-code/btn-select-discount-code";
import CustomizeDialog from "../customize-dialog/customize-dialog.component";
import OverlayLoadingFullScreenComponent from "../overlay-loading-full-screen/OverlayLoadingFullScreenComponent";
import { POSOrderCheckoutSummary } from "../pos-order-checkout-summary/pos-order-checkout-summary.component";
import PosOrderPromotionListComponent from "../pos-order-promotion-list/pos-order-promotion-list.component";
import "./cart-product-detail.scss";
import "./header-cart-checkout.component";
import { isNonEmptyArray } from "../../../utils/helpers";
import { Affix } from "antd";

export default function CartProductDetailComponent(props) {
  const {
    colorGroup,
    shoppingCart,
    addMoreProducts,
    mockupCustomize,
    onUpdateCartQuantity,
    onDeleteProduct,
    setShoppingCart,
    isCart,
    branchId,
    fontFamily,
    statusTabsOrder,
    isDisabledAddItemBtn = false,
    orderDetailByIdData,
    fetchData,
    cartPageRef,
  } = props;
  const { t } = useTranslation();
  const isMaxWidth575 = useMediaQuery({ maxWidth: 575 });
  const storageConfig = JSON.parse(getStorage("config"));
  const [visibleEditProduct, setVisibleEditProduct] = useState(false);
  const [productSelectEdit, setProductSelectEdit] = useState({});
  const [indexDefault, setIndexDefault] = useState(null);
  const [isShowDiscountCodeDialog, setIsShowDiscountCodeDialog] = useState(false);
  const reduxQROrder = useSelector(qrOrderSelector);
  const discountCodeCollectionInRedux = useSelector(discountCodeCollectionSelector);
  const posDiscountCodes = useSelector(posDiscountCodesSelector);
  const [isLoading, setIsLoading] = useState(false);
  const { Toast } = useAppCtx();
  const cartDetailRef = useRef();

  const pageData = {
    addMoreProducts: t("checkOutPage.addMoreProducts", "Add more products"),
    cartProduct: t("checkOutPage.cartProduct", "Product"),
    cartPrice: t("checkOutPage.cartPrice", "Price"),
    cartQuantity: t("checkOutPage.cartQuantity", "Quantity"),
    cartTotal: t("checkOutPage.cartTotal", "Total"),
    placeHolderNote: t("checkOutPage.laceHolderNote", "Note"),
    yourCart: t("checkOutPage.cart", "Cart"),
    items: t("checkOutPage.items", "items"),
    emptyCart: t("checkOutPage.emptyCart", "You do not have any items in your shopping cart"),
    useOfferToGetDiscounts: t("checkOutPage.useOffersToGetDiscounts"),
    promotion: t("checkOutPage.promotion", "Khuyến mãi"),
    addItemTitle: t("orderDetail.addItemTitle", "Add Item"),
  };

  useEffect(() => {
    // In the mobile interface: when hiding/showing the toolbar
    // The height must be reassigned so that the interface does not break
    const windowHeight = () => {
      const element = document.documentElement;
      element.style.setProperty("--window-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", windowHeight);
    windowHeight();
    return () => {
      window.removeEventListener("resize", windowHeight);
    };
  }, []);

  const getTotalIemsInShoppingCart = (shoppingCart, statusTabsOrder) => {
    if (!isNonEmptyArray(shoppingCart)) return 0;
    let totalItems = 0;
    let shoppingCartData = [...shoppingCart];
    if (statusTabsOrder === tabName.RECEIPT) {
      shoppingCartData = shoppingCartData.filter((cart) => !cart?.isRemoveWhenEditOrder);
    }
    totalItems = shoppingCartData.reduce((preValue, cartItem) => preValue + cartItem?.quantity, 0);

    return totalItems;
  };

  function handleCloseViewEdit() {
    setVisibleEditProduct(false);
  }

  function handleOnclickViewEdit(data, index) {
    setProductSelectEdit(data);
    setIndexDefault(index);
    setVisibleEditProduct(true);
  }

  const callBackRedeemDiscountCode = async () => {};

  const applyDiscountCodes = async (discountCodes, isClickRedeem) => {
    setIsLoading(true);
    try {
      const request = {
        orderId: orderDetailByIdData.id,
        discountCodes: discountCodes ?? [],
      };
      const response = await posCartService.applyDiscountCodesToOrderAsync(request);
      setIsLoading(false);
      const { succeeded, message, data } = response?.data;
      if (response?.status === HttpStatusCode.Ok) {
        const discountCodesApplied = data?.discountCodes ?? [];
        reduxService.dispatch(setPOSDiscountCodes(discountCodesApplied));
        if (succeeded) {
          const config = {
            messageType: "success",
            message: t("messages.appliedDiscountCodeSuccessfully", "Áp dụng mã giảm giá thành công"),
            icon: <CheckCircleIcon />,
            placement: "bottom",
            duration: 3,
          };
          if (isClickRedeem) Toast.show(config);
          await fetchData(orderDetailByIdData?.id);
        } else {
          const config = {
            messageType: "warning",
            message: t(message, { remainingAmount: data?.extraData?.remainingAmount }),
            icon: <WarningTriangle />,
            placement: "bottom",
            duration: 3,
          };
          Toast.show(config);
        }
      } else {
        const config = {
          messageType: "warning",
          message: t(message),
          icon: <WarningTriangle />,
          placement: "bottom",
          duration: 3,
        };
        Toast.show(config);
      }
    } catch (error) {
      setIsLoading(false);
      const config = {
        messageType: "warning",
        message: error?.data?.message,
        icon: <WarningTriangle />,
        placement: "bottom",
        duration: 3,
      };
      Toast.show(config);
    }
  };

  const handleCancelDiscountCodeDialog = () => {
    const discountCodeConllection =
      discountCodeCollectionInRedux?.filter((discount) => posDiscountCodes.includes(discount?.code)) ?? [];
    reduxService.dispatch(setDiscountCodeCollection(discountCodeConllection));
    setIsShowDiscountCodeDialog(false);
  };

  let lastScrollTop = 0;
  function isScrollingDown(e) {
    const currentScrollTop = e?.target?.scrollTop ?? 0;
    const goingDown = currentScrollTop > lastScrollTop;
    if (lastScrollTop < 0 || currentScrollTop < 0) return false;
    lastScrollTop = currentScrollTop;
    return goingDown;
  }

  function handleProductsScroll(e) {
    if (e.target.scrollTop <= 0) return;
    if (isMaxWidth575 && isScrollingDown(e)) {
      cartPageRef?.current?.classList.add("hide-summary");
    } else {
      cartPageRef?.current?.classList.remove("hide-summary");
    }
  }

  useEffect(() => {
    cartPageRef.current?.classList.remove('hide-summary');
  }, [statusTabsOrder]);

  return (
    <>
      <div className="check_out_product">
        <div className="product_detail" onScroll={handleProductsScroll} ref={cartDetailRef}>
          <Affix target={() => cartDetailRef.current}>
            <div>
              <div className="product_summary">
                <div className="total">
                  <div className="shoppingCart" style={{ color: colorGroup?.titleColor }}>
                    {pageData.yourCart}
                  </div>
                  <div className="quantity">
                    ({getTotalIemsInShoppingCart(shoppingCart, statusTabsOrder)} {pageData.items})
                  </div>
                </div>
                <div className={`add ${isDisabledAddItemBtn ? "disabled-btn-add" : ""}`}>
                  <div className="add_icon" onClick={isDisabledAddItemBtn ? null : addMoreProducts}>
                    <CheckoutAddIcon />
                  </div>
                  <div className="add_button" onClick={isDisabledAddItemBtn ? null : addMoreProducts}>
                    {pageData.addItemTitle}
                  </div>
                </div>
              </div>
              <div className="product_title product-title-web">
                {!isMaxWidth575 && (
                  <>
                    <div style={{ flex: 3 }}>{pageData.cartProduct}</div>

                    <div style={{ flex: 1 }}>{pageData.cartQuantity}</div>
                    <div style={{ flex: 1 }}>{pageData.cartTotal}</div>
                  </>
                )}
              </div>
              <div className="product_title product-title-mobile">{pageData.cartProduct}</div>
            </div>
          </Affix>
          {Boolean(mockupCustomize) && (
            <div className="noProductInCart">
              <img src={noProductInCart} alt=""></img>
              <div className="noProductInCartText">{pageData.emptyCart}</div>
            </div>
          )}

          {statusTabsOrder === tabName.ORDERING &&
            shoppingCart?.map((cart, index) => {
              return (
                <>
                  <CheckoutOrderItems
                    key={cart.id + index}
                    cartItem={cart}
                    currentIndex={index}
                    onUpdateCartQuantity={onUpdateCartQuantity}
                    onDeleteProduct={onDeleteProduct}
                    setCurrentCartItems={(cartItems) => setShoppingCart(cartItems)}
                    index={index}
                    isCart={isCart}
                    storageConfig={storageConfig}
                    isPos={true}
                    handleOnclickViewEditPos={handleOnclickViewEdit}
                  />
                </>
              );
            })}

          {statusTabsOrder === tabName.ORDERED &&
            shoppingCart?.map((cart, index) => {
              return (
                <OrderedOrderDetailItem key={cart.id + index} cartItem={cart} isPos statusTabsOrder={statusTabsOrder} />
              );
            })}

          {statusTabsOrder === tabName.RECEIPT && (
            <>
              {shoppingCart
                ?.filter((cart) => !cart?.isRemoveWhenEditOrder)
                ?.map((cart, index) => {
                  return (
                    <OrderedOrderDetailItem
                      key={cart.id + index}
                      cartItem={cart}
                      isPos
                      statusTabsOrder={statusTabsOrder}
                    />
                  );
                })}
              <BtnSelectDiscountCode
                title={<span style={{ fontFamily: fontFamily }}>{pageData.useOfferToGetDiscounts}</span>}
                onClick={() => {
                  setIsShowDiscountCodeDialog(true);
                }}
                isApply={true}
              />
              <div className="mb-24">
                <POSOrderCheckoutSummary
                  isPos={true}
                  fontFamily={fontFamily}
                  orderDetailByIdData={orderDetailByIdData}
                  statusTabsOrder={statusTabsOrder}
                  fetchData={fetchData}
                  applyDiscountCodes={applyDiscountCodes}
                  totalCartItems={getTotalIemsInShoppingCart(shoppingCart, statusTabsOrder)}
                />
              </div>
              <CustomizeDialog
                {...props}
                className="pos-discount-code-instore"
                title={<span style={{ fontFamily: fontFamily }}>{pageData.promotion}</span>}
                open={isShowDiscountCodeDialog}
                closable={true}
                forceRenderContent
                displayCenter={true}
                fontFamily={fontFamily}
                content={() => (
                  <PosOrderPromotionListComponent
                    {...props}
                    callBack={() => callBackRedeemDiscountCode()}
                    isPos={true}
                    branchIdPos={reduxQROrder?.branchId}
                    isShowInputDiscountCode={true}
                    posDiscountCodes={posDiscountCodes}
                    orderDetailByIdData={orderDetailByIdData}
                    fontFamily={fontFamily}
                    statusTabsOrder={statusTabsOrder}
                    fetchData={fetchData}
                    applyDiscountCodes={applyDiscountCodes}
                  />
                )}
                onCancel={handleCancelDiscountCodeDialog}
                footer={null}
              />
            </>
          )}
        </div>

        <BoxDrawer
          className="box-drawer-pos-theme2"
          height={"100%"}
          title={<span style={{ fontFamily: fontFamily }}>{t("storeWebPage.generalUse.updateCart")}</span>}
          open={visibleEditProduct}
          onClose={handleCloseViewEdit}
          closeIcon={<BackIcon />}
          closable={true}
          style={{ fontFamily: fontFamily }}
          body={
            <EditOrderItem
              {...props}
              dataEdit={{ ...productSelectEdit }}
              indexDefault={indexDefault}
              onCancel={handleCloseViewEdit}
              stateConfig={storageConfig}
              calculateShoppingCart={() => {}}
              isPos={true}
              branchIdPos={branchId}
              platformId={Platform.POS}
            />
          }
        />
      </div>

      {/*Loading check discount code*/}
      {isLoading && (
        <div className="loading-full-screen">
          <OverlayLoadingFullScreenComponent />
        </div>
      )}
    </>
  );
}
