export const AddItemMyOrderIcon = (props) => {
  const { color = "white" } = props;
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3125 7.3125C13.3121 6.58725 12.7253 6.00038 12 6C11.2747 6.00038 10.6879 6.58725 10.6875 7.3125C10.6879 7.9065 11.0842 8.4015 11.625 8.56387V9.5625C11.625 9.76988 11.7926 9.9375 12 9.9375C12.2074 9.9375 12.375 9.76988 12.375 9.5625V8.56387C12.9158 8.4015 13.3121 7.9065 13.3125 7.3125ZM12 7.875C11.6895 7.87425 11.4383 7.623 11.4375 7.3125C11.4383 7.002 11.6895 6.75075 12 6.75C12.3105 6.75075 12.5618 7.002 12.5625 7.3125C12.5618 7.623 12.3105 7.87425 12 7.875Z"
        fill="#F4F4F4"
      />
      <path
        d="M20.8119 18.3458C20.7957 13.4921 16.8571 9.56288 12.0001 9.5625C7.14311 9.56288 3.20448 13.4921 3.18835 18.3458H20.8119ZM6.36798 13.3534C6.86973 12.7508 7.67785 12.045 8.88123 11.6441C9.07773 11.5785 9.29035 11.6846 9.3556 11.8811C9.42123 12.0776 9.3151 12.2903 9.1186 12.3555C8.1046 12.6938 7.4236 13.2739 6.9856 13.7846C6.54835 14.2946 6.3601 14.7345 6.3481 14.7638C6.27123 14.9561 6.05298 15.0503 5.8606 14.9734C5.66823 14.8965 5.57448 14.6783 5.65135 14.4859C5.6626 14.46 5.86585 13.9575 6.36798 13.3534Z"
        fill="#F4F4F4"
      />
      <path
        d="M2.06555 19.0957L4.18955 21.2197C4.45955 21.4878 4.81918 21.7023 5.20318 21.8628C5.5883 22.0211 5.99443 22.1235 6.37505 22.125H17.6251C18.0057 22.1235 18.4118 22.0211 18.7969 21.8628C19.1809 21.7023 19.5406 21.4878 19.8106 21.2197L21.9346 19.0957H2.06555Z"
        fill="#F4F4F4"
      />
    </svg>
  );
};
