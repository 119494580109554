function DeliveringOrderDetailIconCustomize({ color }) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M74.6822 23C74.6822 39.5685 60.0685 67.5 43.5 67.5C26.9315 67.5 4.5 58.0685 4.5 41.5C4.5 24.9314 18.4315 10.5 35 10.5C51.5685 10.5 74.6822 6.43146 74.6822 23Z"
        fill={color}
        fillOpacity="0.1"
      />
      <g clipPath="url(#clip0_1521_23560)">
        <path
          d="M18.9375 30.625C18.6889 30.625 18.4504 30.7238 18.2746 30.8996C18.0988 31.0754 18 31.3139 18 31.5625V36.25H16.125V31.5625C16.125 31.3139 16.0262 31.0754 15.8504 30.8996C15.6746 30.7238 15.4361 30.625 15.1875 30.625C14.9389 30.625 14.7004 30.7238 14.5246 30.8996C14.3488 31.0754 14.25 31.3139 14.25 31.5625V36.25H12.375V31.5625C12.375 31.3139 12.2762 31.0754 12.1004 30.8996C11.9246 30.7238 11.6861 30.625 11.4375 30.625C11.1889 30.625 10.9504 30.7238 10.7746 30.8996C10.5988 31.0754 10.5 31.3139 10.5 31.5625V39.0625C10.5007 39.644 10.6813 40.2111 11.017 40.6859C11.3527 41.1607 11.827 41.5201 12.375 41.7147V63.4375C12.3938 64.1775 12.6961 64.882 13.2195 65.4055C13.743 65.9289 14.4475 66.2312 15.1875 66.25C15.9275 66.2312 16.632 65.9289 17.1555 65.4055C17.6789 64.882 17.9812 64.1775 18 63.4375V41.7147C18.548 41.5201 19.0223 41.1607 19.358 40.6859C19.6937 40.2111 19.8743 39.644 19.875 39.0625V31.5625C19.875 31.3139 19.7762 31.0754 19.6004 30.8996C19.4246 30.7238 19.1861 30.625 18.9375 30.625ZM18 39.0625C18 39.3111 17.9012 39.5496 17.7254 39.7254C17.5496 39.9012 17.3111 40 17.0625 40C16.8139 40 16.5754 40.0988 16.3996 40.2746C16.2238 40.4504 16.125 40.6889 16.125 40.9375V63.4375C16.1097 63.6811 16.006 63.9108 15.8334 64.0834C15.6608 64.256 15.4311 64.3597 15.1875 64.375C14.9439 64.3597 14.7142 64.256 14.5416 64.0834C14.369 63.9108 14.2653 63.6811 14.25 63.4375V40.9375C14.25 40.6889 14.1512 40.4504 13.9754 40.2746C13.7996 40.0988 13.5611 40 13.3125 40C13.0639 40 12.8254 39.9012 12.6496 39.7254C12.4738 39.5496 12.375 39.3111 12.375 39.0625V38.125H18V39.0625Z"
          fill={color}
        />
        <path
          d="M70.0959 30.7919C69.9714 30.7055 69.8279 30.6505 69.6775 30.6317C69.5271 30.613 69.3744 30.6309 69.2325 30.6841C68.9062 30.8125 61.125 33.9672 61.125 55C61.125 55.2486 61.2238 55.4871 61.3996 55.6629C61.5754 55.8387 61.8139 55.9375 62.0625 55.9375H65.7234L65.0559 63.2753C65.0274 63.6455 65.077 64.0175 65.2017 64.3672C65.3264 64.7169 65.5233 65.0364 65.7797 65.305C66.0301 65.5898 66.3361 65.8206 66.6788 65.9831C67.0215 66.1456 67.3938 66.2364 67.7728 66.25C68.4874 66.2229 69.1655 65.9268 69.6711 65.4211C70.1768 64.9155 70.4729 64.2374 70.5 63.5228V31.5625C70.4999 31.411 70.4632 31.2617 70.3928 31.1275C70.3224 30.9933 70.2206 30.8781 70.0959 30.7919ZM68.625 63.5228C68.6028 63.7413 68.5059 63.9454 68.3506 64.1006C68.1954 64.2559 67.9913 64.3528 67.7728 64.375C67.5356 64.3432 67.3187 64.2242 67.1644 64.0413C67.0865 63.9632 67.025 63.8703 66.9838 63.768C66.9426 63.6657 66.9224 63.5562 66.9244 63.4459L67.6875 55.0853C67.6994 54.9552 67.684 54.8241 67.6422 54.7003C67.6004 54.5765 67.5333 54.4628 67.445 54.3665C67.3567 54.2702 67.2493 54.1934 67.1296 54.1411C67.0099 54.0887 66.8806 54.062 66.75 54.0625H63.0056C63.1613 40.5306 66.6788 35.2675 68.625 33.3869V63.5228Z"
          fill={color}
        />
        <path
          d="M36.2178 19.6291L35.5194 23.9116C35.4912 24.0851 35.5123 24.2631 35.5805 24.4252C35.6486 24.5873 35.7609 24.7269 35.9047 24.8282C36.0484 24.9295 36.2177 24.9883 36.3932 24.998C36.5688 25.0076 36.7435 24.9677 36.8975 24.8828L40.5003 22.8916L44.1031 24.8828C44.2415 24.96 44.3974 25.0003 44.5559 25C44.6918 25 44.8261 24.9705 44.9494 24.9135C45.0727 24.8564 45.1822 24.7733 45.2702 24.6697C45.3582 24.5662 45.4227 24.4448 45.4591 24.3139C45.4956 24.183 45.5031 24.0457 45.4812 23.9116L44.7828 19.6291L47.734 16.6056C47.8544 16.4823 47.9385 16.3281 47.9769 16.16C48.0154 15.992 48.0068 15.8166 47.9521 15.6531C47.8973 15.4897 47.7985 15.3445 47.6666 15.2335C47.5347 15.1225 47.3748 15.0501 47.2044 15.0241L43.1647 14.4063L41.3487 10.5391C41.273 10.378 41.153 10.2418 41.0028 10.1464C40.8525 10.0511 40.6783 10.0004 40.5003 10.0004C40.3223 10.0004 40.148 10.0511 39.9978 10.1464C39.8476 10.2418 39.7276 10.378 39.6519 10.5391L37.8359 14.4063L33.7962 15.0241C33.6258 15.0501 33.4659 15.1225 33.3339 15.2335C33.202 15.3445 33.1033 15.4897 33.0485 15.6531C32.9938 15.8166 32.9852 15.992 33.0236 16.16C33.0621 16.3281 33.1461 16.4823 33.2665 16.6056L36.2178 19.6291ZM38.614 16.1875C38.7662 16.1642 38.9103 16.1038 39.0336 16.0115C39.1569 15.9193 39.2556 15.7982 39.3209 15.6588L40.5003 13.1444L41.6797 15.6559C41.745 15.7953 41.8437 15.9165 41.967 16.0087C42.0903 16.1009 42.2344 16.1614 42.3865 16.1847L45.1175 16.6019L43.1103 18.6588C43.0078 18.7639 42.9314 18.8916 42.8872 19.0316C42.8431 19.1717 42.8325 19.3201 42.8562 19.465L43.325 22.3084L40.9578 20.9959C40.8191 20.9195 40.6633 20.8793 40.505 20.8793C40.3466 20.8793 40.1908 20.9195 40.0522 20.9959L37.685 22.3084L38.149 19.465C38.1728 19.3201 38.1622 19.1717 38.118 19.0316C38.0739 18.8916 37.9975 18.7639 37.895 18.6588L35.8831 16.6019L38.614 16.1875Z"
          fill={color}
        />
        <path
          d="M60.6254 23.9181C60.775 24.0124 60.9482 24.0624 61.1251 24.0624C61.3019 24.0624 61.4751 24.0124 61.6248 23.9181C62.2501 23.5272 67.6876 19.9938 67.6876 16.3928C67.7091 15.5151 67.3988 14.6616 66.8185 14.0027C66.2382 13.3439 65.4307 12.9282 64.5573 12.8388C63.911 12.7692 63.2576 12.8638 62.6577 13.1139C62.0577 13.364 61.5306 13.7614 61.1251 14.2694C60.7199 13.7611 60.1928 13.3634 59.5927 13.1133C58.9927 12.8632 58.3392 12.7687 57.6929 12.8388C56.8195 12.9282 56.012 13.3439 55.4317 14.0027C54.8514 14.6616 54.541 15.5151 54.5626 16.3928C54.5626 19.9938 60.0048 23.5272 60.6254 23.9181ZM57.916 14.7016C57.9891 14.6921 58.0627 14.6874 58.1363 14.6875C58.6548 14.7111 59.1482 14.9178 59.5286 15.2709C59.909 15.624 60.1518 16.1006 60.2138 16.616C60.2632 16.8204 60.38 17.0023 60.5453 17.1323C60.7105 17.2624 60.9147 17.3332 61.1251 17.3332C61.3354 17.3332 61.5396 17.2624 61.7049 17.1323C61.8702 17.0023 61.9869 16.8204 62.0363 16.616C62.0954 16.0608 62.3683 15.5505 62.7973 15.1931C63.2262 14.8357 63.7774 14.6595 64.3341 14.7016C64.7515 14.7375 65.1387 14.9337 65.4144 15.2491C65.6901 15.5645 65.8328 15.9744 65.8126 16.3928C65.8126 18.1788 63.1182 20.635 61.1251 22.0028C59.132 20.635 56.4376 18.1788 56.4376 16.3928C56.4174 15.9744 56.5601 15.5645 56.8358 15.2491C57.1115 14.9337 57.4987 14.7375 57.916 14.7016Z"
          fill={color}
        />
        <path
          d="M25.0004 32.3556C25.15 32.4499 25.3232 32.4999 25.5001 32.4999C25.6769 32.4999 25.8501 32.4499 25.9998 32.3556C26.6251 31.9647 32.0626 28.4313 32.0626 24.8303C32.0841 23.9526 31.7738 23.0991 31.1935 22.4402C30.6132 21.7814 29.8057 21.3657 28.9323 21.2763C28.286 21.2063 27.6325 21.3008 27.0325 21.5509C26.4325 21.801 25.9054 22.1986 25.5001 22.7069C25.095 22.1984 24.5679 21.8007 23.9678 21.5505C23.3678 21.3004 22.7142 21.2061 22.0679 21.2763C21.1945 21.3657 20.387 21.7814 19.8067 22.4402C19.2264 23.0991 18.916 23.9526 18.9376 24.8303C18.9376 28.4313 24.3798 31.9647 25.0004 32.3556ZM22.291 23.1391C22.3641 23.1296 22.4377 23.1249 22.5113 23.125C23.0298 23.1486 23.5232 23.3553 23.9036 23.7084C24.284 24.0615 24.5268 24.5381 24.5888 25.0535C24.6382 25.2579 24.755 25.4398 24.9203 25.5699C25.0855 25.6999 25.2897 25.7707 25.5001 25.7707C25.7104 25.7707 25.9146 25.6999 26.0799 25.5699C26.2452 25.4398 26.3619 25.2579 26.4113 25.0535C26.4706 24.4984 26.7436 23.9882 27.1725 23.6309C27.6014 23.2736 28.1525 23.0972 28.7091 23.1391C29.1265 23.175 29.5137 23.3712 29.7894 23.6866C30.0651 24.002 30.2078 24.4119 30.1876 24.8303C30.1876 26.6116 27.4932 29.0725 25.5001 30.4403C23.507 29.0725 20.8126 26.6163 20.8126 24.8303C20.7924 24.4119 20.9351 24.002 21.2108 23.6866C21.4865 23.3712 21.8737 23.175 22.291 23.1391Z"
          fill={color}
        />
        <path
          d="M40.5 30.625C36.6062 30.625 32.7998 31.7797 29.5622 33.9429C26.3246 36.1062 23.8012 39.181 22.3111 42.7784C20.821 46.3758 20.4312 50.3343 21.1908 54.1533C21.9504 57.9723 23.8255 61.4803 26.5788 64.2337C29.3322 66.987 32.8402 68.8621 36.6592 69.6217C40.4782 70.3814 44.4367 69.9915 48.0341 68.5014C51.6315 67.0113 54.7063 64.4879 56.8696 61.2503C59.0329 58.0127 60.1875 54.2063 60.1875 50.3125C60.1816 45.0929 58.1054 40.0887 54.4146 36.3979C50.7238 32.7071 45.7196 30.631 40.5 30.625ZM40.5 68.125C36.977 68.125 33.5332 67.0803 30.6039 65.1231C27.6747 63.1658 25.3916 60.3839 24.0434 57.129C22.6952 53.8742 22.3425 50.2927 23.0298 46.8375C23.7171 43.3822 25.4135 40.2083 27.9047 37.7172C30.3958 35.226 33.5697 33.5296 37.025 32.8423C40.4802 32.155 44.0617 32.5077 47.3166 33.8559C50.5714 35.2041 53.3533 37.4872 55.3106 40.4164C57.2678 43.3457 58.3125 46.7895 58.3125 50.3125C58.3073 55.0351 56.429 59.5627 53.0896 62.9021C49.7502 66.2414 45.2226 68.1198 40.5 68.125Z"
          fill={color}
        />
        <path
          d="M49.9506 41.1569C49.8562 41.2361 49.7784 41.3332 49.7215 41.4425C49.6647 41.5519 49.63 41.6713 49.6194 41.7941C49.6087 41.9169 49.6224 42.0405 49.6596 42.158C49.6968 42.2755 49.7568 42.3845 49.8362 42.4788C51.7955 44.8181 52.804 47.8084 52.6617 50.8566C52.5194 53.9047 51.2366 56.788 49.0678 58.9346C46.899 61.0812 44.0026 62.3342 40.9532 62.4452C37.9037 62.5561 34.9239 61.5169 32.6049 59.5336C30.2858 57.5503 28.7969 54.7678 28.4334 51.7381C28.0699 48.7084 28.8585 45.6527 30.6426 43.1771C32.4267 40.7016 35.0761 38.987 38.0653 38.3735C41.0544 37.76 44.165 38.2923 46.78 39.865C46.8855 39.9343 47.0038 39.9815 47.128 40.004C47.2522 40.0264 47.3796 40.0235 47.5027 39.9955C47.6257 39.9675 47.7419 39.9149 47.8441 39.841C47.9464 39.767 48.0326 39.6731 48.0977 39.565C48.1628 39.4569 48.2054 39.3368 48.223 39.2118C48.2405 39.0869 48.2327 38.9596 48.1999 38.8378C48.1671 38.7159 48.1101 38.6019 48.0321 38.5026C47.9542 38.4034 47.8571 38.3208 47.7465 38.26C44.7228 36.4419 41.126 35.8268 37.67 36.5369C34.2139 37.2469 31.151 39.2302 29.089 42.0933C27.027 44.9563 26.1166 48.4899 26.5384 51.9928C26.9602 55.4958 28.6834 58.7122 31.3661 61.0039C34.0488 63.2956 37.4949 64.4952 41.0208 64.3645C44.5467 64.2338 47.8946 62.7826 50.4003 60.2986C52.906 57.8146 54.3864 54.4795 54.5478 50.9549C54.7092 47.4303 53.5398 43.9738 51.2715 41.2713C51.1923 41.177 51.0953 41.0993 50.986 41.0425C50.8768 40.9858 50.7574 40.9511 50.6348 40.9405C50.5121 40.9299 50.3885 40.9435 50.2712 40.9806C50.1538 41.0178 50.0448 41.0777 49.9506 41.1569Z"
          fill={color}
        />
        <path
          d="M40.5 58.75C42.0047 58.7726 43.4874 58.3877 44.7913 57.6363C46.0951 56.8849 47.1714 55.7948 47.9062 54.4816C47.9616 54.3715 47.9948 54.2516 48.0039 54.1287C48.013 54.0058 47.9977 53.8824 47.9591 53.7654C47.9204 53.6484 47.8591 53.5401 47.7786 53.4468C47.6981 53.3535 47.6001 53.277 47.49 53.2216C47.3799 53.1662 47.26 53.133 47.1371 53.1239C47.0143 53.1149 46.8908 53.1301 46.7738 53.1688C46.6568 53.2074 46.5485 53.2687 46.4552 53.3492C46.3619 53.4297 46.2854 53.5278 46.23 53.6378C45.6355 54.6249 44.796 55.4415 43.7928 56.0084C42.7896 56.5753 41.6569 56.8732 40.5047 56.8732C39.3524 56.8732 38.2197 56.5753 37.2166 56.0084C36.2134 55.4415 35.3738 54.6249 34.7794 53.6378C34.6675 53.4156 34.4719 53.2468 34.2356 53.1688C33.9993 53.0907 33.7417 53.1097 33.5194 53.2216C33.2971 53.3335 33.1284 53.5291 33.0503 53.7654C32.9722 54.0017 32.9912 54.2593 33.1031 54.4816C33.8372 55.7934 34.912 56.8825 36.2141 57.6339C37.5161 58.3852 38.9969 58.7709 40.5 58.75Z"
          fill={color}
        />
        <path
          d="M33.9375 48.4553C33.9368 48.3322 33.9603 48.2101 34.0067 48.0961C34.0531 47.9821 34.1216 47.8783 34.2081 47.7908C34.3829 47.6139 34.6207 47.5137 34.8694 47.5122C35.118 47.5107 35.3571 47.608 35.5339 47.7828C35.7108 47.9576 35.811 48.1954 35.8125 48.4441C35.8125 48.6927 35.9113 48.9312 36.0871 49.107C36.2629 49.2828 36.5014 49.3816 36.75 49.3816C36.9986 49.3816 37.2371 49.2828 37.4129 49.107C37.5887 48.9312 37.6875 48.6927 37.6875 48.4441C37.6875 47.6981 37.3912 46.9828 36.8637 46.4553C36.3363 45.9279 35.6209 45.6316 34.875 45.6316C34.1291 45.6316 33.4137 45.9279 32.8863 46.4553C32.3588 46.9828 32.0625 47.6981 32.0625 48.4441C32.0627 48.6899 32.1601 48.9257 32.3334 49.1001C32.5068 49.2745 32.742 49.3733 32.9878 49.375H33C33.2451 49.3736 33.4801 49.2768 33.6551 49.1051C33.8301 48.9335 33.9314 48.7004 33.9375 48.4553Z"
          fill={color}
        />
        <path
          d="M48.9375 48.4441C48.9375 47.6981 48.6412 46.9828 48.1137 46.4553C47.5863 45.9279 46.8709 45.6316 46.125 45.6316C45.3791 45.6316 44.6637 45.9279 44.1363 46.4553C43.6088 46.9828 43.3125 47.6981 43.3125 48.4441C43.3127 48.6899 43.4101 48.9257 43.5834 49.1001C43.7568 49.2745 43.992 49.3733 44.2378 49.375H44.25C44.4951 49.3736 44.7301 49.2768 44.9051 49.1051C45.0801 48.9335 45.1814 48.7004 45.1875 48.4553C45.1868 48.3322 45.2103 48.2101 45.2567 48.0961C45.3031 47.9821 45.3716 47.8783 45.4581 47.7908C45.6329 47.6139 45.8707 47.5137 46.1194 47.5122C46.368 47.5107 46.6071 47.608 46.7839 47.7828C46.9608 47.9576 47.061 48.1954 47.0625 48.4441C47.0625 48.6927 47.1613 48.9312 47.3371 49.107C47.5129 49.2828 47.7514 49.3816 48 49.3816C48.2486 49.3816 48.4871 49.2828 48.6629 49.107C48.8387 48.9312 48.9375 48.6927 48.9375 48.4441Z"
          fill={color}
        />
        <path
          d="M52.4997 31.5438C52.5613 31.5566 52.6242 31.5629 52.6872 31.5625C52.9031 31.5623 53.1124 31.4876 53.2797 31.351C53.4469 31.2143 53.5618 31.0241 53.605 30.8125L54.5425 26.125C54.5714 26.0026 54.5754 25.8755 54.5541 25.7515C54.5329 25.6275 54.4869 25.509 54.419 25.4031C54.351 25.2972 54.2624 25.2061 54.1584 25.1352C54.0545 25.0643 53.9373 25.015 53.8139 24.9903C53.6905 24.9657 53.5635 24.9661 53.4402 24.9916C53.317 25.017 53.2002 25.0671 53.0967 25.1387C52.9932 25.2102 52.9052 25.3019 52.8379 25.4083C52.7706 25.5146 52.7254 25.6334 52.705 25.7575L51.7675 30.445C51.7199 30.6877 51.7702 30.9394 51.9074 31.1452C52.0445 31.351 52.2574 31.4942 52.4997 31.5438Z"
          fill={color}
        />
        <path
          d="M59.8362 26.1428C59.7401 26.0659 59.6297 26.0086 59.5114 25.9743C59.3931 25.9399 59.2693 25.9293 59.1469 25.9429C59.0244 25.9564 58.9059 25.994 58.798 26.0534C58.6901 26.1128 58.595 26.1929 58.5181 26.2891L54.7681 30.9766C54.6911 31.0727 54.6339 31.1831 54.5996 31.3014C54.5653 31.4197 54.5546 31.5435 54.5682 31.6659C54.5818 31.7883 54.6194 31.9069 54.6787 32.0147C54.7381 32.1226 54.8182 32.2178 54.9143 32.2947C55.0105 32.3717 55.1209 32.4289 55.2391 32.4632C55.3574 32.4975 55.4813 32.5082 55.6037 32.4946C55.7261 32.481 55.8446 32.4434 55.9525 32.3841C56.0604 32.3247 56.1555 32.2446 56.2325 32.1485L59.9825 27.461C60.0594 27.3648 60.1167 27.2545 60.151 27.1362C60.1854 27.0179 60.196 26.894 60.1824 26.7716C60.1689 26.6492 60.1313 26.5307 60.0719 26.4228C60.0125 26.3149 59.9324 26.2198 59.8362 26.1428Z"
          fill={color}
        />
        <path
          d="M58.3124 34.375C58.4575 34.375 58.6006 34.3413 58.7305 34.2766L62.4805 32.4016C62.5907 32.3466 62.6889 32.2704 62.7697 32.1774C62.8504 32.0843 62.9121 31.9763 62.9511 31.8595C62.9901 31.7427 63.0057 31.6193 62.997 31.4965C62.9883 31.3736 62.9555 31.2536 62.9005 31.1435C62.8455 31.0333 62.7693 30.935 62.6763 30.8543C62.5832 30.7735 62.4752 30.7119 62.3584 30.6729C62.1225 30.5941 61.8649 30.6123 61.6424 30.7235L57.8924 32.5985C57.7031 32.6928 57.5513 32.8483 57.4616 33.0398C57.3719 33.2313 57.3495 33.4474 57.3982 33.6532C57.4468 33.859 57.5636 34.0423 57.7296 34.1733C57.8955 34.3044 58.1009 34.3754 58.3124 34.375Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1521_23560">
          <rect width="60" height="60" fill="white" transform="translate(10.5 10)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DeliveringOrderDetailIconCustomize;
