import { useTranslation } from "react-i18next";

export default function CancelNotificationContent(props) {
  const { storeBranchPhoneNumber } = props;
  const [t] = useTranslation();
  const isMobile = navigator.userAgent.match(/iPhone|Android/i);

  const translateData = {
    okay: t("form.okay"),
    notification: t("loginPage.notification", "Thông báo"),
    notificationCancelOrder: t("loginPage.notificationCancelOrder"),
  };

  return (
    <>
      <span>{translateData.notificationCancelOrder}</span>
      <a className="hotline-cancel-notification" href={isMobile ? `tel:${storeBranchPhoneNumber}` : "javascript:void(0)"}>
        Hotline: {storeBranchPhoneNumber}
      </a>
    </>
  );
}
