import { getInternationalRegion } from "../../../utils/helpers";
import {
  discountCodeDefaultInternational,
  flashSaleDefaultInternational,
  storeDiscountDefaultInternational,
} from "./customize-default-data/en";
import {
  discountCodeDefaultLocal,
  flashSaleDefaultLocal,
  storeDiscountDefaultLocal,
} from "./customize-default-data/vi";

const isInternationalRegion = getInternationalRegion();

export const flashSaleDefault = isInternationalRegion ? flashSaleDefaultInternational : flashSaleDefaultLocal;
export const storeDiscountDefault = isInternationalRegion
  ? storeDiscountDefaultInternational
  : storeDiscountDefaultLocal;
export const discountCodeDefault = isInternationalRegion ? discountCodeDefaultInternational : discountCodeDefaultLocal;
