import { MyReservationIcon } from "../../assets/icons.constants";
import LoginPopover from "../login-popover.component";

export function UserIcon(props) {
  const { isLogin, translateData, groupColorConfig, profileTab, isAllowReserveTable, onLogout } = props;
  return (
    <div className="user-profile-contain">
      {isLogin && (
        <>
          <a className="user-profile-icon" href={`/my-profile/${profileTab.myAccount}`}>
            {/* <img src={userInfoNav} alt="" /> */}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 2C9.38 2 7.25 4.13 7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2Z"
                fill={groupColorConfig?.buttonBackgroundColor ?? "#DB4D29"}
              />
              <path
                d="M17.0809 14.15C14.2909 12.29 9.74094 12.29 6.93094 14.15C5.66094 15 4.96094 16.15 4.96094 17.38C4.96094 18.61 5.66094 19.75 6.92094 20.59C8.32094 21.53 10.1609 22 12.0009 22C13.8409 22 15.6809 21.53 17.0809 20.59C18.3409 19.74 19.0409 18.6 19.0409 17.36C19.0309 16.13 18.3409 14.99 17.0809 14.15Z"
                fill={groupColorConfig?.buttonBackgroundColor ?? "#DB4D29"}
              />
            </svg>

            {translateData.myAccount}
          </a>
          <a className="user-profile-icon" href={`/my-profile/${profileTab.myOrder}`}>
            {/* <img src={userCartNav} alt="" /> */}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.25 22.5C17.2165 22.5 18 21.7165 18 20.75C18 19.7835 17.2165 19 16.25 19C15.2835 19 14.5 19.7835 14.5 20.75C14.5 21.7165 15.2835 22.5 16.25 22.5Z"
                fill={groupColorConfig?.buttonBackgroundColor ?? "#DB4D29"}
              />
              <path
                d="M8.25 22.5C9.2165 22.5 10 21.7165 10 20.75C10 19.7835 9.2165 19 8.25 19C7.2835 19 6.5 19.7835 6.5 20.75C6.5 21.7165 7.2835 22.5 8.25 22.5Z"
                fill={groupColorConfig?.buttonBackgroundColor ?? "#DB4D29"}
              />
              <path
                d="M4.84 3.94L4.64 6.39C4.6 6.86 4.97 7.25 5.44 7.25H20.75C21.17 7.25 21.52 6.93 21.55 6.51C21.68 4.74 20.33 3.3 18.56 3.3H6.27C6.17 2.86 5.97 2.44 5.66 2.09C5.16 1.56 4.46 1.25 3.74 1.25H2C1.59 1.25 1.25 1.59 1.25 2C1.25 2.41 1.59 2.75 2 2.75H3.74C4.05 2.75 4.34 2.88 4.55 3.1C4.76 3.33 4.86 3.63 4.84 3.94Z"
                fill={groupColorConfig?.buttonBackgroundColor ?? "#DB4D29"}
              />
              <path
                d="M20.5101 8.75H5.17005C4.75005 8.75 4.41005 9.07 4.37005 9.48L4.01005 13.83C3.87005 15.54 5.21005 17 6.92005 17H18.0401C19.5401 17 20.8601 15.77 20.9701 14.27L21.3001 9.6C21.3401 9.14 20.9801 8.75 20.5101 8.75Z"
                fill={groupColorConfig?.buttonBackgroundColor ?? "#DB4D29"}
              />
            </svg>

            {translateData.order}
          </a>
          {isAllowReserveTable ? (
            <a className="user-profile-icon" href={`/my-profile/${profileTab.myReservation}`}>
              <MyReservationIcon className="icon" />
              {translateData.myReservation}
            </a>
          ) : (
            <></>
          )}
        </>
      )}
      <p className="user-profile-icon">
        {/* <img src={userLogoutNav} alt="" /> */}
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_3599_4174)">
            <path
              d="M14.9998 13C14.4469 13 13.9999 13.4481 13.9999 14V18.0001C13.9999 18.551 13.5519 19 12.9998 19H9.99983V4.00014C9.99983 3.14615 9.45583 2.38315 8.63791 2.09916L8.34183 2.0001H12.9998C13.5519 2.0001 13.9999 2.44907 13.9999 3.00021V6.00018C13.9999 6.55206 14.4469 7.00011 14.9998 7.00011C15.5528 7.00011 15.9998 6.55206 15.9998 6.00018V3.00021C15.9998 1.34624 14.6538 0.000244141 12.9998 0.000244141H2.24998C2.21189 0.000244141 2.18003 0.0172728 2.14304 0.0222166C2.09489 0.0181883 2.04893 0.000244141 2.00004 0.000244141C0.897024 0.000244141 0 0.897085 0 2.0001V19.9999C0 20.8539 0.544001 21.6169 1.36192 21.9009L7.37999 23.907C7.58397 23.97 7.78684 24 7.99998 24C9.10299 24 9.99983 23.103 9.99983 22V21H12.9998C14.6538 21 15.9998 19.654 15.9998 18.0001V14C15.9998 13.4481 15.5528 13 14.9998 13Z"
              fill={groupColorConfig?.buttonBackgroundColor ?? "#DB4D29"}
            />
            <path
              d="M23.707 9.29317L19.7069 5.29327C19.4211 5.00726 18.991 4.9212 18.6171 5.07611C18.2441 5.2312 18 5.59612 18 6.00023V9.0002H14.0001C13.4481 9.0002 13 9.44807 13 10.0001C13 10.5522 13.4481 11.0001 14.0001 11.0001H18V14C18 14.4041 18.2441 14.7691 18.6171 14.9241C18.991 15.0791 19.4211 14.993 19.7069 14.7072L23.707 10.7071C24.0979 10.3162 24.0979 9.68409 23.707 9.29317Z"
              fill={groupColorConfig?.buttonBackgroundColor ?? "#DB4D29"}
            />
          </g>
          <defs>
            <clipPath id="clip0_3599_4174">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>

        {isLogin ? (
          <div
            onClick={() => {
              onLogout();
            }}
            className="login_content_theme1"
          >
            {translateData.logOut}
          </div>
        ) : (
          <LoginPopover isAllowReserveTable={isAllowReserveTable} />
        )}
      </p>
    </div>
  );
}
