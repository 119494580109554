import { Button, Drawer, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { EnumAddToCartType, EnumFlashSaleResponseCode } from "../../../../../constants/enums";
import { Platform } from "../../../../../constants/platform.constants";
import productDataService from "../../../../../data-services/product-data.service";
import { setToastMessageAddUpdateProductToCart } from "../../../../../modules/toast-message/toast-message.actions";
import { checkOutOfStockProductList } from "../../../../../services/material/check-out-of-stock.service";
import posAddToCartServices from "../../../../../services/pos/pos-add-to-cart.services";
import productComboAddToCartServices from "../../../../../services/product-combo-add-to-cart.services";
import mapperOutOfStockServices from "../../../../../services/product/product-mapping.services";
import { isNonEmptyArray } from "../../../../../utils/helpers";
import { getStorage } from "../../../../../utils/localStorage.helpers";
import EditOrderItem from "../../../../components/edit-order-item/edit-order-item.component";
import NotificationDialog from "../../../../components/notification-dialog/notification-dialog.component";
import { enumOrderType } from "../../../../constants/enums";
import PageType from "../../../../constants/page-type.constants";
import { comboTypeEnum } from "../../../../constants/store-web-page.constants";
import useDebounce, { TIME_DELAY } from "../../../../hooks/useDebounce";
import ProductListRowComponent from "./product-list-row.component";
import { useScrollSpy } from "./product-list-scroll-spy.provider";
const ProductListContentComponent = (props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [isShowNotifyFlashSaleDialog, setIsShowNotifyFlashSaleDialog] = useState(false);
  const [flashSaleProduct, setFlashSaleProduct] = useState(null);
  const [checkOutOfStockQueue, setCheckOutOfStockQueue] = useState([]);
  const [outOfStockChecked, setOutOfStockChecked] = useState({});

  const translatedData = {
    okay: t("storeWebPage.generalUse.okay"),
    notification: t("storeWebPage.generalUse.notification"),
    flashSaleEndNotification: t("storeWebPage.flashSale.flashSaleEndNotification"),
  };

  const {
    categories = [],
    products = undefined,
    clickToFocusCustomize = undefined,
    styledCardProductList = undefined,
    paging = undefined,
    onChangeTab,
    isLoadData = true,
  } = props;
  const {
    onSetLoading,
    tab,
    sources,
    onSetSources,
    onSetCategories,
    onLoadMoreCategory,
    listLoadings,
    onAddListLoadings,
    onRemoveListLoadings,
    onSetScrollContainerRef,
    onSetProductStyles,
  } = useScrollSpy();

  const deliveryAddress = useSelector((state) => state?.session?.deliveryAddress);
  const branchAddress = useSelector((state) => state.session?.deliveryAddress?.branchAddress);
  const isMaxWidth500 = useMediaQuery({ maxWidth: 500 });
  const scrollContainerRef = useRef(null);
  const configCustomize = JSON.parse(getStorage("config"));
  const { pages } = configCustomize;
  let configDetail = pages?.find((x) => x.id === PageType.PRODUCT_DETAIL);
  const storageConfig = JSON.parse(getStorage("config"));

  useEffect(() => {
    const res = categories.reduce((acc, curr) => {
      const itemPaging = paging ? paging[curr.id] : undefined;
      const isCombo = curr?.isCombo === true;
      const description = curr?.description;

      let totalItems = 0;

      const data = products[curr.id] || [];
      const page = isCombo || data.length === 0 ? -1 : itemPaging?.page || -1;
      if (isCombo) {
        if (curr?.comboTypeId === comboTypeEnum.comboProductPrice.id) {
          totalItems = 1;
        } else {
          totalItems = data.length;
        }
      } else {
        totalItems = itemPaging?.totalItem || 0;
      }
      return (
        (acc[curr.id] = {
          ...curr,
          title: curr.name,
          page,
          data,
          isCombo: isCombo,
          comboTypeId: curr?.comboTypeId,
          thumbnail: curr?.thumbnail,
          thumbnailFallback: curr?.thumbnailFallback,
          sellingPrice: curr?.sellingPrice,
          originalPrice: curr?.originalPrice,
          total: totalItems,
          description: description,
        }),
        acc
      );
    }, {});

    onSetProductStyles(styledCardProductList);
    onSetCategories(() => {
      onSetLoading(false);
      onSetSources(res);
      return categories.map((x) => ({
        ...x,
        page: 1,
      }));
    });
  }, []);

  useEffect(() => {
    scrollContainerRef.current && onSetScrollContainerRef(scrollContainerRef);
  }, [scrollContainerRef]);

  useDebounce(
    async () => {
      if (tab !== "") {
        onChangeTab(tab);
        const item = sources[tab];
        const tabIsLoading = listLoadings.indexOf(tab) !== -1;
        if (isLoadData && item && item.page === 1 && !tabIsLoading) {
          onAddListLoadings(tab);
          const rsProducts = await productDataService.getProductsStoreScrollSpy(tab, branchAddress?.id);
          if (rsProducts.data) {
            const { products: productsRes } = rsProducts.data;
            onRemoveListLoadings(tab);
            onLoadMoreCategory(tab, Object.keys(productsRes).length ? productsRes[tab] : []);

            const productPricesNew = mapperOutOfStockServices.mapperProducts2VariantsObject(productsRes);
            setCheckOutOfStockQueue(productPricesNew);
          }
        }
      }
    },
    [tab, listLoadings, sources],
    TIME_DELAY,
  );
  const [isShowModalProductDetail, setIsShowModalProductDetail] = useState(false);
  const [dataAddNew, setDataAddNew] = useState(null);


  const handleAddToCart = (_data) => {
    if (_data?.isNotQuickAdd) {
      if (_data?.isCombo) {
        posAddToCartServices
          .getComboDetailAsync(_data?.id, _data?.comboPricingProducts ? 0 : 1)
          .then((response) => {
            console.log("Res", response);
            setIsShowModalProductDetail(true);
            setDataAddNew(response);
          })
          .catch((response) => {
            // To do
          });
      } else {
        setIsShowModalProductDetail(true);
        const productData = {
          isCombo: false,
          id: _data?.id,
          quantity: 1,
          productPrice: {
            id: _data?.productPrices[0]?.id,
            originalPrice: _data?.productPrices[0]?.originalPrice,
            priceValue: _data?.productPrices[0]?.sellingPrice,
            isApplyPromotion:
              _data?.productPrices[0]?.discountAmount?.id !== null &&
              _data?.productPrices[0]?.discountAmount?.id !== undefined,
            promotionId: _data?.productPrices[0]?.discountAmount?.id,
            isDiscountPercent: _data?.productPrices[0]?.discountAmount?.isPercentDiscount,
            discountValue: _data?.productPrices[0]?.discountAmount?.isPercentDiscount
              ? _data?.productPrices[0]?.discountAmount?.percentNumber
              : _data?.productPrices[0]?.discountAmount?.maximumDiscountAmount,
          },
          toppings: [],
          options: [],
        };
        setDataAddNew(productData);
      }
    } else {
      //Verify selected delivery method before add to cart
      if (deliveryAddress?.orderType === enumOrderType.ONLINE_DELIVERY && !deliveryAddress?.receiverAddress) {
        setTimeout(() => {
          const chooseAddressModal = document.getElementsByClassName("receiver-address-select-button-from-checkout")[0];
          return chooseAddressModal?.click();
        }, 800);
      }

      let params = { id: _data.id, isCombo: _data?.isCombo };
      if (_data?.isCombo) {
        params.type = _data?.type;
        params.comboId = _data?.comboId;
        params.id = _data?.id;
        params.comboPricingProducts = _data?.comboPricingProducts;
        params.comboProductPrices = _data?.comboProductPrices;
      } else {
        params.productPriceId = _data?.productPrices[0]?.id;
        params.isFlashSale = _data?.isFlashSale;
        params.flashSaleId = _data?.flashSaleId;
      }

      productComboAddToCartServices.quickAddToCart(
        params,
        !_data.isCombo ? EnumAddToCartType.Product : _data.type,
        branchAddress?.id,
        null,
        (allAreApplicable, responseCode, product) => checkFlashSaleApplicable(allAreApplicable, responseCode, product),
      );
    }
  };

  const checkFlashSaleApplicable = (allAreApplicable, responseCode, product) => {
    if (allAreApplicable) {
      updateCartToRedux(product);
    } else {
      if (responseCode === EnumFlashSaleResponseCode.Inactive) {
        setIsShowNotifyFlashSaleDialog(true);
        setFlashSaleProduct(product);
      } else {
        updateCartToRedux(product);
      }
    }
  };

  const handleConfirmNotify = () => {
    if (flashSaleProduct) {
      updateCartToRedux();
      setIsShowNotifyFlashSaleDialog(false);
      window.location.reload();
    }
  };

  const updateCartToRedux = (product) => {
    const currentProduct = product ?? flashSaleProduct;
    productComboAddToCartServices.updateCartInLocalAndRedux(
      productComboAddToCartServices.mappingToProductLocal(currentProduct),
      false,
    );
    dispatch(
      setToastMessageAddUpdateProductToCart({
        icon: null,
        message: t("addCartItemToastMessage", "Sản phẩm đã được thêm vào giỏ hàng thành công"),
      }),
    );
  };

  async function checkOutOfStock(productPrices) {
    const outOfStockData = await checkOutOfStockProductList(branchAddress?.id, true, productPrices, "dictionary");
    setOutOfStockChecked({ ...outOfStockChecked, ...outOfStockData });
  }

  // Init product variants after loaded
  useEffect(() => {
    if (!sources || !isNonEmptyArray(Object.keys(sources))) return;
    const productPricesData = mapperOutOfStockServices.mapperProducts2ProductVariantsFromCategoriesObject(sources);
    setCheckOutOfStockQueue(productPricesData);
  }, [sources]);

  useEffect(() => {
    if (checkOutOfStockQueue.length === 0) {
      return;
    }

    checkOutOfStock(checkOutOfStockQueue);
  }, [checkOutOfStockQueue]);

  return (
    <div
      id="themeProductProductList"
      onClick={() => configCustomize?.customizeTheme && clickToFocusCustomize("customizeProductProductList")}
    >
      <div id={"product-list-wrapper-rows"} className="list-product-by-category">
        {sources &&
          Object.keys(sources).map((_key, index) => (
            <ProductListRowComponent
              key={`tab_${_key}_${index}`}
              id={_key}
              addToCart={handleAddToCart}
              row={sources[_key]}
              data={sources[_key].data}
              isLoading={listLoadings.indexOf(_key) !== -1}
              outOfStockChecked={outOfStockChecked}
            />
          ))}
      </div>
      {/* Product flash sale notify */}
      <NotificationDialog
        open={isShowNotifyFlashSaleDialog}
        title={translatedData.notification}
        onConfirm={handleConfirmNotify}
        confirmLoading={false}
        className="checkout-theme1-notify-dialog"
        content={translatedData.flashSaleEndNotification}
        footer={[<Button onClick={handleConfirmNotify}>{translatedData.okay}</Button>]}
        closable={true}
      />
      {isShowModalProductDetail && (
        <>
          <div className="edit-order-item">
            {isMaxWidth500 ? (
              <Drawer
                className="drawer-product-cart-detail"
                placement="bottom"
                open={isShowModalProductDetail}
                onClose={() => {
                  setIsShowModalProductDetail(false);
                }}
                destroyOnClose={true}
                closable={false}
                style={null}
                height={"85%"}
              >
                <EditOrderItem
                  indexDefault={-1}
                  dataEdit={dataAddNew}
                  onCancel={() => {
                    setIsShowModalProductDetail(false);
                  }}
                  stateConfig={null}
                  calculateShoppingCart={null}
                  platformId={Platform.StoreWebsite}
                />
              </Drawer>
            ) : (
              <Modal
                className="modal-product-cart-detail cart-edit-item"
                open={isShowModalProductDetail}
                onOk={() => {
                  setIsShowModalProductDetail(false);
                }}
                onCancel={() => {
                  setIsShowModalProductDetail(false);
                }}
                footer={(null, null)}
                centered
                destroyOnClose={true}
                width={"80%"}
                style={{ backgroundColor: "white" }}
              >
                <EditOrderItem
                  indexDefault={-1}
                  dataEdit={dataAddNew}
                  onCancel={() => {
                    setIsShowModalProductDetail(false);
                  }}
                  stateConfig={storageConfig}
                  calculateShoppingCart={null}
                  platformId={Platform.StoreWebsite}
                />
              </Modal>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default ProductListContentComponent;
