import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import React, { useMemo } from "react";
const DEFAULT_LANG = "vi";
const DEFAULT_LOCALE = "vi";
const MetaInfo = (props) => {
  const {
    meta = [],
    defer = false,
    lang = DEFAULT_LANG,
    title = "",
    locale = DEFAULT_LOCALE,
    description = "",
    author = "",
    image = "",
  } = props;

  const [t] = useTranslation();
  const url = window?.location.href || "unknown";
  const seoData = useSelector((state) => state.seo?.seoData);
  const keywords = useMemo(() => seoData?.keywords, [seoData]);
  const storeName = useMemo(() => document.title, []);

  if (!Boolean(title)) {
    return <></>;
  }

  return (
    <Helmet
      defer={defer}
      title={title}
      htmlAttributes={{ lang }}
      titleTemplate={`${storeName} - %s`}
      link={[
        {
          rel: "canonical",
          href: url,
        },
      ]}
      meta={[
        {
          name: "description",
          content: t(description),
        },
        {
          property: "og:description",
          content: t(description),
        },
        {
          name: "keywords",
          content: keywords,
        },
        {
          name: "title",
          content: t(title),
        },
        {
          property: "og:title",
          content: t(title),
        },
        {
          property: "og:site_name",
          content: storeName,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:url",
          content: url,
        },
        {
          property: "og:locale",
          content: locale,
        },
        {
          property: "og:image",
          content: image ?? "/logo.png",
        },
        {
          name: "author",
          content: author,
        },
      ].concat(meta)}
    />
  );
};

const MetaInfoMemoized = React.memo(MetaInfo);

export default MetaInfoMemoized;
