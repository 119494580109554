import { createHttp } from "../utils/http-common";

const controller = "material";

const checkInventoryAsync = (data) => {
  const http = createHttp();
  return http.post(`/${controller}/check`, data);
};

const materialDataService = {
  checkInventoryAsync,
};
export default materialDataService;
