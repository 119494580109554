import { createSlice } from "@reduxjs/toolkit";

const PREFIX = "AUTH";

// region constants
export const STATUS_RESET_PASSWORD = {
  INPUT_PHONE_NUMBER: 0,
  INPUT_OTP: 1,
  INPUT_NEW_PASSWORD: 2,
};

export const METHOD_SEND_OTP = {
  FIREBASE: 0,
  GO_SELL: 1,
};
//end region

const initialState = {
  resetPassword: {
    currentStatus: STATUS_RESET_PASSWORD.INPUT_PHONE_NUMBER,
    currentMethodSendOTP: METHOD_SEND_OTP.FIREBASE,
    token: {},
    phoneCode: "",
    iso: "",
    phoneNumber: "",
  },
};
const authSlice = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    setResetPasswordState: (state, action) => {
      state.resetPassword = {
        ...state.resetPassword,
        ...action.payload,
      };
    },
    resetState: (state) => {
      state.resetPassword = {
        currentStatus: STATUS_RESET_PASSWORD.INPUT_PHONE_NUMBER,
        currentMethodSendOTP: METHOD_SEND_OTP.FIREBASE,
        token: {},
        phoneCode: "",
        iso: "",
        phoneNumber: "",
      };
    },
  },
});
export const authActions = authSlice.actions;
export const authSelector = (state) => state.auth;
export const resetPasswordSelector = (state) => state.auth.resetPassword;

const authReducer = authSlice.reducer;
export default authReducer;
