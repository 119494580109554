import { useTranslation } from "react-i18next";
import "./out-of-stock-label-box.scss";

export default function OutOfStockLabelBoxComponent(props) {
  const { isShow, nameComponent } = props;
  const [t] = useTranslation();

  return (
    <>
      {isShow && (
        <div
          className={`out-of-stock-label-box ${nameComponent == "small-size" && "small-size"} ${
            nameComponent == "todays" && "todays"
          } ${nameComponent == "small-size-pos" && "small-size-pos"}  ${
            nameComponent == "is-cart-item" && "is-cart-item"
          }`}
        >
          <span className="text-label">{t("storeWebPage.productDetailPage.labelOutOfStock", "Hết hàng")}</span>
        </div>
      )}
    </>
  );
}
