import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArrowLeftMemberOffer } from "../../../../assets/icons.constants";
import { useSearchParams } from "../../../../hooks";
import "./HeaderOrder.scss";

function HeaderOrder(props) {
  const { title, fontFamily, stringCode = "" } = props;
  const { t } = useTranslation();
  const query = useSearchParams();

  return (
    <div style={{ fontFamily: fontFamily }}>
      <div className="header-cart-checkout-scan-qr">
        <div className="header-cart-checkout-scan-qr-left">
          <Link to={"/pos?" + query.toString()}>
            <ArrowLeftMemberOffer />
          </Link>
          <span>{title}</span>
        </div>
        {stringCode && (
          <div className="header-cart-checkout-scan-qr-right">
            <div className="order-text">{t("orderDetail.order", { stringCode }, "Order")}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HeaderOrder;
