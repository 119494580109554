import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import { getStorage, localStorageKeys, setStorage } from "../../../utils/localStorage.helpers";
import DefaultCheckoutBackgroundMobile from "../../assets/images/default-checkout-bg-mobile.png";
import DefaultCheckoutBackground from "../../assets/images/default-checkout-bg.png";
import ConfirmationDialog from "../../components/confirmation-dialog/confirmation-dialog.component";
import Index from "../../index";
import "./checkout.page.scss";
import CheckOutDetail from "./components/checkout-detail.component";
import CheckOutHeader from "./components/checkout.header";
import CheckOutRelatedProducts from "./components/checkout.related.products";
import useResponsiveChatContainer from "../../hooks/useResponsiveChatContainer";

function CheckoutPage(props) {
  useResponsiveChatContainer();
  const { t } = useTranslation();
  const history = useHistory();
  const { colorGroups, checkoutConfig, clickToFocusCustomize, isCustomize, isDefault } = props;
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const backgroundColor = checkoutConfig?.backgroundColor;
  const backgroundType = checkoutConfig?.backgroundType;
  const backgroundImage = () => {
    if (!isMobile) return checkoutConfig?.backgroundImage ?? DefaultCheckoutBackground;
    if (isMobile) return checkoutConfig?.backgroundImage ?? DefaultCheckoutBackgroundMobile;
  };

  const pageData = {
    login: t("checkOutPage.login"),
    loginMessage: t("checkOutPage.loginMessage"),
  };

  const login = () => {
    setStorage(localStorageKeys.CHECK_OUT_HOME_PAGE, true);
    history.push("/login");
  };

  const getLogin = () => {
    const loginData = getStorage(localStorageKeys.LOGIN);
    return loginData;
  };

  const checkoutDetailStyle = () => {
    if (backgroundType === 1) {
      return {
        background: backgroundColor,
        display: !checkoutConfig?.visible && "none",
      };
    }

    if (backgroundType === 2) {
      return {
        backgroundImage: "url(" + backgroundImage() + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        display: !checkoutConfig?.visible && "none",
      };
    }
  };

  return (
    <>
      <ConfirmationDialog
        open={!clickToFocusCustomize && !isDefault && !getLogin()}
        onCancel={login}
        onConfirm={login}
        confirmLoading={false}
        className="modal_login_theme2"
        content={pageData.loginMessage}
        title={pageData.login}
        footer={[
          <Button className="modal_login_button" type="primary" onClick={login}>
            {pageData.login}
          </Button>,
        ]}
      />
      <div className="check_out_page" style={checkoutDetailStyle()}>
        <CheckOutHeader
          key={"CheckOutHeader" + Math.random()}
          colorGroups={colorGroups}
          configuration={checkoutConfig?.header}
          clickToFocusCustomize={clickToFocusCustomize}
        />
        <CheckOutDetail
          key={"CheckOutDetail" + Math.random()}
          {...props}
          colorGroups={colorGroups}
          configuration={checkoutConfig?.checkout}
          clickToFocusCustomize={clickToFocusCustomize}
          isCustomize={isCustomize}
          isDefault={props?.isDefault}
        />
      </div>
      <div>
        <CheckOutRelatedProducts
          key={"CheckOutRelatedProducts" + Math.random()}
          colorGroups={colorGroups}
          configuration={checkoutConfig?.relatedProducts}
          clickToFocusCustomize={clickToFocusCustomize}
          isDefault={props?.isDefault}
        />
      </div>
    </>
  );
}

export default function Theme2Checkout(props) {
  return (
    <Index
      {...props}
      contentPage={(propContents) => {
        return (
          <>
            <CheckoutPage
              {...propContents}
              isDefault={props?.isDefault}
              clickToFocusCustomize={propContents?.clickToFocusCustomize}
              checkoutConfig={propContents?.config?.checkout}
              colorGroups={propContents?.general?.color?.colorGroups}
              isCustomize={props?.isCustomize}
            />
          </>
        );
      }}
    />
  );
}
