import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { CopyIcon } from "../../assets/icons.constants";
import "./BankAccountDetailComponent.scss";

const BankAccountDetailComponent = (props) => {
  const { selectedBankAccountInfo, handleCopyText, reserveTableDetailData = null } = props;
  const { t } = useTranslation();

  return (
    <div className="banking-detail">
      <div className="banking-detail-title">
        <span>{t("bankTransfer.transferInfo")}</span>
      </div>
      <div className="banking-detail-content">
        <div className="bank-name">
          <span className="bank-content-title">{t("bankTransfer.bankName")}</span>
          <span className="bank-content-description">{selectedBankAccountInfo?.bankName}</span>
        </div>
        <div className="bank-account-number">
          <span className="bank-content-title">{t("bankTransfer.accountNumber")}</span>
          <div className="bank-content-description copy-content">
            <span>{selectedBankAccountInfo?.bankAccountNumber}</span>
            <Button className="copy-icon" onClick={() => handleCopyText(selectedBankAccountInfo?.bankAccountNumber)}>
              <CopyIcon />
            </Button>
          </div>
        </div>
        <div className="bank-account-holder-name">
          <span className="bank-content-title">{t("bankTransfer.accountHolder")}</span>
          <div className="bank-content-description copy-content">
            <span className="bank-content-description"> {selectedBankAccountInfo?.bankAccountName}</span>
            <Button className="copy-icon" onClick={() => handleCopyText(t(selectedBankAccountInfo?.bankAccountName))}>
              <CopyIcon />
            </Button>
          </div>
        </div>
        <div className="bank-account-content">
          <span className="bank-content-title">{t("bankTransfer.content")}</span>
          <div className="bank-content-description copy-content">
            <span>{t("reserveTable.paymentForReservationId")}</span>
            <Button className="copy-icon" onClick={() => handleCopyText(t("reserveTable.paymentForReservationId"))}>
              <CopyIcon />
            </Button>
          </div>
        </div>
        {selectedBankAccountInfo?.qrCodeUrl &&
          selectedBankAccountInfo?.isVietnameseBank &&
          selectedBankAccountInfo?.bankCode && (
            <div className="qr-code">
              <img src={selectedBankAccountInfo?.qrCodeUrl} alt="QR" width="200" height="200" />
            </div>
          )}
      </div>
    </div>
  );
};

export default BankAccountDetailComponent;
