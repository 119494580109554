import { useEffect } from "react";


// This function creates a script element and loads Google Analytics with the provided Measurement ID
function loadGA4Script(measurementId) {
  const script = document.createElement("script");
  script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
  script.async = true;
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", measurementId);
}

const GoogleAnalytics = ({ measurementId }) => {
  useEffect(() => {
    if (measurementId) {
      loadGA4Script(measurementId);
    }
  }, [measurementId]);

  return null; // This component doesn't render anything
};

export default GoogleAnalytics;
