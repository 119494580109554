const theme = {
  colors: {
    text: {
      main: "#282828",
    },
    common: {
      tavenderPinocchio: "#E1E1E1",
    },
    shadowColor: "#000",
  },
  fonts: ["Plus Jakarta Sans"],
  fontSizes: {
    small: "16px",
    medium: "24px",
    large: "32px",
  },
};

export default theme;
