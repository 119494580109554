import materialDataService from "../../data-services/material-data.service";
import { EnumComboType } from "../../theme/constants/enums";
import { isNonEmptyArray } from "../../utils/helpers";
import { getStorage, localStorageKeys } from "../../utils/localStorage.helpers";

export async function checkOutOfStockWhenQuickAdd(isCombo, branchId, product, quantityProduct = 1, isPos = false) {
  if (isCombo === true) {
    const comboIdNew = product?.comboTypeId === EnumComboType.Flexible ? product?.comboId : product?.id;
    const localStorageKey = isPos ? localStorageKeys.POS_CART : localStorageKeys.STORE_CART;
    const storeCart = getStorage(localStorageKey);
    let objectStoreCart = JSON.parse(storeCart);

    const productPriceIds =
      objectStoreCart?.reduce((acc, item, index) => {
        if (item?.isCombo) {
          const comboId =
            item?.dataDetails?.comboTypeId === EnumComboType.Flexible
              ? item?.dataDetails?.comboId
              : item?.dataDetails?.id;
          item?.dataDetails?.comboProductPrices?.forEach((product) => {
            acc.push({
              index: index, // index of item in cart
              comboId: comboId,
              productPriceId: product?.productPriceId,
              quantity: comboId === comboIdNew ? item?.quantity + quantityProduct : item?.quantity, // plus quantity when item's index and click item's index matching
            });
          });
        } else {
          acc.push({
            index: index, // index of item in cart
            productPriceId: item?.productPrice.id,
            quantity: item.productPrice?.id === product ? item?.quantity + quantityProduct : item?.quantity, // plus quantity when item's index and click item's index matching
          });
        }
        return acc;
      }, []) ?? [];

    let checkComboContaintInCart = objectStoreCart
      ?.filter((item) => item.isCombo)
      ?.find((item) => item.id === comboIdNew);
    if (!checkComboContaintInCart) {
      const maxIndex = productPriceIds?.reduce((max, item) => (item.index > max ? item.index : max), -1) ?? -1;
      const comboProduct = product?.comboProductPrices ?? product?.comboPricingProducts;
      const comboPricingNew = comboProduct?.map((product) => {
        return {
          index: maxIndex + 1, // index of item in cart
          comboId: comboIdNew,
          productPriceId: product?.productPriceId,
          quantity: quantityProduct ?? 1, // plus quantity when item's index and click item's index matching
        };
      });
      if (isNonEmptyArray(comboPricingNew)) {
        productPriceIds.push(...comboPricingNew);
      }
    }
    const request = {
      branchId: branchId,
      productPrices: productPriceIds,
    };
    const dataResult = await materialDataService.checkInventoryAsync(request);
    const outOfStock = dataResult?.data?.some((item) => item.outOfStock === true && item.comboId === comboIdNew);
    return outOfStock;
  } else {
    let input = {
      branchId: branchId,
      productPrices: getListProductPricesOfProductsInCart(product, quantityProduct, false, undefined, undefined, isPos),
    };

    const dataResult = await materialDataService.checkInventoryAsync(input);

    const outOfStock = dataResult?.data?.some((item) => item.outOfStock === true && item.productPriceId === product);
    return outOfStock ? true : false;
  }
}

/**
 * Bug 31782
 * @param {*} product
 * @param {*} quantityProduct
 * @param {*} isUpdateCart
 * @returns
 */
function getListProductPricesOfProductsInCart(
  product,
  quantityProduct,
  isUpdateCart,
  oldProductPriceSelected,
  initCurrentIndex,
  isPos = false,
) {
  const localStorageKey = isPos ? localStorageKeys.POS_CART : localStorageKeys.STORE_CART;
  const storeCart = getStorage(localStorageKey);
  let objectStoreCart = JSON.parse(storeCart);
  let productPriceIds =
    objectStoreCart
      ?.reduce((acc, item, index) => {
        if (item?.isCombo) {
          const { comboId, id } = item?.dataDetails;
          item?.dataDetails?.comboProductPrices?.forEach((comboProductPrice) => {
            acc.push({
              index: index, // index of item in cart
              comboId: comboId ?? id,
              productPriceId: comboProductPrice?.productPriceId,
              quantity: product?.id === item?.id && isUpdateCart ? quantityProduct : item?.quantity, // plus quantity when item's index and click item's index matching
            });
          });
        } else {
          if (index === initCurrentIndex) {
            if (item?.productPrice.id != oldProductPriceSelected?.id && item?.productPrice.id != product) {
              acc.push({
                index: isUpdateCart ? objectStoreCart?.length : index, // index of item in cart (if edit order item => move index to last index)
                productPriceId: product,
                quantity:
                  item.productPrice?.id === product && !isUpdateCart
                    ? item?.quantity + quantityProduct
                    : item?.quantity, // plus quantity when item's index and click item's index matching
              });
            } else {
              acc.push({
                index: isUpdateCart ? objectStoreCart?.length : index, //  index of item in cart (if edit order item => move index to last index)
                productPriceId: product,
                quantity: quantityProduct, // plus quantity when item's index and click item's index matching
              });
            }
          } else {
            acc.push({
              index: index, // index of item in cart
              productPriceId: item?.productPrice.id,
              quantity: item?.quantity,
            });
          }
        }
        return acc;
      }, [])
      .sort((a, b) => a.index - b.index) ?? [];

  // check duplicate productPriceId (edit order item)
  const hasDuplicates = productPriceIds?.some((item, index) => {
    return productPriceIds?.findIndex((innerItem) => innerItem.productPriceId === item.productPriceId) !== index;
  });

  if (hasDuplicates && oldProductPriceSelected) {
    const groupedData = productPriceIds?.reduce((acc, curr) => {
      const { productPriceId, index } = curr;
      if (!acc[productPriceId]) {
        acc[productPriceId] = { productPriceId, quantity: 0, index };
      }
      acc[productPriceId].quantity += curr.quantity;
      if (productPriceId === product) {
        acc[productPriceId].index = productPriceIds?.length;
      }
      return acc;
    }, []);
    productPriceIds = Object.values(groupedData).sort((a, b) => a.index - b.index);
  }

  //QuickAdd
  if (!oldProductPriceSelected && !product?.isCombo) {
    const maxIndex = productPriceIds?.reduce((max, item) => (item.index > max ? item.index : max), -1);
    if (isNonEmptyArray(productPriceIds)) {
      productPriceIds?.push({
        index: maxIndex + 1, // index of item in cart
        productPriceId: product,
        quantity: quantityProduct, // plus quantity when item's index and click item's index matching
      });
    } else {
      productPriceIds?.push({
        index: 0, // index of item in cart
        productPriceId: product,
        quantity: quantityProduct, // plus quantity when item's index and click item's index matching
      });
    }
  }
  return productPriceIds;
}

// update cart
export async function checkOutOfStockAllProductWhenUpdateCart(
  branchId,
  cartData,
  cartIndex,
  quantityProduct,
  outOfStockIndices,
  isViewEditOrder,
  productPriceSelected,
) {
  let productPriceIds = cartData?.reduce((acc, item, index) => {
    if (item?.isCombo) {
      const { comboId } = item?.dataDetails;
      item?.dataDetails?.comboProductPrices?.forEach((product) => {
        acc.push({
          index: index, // Index of item in cart
          comboId: comboId, // Combo ID (if applicable)
          productPriceId: product?.productPriceId,
          quantity: isViewEditOrder
            ? index === cartIndex
              ? quantityProduct
              : item?.quantity
            : item?.quantity + (index === cartIndex ? 1 : 0),
        });
      });
    } else {
      if (index === cartIndex) {
        if (productPriceSelected?.id === item?.productPrice?.id) {
          acc.push({
            index: index,
            productPriceId: item?.productPrice?.id ?? item?.productPriceId,
            quantity: isViewEditOrder
              ? index === cartIndex
                ? quantityProduct
                : item?.quantity
              : item?.quantity + (index === cartIndex ? 1 : 0),
          });
        } else {
          if (productPriceSelected) {
            acc.push({
              index: index,
              productPriceId: productPriceSelected?.id,
              quantity: isViewEditOrder
                ? index === cartIndex
                  ? quantityProduct
                  : item?.quantity
                : item?.quantity + (index === cartIndex ? 1 : 0),
            });
          } else {
            acc.push({
              index: index,
              productPriceId: item?.productPrice?.id ?? item?.productPriceId,
              quantity: isViewEditOrder
                ? index === cartIndex
                  ? quantityProduct
                  : item?.quantity
                : item?.quantity + (index === cartIndex ? 1 : 0),
            });
          }
        }
      } else {
        acc.push({
          index: index,
          productPriceId: item?.productPrice?.id ?? item?.productPriceId,
          quantity: isViewEditOrder
            ? index === cartIndex
              ? quantityProduct
              : item?.quantity
            : item?.quantity + (index === cartIndex ? 1 : 0),
        });
      }
    }
    return acc;
  }, []);

  const isEmptyCartData = !isNonEmptyArray(productPriceIds) && productPriceSelected?.id;
  if (isEmptyCartData) {
    const data = { index: 0, productPriceId: productPriceSelected?.id, quantity: quantityProduct };
    productPriceIds = [data];
  }
  const request = {
    branchId: branchId,
    productPrices: productPriceIds,
  };
  const dataResult = await materialDataService.checkInventoryAsync(request);
  if (outOfStockIndices?.length > 0) {
    const hasOutOfStock = dataResult?.data?.some((item, index) => {
      const isNotInIndices = !outOfStockIndices.includes(index);
      const isCurrentItemOutOfStock = item.index === cartIndex && item.outOfStock;

      return (item.outOfStock && isNotInIndices) || isCurrentItemOutOfStock;
    });
    return hasOutOfStock;
  } else {
    const outOfStock = dataResult?.data?.some((item) => item?.outOfStock === true);
    return outOfStock;
  }
}

export async function checkOutOfStockWhenUpdateCart(
  isCombo,
  branchId,
  cartData,
  quantityProduct,
  oldProductPriceSelected,
  initCurrentIndex,
  isPos = false,
) {
  const localStorageKey = isPos ? localStorageKeys.POS_CART : localStorageKeys.STORE_CART;
  const storeCart = getStorage(localStorageKey);
  let objectStoreCart = JSON.parse(storeCart);
  if (!isCombo) {
    const product = objectStoreCart?.find((item) => item.productPrice?.id === cartData?.id);
    const request = {
      branchId: branchId,
      productPrices: getListProductPricesOfProductsInCart(
        cartData?.id,
        quantityProduct,
        true,
        oldProductPriceSelected,
        initCurrentIndex,
        isPos,
      ),
    };

    const dataResult = await materialDataService.checkInventoryAsync(request);
    const selectedProduct = dataResult?.data?.find(
      (item) => item.productPriceId === product?.productPrice?.id || item.productPriceId === cartData?.id,
    );
    return initCurrentIndex
      ? dataResult?.data[initCurrentIndex]?.outOfStock
      : selectedProduct?.outOfStock
      ? true
      : false;
  } else {
    const request = {
      branchId: branchId,
      productPrices: getListProductPricesOfProductsInCart(
        cartData,
        quantityProduct,
        true,
        oldProductPriceSelected,
        initCurrentIndex,
        isPos,
      ),
    };

    const dataResult = await materialDataService.checkInventoryAsync(request);
    const outOfStock = dataResult?.data?.some((item) => item.outOfStock === true && cartData?.id === item?.comboId);
    return outOfStock;
  }
}

export async function checkProductPriceIdOutOfStockAsync(branchId, productPriceId, quantityProduct) {
  const request = {
    branchId: branchId,
    productPrices: [
      {
        productPriceId: productPriceId,
        quantity: quantityProduct,
      },
    ],
  };
  const dataResult = await materialDataService.checkInventoryAsync(request);
  const selectedProduct = dataResult?.data?.find((item) => item.productPriceId === productPriceId);
  if (selectedProduct?.outOfStock) {
    return true;
  } else {
    return false;
  }
}

export async function checkListProductPriceIdOutOfStock(branchId, productPriceIds, quantityProduct) {
  const request = {
    branchId: branchId,
    productPrices: productPriceIds.map((productPriceId) => ({
      productPriceId,
      quantity: quantityProduct,
    })),
  };

  const dataResult = await materialDataService.checkInventoryAsync(request);
  const outOfStock = dataResult?.data?.some((item) => item.outOfStock === true);
  return outOfStock;
}

export async function checkOutOfStockWhenAddCombo(branchId, comboId, quantityProduct) {
  const storeCart = getStorage(localStorageKeys.STORE_CART);
  let objectStoreCart = JSON.parse(storeCart);
  const cartProduct = objectStoreCart?.find((item) => item?.id === comboId);
  const productPriceIds = cartProduct?.dataDetails?.comboProductPrices?.map(
    (productPrice) => productPrice.productPriceId,
  );
  if (productPriceIds) {
    const request = {
      branchId: branchId,
      productPrices: productPriceIds?.map((productPriceId) => ({
        comboId: comboId,
        productPriceId,
        quantity: cartProduct?.quantity ? cartProduct?.quantity + quantityProduct : quantityProduct,
      })),
    };

    const dataResult = await materialDataService.checkInventoryAsync(request);
    const outOfStock = dataResult?.data?.some((item) => item.outOfStock === true);
    return outOfStock;
  } else {
    return false;
  }
}

// Check product list
export async function checkOutOfStockProductList(branchId, isProductVariant = true, productPrices, format = "array") {
  try {
    const request = { branchId, isProductVariant, productPrices };
    const outOfStockData = await materialDataService.checkInventoryAsync(request);

    if (!outOfStockData?.data) {
      if (format === "dictionary") {
        return {};
      }

      return [];
    }

    if (format === "dictionary") {
      const products = outOfStockData?.data;
      const productDictionary = products.reduce((acc, product) => {
        const isCombo = Boolean(product["comboId"]);
        if (isCombo) {
          acc[product.comboId] = {
            availableQuantity: product.availableQuantity,
            outOfStock: product.outOfStock,
            productPriceId: product.productPriceId,
            index: product.index,
          };
          return acc;
        } else {
          acc[product.productPriceId] = {
            availableQuantity: product.availableQuantity,
            outOfStock: product.outOfStock,
            productPriceId: product.productPriceId,
            index: product.index,
          };
          return acc;
        }
      }, {});

      return productDictionary;
    }

    return outOfStockData?.data;
  } catch (error) {
    return [];
  }
}
