const actionTypes = {
  SET_THEME_CUSTOMIZE_CONFIG: "SET_THEME_CUSTOMIZE_CONFIG",
  SET_LOGIN_SESSION: "SET_LOGIN_SESSION",
  SET_CART_ITEMS: "SET_CART_ITEMS",
  SET_NEAREST_STORE_BRANCHES: "SET_NEAREST_STORE_BRANCHES",
  SET_DELIVERY_ADDRESS: "SET_DELIVERY_ADDRESS",
  SET_PAYMENT_METHODS: "SET_PAYMENT_METHODS",
  SET_DELIVERY_METHODS: "SET_DELIVERY_METHODS",
  SET_ORDER_INFO: "SET_ORDER_INFO",
  SET_IS_OPEN_RECEIVER_ADDRESS_DIALOG: "SET_IS_OPEN_RECEIVER_ADDRESS_DIALOG",
  SET_ORDER_PAYMENT_METHOD: "SET_ORDER_PAYMENT_METHOD",
  SET_USER_INFO: "SET_USER_INFO",
  LANGUAGE_SESSION: "LANGUAGE_SESSION",
  SET_DATA_CALL_BACK_ADD_TO_CART: "SET_DATA_CALL_BACK_ADD_TO_CART",
  SET_SHOW_FLASH_SALE_IN_ACTIVE: "SET_SHOW_FLASH_SALE_IN_ACTIVE",
  SET_NOTIFICATION_DIALOG: "SET_NOTIFICATION_DIALOG",
  SET_DISCOUNT_CODES: "SET_DISCOUNT_CODES",
  SET_APPLIED_DISCOUNT_CODES: "SET_APPLIED_DISCOUNT_CODES",
  SET_TOAST_MESSAGE: "SET_TOAST_MESSAGE",
  SET_SELECTED_SUB_MENU_ID: "SET_SELECTED_SUB_MENU_ID",
  SET_DELIVERY_SCHEDULE: "SET_DELIVERY_SCHEDULE",
  SET_POS_CART_ITEMS: "SET_POS_CART_ITEMS",
  SET_WORKING_HOUR: "SET_WORKING_HOUR",
  SET_PACKAGE_EXPIRED_INFO: "SET_PACKAGE_EXPIRED_INFO",
  SET_SELECTED_MENU_ID: "SET_SELECTED_MENU_ID",
  SET_LOYALTY_POINT: "SET_LOYALTY_POINT",
  SET_STORE_CONFIG: "SET_STORE_CONFIG",
  SET_ADDRESS_LIST: "SET_ADDRESS_LIST",
};

export default actionTypes;
