import { getInternationalRegion } from "../../../../../utils/helpers";
import todayMenuDefault1Img from "../../../../assets/images/menu-special-1.png";
import todayMenuDefault2Img from "../../../../assets/images/menu-special-2.png";
import todayMenuDefault3Img from "../../../../assets/images/menu-special-3.png";

const isInternationalRegion = getInternationalRegion();
// LOCAL
export const categoryMenuDefaultLocal = [
  {
    no: 0,
    id: "c06ddc24-a50e-4c98-a077-446c4fe15450",
    name: "PHỞ",
    priority: 0,
    numberOfProduct: 0,
  },
  {
    no: 0,
    id: "ed4642d2-08dc-445b-b6b0-2dc65c0b0974",
    name: "TRƯA",
    priority: 0,
    numberOfProduct: 0,
  },
  {
    no: 0,
    id: "789255ea-80ec-4c62-9784-a88a5010041c",
    name: "TỐI",
    priority: 0,
    numberOfProduct: 0,
  },
  {
    no: 0,
    id: "d627db2e-7118-432c-b598-962d4ade24e4",
    name: "ĐỒ UỐNG",
    priority: 0,
    numberOfProduct: 0,
  },
];

export const todayMenuDefaultLocal = [
  {
    productId: "1f35cec9-bd71-404e-9cbb-1e44dc2975f8",
    name: "Phở tái đặc biệt",
    thumbnail: todayMenuDefault1Img,
    unitName: "package",
    index: 0,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
    isHasPromotion: true,
    isPromotionProductCategory: false,
    discountValue: 10.0,
    isDiscountPercent: true,
    isFlashSale: false,
    defaultProductPriceIndex: 0,
    productPrices: [
      {
        id: "52564c94-e437-4cf7-be72-b5546d06bd18",
        isApplyPromotion: false,
        priceName: "Nhỏ",
        priceValue: 40000.0,
        originalPrice: 50000.0,
        createdTime: "2023-04-03T07:29:05.0509188",
      },
    ],
  },
  {
    productId: "84a642fd-ed43-4743-a561-5bea2756602a",
    name: "Cơm cá hồi",
    thumbnail: todayMenuDefault2Img,
    unitName: "cái",
    index: 0,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
    isHasPromotion: false,
    isPromotionProductCategory: false,
    discountPrice: 0.0,
    discountValue: 0.0,
    isDiscountPercent: false,
    isFlashSale: false,
    defaultProductPriceIndex: 0,
    productPrices: [
      {
        id: "e43be51d-7674-43a7-8725-0954a35af4a6",
        isApplyPromotion: true,
        priceName: "S",
        priceValue: 40000.0,
        originalPrice: 0.0,
        createdTime: "2023-04-09T03:27:42.3237068",
      },
    ],
  },
  {
    productId: "183ebe3d-038b-4b7e-b1c8-63f561d9850b",
    name: "Mì xào thập cẩm",
    thumbnail: todayMenuDefault3Img,
    unitName: "kg",
    index: 0,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
    isHasPromotion: false,
    isPromotionProductCategory: false,
    discountPrice: 0.0,
    isDiscountPercent: false,
    isFlashSale: false,
    defaultProductPriceIndex: 0,
    productPrices: [
      {
        id: "8ec1f222-aad2-4604-ae57-1a4b69a8457b",
        isApplyPromotion: false,
        priceValue: 40000.0,
        originalPrice: 0.0,
        createdTime: "2022-09-15T10:48:38.7762559",
      },
    ],
  },
  {
    productId: "1f35cec9-bd71-404e-9cbb-1e44dc2975f1",
    name: "Phở tái đặc biệt",
    thumbnail: todayMenuDefault1Img,
    unitName: "package",
    index: 0,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
    isHasPromotion: true,
    isPromotionProductCategory: false,
    discountValue: 10.0,
    isDiscountPercent: true,
    isFlashSale: false,
    defaultProductPriceIndex: 0,
    productPrices: [
      {
        id: "52564c94-e437-4cf7-be72-b5546d06bd18",
        isApplyPromotion: false,
        priceName: "Nhỏ",
        priceValue: 40000.0,
        originalPrice: 50000.0,
        createdTime: "2023-04-03T07:29:05.0509188",
      },
    ],
  },
  {
    productId: "84a642fd-ed43-4743-a561-5bea27566021",
    name: "Cơm cá hồi",
    thumbnail: todayMenuDefault2Img,
    unitName: "cái",
    index: 0,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
    isHasPromotion: false,
    isPromotionProductCategory: false,
    discountPrice: 0.0,
    discountValue: 0.0,
    isDiscountPercent: false,
    isFlashSale: false,
    defaultProductPriceIndex: 0,
    productPrices: [
      {
        id: "e43be51d-7674-43a7-8725-0954a35af4a6",
        isApplyPromotion: true,
        priceName: "S",
        priceValue: 40000.0,
        originalPrice: 0.0,
        createdTime: "2023-04-09T03:27:42.3237068",
      },
    ],
  },
  {
    productId: "183ebe3d-038b-4b7e-b1c8-63f561d98501",
    name: "Mì xào thập cẩm",
    thumbnail: todayMenuDefault3Img,
    unitName: "kg",
    index: 0,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
    isHasPromotion: false,
    isPromotionProductCategory: false,
    discountPrice: 0.0,
    isDiscountPercent: false,
    isFlashSale: false,
    defaultProductPriceIndex: 0,
    productPrices: [
      {
        id: "8ec1f222-aad2-4604-ae57-1a4b69a8457b",
        isApplyPromotion: false,
        priceValue: 40000.0,
        originalPrice: 0.0,
        createdTime: "2022-09-15T10:48:38.7762559",
      },
    ],
  },
  {
    productId: "1f35cec9-bd71-404e-9cbb-1e44dc2975f2",
    name: "Phở tái đặc biệt",
    thumbnail: todayMenuDefault1Img,
    unitName: "package",
    index: 0,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
    isHasPromotion: true,
    isPromotionProductCategory: false,
    discountValue: 10.0,
    isDiscountPercent: true,
    isFlashSale: false,
    defaultProductPriceIndex: 0,
    productPrices: [
      {
        id: "52564c94-e437-4cf7-be72-b5546d06bd18",
        isApplyPromotion: false,
        priceName: "Nhỏ",
        priceValue: 40000.0,
        originalPrice: 50000.0,
        createdTime: "2023-04-03T07:29:05.0509188",
      },
    ],
  },
  {
    productId: "84a642fd-ed43-4743-a561-5bea27566022",
    name: "Cơm cá hồi",
    thumbnail: todayMenuDefault2Img,
    unitName: "cái",
    index: 0,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
    isHasPromotion: false,
    isPromotionProductCategory: false,
    discountPrice: 0.0,
    discountValue: 0.0,
    isDiscountPercent: false,
    isFlashSale: false,
    defaultProductPriceIndex: 0,
    productPrices: [
      {
        id: "e43be51d-7674-43a7-8725-0954a35af4a6",
        isApplyPromotion: true,
        priceName: "S",
        priceValue: 40000.0,
        originalPrice: 0.0,
        createdTime: "2023-04-09T03:27:42.3237068",
      },
    ],
  },
  {
    productId: "183ebe3d-038b-4b7e-b1c8-63f561d98502",
    name: "Mì xào thập cẩm",
    thumbnail: todayMenuDefault3Img,
    unitName: "kg",
    index: 0,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
    isHasPromotion: false,
    isPromotionProductCategory: false,
    discountPrice: 0.0,
    isDiscountPercent: false,
    isFlashSale: false,
    defaultProductPriceIndex: 0,
    productPrices: [
      {
        id: "8ec1f222-aad2-4604-ae57-1a4b69a8457b",
        isApplyPromotion: false,
        priceValue: 40000.0,
        originalPrice: 0.0,
        createdTime: "2022-09-15T10:48:38.7762559",
      },
    ],
  },
];

// INTERNATIONAL
export const categoryMenuDefaultInternational = [
  {
    no: 0,
    id: "c06ddc24-a50e-4c98-a077-446c4fe15450",
    name: "PHO",
    priority: 0,
    numberOfProduct: 0,
  },
  {
    no: 0,
    id: "ed4642d2-08dc-445b-b6b0-2dc65c0b0974",
    name: "LUNCH",
    priority: 0,
    numberOfProduct: 0,
  },
  {
    no: 0,
    id: "789255ea-80ec-4c62-9784-a88a5010041c",
    name: "DINNER",
    priority: 0,
    numberOfProduct: 0,
  },
  {
    no: 0,
    id: "d627db2e-7118-432c-b598-962d4ade24e4",
    name: "DRINKS",
    priority: 0,
    numberOfProduct: 0,
  },
];

export const todayMenuDefaultInternational = [
  {
    productId: "1f35cec9-bd71-404e-9cbb-1e44dc2975f8",
    name: "Special Pho",
    thumbnail: todayMenuDefault1Img,
    unitName: "package",
    index: 0,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
    isHasPromotion: true,
    isPromotionProductCategory: false,
    discountValue: 10.0,
    isDiscountPercent: true,
    isFlashSale: false,
    defaultProductPriceIndex: 0,
    productPrices: [
      {
        id: "52564c94-e437-4cf7-be72-b5546d06bd18",
        isApplyPromotion: false,
        priceName: "Small",
        priceValue: 40000.0,
        originalPrice: 50000.0,
        createdTime: "2023-04-03T07:29:05.0509188",
      },
    ],
  },
  {
    productId: "84a642fd-ed43-4743-a561-5bea2756602a",
    name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    thumbnail: todayMenuDefault2Img,
    unitName: "item",
    index: 0,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
    isHasPromotion: false,
    isPromotionProductCategory: false,
    discountPrice: 0.0,
    discountValue: 0.0,
    isDiscountPercent: false,
    isFlashSale: false,
    defaultProductPriceIndex: 0,
    productPrices: [
      {
        id: "e43be51d-7674-43a7-8725-0954a35af4a6",
        isApplyPromotion: true,
        priceName: "S",
        priceValue: 40000.0,
        originalPrice: 0.0,
        createdTime: "2023-04-09T03:27:42.3237068",
      },
    ],
  },
  {
    productId: "183ebe3d-038b-4b7e-b1c8-63f561d9850b",
    name: "Sliced medium-rare beef",
    thumbnail: todayMenuDefault3Img,
    unitName: "kg",
    index: 0,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
    isHasPromotion: false,
    isPromotionProductCategory: false,
    discountPrice: 0.0,
    isDiscountPercent: false,
    isFlashSale: false,
    defaultProductPriceIndex: 0,
    productPrices: [
      {
        id: "8ec1f222-aad2-4604-ae57-1a4b69a8457b",
        isApplyPromotion: false,
        priceValue: 40000.0,
        originalPrice: 0.0,
        createdTime: "2022-09-15T10:48:38.7762559",
      },
    ],
  },
];

export const categoryMenuDefault = isInternationalRegion ? categoryMenuDefaultInternational : categoryMenuDefaultLocal;
export const todayMenuDefault = isInternationalRegion ? todayMenuDefaultInternational : todayMenuDefaultLocal;
