function EyeOutline({ width = 25, height = 25, color = "#B8B8B8" }) {
  return (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1132_34757)">
        <path
          d="M12.8471 19.7607C8.35513 19.7607 4.05416 16.4559 1.21514 13.0249C0.724511 12.4319 0.724511 11.5712 1.21514 10.9782C1.92894 10.1156 3.42479 8.45129 5.423 6.99677C10.4559 3.33339 15.2286 3.32632 20.2713 6.99677C22.6188 8.7055 24.4791 10.9462 24.4791 10.9782C24.9698 11.5712 24.9698 12.4319 24.4791 13.0249C21.6405 16.4554 17.3401 19.7607 12.8471 19.7607ZM12.8471 5.72034C7.87092 5.72034 3.5573 10.4659 2.3538 11.9204C2.31491 11.9674 2.31491 12.0357 2.3538 12.0827C3.55734 13.5372 7.87092 18.2828 12.8471 18.2828C17.8233 18.2828 22.137 13.5372 23.3405 12.0827C23.4106 11.9979 23.3359 11.9204 23.3405 11.9204C22.1369 10.4659 17.8233 5.72034 12.8471 5.72034Z"
          fill={color}
        />
        <path
          d="M12.8471 17.1744C9.99481 17.1744 7.67432 14.8539 7.67432 12.0016C7.67432 9.14935 9.99481 6.82886 12.8471 6.82886C15.6994 6.82886 18.0199 9.14935 18.0199 12.0016C18.0199 14.8539 15.6994 17.1744 12.8471 17.1744ZM12.8471 8.30679C10.8098 8.30679 9.15225 9.9643 9.15225 12.0016C9.15225 14.039 10.8098 15.6965 12.8471 15.6965C14.8844 15.6965 16.5419 14.039 16.5419 12.0016C16.5419 9.9643 14.8844 8.30679 12.8471 8.30679Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1132_34757">
          <rect width="24" height="24" fill="white" transform="translate(0.847168 0.00366211)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EyeOutline;
