import { createHttp } from "../utils/http-common";
const controller = "resetPassword";

const generateTokenAsync = (data) => {
  const http = createHttp();
  return http.post(`/${controller}/generate-token`, data);
};

const resetPasswordAsync = (data) => {
  const http = createHttp();
  return http.post(`/${controller}`, data);
};

const resetPasswordService = {
  generateTokenAsync,
  resetPasswordAsync,
};

export default resetPasswordService;
