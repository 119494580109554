import { Button } from "antd";
import { useTranslation } from "react-i18next";

function HistoryIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_24176_34389)">
        <path
          d="M23.4997 16.9372C22.982 16.9372 22.5622 16.5175 22.5622 15.9997C22.5622 15.482 22.982 15.0622 23.4997 15.0622H26.2646C26.0547 12.7421 25.0641 10.48 23.2918 8.70774C21.5196 6.93549 19.2574 5.94486 16.9372 5.73486V8.49974C16.9372 9.01749 16.5175 9.43724 15.9997 9.43724C15.482 9.43724 15.0622 9.01749 15.0622 8.49974V5.73486C12.7421 5.9448 10.4799 6.93543 8.70768 8.70768C6.93543 10.48 5.94486 12.7421 5.73486 15.0622H8.49974C9.01749 15.0622 9.43724 15.482 9.43724 15.9997C9.43724 16.5175 9.01749 16.9372 8.49974 16.9372H5.73486C5.9448 19.2574 6.93543 21.5195 8.70768 23.2917C10.4799 25.064 12.7421 26.0546 15.0622 26.2646V23.4997C15.0622 22.982 15.482 22.5622 15.9997 22.5622C16.5175 22.5622 16.9372 22.982 16.9372 23.4997V26.2646C19.2574 26.0547 21.5196 25.0641 23.2918 23.2918C25.0641 21.5196 26.0546 19.2574 26.2646 16.9373H23.4997V16.9372ZM20.6401 12.6852L16.6626 16.6627C16.4796 16.8457 16.2397 16.9372 15.9997 16.9372C15.7598 16.9372 15.5199 16.8457 15.3369 16.6626L11.3594 12.6851C10.9932 12.319 10.9932 11.7254 11.3594 11.3593C11.7254 10.9932 12.3191 10.9932 12.6852 11.3593L15.9997 14.6739L19.3143 11.3594C19.6804 10.9932 20.274 10.9932 20.6401 11.3594C21.0062 11.7255 21.0062 12.3191 20.6401 12.6852Z"
          fill="#0E7ADD"
        />
        <path
          d="M27.3008 4.69925C24.2704 1.66888 20.2571 0 16 0C13.54 0 11.1729 0.560063 8.96425 1.66463C8.05756 2.11806 7.19044 2.66225 6.37925 3.28338L6.48325 2.97137C6.64694 2.48019 6.3815 1.94925 5.89031 1.7855C5.39925 1.62181 4.86819 1.88725 4.70444 2.37844L3.37862 6.35594C3.13387 7.09 3.83525 7.78469 4.5645 7.54181L8.542 6.216C9.03319 6.05225 9.29862 5.52131 9.13494 5.03013C8.97369 4.54656 8.45669 4.28275 7.97213 4.43075C10.3112 2.78006 13.0946 1.875 16 1.875C23.7886 1.875 30.125 8.21144 30.125 16C30.125 23.7886 23.7886 30.125 16 30.125C8.21144 30.125 1.875 23.7886 1.875 16C1.875 15.4823 1.45525 15.0625 0.9375 15.0625C0.41975 15.0625 0 15.4823 0 16C0 20.2571 1.66888 24.2704 4.69925 27.3008C7.72956 30.3311 11.7429 32 16 32C20.2571 32 24.2704 30.3311 27.3008 27.3008C30.3311 24.2704 32 20.2571 32 16C32 11.7429 30.3311 7.72956 27.3008 4.69925Z"
          fill="#0E7ADD"
        />
      </g>
      <defs>
        <clipPath id="clip0_24176_34389">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function HistoryButton(props) {
  const { onClick } = props;
  const { t } = useTranslation();
  return (
    <Button type="link" className="history-button" onClick={onClick}>
      <HistoryIcon />
      <div>
        <p>{t("order.history")}</p>
      </div>
    </Button>
  );
}

export default HistoryButton;
