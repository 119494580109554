import { Col, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Platform } from "../../../constants/platform.constants";
import { ToastMessageType } from "../../../constants/toast-message.constants";
import { store } from "../../../modules";
import { posOrderDetailSelector, qrOrderSelector } from "../../../modules/order/order.reducers";
import { productListPageDataSelector } from "../../../modules/product/product.reducers";
import { setPackageExpiredInfo } from "../../../modules/session/session.actions";
import { useAppCtx } from "../../../providers/app.provider";
import { checkOutOfStockProductList } from "../../../services/material/check-out-of-stock.service";
import orderService from "../../../services/orders/order-service";
import posAddToCartServices from "../../../services/pos/pos-add-to-cart.services";
import posCartService from "../../../services/pos/pos-cart.services";
import mapperOutOfStockServices from "../../../services/pos/pos-product-mapping.services";
import PackageExpiredDialog from "../../../shared/components/package-expired-dialog/package-expired-dialog.component";
import { StringWithLimitLength, isNonEmptyArray } from "../../../utils/helpers";
import { getStorage, localStorageKeys } from "../../../utils/localStorage.helpers";
import {
  ArrowBackIcon,
  ArrowRightIcon,
  CheckCircleIcon,
  LocationDiningTableIcon,
  StoreIcon,
  WarningTriangle,
} from "../../assets/icons.constants";
import { BackIcon } from "../../assets/icons/BackIcon";
import { AddToCartToastMessage } from "../../components/add-update-to-cart-toast-message/add-to-cart-toast-message.component";
import { UpdateToCartToastMessage } from "../../components/add-update-to-cart-toast-message/update-to-cart-toast.component";
import EditOrderItem from "../../components/edit-order-item/edit-order-item.component";
import MinHeader from "../../components/min-header/MinHeader";
import NotificationContainer from "../../components/notification-container/notification-container.component";
import { CloseBranchContainer } from "../../containers/close-branch/close-branch.container";
import { useSearchParams } from "../../hooks";
import BoxDrawer from "../order/components/BoxDrawer";
import "./POSProductListPage.scss";
import ProductCategoryComponent from "./components/ProductCategoryComponent";
import ModeViewProductList from "../productlist/components/product-list-view-mode/ModeViewProductList";

export function POSProductListPage(props) {
  const { fontFamily } = props;
  const menuRef = useRef(null);
  const [swiperRef, setSwiperRef] = useState(null);
  const reduxQROrder = useSelector(qrOrderSelector);
  const query = useSearchParams();
  const qrCodeId = query.get("qrCodeId");
  const orderId = query.get("orderId");
  const paramIsLoadData = "isLoadData";
  const isLoadQRCodeData = query.get(paramIsLoadData);
  const customerInfo = JSON.parse(getStorage(localStorageKeys.CUSTOMER_INFO));
  const storageConfig = JSON.parse(getStorage("config")); //Refactor later
  const posOrderDetail = useSelector(posOrderDetailSelector);
  const [isGridView, setIsGridView] = useState(true);

  const [t] = useTranslation();
  const [currentProductCategoryId, setCurrentProductCategoryId] = useState(null);

  const { Toast } = useAppCtx();
  const [categories, setCategories] = useState([]);
  const history = useHistory();
  const [categoryIdSelected, setCategoryIdSelected] = useState(null);

  const [isVisibleBoxDrawerProductDetail, setIsVisibleBoxDrawerProductDetail] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  const productListPageData = useSelector(productListPageDataSelector);
  const indexAddToCart = -1;

  async function fetchQRCodeData(qrCodeId) {
    if (qrCodeId && isLoadQRCodeData) {
      await orderService.getQrCodeOrderAsync(qrCodeId, false, posOrderDetail, query, history);
    }
  }

  useEffect(() => {
    if (productListPageData && productListPageData?.length > 0) {
      setCategories(productListPageData);
      setCategoryIdSelected(productListPageData?.[0]?.id);
    }
  }, [productListPageData]);

  async function initData() {
    const request = {
      branchId: reduxQROrder?.branchId ?? posOrderDetail?.branchId,
    };
    await posCartService.fetchProductListPageDataAsync(request);
  }

  useEffect(() => {
    posCartService.handelRemoveOrderInQrCode(qrCodeId, posOrderDetail, customerInfo);

    fetchQRCodeData(qrCodeId);
  }, [qrCodeId]);

  useEffect(() => {
    store?.dispatch(setPackageExpiredInfo(null));
    if (reduxQROrder) {
      if (qrCodeId === reduxQROrder?.qrCodeId || Boolean(orderId)) {
        posCartService.deleteUrlParam(query, history, paramIsLoadData);
      }
      const statusQrOrder = posCartService.checkStatusQrOrder(reduxQROrder);
      if (statusQrOrder && isLoadQRCodeData && qrCodeId === reduxQROrder?.qrCodeId) {
        handleShowToast(statusQrOrder);
      }
      initData();
    }
  }, [reduxQROrder]);

  useEffect(() => {
    let nav = document.querySelectorAll(".swiper-slide");
    let indexMenuItem = Array.from(nav).findIndex((el) => el.id === `title_${categoryIdSelected}`);

    if (swiperRef && indexMenuItem !== -1 && !checkIfVisible(indexMenuItem)) {
      swiperRef.slideTo(indexMenuItem, 0);
    }
  }, [categoryIdSelected]);

  useEffect(() => {
    const isElementInViewport = (element) => {
      const rect = element.getBoundingClientRect();

      return rect.top >= 0 && rect.left >= 0 && rect.bottom <= window.innerHeight && rect.right <= window.innerWidth;
    };

    const handleScrollContent = () => {
      const elements = document.querySelectorAll(".product-category");
      if (elements) {
        for (const element of elements) {
          const productCategoryLabel = element.querySelector(".product-category-name");
          if (isElementInViewport(productCategoryLabel)) {
            const productCategoryId = element.getAttribute("data-id");
            if (productCategoryId && currentProductCategoryId !== productCategoryId) {
              setCategoryIdSelected(productCategoryId);
            }
            break;
          }
        }
      }
    };
    window.addEventListener("scroll", handleScrollContent);
    return () => {
      window.removeEventListener("scroll", handleScrollContent);
    };
  }, []);

  function handleShowToast(statusQrOrder) {
    const icon = statusQrOrder?.messageType === ToastMessageType.WARNING ? <WarningTriangle /> : <CheckCircleIcon />;
    Toast.show({
      messageType: statusQrOrder?.messageType,
      message: t(statusQrOrder?.message),
      icon: icon,
      placement: "bottom",
      duration: 3,
    });
  }

  const swiperPosition = document.getElementById("product-menu-swiper")?.getBoundingClientRect();
  function checkIfVisible(index) {
    if (swiperRef && index && swiperPosition) {
      const slide = swiperRef.slides[index];
      const slidePosition = slide ? slide.getBoundingClientRect() : null;
      return slidePosition && slidePosition.left > swiperPosition.left && slidePosition.right < swiperPosition.right;
    }
    return false;
  }

  async function handleShowProductDetail(item) {
    setIsVisibleBoxDrawerProductDetail(true);
    if (item?.isCombo) {
      const comboData = await posAddToCartServices.getComboDetailAsync(item?.id, item?.comboTypeId);
      setDataEdit(comboData);
    } else {
      const productData = {
        isCombo: false,
        id: item?.id,
        quantity: 1,
        productPrice: {
          id: item?.productPriceDefault?.id,
          originalPrice: item?.productPriceDefault?.originalPrice,
          priceValue: item?.productPriceDefault?.sellingPrice,
          isApplyPromotion:
            item?.productPriceDefault?.discountAmount?.id !== null &&
            item?.productPriceDefault?.discountAmount?.id !== undefined,
          promotionId: item?.productPriceDefault?.discountAmount?.id,
          isDiscountPercent: item?.productPriceDefault?.discountAmount?.isPercentDiscount,
          discountValue: item?.productPriceDefault?.discountAmount?.isPercentDiscount
            ? item?.productPriceDefault?.discountAmount?.percentNumber
            : item?.productPriceDefault?.discountAmount?.maximumDiscountAmount,
        },
        toppings: [],
        options: [],
        branchId: reduxQROrder?.branchId,
      };
      setDataEdit(productData);
    }
  }

  function handleCloseViewDetail() {
    setIsVisibleBoxDrawerProductDetail(false);
  }

  function handleClick(event, id) {
    setCategoryIdSelected(id);
    document.getElementById(id)?.scrollIntoView({
      block: "start",
    });
    const element = document.getElementById("product-category-" + id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  const max1Lines = 50;

  const renderCategories = categories.map((pc, index) => {
    const N_COMBOS = categories?.length;
    const isHasLeft = index + N_COMBOS > 0;
    const isHasRight = index + N_COMBOS < categories.length + categories.length - 1;
    const categoryStyle = {};
    if (isHasLeft) {
      Object.assign(categoryStyle, { paddingLeft: 24 });
    }
    if (isHasRight) {
      Object.assign(categoryStyle, { paddingRight: 24 });
    }
    if (categoryIdSelected === pc?.id) {
      Object.assign(categoryStyle, {
        color: "rgb(255, 255, 255)",
        backgroundColor: "rgb(219, 77, 41)",
        borderColor: "transparent",
        borderWidth: "1px",
      });
    }
    return (
      <SwiperSlide
        style={categoryStyle}
        className={"li-normal"}
        key={pc.id}
        onClick={(event) => {
          handleClick(event, pc?.id);
        }}
        id={"title_" + pc?.id}
      >
        {StringWithLimitLength(pc?.name, max1Lines, "...")}
      </SwiperSlide>
    );
  });

  // Out of stock
  const [productPrices, setProductPrices] = useState([]);

  async function fetchOutOfStockData(productPrices) {
    const outOfStockData = await checkOutOfStockProductList(
      reduxQROrder?.branchId ?? posOrderDetail?.branchId,
      true,
      productPrices,
    );
    setProductPrices(outOfStockData);
  }

  useEffect(() => {
    if (!isNonEmptyArray(categories)) return;
    const productPricesData = mapperOutOfStockServices.mapperProducts2ProductVariantsFromCategories(categories);
    if (!isNonEmptyArray(productPricesData)) return;
    fetchOutOfStockData(productPricesData);
  }, [categories]);

  const renderMenus = (
    <div id="nav-category-sticky" className="nav-category-sticky">
      <div className={`product-menu`} ref={menuRef}>
        <div className="arrow-left">
          <ArrowRightIcon width={24} height={24} />
        </div>
        <Swiper
          onSwiper={setSwiperRef}
          grabCursor={true}
          preventClicks={true}
          simulateTouch={true}
          slidesPerGroupAuto={true}
          navigation={{ nextEl: ".arrow-right", prevEl: ".arrow-left" }}
          slidesPerView={"auto"}
          modules={[Navigation]}
          className="swiper-related-product"
          id="product-menu-swiper"
        >
          {renderCategories}
        </Swiper>
        <div className="arrow-right">
          <ArrowRightIcon width={24} height={24} />
        </div>
      </div>
    </div>
  );

  function onChangeGridView(isGridViewMode) {
    setIsGridView(isGridViewMode);
  }

  return (
    <>
      <AddToCartToastMessage />
      <UpdateToCartToastMessage />
      <NotificationContainer style={{ fontFamily }} />
      <div className="pos-product-list" style={{ fontFamily: fontFamily }}>
        <MinHeader
          addonBefore={
            <ArrowBackIcon
              className="arrow-left-icon cursor-pointer"
              onClick={() => {
                history.push("/action-page?" + query.toString());
              }}
            />
          }
          orderId={posOrderDetail?.orderId}
        />
        <Col className="store-info">
          <Col className="store-info-col">
            <Row className="store-info-title">
              <StoreIcon className="icon" />
              <span className="text">{reduxQROrder?.branchName ?? posOrderDetail?.branchName ?? "-"}</span>
            </Row>
            <Row className="store-info-sub-title">
              <LocationDiningTableIcon className="icon" />
              <span className="text">{reduxQROrder?.areaName ?? posOrderDetail?.areaName ?? "-"}</span>
            </Row>
          </Col>
          {posOrderDetail?.stringCode && (
            <Col className="pos-string-code-title">
              <Row>
                <span>{t("orderDetail.order", { stringCode: posOrderDetail?.stringCode })}</span>
              </Row>
            </Col>
          )}
        </Col>

        <div
          className={`pos-product-list-body ${
            isGridView ? "qr-order-product-grid-view-mode" : "qr-order-product-list-view-mode"
          }`}
          id="pos-product-list-body"
        >
          <CloseBranchContainer branchId={reduxQROrder?.branchId ?? posOrderDetail?.branchId} />
          {renderMenus}
          <ModeViewProductList isGridView={isGridView} onChangeGridView={onChangeGridView} />
          <div className="list-product-container" id="listProductContainer">
            {categories?.map((item) => {
              return (
                <ProductCategoryComponent
                  data={item}
                  key={item?.id}
                  handleShowProductDetail={handleShowProductDetail}
                  productPricesFirstTime={productPrices}
                  isGridView={isGridView}
                />
              );
            })}
          </div>
        </div>
        <BoxDrawer
          closeIcon={<BackIcon />}
          closable={true}
          className="product-detail-box-drawer"
          title={""}
          height={"100%"}
          open={isVisibleBoxDrawerProductDetail}
          onClose={handleCloseViewDetail}
          forceRender={true}
          destroyOnClose={true}
          body={
            <EditOrderItem
              {...props}
              dataEdit={{ ...dataEdit }}
              indexDefault={indexAddToCart}
              onCancel={handleCloseViewDetail}
              stateConfig={storageConfig}
              calculateShoppingCart={() => {}}
              isPos={true}
              branchIdPos={reduxQROrder?.branchId}
              platformId={Platform.POS}
            />
          }
        />
      </div>
      <PackageExpiredDialog />
    </>
  );
}
