export const StyleLoadingProducts = {
  width: "100%",
  position: "fixed",
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  display: "flex",
  zIndex: 10000,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(255, 255, 255, 0.4)",
};
