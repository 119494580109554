function SwiperRightIconCustomize({ color }) {
  return (
    <svg width="62" height="65" viewBox="0 0 62 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_264_43108)">
        <rect
          x="52.3867"
          y="52.1611"
          width="40"
          height="40"
          rx="12"
          transform="rotate(-180 52.3867 52.1611)"
          fill="white"
          shape-rendering="crispEdges"
        />
        <path
          d="M28.4688 39.9941L36.3854 32.0775L28.4688 24.1608"
          stroke={color ? "#DB4D29" : "#51526C"}
          strokeWidth="0.95"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_264_43108"
          x="0.386719"
          y="0.161133"
          width="64"
          height="64"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_264_43108" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_264_43108" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SwiperRightIconCustomize;
