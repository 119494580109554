import { Col, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import reserveTableService from "../../../../../../../data-services/reserve-table-data.service";
import storeDataService from "../../../../../../../data-services/store-data.service";
import { useAppCtx } from "../../../../../../../providers/app.provider";
import { formatDate } from "../../../../../../../utils/helpers";
import {
  getStorage,
  localStorageKeys,
  removeStorage,
  setStorage,
} from "../../../../../../../utils/localStorage.helpers";
import { ReserveTableIcon } from "../../../../../../assets/icons.constants";
import ConfirmationDialog from "../../../../../../components/confirmation-dialog/confirmation-dialog.component";
import { EnumReservationStatusStoreWeb } from "../../../../../../constants/enum";
import { DateFormat, profileTab } from "../../../../../../constants/string.constant";
import { ReserveDepositStatus } from "../../../../../../constants/reserve.constants";
import ReservationStatus from "../ReservationStatus/ReservationStatus";
import "./ReservationCard.scss";

const ReservationCard = (props) => {
  const { data, navigateToOrderDetail, navigateToReservationDetail, isLoggedIn, onFocusCancelTab } = props;
  const [t] = useTranslation();
  const history = useHistory();
  const translateData = {
    delivery: t("checkOutPage.delivery", "Giao hàng"),
    orderId: t("reservation.orderId", "Mã đơn hàng"),
    branch: t("reservation.branch", "Chi nhánh"),
    numberOfGuest: t("reservation.numberOfGuest", "Số khách"),
    arrivalTime: t("reservation.arrivalTime", "Thời gian đến"),
    reserveTable: t("reservation.reserveTable", "Đặt bàn"),
    cancelReservation: t("reservation.reserveTableDetail.cancelReservation", "Huỷ đặt bàn"),
    cancel: t("reservation.reserveTableDetail.cancel"),
    cancelSuccess: t("reservation.successfullyCancelled"),
    unSuccessfullyCancelled: t("reservation.unSuccessfullyCancelled"),
    confirmation: t("order.confirmation"),
    contentCancel: t("reservation.reserveTableDetail.contentCancel"),
    cancelReservationOK: t("reservation.reserveTableDetail.cancelReservation"),
    okCancel: t("orderDetail.ignore"),
    cancelReservationTitle: t("reservation.cancelReservationTitle"),
    paidConfirm: t("reservation.paidConfirm"),
    contactStore: t("reservation.contactStore"),
    hotline: t("reservation.hotline"),
  };
  const [isLoadingCancelResevation, setIsLoadingCancelResevation] = useState(false);
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);
  const [isCancelDeposit, setIsCancelDeposit] = useState(false);
  const reservationId = data?.id;
  const { Toast } = useAppCtx();
  const isWaitToConfirmedStatus = data?.status === EnumReservationStatusStoreWeb.WaitToConfirm;
  const isServingOrCompletedStatus =
    data?.status === EnumReservationStatusStoreWeb.Serving || data?.status === EnumReservationStatusStoreWeb.Completed;

  const goToReservationDetail = (id) => {
    navigateToReservationDetail(id);
  };

  const cancelReserveTable = async (reserveTableId) => {
    const orderDetailResult = await reserveTableService.getReserveTableDetailAsync(reserveTableId);
    if (orderDetailResult?.data?.status !== EnumReservationStatusStoreWeb.WaitToConfirm) {
      Toast.error({
        message: translateData.unSuccessfullyCancelled,
        placement: "bottom",
      });
      setTimeout(() => {
        onFocusCancelTab(EnumReservationStatusStoreWeb.All);
      }, 500);
      return;
    }

    setIsShowConfirmDialog(true);
    setIsLoadingCancelResevation(true);
  };

  const onCancelReserveTable = (reservationId) => {
    setIsShowConfirmDialog(true);
    onCancelReservation(reservationId);
    setIsShowConfirmDialog(false);
  };

  async function onCancelReservation(reservationId) {
    if (reservationId) {
      const res = await reserveTableService.cancelReserveTableByIdAsync(reservationId);
      if (res?.data == true) {
        Toast.success({
          message: translateData.cancelSuccess,
          placement: "bottom",
        });
        setTimeout(() => {
          setIsLoadingCancelResevation(false);
          onFocusCancelTab();
        }, 500);
      } else {
        Toast.error({
          message: translateData.unSuccessfullyCancelled,
          placement: "bottom",
        });
      }
    }
  }

  function handleClickOrder() {
    if (isLoggedIn) {
      navigateToOrderDetail && navigateToOrderDetail(data?.orderId);
    } else {
      const myAccountState = {
        currentTab: profileTab.myReservation,
        navigate: true,
        orderId: data?.orderId,
      };
      removeStorage(localStorageKeys.MY_ACCOUNT_STATE);
      setStorage(localStorageKeys.MY_ACCOUNT_STATE, JSON.stringify(myAccountState));
      history.push("/login");
    }
  }

  //US 44776
  useEffect(() => {
    if (isShowConfirmDialog) {
      getStoreConfig();
    }
  }, [isShowConfirmDialog]);

  async function getStoreConfig() {
    const jsonConfig = getStorage(localStorageKeys.STORE_CONFIG);
    const storeConfig = JSON.parse(jsonConfig);
    const storeId = storeConfig.storeId;
    const res = await storeDataService.getStoreConfig(storeId);
    if (res?.data?.storeConfig?.isDeposit && data?.depositStatusId === ReserveDepositStatus.Received) {
      setIsCancelDeposit(true);
    }
  }

  function renderContentCancelDialog() {
    const contentCancelDeposit = (
      <div className="content-cancel-deposit">
        <span>{translateData.paidConfirm}</span>
        <br />
        <span>{translateData.contactStore}</span>
        <div className="hotline-text">
          {translateData.hotline} {data?.branchPhone ?? "1900 6067"}
        </div>
      </div>
    );
    return isCancelDeposit ? contentCancelDeposit : translateData.contentCancel;
  }

  return (
    <div className="reservation-card">
      <Row className="reservation-card-header">
        <Col className="reservation-card-header-left">
          <div className="icon">
            <ReserveTableIcon />
          </div>
          <div className="reservation-info">
            <div>
              <span className="my-reservation-delivery-name" onClick={() => goToReservationDetail(data?.id)}>
                {translateData.reserveTable}
              </span>
              <span className="my-reservation-code" onClick={() => goToReservationDetail(data?.id)}>
                #{data?.stringCode}
              </span>
            </div>
            <span className="my-reservation-status">
              <ReservationStatus status={data?.status} />
            </span>
          </div>
        </Col>
      </Row>
      <div className="reservation-card-content">
        <div className="reservation-item-content">
          <Row
            className={`reservation-item-info ${
              isWaitToConfirmedStatus ? "reservation-item-info-wait-to-confirm" : ""
            }`}
          >
            {isServingOrCompletedStatus && (
              <Col span={6} className="reservation-item">
                <Row className="reservation-item-title">{translateData.orderId}</Row>
                <Row className="reservation-item-order-id" onClick={handleClickOrder}>
                  <Link>#{data?.orderCode}</Link>
                </Row>
              </Col>
            )}

            <Col span={isServingOrCompletedStatus ? 6 : 12} className="reservation-item">
              <Row className="reservation-item-title">{translateData.branch}</Row>
              <Row className="text-content text-content-branch">
                <Typography.Text ellipsis={{ rows: 1 }}>{data?.storeBranchName}</Typography.Text>
              </Row>
            </Col>

            <Col
              span={6}
              className={`reservation-item ${isServingOrCompletedStatus ? "reservation-item-number-of-guest" : ""}`}
            >
              <Row className="reservation-item-title">{translateData.numberOfGuest}</Row>
              <Row className="text-content">{data?.numberOfSeats}</Row>
            </Col>

            <Col span={6} className="reservation-item reservation-item-arrival-time">
              <Row className="reservation-item-title">{translateData.arrivalTime}</Row>
              <Row className="arrival-time">{formatDate(data?.arrivalTime, DateFormat.HH_MM_DD_MM_YYYY_)}</Row>
            </Col>
          </Row>
        </div>

        {isWaitToConfirmedStatus && (
          <div className="reservation-button-cancel">
            <button
              onClick={() => {
                cancelReserveTable(reservationId);
              }}
              loading={isLoadingCancelResevation}
            >
              {translateData.cancelReservation}
            </button>
          </div>
        )}
      </div>
      <ConfirmationDialog
        open={isShowConfirmDialog}
        onCancel={() => {
          setIsShowConfirmDialog(false);
          setIsLoadingCancelResevation(false);
        }}
        onConfirm={() => {
          onCancelReserveTable(reservationId);
        }}
        title={translateData.cancelReservation}
        content={renderContentCancelDialog()}
        okText={translateData.cancelReservationOK}
        cancelText={translateData.okCancel}
      />
    </div>
  );
};

export default ReservationCard;
