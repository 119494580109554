
function CartItemName(props) {
  const { onClick, text, color } = props;
  return (
    <div style={{ cursor: "pointer" }} onClick={onClick}>
      <p style={{ color: color }} className="cart-item-name">
        {text}
      </p>
    </div>
  );
}

export default CartItemName;
