import React from "react";
import styled from "styled-components";

function BellIcon({ hideBg = false, width, style, ...props }) {
  const IconWrapper = styled.div`
    width: ${width ? width : "auto"};
    border-radius: 60px;
    background: ${hideBg === true ? "unset" : "#9fd983"};
    svg {
      padding: 12px;
      width: 100%;
      height: 100%;
    }
  `;

  return (
    <IconWrapper style={style}>
      <svg xmlns="http://www.w3.org/2000/svg" width="45" height="44" viewBox="0 0 45 44" fill="none">
        <g clipPath="url(#clip0_24176_34343)">
          <path d="M21.3442 6.04004H24.5619V11.5908H21.3442V6.04004Z" fill="#FC7D4B" />
          <path d="M19.3403 30.7297H26.5664V35.6721H19.3403V30.7297Z" fill="#FC7D4B" />
          <path d="M19.3403 30.7297H26.5664V33.7021H19.3403V30.7297Z" fill="#FB6A3B" />
          <path
            d="M44.4503 26.7138C42.2461 16.8727 33.459 9.51831 22.9529 9.51831C12.4468 9.51831 3.65978 16.8727 1.45557 26.7138H44.4503Z"
            fill="#FEE265"
          />
          <path
            d="M39.8911 26.7142H44.45C42.2459 16.8729 33.459 9.51904 22.953 9.51904C22.1827 9.51904 21.4223 9.55892 20.6733 9.63592C30.152 10.6104 37.8482 17.5941 39.8911 26.7142Z"
            fill="#FDD330"
          />
          <path
            d="M44.2975 26.0742H1.60888C1.25654 27.4521 1.03353 28.8816 0.954641 30.3496C0.922501 30.949 1.40556 31.4506 2.00583 31.4506H43.9004C44.5007 31.4506 44.9838 30.9489 44.9516 30.3496C44.8728 28.8816 44.6498 27.452 44.2975 26.0742Z"
            fill="#FCB44D"
          />
          <path
            d="M43.9016 31.4502H39.3423C39.9426 31.4502 40.4259 30.9491 40.394 30.3498C40.3152 28.8819 40.0929 27.4513 39.7397 26.0737H44.2982C44.6514 27.4513 44.8737 28.8819 44.9525 30.3498C44.9852 30.9491 44.5019 31.4502 43.9016 31.4502Z"
            fill="#FB9927"
          />
          <path
            d="M28.7376 6.99653H17.169C16.1925 6.99653 15.4009 6.20488 15.4009 5.22837C15.4009 4.25186 16.1925 3.46021 17.169 3.46021H28.7376C29.7141 3.46021 30.5058 4.25186 30.5058 5.22837C30.5058 6.20488 29.7141 6.99653 28.7376 6.99653Z"
            fill="#FEE265"
          />
          <path
            d="M30.506 5.22893C30.506 5.71766 30.3086 6.15937 29.9882 6.47898C29.6686 6.79944 29.2268 6.99692 28.7381 6.99692H25.9785C26.4663 6.99692 26.909 6.79952 27.2294 6.47898C27.549 6.15937 27.7474 5.71766 27.7474 5.22893C27.7474 4.25242 26.955 3.46094 25.9785 3.46094H28.7381C29.7146 3.46094 30.506 4.25242 30.506 5.22893Z"
            fill="#FDD330"
          />
          <path
            d="M43.9764 40.5398H1.92989C1.39046 40.5398 0.953125 40.1025 0.953125 39.563V38.2723C0.953125 36.654 2.26505 35.342 3.88334 35.342H42.0228C43.6411 35.342 44.953 36.654 44.953 38.2723V39.563C44.9531 40.1025 44.5158 40.5398 43.9764 40.5398Z"
            fill="#FCB44D"
          />
          <path
            d="M44.954 38.2717V39.5625C44.954 40.1025 44.5167 40.539 43.9775 40.539H40.3645C40.9037 40.539 41.341 40.1025 41.341 39.5625V38.2717C41.341 36.6533 40.029 35.3413 38.4106 35.3413H42.0236C43.642 35.3413 44.954 36.6534 44.954 38.2717Z"
            fill="#FB9927"
          />
          <path
            d="M39.7787 13.0634C39.6081 13.0634 39.4376 12.998 39.3078 12.8674C39.0493 12.6073 39.0506 12.1869 39.3106 11.9284L40.3732 10.8722C40.6334 10.6138 41.0538 10.615 41.3122 10.875C41.5707 11.1352 41.5694 11.5555 41.3094 11.8141L40.2467 12.8703C40.1173 12.999 39.948 13.0634 39.7787 13.0634Z"
            fill="#FCB44D"
          />
          <path
            d="M36.6798 10.867C36.6262 10.867 36.5719 10.8605 36.5177 10.8469C36.162 10.7577 35.946 10.397 36.0352 10.0413L36.4038 8.57187C36.493 8.21617 36.8541 8.0003 37.2094 8.08941C37.5651 8.17862 37.7811 8.5393 37.6919 8.89499L37.3233 10.3644C37.2476 10.6659 36.977 10.867 36.6798 10.867Z"
            fill="#FCB44D"
          />
          <path
            d="M41.9571 16.1749C41.6583 16.1749 41.3868 15.9717 41.3126 15.6685C41.2255 15.3123 41.4437 14.9529 41.7998 14.8657L43.2717 14.5057C43.6282 14.4186 43.9874 14.6368 44.0744 14.9929C44.1615 15.3491 43.9433 15.7085 43.5872 15.7956L42.1153 16.1556C42.0623 16.1686 42.0093 16.1749 41.9571 16.1749Z"
            fill="#FCB44D"
          />
          <path
            d="M6.12604 13.0633C5.95674 13.0633 5.78745 12.9991 5.65794 12.8703L4.59532 11.8141C4.33519 11.5556 4.33399 11.1352 4.59249 10.8751C4.85107 10.6151 5.27148 10.6137 5.53153 10.8723L6.59414 11.9284C6.85428 12.1869 6.85548 12.6074 6.59698 12.8675C6.46721 12.9979 6.29663 13.0633 6.12604 13.0633Z"
            fill="#FCB44D"
          />
          <path
            d="M9.22506 10.8669C8.92772 10.8669 8.65719 10.6658 8.58156 10.3643L8.21298 8.89483C8.12377 8.53914 8.33974 8.17846 8.69543 8.08925C9.05087 7.99988 9.41181 8.21601 9.50101 8.57171L9.86959 10.0412C9.9588 10.3968 9.74284 10.7575 9.38714 10.8467C9.33291 10.8604 9.27852 10.8669 9.22506 10.8669Z"
            fill="#FCB44D"
          />
          <path
            d="M3.94808 16.1748C3.89583 16.1748 3.84281 16.1686 3.78987 16.1556L2.31793 15.7956C1.96172 15.7085 1.74361 15.3491 1.83066 14.9929C1.91772 14.6368 2.27685 14.4185 2.63341 14.5058L4.10534 14.8658C4.46156 14.9529 4.67966 15.3123 4.59252 15.6685C4.51845 15.9715 4.24697 16.1748 3.94808 16.1748Z"
            fill="#FCB44D"
          />
        </g>
        <defs>
          <clipPath id="clip0_24176_34343">
            <rect width="44" height="44" fill="white" transform="translate(0.953125)" />
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  );
}

export default BellIcon;
