import { Image, Modal } from "antd";
import parse from "html-react-parser";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { CloseIcon } from "../../../../../assets/icons.constants";
import DefaultAreaTable from "../../../../../assets/images/default-area-table.png";
import "./ReserveAreaTableModal.scss";

const ReserveAreaTableModal = (props) => {
  const { open, onCancel, data } = props;
  const [t] = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const pageData = {
    detailDescription: t("reserveTable.detailDescription"),
    seats: t("reserveTable.seats"),
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const renderDescription = useMemo(() => {
    if (data?.description) {
      return parse(data?.description);
    }
  }, [data?.description]);

  // Locking body scroll for modals
  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (isMobile) {
      bodyClassList.toggle("locking-body-scroll", open);
    } else {
      bodyClassList.remove("locking-body-scroll");
    }
  }, [open, isMobile]);

  const StyledDescription = styled.div`
    span {
      font-family: unset !important;
    }
    li {
      font-family: unset !important;
    }
  `;

  return (
    <Modal
      open={open}
      title={
        <div className="reserve-modal-table">
          <div className="title">{data?.name}</div>
          <div className="button" onClick={handleCancel}>
            <CloseIcon />
          </div>
        </div>
      }
      onCancel={handleCancel}
      footer={null}
      closable={false}
      transitionName={null}
      wrapClassName="reserve-popover-class-name-overwrite"
      className="reserve-modal-table"
    >
      <div className="reserve-area-table-body">
        <Image className="image" preview={false} src={data?.imageUrl || DefaultAreaTable} alt={data?.name || ""} />
        <div className="information">
          <span className="detail">{pageData.detailDescription}</span>
          <span className="seats">
            {pageData.seats}: {data?.numberOfSeat}
          </span>
        </div>
        <StyledDescription>{renderDescription}</StyledDescription>
      </div>
    </Modal>
  );
};

export default ReserveAreaTableModal;
