import { Space } from "antd";
import { BackIcon } from "../../../assets/icons/BackIcon";

function BackButton(props) {
  return (
    <Space>
      <BackIcon />
    </Space>
  );
}

export default BackButton;
