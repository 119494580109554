import { Modal } from "antd";
import React from "react";
import "./notification-dialog.component.scss";
import { useTranslation } from "react-i18next";

function NotificationDialog(props) {
  const { confirmLoading, open, className, title, content, onCancel, onConfirm, okText, footer } = props;
  const { t } = useTranslation();
  const translateData = {
    notification: t("storeWebPage.generalUse.notification"),
  };
  return (
    <div className={`notification-dialog${className ? ` ${className}` : ""}`}>
      <Modal
        {...props}
        className="notification-modal-theme2"
        title={title ?? translateData.notification}
        open={open}
        onOk={onConfirm}
        onCancel={onCancel}
        okText={okText ?? "Okay"}
        closable={false}
        confirmLoading={confirmLoading ?? false}
        wrapClassName={className}
        footer={footer}
        zIndex={9999}
      >
        <div className="notification-dialog-content">{content ?? "Notification content"}</div>
      </Modal>
    </div>
  );
}

export default NotificationDialog;
