import { Grid, Row } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { EnumActionTypeOrderHistoryLocal as actionType } from "../../../../../constants/enums";
import { posOrderDetailSelector } from "../../../../../modules/order/order.reducers";
import { isMobileBreakPoint } from "../../../../../utils/antdBreakPoint.helpers";
import "./ActionButton.style.scss";
import BellIcon from "./BellIcon";
import BookOrderIcon from "./BookOrderIcon";
import CashIcon from "./CashIcon";

export const actionName = (type) => {
  if (type === actionType.ORDER) {
    return "order.bookOrder";
  }
  if (type === actionType.ADD_ITEM) {
    return "order.addItemTitle";
  }
  if (type === actionType.CALL_WAITER) {
    return "order.callWaiter";
  }
  if (type === actionType.CALL_PAYMENT) {
    return "order.callPayment";
  }
};

export const actionIcon = (type, width) => {
  if (type === actionType.ORDER || type === actionType.ADD_ITEM) {
    return <BookOrderIcon width={width} />;
  }
  if (type === actionType.CALL_WAITER) {
    return <BellIcon width={width} />;
  }
  if (type === actionType.CALL_PAYMENT) {
    return <CashIcon width={width} />;
  }

  return <BookOrderIcon width={width} />;
};

export const actionBgColor = (type) => {
  if (type === actionType.ORDER || type === actionType.ADD_ITEM) {
    return "#F7F5FF";
  }
  if (type === actionType.CALL_WAITER) {
    return "#F4FFEF";
  }
  if (type === actionType.CALL_PAYMENT) {
    return "#FFF8F1";
  }
};

const { useBreakpoint } = Grid;

function ActionButton(props) {
  const { action, className, onClick, isLoading = false } = props;
  const screens = useBreakpoint();
  const isMobile = isMobileBreakPoint(screens);
  const { t } = useTranslation();
  const posOrderDetail = useSelector(posOrderDetailSelector);
  const bgColor = useMemo(() => {
    let value = "#e2ddff";
    if (action === actionType.CALL_WAITER) {
      value = "#d4f3c5";
    }
    if (action === actionType.CALL_PAYMENT) {
      value = "#ffdfc1";
    }
    return value;
  }, [action]);

  const isSmallButton = useMemo(() => {
    return action === actionType.CALL_WAITER || action === actionType.CALL_PAYMENT;
  }, [action]);

  const ActionButtonStyled = styled.div`
    cursor: pointer;
    background-color: ${bgColor};
    svg {
      position: relative;
    }
    &:hover {
      opacity: 0.8;
    }
  `;

  const icon = useMemo(() => {
    const size = isMobile ? "50px" : "120px";
    return actionIcon(action, size);
  }, [action]);

  const label = useMemo(() => {
    let buttonLabel = posOrderDetail?.stringCode ? t("orderDetail.addItemTitle") : t("order.bookOrder");
    if (action === actionType.CALL_WAITER) {
      buttonLabel = t("order.callWaiter");
    }
    if (action === actionType.CALL_PAYMENT) {
      buttonLabel = t("order.callPayment");
    }
    return buttonLabel;
  }, [action, t, posOrderDetail]);

  return (
    <ActionButtonStyled
      className={`action-button ${isSmallButton ? "btn-small" : "btn-large"} ${className ?? ""}`}
      onClick={onClick && isLoading === false && onClick}
    >
      <Row>
        <div className="icon content-center icon-instore-action">{icon}</div>
      </Row>
      <Row className={`content-center`}>
        <div className="text-wrapper">{label}</div>
      </Row>
    </ActionButtonStyled>
  );
}

export default ActionButton;
