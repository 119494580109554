import { useEffect, useRef } from "react";
import { useState } from "react";
import i18n from "../../utils/i18n";
import SelectLanguage from "../components/SelectLanguage/SelectLanguage";
import ListLanguage from "../components/ListLanguage/ListLanguage";
import { listDefaultLanguage } from "../components/change-language/list-language";
import { setLanguageSession } from "../../modules/session/session.actions";
import { useDispatch, useSelector } from "react-redux";
import { storeConfigSelector } from "../../modules/session/session.reducers";
import { EnumInternationalRegion } from "../constants/store-web-page.constants";

const SelectLanguageContainer = (props) => {
  const { className = "" } = props;
  const [languageSelected, setLanguageSelected] = useState(i18n?.language);
  const [isShowListLanguage, setIsShowListLanguage] = useState(false);
  const dispatch = useDispatch();
  const prevLangRef = useRef(i18n.language);
  const storeType = useSelector(storeConfigSelector)?.type ?? EnumInternationalRegion.VIETNAM;
  const [listLanguage, setListLanguage] = useState([]);

  useEffect(() => {
    const langCode = localStorage?.getItem("i18nextLng");
    let languages = listDefaultLanguage;
    if (storeType === EnumInternationalRegion.INTERNATIONAL) {
      languages = languages?.filter((language) => language?.languageCode !== "vi");
    }
    setListLanguage(languages);
    const lang = languages?.find((lang) => lang?.languageCode === langCode) || languages[0];
    if (lang?.languageCode !== prevLangRef?.current) {
      i18n.changeLanguage(lang?.languageCode);
      setLanguageSelected(lang);
      dispatch(setLanguageSession({ default: lang, list: languages }));
      if (prevLangRef?.current) prevLangRef.current = lang?.languageCode;
    } else {
      setLanguageSelected(lang);
    }
  }, [i18n.language]);

  function handleClickSelectLanguage() {
    setIsShowListLanguage(true);
  }

  function handleChooseLanguage(lang) {
    setLanguageSelected(lang);
    i18n.changeLanguage(lang.languageCode);
    setIsShowListLanguage(false);
  }

  return (
    <>
      <SelectLanguage
        onClick={handleClickSelectLanguage}
        languages={listLanguage}
        currentLang={languageSelected}
        isMobileMode={true}
        className={className}
      />
      {isShowListLanguage && (
        <ListLanguage
          languages={listLanguage}
          onClick={handleChooseLanguage}
          onCancel={() => setIsShowListLanguage(false)}
        />
      )}
    </>
  );
};

export default SelectLanguageContainer;
