import React from "react";
import styled from "styled-components";

function CashIcon({ hideBg = false, width, style, ...props }) {
  const IconWrapper = styled.div`
    width: ${width ? width : "auto"};
    border-radius: 60px;
    background: ${hideBg === true ? "unset" : "#fec998"};
    svg {
      padding: 13px;
      width: 100%;
      height: 100%;
    }
  `;
  return (
    <IconWrapper style={style}>
      <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
        <g clipPath="url(#clip0_24176_34370)">
          <path
            d="M40.4722 8.59375e-05H40.4299C40.0037 8.59375e-05 16.2633 0 16.2331 0C14.3493 0 12.8058 1.45552 12.6654 3.30301C12.6452 3.56048 12.6584 1.6665 12.6547 32.5518H33.9638C35.7716 32.5518 37.2605 31.0871 37.2605 29.2551V26.2461H42.7732C43.2545 26.2461 43.6446 25.856 43.6446 25.3747V3.17255C43.6446 1.42768 42.217 8.59375e-05 40.4722 8.59375e-05Z"
            fill="#47821C"
          />
          <path
            d="M40.2361 0V0.0061875C38.5798 0.105703 37.2566 1.49196 37.2566 3.17144L37.2601 9.12252V29.255C37.2601 30.4653 36.6078 31.5227 35.6357 32.0959C35.1452 32.3856 34.5738 32.5518 33.9634 32.5518H12.6543V3.57844C12.6543 3.48605 12.6578 3.39359 12.665 3.30301C12.8054 1.45552 14.3489 0 16.2327 0H40.2361Z"
            fill="#91DC5A"
          />
          <path
            d="M33.1524 29.0009V5.95023C33.1524 4.48817 31.9672 3.30301 30.5052 3.30301H17.2956C16.8639 3.30301 16.5628 2.88054 16.6964 2.46993C17.1629 1.03632 18.5094 0 20.0987 0H16.2332C14.3497 0 12.8071 1.45552 12.6664 3.30301H12.6654C12.641 3.6147 12.6642 3.00257 12.6548 32.5518H16.5202H33.9638C34.5743 32.5518 35.1457 32.3856 35.6362 32.0959C34.2197 31.7806 33.1524 30.5098 33.1524 29.0009Z"
            fill="#7ED63E"
          />
          <path
            d="M28.1944 5.2583C29.6568 5.2583 30.8422 6.44329 30.8422 7.90561C30.8422 14.3724 30.8422 29.0846 30.8422 29.379C30.8422 30.9924 32.0624 32.3348 33.6265 32.5266V32.5508H12.7722V32.5482C12.7406 32.5491 12.7091 32.5506 12.6774 32.5506H11.7624C10.4972 32.5506 9.46191 31.5154 9.46191 30.2501V9.368V5.2583H28.1944Z"
            fill="#5EAC24"
          />
          <path
            d="M0.354492 40.4008V8.83691C0.354492 6.86052 1.95671 5.2583 3.9331 5.2583H27.9367V5.26457C26.2803 5.364 24.9567 6.75035 24.9567 8.43025L24.9606 14.381V40.7037C24.9606 42.5242 23.4847 44.0001 21.6642 44.0001H3.95381C1.96591 44.0001 0.354492 42.3886 0.354492 40.4008Z"
            fill="#B6E892"
          />
          <path
            d="M16.6975 12.2769H4.36225C3.99418 12.2769 3.6958 12.5751 3.6958 12.9433C3.6958 13.3115 3.99418 13.6097 4.36225 13.6097H16.6975C17.0656 13.6097 17.364 13.3115 17.364 12.9433C17.364 12.5751 17.0655 12.2769 16.6975 12.2769Z"
            fill="#5EAC24"
          />
          <path
            d="M19.9673 14.4663H19.9553C19.6875 14.4663 19.4698 14.2486 19.4698 13.9808C19.4698 13.6878 19.2315 13.4495 18.9385 13.4495C18.6455 13.4495 18.4072 13.6878 18.4072 13.9808C18.4072 14.633 18.823 15.2179 19.4301 15.4371V15.796C19.4301 16.0888 19.6684 16.3271 19.9613 16.3271C20.2543 16.3271 20.4926 16.0888 20.4926 15.796V15.4371C21.0996 15.2179 21.5154 14.633 21.5154 13.9807C21.5154 13.1324 20.8259 12.4384 19.9781 12.4326L19.9581 12.4319C19.6998 12.4265 19.4817 12.1935 19.4817 11.9231C19.4817 11.6587 19.6973 11.4408 19.9595 11.4375C20.2249 11.4398 20.4408 11.6576 20.4408 11.9231C20.4408 12.2161 20.6791 12.4544 20.972 12.4544C21.2649 12.4544 21.5033 12.2161 21.5033 11.9231C21.5033 11.2787 21.0924 10.6959 20.4925 10.4723V10.0911C20.4925 9.79812 20.2542 9.55981 19.9612 9.55981C19.6683 9.55981 19.43 9.79812 19.43 10.0911V10.4719C18.83 10.6956 18.4192 11.2784 18.4192 11.9231C18.4192 12.7698 19.0975 13.4746 19.93 13.4941L19.9673 13.4952C20.235 13.4952 20.4528 13.7129 20.4528 13.9807C20.4528 14.2485 20.235 14.4663 19.9673 14.4663Z"
            fill="#5EAC24"
          />
          <path
            d="M16.6975 20.1604H4.36225C3.99418 20.1604 3.6958 20.4587 3.6958 20.8268C3.6958 21.195 3.99418 21.4933 4.36225 21.4933H16.6975C17.0656 21.4933 17.364 21.195 17.364 20.8268C17.364 20.4587 17.0655 20.1604 16.6975 20.1604Z"
            fill="#5EAC24"
          />
          <path
            d="M19.9781 20.3159L19.9581 20.3152C19.6998 20.3098 19.4817 20.0768 19.4817 19.8064C19.4817 19.542 19.6973 19.3241 19.9595 19.3208C20.2249 19.3231 20.4408 19.5409 20.4408 19.8064C20.4408 20.0994 20.6791 20.3377 20.972 20.3377C21.2649 20.3377 21.5033 20.0994 21.5033 19.8064C21.5033 19.162 21.0924 18.5792 20.4925 18.3556V17.9744C20.4925 17.6814 20.2542 17.4431 19.9612 17.4431C19.6683 17.4431 19.43 17.6814 19.43 17.9744V18.3552C18.83 18.5789 18.4192 19.1617 18.4192 19.8064C18.4192 20.6531 19.0975 21.3579 19.93 21.3774L19.9673 21.3785C20.235 21.3785 20.4528 21.5962 20.4528 21.864C20.4528 22.1319 20.235 22.3496 19.9673 22.3496H19.9553C19.6875 22.3496 19.4698 22.1319 19.4698 21.8641C19.4698 21.5711 19.2315 21.3328 18.9385 21.3328C18.6455 21.3328 18.4072 21.5711 18.4072 21.8641C18.4072 22.5163 18.823 23.1012 19.4301 23.3204V23.6793C19.4301 23.9721 19.6684 24.2104 19.9613 24.2104C20.2543 24.2104 20.4926 23.9721 20.4926 23.6793V23.3204C21.0996 23.1012 21.5154 22.5163 21.5154 21.864C21.5153 21.0157 20.8259 20.3217 19.9781 20.3159Z"
            fill="#5EAC24"
          />
          <path
            d="M16.6975 28.0437H4.36225C3.99418 28.0437 3.6958 28.342 3.6958 28.7101C3.6958 29.0783 3.99418 29.3766 4.36225 29.3766H16.6975C17.0656 29.3766 17.364 29.0783 17.364 28.7101C17.364 28.342 17.0655 28.0437 16.6975 28.0437Z"
            fill="#5EAC24"
          />
          <path
            d="M19.9781 28.1992L19.9581 28.1985C19.6998 28.1931 19.4817 27.9601 19.4817 27.6897C19.4817 27.4253 19.6973 27.2074 19.9595 27.2042C20.2249 27.2064 20.4408 27.4242 20.4408 27.6897C20.4408 27.9827 20.6791 28.221 20.972 28.221C21.2649 28.221 21.5033 27.9827 21.5033 27.6897C21.5033 27.0453 21.0924 26.4625 20.4925 26.2389V25.8577C20.4925 25.5647 20.2542 25.3264 19.9612 25.3264C19.6683 25.3264 19.43 25.5647 19.43 25.8577V26.2385C18.83 26.4622 18.4192 27.045 18.4192 27.6897C18.4192 28.5364 19.0975 29.2412 19.93 29.2607L19.9673 29.2618C20.235 29.2618 20.4528 29.4795 20.4528 29.7473C20.4528 30.0152 20.235 30.2329 19.9673 30.2329H19.9553C19.6875 30.2329 19.4698 30.0152 19.4698 29.7474C19.4698 29.4544 19.2315 29.2161 18.9385 29.2161C18.6455 29.2161 18.4072 29.4544 18.4072 29.7474C18.4072 30.3996 18.823 30.9845 19.4301 31.2037V31.5626C19.4301 31.8554 19.6684 32.0937 19.9613 32.0937C20.2543 32.0937 20.4926 31.8554 20.4926 31.5626V31.2037C21.0996 30.9845 21.5154 30.3996 21.5154 29.7473C21.5153 28.899 20.8259 28.2051 19.9781 28.1992Z"
            fill="#5EAC24"
          />
          <path
            d="M16.6975 35.9272H4.36225C3.99418 35.9272 3.6958 36.2255 3.6958 36.5937C3.6958 36.9618 3.99418 37.2601 4.36225 37.2601H16.6975C17.0656 37.2601 17.364 36.9618 17.364 36.5937C17.364 36.2255 17.0655 35.9272 16.6975 35.9272Z"
            fill="#5EAC24"
          />
          <path
            d="M19.9781 36.0825L19.9581 36.0818C19.6998 36.0764 19.4817 35.8434 19.4817 35.573C19.4817 35.3086 19.6973 35.0907 19.9595 35.0875C20.2249 35.0897 20.4408 35.3075 20.4408 35.573C20.4408 35.866 20.6791 36.1043 20.972 36.1043C21.2649 36.1043 21.5033 35.866 21.5033 35.573C21.5033 34.9286 21.0924 34.3458 20.4925 34.1222V33.741C20.4925 33.448 20.2542 33.2097 19.9612 33.2097C19.6683 33.2097 19.43 33.448 19.43 33.741V34.1218C18.83 34.3455 18.4192 34.9283 18.4192 35.573C18.4192 36.4197 19.0975 37.1245 19.93 37.144L19.9673 37.1451C20.235 37.1451 20.4528 37.3628 20.4528 37.6306C20.4528 37.8985 20.235 38.1162 19.9673 38.1162H19.9553C19.6875 38.1162 19.4698 37.8985 19.4698 37.6307C19.4698 37.3377 19.2315 37.0994 18.9385 37.0994C18.6455 37.0994 18.4072 37.3377 18.4072 37.6307C18.4072 38.2829 18.823 38.8678 19.4301 39.087V39.4459C19.4301 39.7387 19.6684 39.977 19.9613 39.977C20.2543 39.977 20.4926 39.7387 20.4926 39.4459V39.087C21.0996 38.8678 21.5154 38.2829 21.5154 37.6306C21.5153 36.7823 20.8259 36.0883 19.9781 36.0825Z"
            fill="#5EAC24"
          />
          <path
            d="M7.01912 44H3.95338C1.96556 44 0.354492 42.3889 0.354492 40.4011V8.83649C0.354492 6.86019 1.95671 5.25806 3.93293 5.25806H6.99866C5.02236 5.25806 3.42023 6.86028 3.42023 8.83649V40.4011C3.42023 42.3889 5.0313 44 7.01912 44Z"
            fill="#A4E276"
          />
        </g>
        <defs>
          <clipPath id="clip0_24176_34370">
            <rect width="44" height="44" fill="white" transform="translate(-0.000488281)" />
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  );
}

export default CashIcon;
