import { createHttp } from "../utils/http-common";

const controller = "notification";

const getNotificationByCustomerIdAsync = (params) => {
  const http = createHttp();
  return http.get(`/${controller}`, { params });
};

const markAsReadAsync = (id) => {
  const http = createHttp();
  return http.put(`/${controller}/${id}/mark-as-read`);
};

const markAllAsReadAsync = (customerId) => {
  const http = createHttp();
  return http.put(`/${controller}/mark-all-as-read-by-customer/${customerId}`);
};

const notificationDataService = {
  getNotificationByCustomerIdAsync,
  markAsReadAsync,
  markAllAsReadAsync,
};

export default notificationDataService;
