import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { EnumOrderItemStatus } from "../../../../../../constants/enums";
import { tabName } from "../../TabsStatusOrder/TabsStatusOrder";

const QuantityItem = (props) => {
  const { cartItem, statusTabsOrder } = props;
  const isMaxWidth575 = useMediaQuery({ maxWidth: 575 });
  const { t } = useTranslation();
  const itemsCompleted = cartItem?.quantityCompleted ?? 0;
  let itemsTotal = cartItem?.quantity ?? 0;

  function getQuantityCartItem() {
    let result =
      itemsCompleted < itemsTotal &&
      cartItem?.statusId !== EnumOrderItemStatus.Canceled &&
      statusTabsOrder !== tabName.RECEIPT
        ? `${itemsCompleted}/${itemsTotal}`
        : `x${itemsTotal}`;

    if (isMaxWidth575) {
      result = `${t("checkOutPage.cartQuantity")}: ${result}`;
    }

    return result;
  }

  return <>{getQuantityCartItem()}</>;
};

export default QuantityItem;
