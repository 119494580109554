import { useEffect } from 'react';

const useResponsiveChatContainer = (selector = '.messenger-chat-container', breakpoint = 1199) => {
  useEffect(() => {
    const chatContainer = document.querySelector(selector);

    const handleResize = () => {
      if (chatContainer) {
        chatContainer.style.display = window.innerWidth <= breakpoint ? 'none' : 'block';
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [selector, breakpoint]);
};

export default useResponsiveChatContainer;
