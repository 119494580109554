export const EnumLogin = {
  LOGIN: 1,
  OTP: 2,
  SUCCESS: 3,
  INPUT_INFO: 4,
  REGISTER: 5,
  FORGOT_PASSWORD: 6,
  OTP_RESET_PASSWORD: 7,
  RESET_PASSWORD_FORM: 8,
};

export const KEYBOARD = {
  ENTER: "Enter",
};
