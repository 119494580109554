import React from "react";
import { useTranslation } from "react-i18next";
import "./TabsStatusOrder.scss";

export const tabName = {
  ORDERING: 1,
  ORDERED: 2,
  RECEIPT: 3,
};

function TabsStatusOrder(props) {
  const { onChangeIsStatusOrder, value = tabName.ORDERING, orderId } = props;
  const { t } = useTranslation();

  const onChange = (status) => {
    onChangeIsStatusOrder && onChangeIsStatusOrder(status, orderId);
  };
  return (
    <div className="order-status-tabs">
      <div
        className={`order-ordering ${value === tabName.ORDERING && "order-ordering-active"}`}
        onClick={() => onChange(tabName.ORDERING)}
      >
        {t("orderDetail.ordering", "Ordering")}
      </div>
      <div
        className={`order-ordering ${value === tabName.ORDERED && "order-ordering-active"}`}
        onClick={() => onChange(tabName.ORDERED)}
      >
        {t("orderDetail.ordered", "Ordered")}
      </div>
      <div
        className={`order-ordering ${value === tabName.RECEIPT && "order-ordering-active"}`}
        onClick={() => onChange(tabName.RECEIPT)}
      >
        {t("orderDetail.receipt")}
      </div>
    </div>
  );
}

export default TabsStatusOrder;
