import { Progress, Radio } from "antd";
import "./fnb-review.scss";
import { StarIcon } from "../../assets/icons.constants";
import { useTranslation } from "react-i18next";

const FnbReview = (props) => {
  const { onChange, data, className } = props;
  const [t] = useTranslation();

  const pageData = {
    all: t("storeWebPage.generalUse.all"),
  };

  return (
    <div className={`container ${className}`}>
      <div className="review-total"></div>
      <div className="review-ratio">
        <Radio.Group defaultValue={0} onChange={onChange}>
          <Radio value={0}>
            <div className="rating">
              {pageData.all}
              <span className="star-icon">
                <StarIcon />
              </span>
            </div>
          </Radio>
          {data?.map((item, index) => (
            <Radio key={index} value={item?.value}>
              <div className="rating">
                <span>{item?.value}</span>
                <span className="star-icon">
                  <StarIcon />
                </span>
                <Progress className="rating-percent" status="normal" percent={item?.percent} size={"small"} />
                <span>{item?.totalStarReview}</span>
              </div>
            </Radio>
          ))}
        </Radio.Group>
      </div>
    </div>
  );
};

export default FnbReview;
