import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { EnumDepositStatus } from "../../../../../../../constants/enums";
import paymentMethodDataService from "../../../../../../../data-services/paymentMethod-data.service";
import { formatDate, formatTextCurrency, getStoreConfig } from "../../../../../../../utils/helpers";
import BankAccountDetailComponent from "../../../../../../components/bank-account-detail-component/BankAccountDetailComponent";
import { PaymentMethodType } from "../../../../../../constants/payment-method.constants";
import { DateFormat } from "../../../../../../constants/string.constant";
import "../ReserveTableDetail.style.scss";

function ReserveTableDetailContent({ reserveTableDetailData }) {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 575px)" });
  const numCol = !isSmallScreen ? 8 : 24;
  const [t] = useTranslation();
  const selectedBankAccountInfo = reserveTableDetailData?.bankAccountInfo;
  const handleCopyText = async (text) => {
    await navigator.clipboard.writeText(text);
  };
  const imgSlash = "../../../";
  const pageData = {
    name: t("reservation.reserveTableDetail.name", "Name"),
    phone: t("reservation.reserveTableDetail.phone", "Phone"),
    email: t("reservation.reserveTableDetail.email", "Email"),
    numberOfGuests: t("reservation.reserveTableDetail.numberOfGuests", "Number Of Guests"),
    guestArrivalTime: t("reservation.reserveTableDetail.guestArrivalTime", "Guest Arrival Time"),
    createdOrderTime: t("reservation.reserveTableDetail.createdOrderTime", "Created Order Time"),
    branch: t("reservation.reserveTableDetail.branch", "Branch"),
    hotlineBranch: t("reservation.reserveTableDetail.hotlineBranch", "Hotline Branch"),
    assignedTable: t("reservation.reserveTableDetail.assignedTable", "Assigned Table(s)"),
    notes: t("reservation.reserveTableDetail.notes", "Notes"),
    area: t("reservation.reserveTableDetail.area", "Area"),
    table: t("reservation.reserveTableDetail.table", "Table"),
    depositMethod: t("reserveTable.depositMethod", ""),
    depositStatus: t("reserveTable.depositStatus", "Deposit status"),
    received: t("reserveTable.received", ""),
    notReceived: t("reserveTable.notReceived", ""),
    depositAmount: t("reserveTable.depositAmount", ""),
    waitToRefund: t("reserveTable.waitToRefund", ""),
    refunded: t("reserveTable.refunded", ""),
  };
  const [paymentMethods, setPaymentMethods] = useState([]);
  const storeConfig = getStoreConfig();
  const deliveryAddress = useSelector((state) => state?.session?.deliveryAddress);
  useEffect(() => {
    handleGetPaymentMethods();
  }, [reserveTableDetailData]);

  const handleGetPaymentMethods = async () => {
    const paymentMethods = await paymentMethodDataService.getPaymentMethods(
      storeConfig?.storeId,
      deliveryAddress?.branchAddress?.id,
    );
    const filterPaymentMethod = paymentMethods?.data?.find(
      (item) => item.paymentMethodEnumId == reserveTableDetailData?.paymentMethodId,
    );

    setPaymentMethods(filterPaymentMethod);
  };

  function getDepositStatus(depositStatus) {
    switch (depositStatus) {
      case EnumDepositStatus.NotReceived:
        return <p className="payment-method-left-content-name">{pageData.notReceived}</p>;
      case EnumDepositStatus.Received:
        return <p className="payment-method-left-content-name">{pageData.received}</p>;
      case EnumDepositStatus.WaitToRefund:
        return <p className="payment-method-left-content-name">{pageData.waitToRefund}</p>;
      case EnumDepositStatus.Refunded:
        return <p className="payment-method-left-content-name">{pageData.refunded}</p>;
      default:
        return "-";
    }
  }

  return (
    <div className="reserve-table-detail-content">
      <Row gutter={[24, 24]}>
        <Col span={numCol} className="name-customer-reserve-table">
          <Row className="title-text-reserve-table-detail-content">{pageData.name}</Row>
          <Row className="name-customer name-text-reserve-table-detail-content">
            {reserveTableDetailData?.customerName}
          </Row>
        </Col>
        <Col span={numCol} className="phone-customer-reserve-table">
          <Row className="title-text-reserve-table-detail-content">{pageData.phone}</Row>
          <Row className="name-text-reserve-table-detail-content">{reserveTableDetailData?.customerPhone}</Row>
        </Col>
        <Col span={numCol} className="email-customer-reserve-table">
          <Row className="title-text-reserve-table-detail-content">{pageData.email}</Row>
          <Row className="name-text-reserve-table-detail-content">
            {Boolean(reserveTableDetailData?.customerEmail) ? reserveTableDetailData?.customerEmail : "-"}
          </Row>
        </Col>
        <Col span={numCol} className="count-customer-reserve-table">
          <Row className="title-text-reserve-table-detail-content">{pageData.numberOfGuests}</Row>
          <Row className="name-text-reserve-table-detail-content">{reserveTableDetailData?.numberOfGuests}</Row>
        </Col>
        <Col span={numCol} className="arrival-time-reserve-table">
          <Row className="title-arrival-time title-text-reserve-table-detail-content">{pageData.guestArrivalTime}</Row>
          <Row className="arrival-time mt">
            {formatDate(reserveTableDetailData?.guestArrivalTime, DateFormat.HH_MM_DD_MM_YYYY_)}
          </Row>
        </Col>
        <Col span={24} className="book-arrival-info">
          <Row className="title-book-arrival-info title-text-reserve-table-detail-content">{pageData.branch}</Row>
          <Row className="branch-book-arrival-info name-text-reserve-table-detail-content mt">
            {reserveTableDetailData?.branchName}
          </Row>
          <Row className="address-book-arrival-info mt">{reserveTableDetailData?.branchAddress}</Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={12}>
              <Row className="title-area title-text-reserve-table-detail-content">{pageData.area}</Row>
            </Col>
            <Col span={12}>
              <Row className="title-area title-text-reserve-table-detail-content">{pageData.table}</Row>
            </Col>
          </Row>
          {reserveTableDetailData?.listAssignedTables?.length > 0 &&
            reserveTableDetailData?.listAssignedTables?.map((item, index) => (
              <Row key={index} className="area-table">
                <Col span={12} className="name-area">
                  {item?.areaName}
                </Col>
                <Col span={12} className="name-area">
                  {item?.tableName}
                </Col>
              </Row>
            ))}
        </Col>

        <Col span={24} className="book-arrival-info">
          <Row className="title-book-arrival-info title-text-reserve-table-detail-content">
            {pageData.hotlineBranch}
          </Row>
          <Row className="branch-book-arrival-info name-text-reserve-table-detail-content mt">
            {reserveTableDetailData?.branchPhone}
          </Row>
        </Col>
        <Col span={24}>
          <Row className="title-note title-text-reserve-table-detail-content">{pageData.notes}</Row>
          <Row className="note name-text-reserve-table-detail-content mt">{reserveTableDetailData?.notes}</Row>
        </Col>
        {paymentMethods && (
          <>
            <Col span={24} className="payment-method">
              <p className="title-text-reserve-table-detail-content">{pageData.depositMethod}</p>
              <p>
                <div className="payment-method-left-content">
                  <div className="method-img-container">
                    <img
                      className="payment-method-radio-image"
                      src={`${imgSlash}${paymentMethods?.icon}`}
                      alt={paymentMethods?.paymentMethodName}
                    />
                  </div>
                  <div className="payment-method-left-content-name">{paymentMethods?.paymentMethodName}</div>
                </div>
              </p>
              {reserveTableDetailData?.paymentMethodId == PaymentMethodType.BankTransfer && (
                <BankAccountDetailComponent
                  selectedBankAccountInfo={selectedBankAccountInfo}
                  handleCopyText={handleCopyText}
                  reserveTableDetailData={reserveTableDetailData}
                />
              )}
            </Col>
          </>
        )}
        <>
          <Col span={24} className="reserve-amount">
            <p className="title-text-reserve-table-detail-content">{pageData.depositAmount}</p>
            <p className="amount-left-content-name">
              {reserveTableDetailData?.depositAmount ? formatTextCurrency(reserveTableDetailData?.depositAmount) : "-"}
            </p>
          </Col>
          <Col span={24} className="payment-method-status">
            <p className="title-text-reserve-table-detail-content">{pageData.depositStatus}</p>
            {getDepositStatus(reserveTableDetailData?.depositStatusId ?? "-")}
          </Col>
        </>
      </Row>
    </div>
  );
}

export default ReserveTableDetailContent;
