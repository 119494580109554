export const mockupDelivery = [
  {
    deliveryMethodId: "4eeb36bd-d0e6-4ef2-a89e-a0057e8540cb",
    deliveryMethodName: "Delivery by shop",
    enumDeliveryMethod: 1,
    pricing: 20000,
  },
  {
    deliveryMethodId: "be8dac49-96a5-482e-a49b-6bf1a223156f",
    deliveryMethodName: "AhaMove",
    enumDeliveryMethod: 2,
    pricing: 47000,
  },
];
