import styled from "styled-components";
import { colorToRgba } from "../../../utils/helpers";

export function StyledSubMenuItem(props) {
  const { groupColorConfig, children } = props;
  const Component = styled.div`
    .subMenuHover:hover .child-menu-item-header,
    .subMenuHover:hover .sub-more-menu-items-theme2 {
      color: rgba(32, 32, 32, 0.8);
      cursor: pointer;
    }
    .subMenuHover {
      &:hover {
        background-color: ${colorToRgba(groupColorConfig?.buttonBackgroundColor, 0.2)} !important;
      }
    }
  `;

  return <Component>{children}</Component>;
}

export function StyledPopoverLoginHeader(props) {
  const { groupColorConfig, children } = props;
  const Component = styled.div`
    .login_content_theme2 {
      .title {
        color: "#282828";
        &:hover {
          color: ${groupColorConfig?.buttonBackgroundColor ? groupColorConfig?.buttonBackgroundColor : "#DB4D29"};
        }
      }
    }

    .user-profile-contain {
      .user-profile-icon {
        color: "#282828";
        &:hover {
          color: ${groupColorConfig?.buttonBackgroundColor ? groupColorConfig?.buttonBackgroundColor : "#DB4D29"};
          opacity: 0.8
        }
        svg {
          fill: ${groupColorConfig?.buttonBackgroundColor};
          path {
            fill: ${groupColorConfig?.buttonBackgroundColor};
          }
        }
      }
    }
  `;

  return <Component>{children}</Component>;
}

export function StyledMenuItems(props) {
  const { groupColorConfig, children } = props;
  const Component = styled.span`
    .view-more-icon {
      svg {
        fill: ${groupColorConfig?.titleColor};
      }
    }
  `;

  return <Component>{children}</Component>;
}

export function StyledLanguage(props) {
  const { groupColorConfig, children } = props;
  const Component = styled.div`
    .change-language-desktop {
      .link-language {
        color: ${groupColorConfig?.titleColor};
        .link-language-icon-desktop path {
          stroke: ${groupColorConfig?.titleColor};
        }
      }
    }
  `;

  return <Component>{children}</Component>;
}

export function StyledDeliveryAddressSelector(props) {
  const { groupColorConfig, children } = props;
  const Component = styled.div`
  .delivery-address-selector-theme2 {
    .delivery-address-header-box {
      background-color: ${colorToRgba(groupColorConfig?.titleColor)};
      .right-box {
        svg path {
          stroke: ${groupColorConfig?.titleColor ?? "white"};
        }
      }
      .img-box {
        svg path {
          stroke: unset;
        }
      }
    }
  }
`;

  return <Component>{children}</Component>;
}

export function StyledIconStroke(props) {
  const { groupColorConfig, children } = props;
  const Component = styled.div`
  .icon-stroke-color {
    path {
      stroke: ${groupColorConfig?.titleColor};
    }
  }
`;

  return <Component>{children}</Component>;
}