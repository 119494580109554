import { createHttp } from "../utils/http-common";
const controller = "qrCode";

const getQrCodeOrderAsync = (qrCodeId) => {
  const http = createHttp();
  return http.get(`/${controller}/${qrCodeId}`);
};

const qrCodeDataService = {
  getQrCodeOrderAsync,
};

export default qrCodeDataService;
