import { combineReducers } from "redux";
import collectionReducer from "./collection/collection.reducers";
import orderReducer from "./order/order.reducers";
import processingReducer from "./processing/processing.reducers";
import productReducer from "./product/product.reducers";
import sessionReducer from "./session/session.reducers";
import themeConfigReducer from "./theme-customize/theme-customize.reducers";
import thirdPartyReducer from "./third-party-response/third-party-response.reducers";
import toastMessageReducer from "./toast-message/toast-message.reducers";
import seoReducer from "./seo/seo.reducers"
import authReducer from "./auth/auth.reducer";
import notificationReducer from "./notification/notification.reducer";

const rootReducer = combineReducers({
  session: sessionReducer,
  processing: processingReducer,
  thirdParty: thirdPartyReducer,
  toastMessage: toastMessageReducer,
  order: orderReducer,
  product: productReducer,
  themeConfig: themeConfigReducer,
  seo: seoReducer,
  collection: collectionReducer,
  auth: authReducer,
  notification: notificationReducer,
});

export default rootReducer;
