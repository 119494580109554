import { createHttp } from "../utils/http-common";

const controller = "ordersession";

const addNewOrderSession = (data) => {
  const http = createHttp();
  return http.post(`/${controller}`, data);
};

const orderSessionDataService = {
  addNewOrderSession,
};

export default orderSessionDataService;
