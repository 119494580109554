import { BucketGeneralCustomizeIcon } from "../../assets/icons.constants";
import SelectBackgroundComponent from "../../components/select-background/select-background.component";
import SelectColorGroupComponent from "../../components/select-color-group/select-color-group.component";
import PageType from "../../constants/page-type.constants";
import defaultConfig from "../../default-store.config";

function GeneralMyProfileCustomize(props) {
  const { pageConfig } = props;
  const { config, general } = pageConfig;
  const defaultThemePageConfig = defaultConfig?.pages?.find((p) => p.id === PageType.MY_PROFILE_PAGE);
  let configPage = config;
  if (config === null && general !== null) {
    configPage = general?.generalBackground;
  } else if (config === null && general === null) {
    configPage = defaultThemePageConfig?.config
  }

  const setFocusElementProfile = () => {
    try {
      const element = document.querySelector('.profile-page-background');
      if (element) {
        // set border element on focused
        element.setAttribute("style", "border: 5px solid #50429b !important;");
        element.scrollIntoView({ behavior: "smooth" });
      }
    } catch { }
  };
  return (
    <div onClick={setFocusElementProfile}>
      <SelectBackgroundComponent
        {...props}
        defaultColorPath="config.backgroundColor"
        defaultConfig={defaultConfig}
        formItemPreName={["config"]}
        backgroundCustomize={configPage}
        defaultThemeColor={defaultThemePageConfig?.config?.backgroundColor}
      />
      <SelectColorGroupComponent {...props} formItemPreName={["config"]} />
    </div>
  );
}

export const ProfilePageCustomizes = [
  {
    icon: <BucketGeneralCustomizeIcon />,
    title: "General customization",
    isNormal: true,
    defaultActiveKey: 1,
    iconRight: <></>,
    collapsible: false,
    content: (props) => {
      return <GeneralMyProfileCustomize {...props} />;
    },
  },
];
