import { Button } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import reserveTableDataService from "../../../../../../../data-services/reserve-table-data.service";
import storeDataService from "../../../../../../../data-services/store-data.service.js";
import { useAppCtx } from "../../../../../../../providers/app.provider.js";
import { getStorage, localStorageKeys } from "../../../../../../../utils/localStorage.helpers.js";
import { HeaderOrderDetailIcon } from "../../../../../../assets/icons.constants";
import reserveTableDetailIcon from "../../../../../../assets/icons/reserve-table-detail-icon.svg";
import ConfirmationDialog from "../../../../../../components/confirmation-dialog/confirmation-dialog.component.js";
import { enumReserveTable } from "../../../../../../constants/enums.js";
import { ReserveDepositStatus } from "../../../../../../constants/reserve.constants.js";
import "../ReserveTableDetail.style.scss";
import reserveTableService from "./../../../../../../../data-services/reserve-table-data.service";
import { EnumReservationStatusStoreWeb } from "../../../../../../constants/enum.js";
function ReserveTableDetailInfoComponent({ reserveTableDetailData, navigateToOrderDetail, getReserveTableDetailData }) {
  const [t] = useTranslation();
  const pageData = {
    cancelled: t("reservation.reserveTableStatus.cancelled"),
    waitToConfirm: t("reservation.reserveTableStatus.waitToConfirm"),
    confirmed: t("reservation.reserveTableStatus.confirmed"),
    serving: t("reservation.reserveTableStatus.serving"),
    completed: t("reservation.reserveTableStatus.completed"),
    reserveTable: t("reservation.reserveTableDetail.reserveTable"),
    orderNo: t("reservation.reserveTableDetail.order"),
    cancel: t("reservation.reserveTableDetail.cancel"),
    cancelSuccess: t("reservation.successfullyCancelled"),
    unSuccessfullyCancelled: t("reservation.unSuccessfullyCancelled"),
    confirmation: t("order.confirmation"),
    contentCancel: t("reservation.reserveTableDetail.contentCancel"),
    cancelReservation: t("reservation.reserveTableDetail.cancelReservation"),
    okCancel: t("orderDetail.ignore"),
    cancelReservationTitle: t("reservation.cancelReservationTitle"),
    paidConfirm: t("reservation.paidConfirm"),
    contactStore: t("reservation.contactStore"),
    hotline: t("reservation.hotline"),
  };
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);
  const [isDeposit, setIsDeposit] = useState(false);
  const [isCancelDeposit, setIsCancelDeposit] = useState(false);
  const reservationId = reserveTableDetailData?.id;
  const { Toast } = useAppCtx();
  const getColorStatus = (statusId) => {
    let data = { textStatus: "", colorStatus: "", colorBackground: "" };
    switch (statusId) {
      case enumReserveTable.Cancelled:
        data.colorStatus = "#FC0D1B";
        data.colorBackground = "#FDE7E7";
        data.textStatus = pageData.cancelled;
        break;
      case enumReserveTable.WaitToConfirm:
        data.colorStatus = "#428BC1";
        data.colorBackground = "#E4EFF6";
        data.textStatus = pageData.waitToConfirm;
        break;
      case enumReserveTable.Confirmed:
        data.colorStatus = "#2B2162";
        data.colorBackground = "#DBD6FF";
        data.textStatus = pageData.confirmed;
        break;
      case enumReserveTable.Serving:
        data.colorStatus = "#FF8C21";
        data.colorBackground = "#FFE8D2";
        data.textStatus = pageData.serving;
        break;
      case enumReserveTable.Completed:
        data.colorStatus = "#33B530";
        data.colorBackground = "#E7F9E7";
        data.textStatus = pageData.completed;
        break;
      default:
        break;
    }

    return data;
  };
  const status = useMemo(() => reserveTableDetailData?.status, [reserveTableDetailData]);
  const orderId = useMemo(() => reserveTableDetailData?.orderId, [reserveTableDetailData]);
  const getStatus = getColorStatus(status);

  const [reserveTableDetailDataChecking, setReserveTableDetailChecking] = useState();
  useEffect(() => {
    if (reserveTableDetailDataChecking) {
      if (reserveTableDetailDataChecking?.status !== EnumReservationStatusStoreWeb.WaitToConfirm) {
        Toast.error({
          message: pageData.unSuccessfullyCancelled,
          placement: "bottom",
        });
        setTimeout(() => {
          getReserveTableDetailData(reservationId);
        }, 500);
        return;
      }
      if (isDeposit && reserveTableDetailDataChecking?.depositStatusId === ReserveDepositStatus.Received) {
        setIsCancelDeposit(true);
      }
      setIsShowConfirmDialog(true);
    }
  }, [reserveTableDetailDataChecking]);

  const getReserveTableDetailDataChecking = async (reserveTableId) => {
    const orderDetailResult = await reserveTableDataService.getReserveTableDetailAsync(reserveTableId);
    setReserveTableDetailChecking(orderDetailResult?.data);
  };

  const cancelReserveTable = () => {
    getStoreConfig();
    getReserveTableDetailDataChecking(reservationId);
  };

  const onCancelReserveTable = (reservationId) => {
    setIsShowConfirmDialog(true);
    onCancelReservation(reservationId);
    setIsShowConfirmDialog(false);
  };

  async function onCancelReservation(reservationId) {
    if (reservationId) {
      const res = await reserveTableService.cancelReserveTableByIdAsync(reservationId);
      if (res?.data == true) {
        Toast.success({
          message: pageData.cancelSuccess,
          placement: "bottom",
        });
        setTimeout(() => {
          getReserveTableDetailData(reservationId);
        }, 500);
      } else {
        Toast.error({
          message: pageData.unSuccessfullyCancelled,
          placement: "bottom",
        });
      }
    }
  }

  //US 44776
  useEffect(() => {
    if (isShowConfirmDialog) {
      getStoreConfig();
    }
  }, [isShowConfirmDialog]);

  async function getStoreConfig() {
    const jsonConfig = getStorage(localStorageKeys.STORE_CONFIG);
    const storeConfig = JSON.parse(jsonConfig);
    const storeId = storeConfig.storeId;
    const res = await storeDataService.getStoreConfig(storeId);
    if (
      res?.data?.storeConfig?.isDeposit &&
      reserveTableDetailData?.depositStatusId === ReserveDepositStatus.Received
    ) {
      setIsCancelDeposit(true);
    }
    if (res?.data?.storeConfig?.isDeposit) {
      setIsDeposit(true);
    }
  }

  function renderContentCancelDialog() {
    const contentCancelDeposit = (
      <div className="content-cancel-deposit">
        <span>{pageData.paidConfirm}</span>
        <br />
        <span>{pageData.contactStore}</span>
        <div className="hotline-text">
          {pageData.hotline} {reserveTableDetailData?.branchPhone ?? "1900 6067"}
        </div>
      </div>
    );
    return isCancelDeposit ? contentCancelDeposit : pageData.contentCancel;
  }

  return (
    <div className="reserve-table-detail-info">
      <div className="reserve-table-detail-info-left">
        <div className="title-name-reserve-table">
          <div className="reserve-table-detail-icon">
            <img src={reserveTableDetailIcon} alt=""></img>
          </div>
          <div className="title-name">
            <span className="title-reserve-table">{pageData.reserveTable}</span>
            <span className="name-reserve-table">#{reserveTableDetailData?.stringCode}</span>
          </div>
        </div>
        <div className="status-reserve-table">
          <span
            className="title-status"
            style={{
              color: `${getStatus.colorStatus}`,
              background: `${getStatus.colorBackground}`,
            }}
          >
            {getStatus.textStatus}
          </span>
        </div>
        {orderId && (status === enumReserveTable.Serving || status === enumReserveTable.Completed) && (
          <div className="order-reserve-table">
            <span className="title-order-reserve-table">{pageData.orderNo}:</span>
            <span
              className="name-order-reserve-table"
              onClick={() => navigateToOrderDetail && navigateToOrderDetail(orderId)}
            >
              #{reserveTableDetailData?.orderCode}
            </span>
          </div>
        )}
        {status === enumReserveTable.WaitToConfirm && (
          <Button
            className="btn-cancel-reserve-table"
            onClick={() => {
              cancelReserveTable();
            }}
          >
            <span>{pageData.cancel}</span>
          </Button>
        )}
      </div>
      <div className="reserve-table-detail-info-right">
        <HeaderOrderDetailIcon />
      </div>
      <ConfirmationDialog
        open={isShowConfirmDialog}
        onCancel={() => {
          setIsShowConfirmDialog(false);
        }}
        onConfirm={() => {
          onCancelReserveTable(reservationId);
        }}
        title={pageData.confirmation}
        content={renderContentCancelDialog()}
        okText={pageData.cancelReservation}
        cancelText={pageData.okCancel}
      />
    </div>
  );
}

export default ReserveTableDetailInfoComponent;
