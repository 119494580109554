export const Platform = {
  /// <summary>
  /// AdminWebsite
  /// </summary>
  AdminWebsite: "6C626154-5065-616C-7466-6F7200000000",

  /// <summary>
  /// AdminMobileApp
  /// </summary>
  AdminMobileApp: "6C626154-5065-616C-7466-6F7200000001",

  /// <summary>
  /// POSWebsite
  /// </summary>
  POS: "6C626154-5065-616C-7466-6F7200000002",

  /// <summary>
  /// POSMobileApp
  /// </summary>
  POSMobileApp: "6C626154-5065-616C-7466-6F7200000003",

  /// <summary>
  /// StoreWebsite
  /// </summary>
  StoreWebsite: "6C626154-5065-616C-7466-6F7200000004",

  /// <summary>
  /// StoreMobileApp
  /// </summary>
  StoreMobileApp: "6C626154-5065-616C-7466-6F7200000005",

  /// <summary>
  /// GoFood
  /// </summary>
  GoFOOD: "6C626154-5065-616C-7466-6F7200000009",
};
