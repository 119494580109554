export const IconOrderDetailInstore = (props) => {
  const { width = 49, height = 48, color } = props;
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.751953" width="48" height="48" rx="24" fill="#B9D7FC" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.217 16.0499C12.127 16.0499 11.252 16.9249 11.252 18.0149V23.1899L15.362 25.5299L17.352 25.2449L15.182 18.0149C15.182 16.9249 14.307 16.0499 13.217 16.0499Z"
        fill="#E4ECF0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.217 21.225C12.127 21.225 11.252 22.1 11.252 23.19V32.275H15.182L16.547 26.515L15.182 23.19C15.182 22.1 14.307 21.225 13.217 21.225Z"
        fill="#2D86F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.397 32.2749C36.397 33.3649 35.522 34.2399 34.432 34.2399H13.217C12.127 34.2399 11.252 33.3649 11.252 32.2749C11.252 31.1849 12.127 30.3099 13.217 30.3099C14.307 30.3099 15.182 31.1849 15.182 32.2749L19.137 30.3549L36.397 32.2749Z"
        fill="#599CEF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.2505 23.19L30.9455 22.17L15.1855 23.19V32.275H36.3955C37.4255 32.275 38.2505 31.45 38.2505 30.42V23.19Z"
        fill="#98C4FB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1816 18.0148V23.1898H38.2516V19.8698C38.2516 18.8398 37.4216 18.0148 36.3966 18.0148H15.1816Z"
        fill="#F0F3F5"
      />
      <path
        d="M22.5426 25.65H19.3926C19.1855 25.65 19.0176 25.8179 19.0176 26.025V29.73C19.0176 29.9371 19.1855 30.105 19.3926 30.105H22.5426C22.7497 30.105 22.9176 29.9371 22.9176 29.73V26.025C22.9176 25.8179 22.7497 25.65 22.5426 25.65Z"
        fill="#F0F3F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.2004 14.0499L27.8954 15.3799L29.3754 15.6299C29.8104 15.7049 29.9804 16.2349 29.6754 16.5499L28.6254 17.6249L28.8404 19.1099C28.9054 19.5449 28.4554 19.8749 28.0604 19.6799L26.7154 19.0149L25.3704 19.6799C24.9754 19.8749 24.5254 19.5449 24.5904 19.1099L24.8104 17.6249L23.7604 16.5499C23.4504 16.2349 23.6254 15.7049 24.0604 15.6299L25.5404 15.3799L26.2354 14.0499C26.4404 13.6599 26.9954 13.6599 27.2004 14.0499Z"
        fill="#FC9C26"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.0038 16.575L21.5588 17.635L22.7388 17.835C23.0438 17.885 23.1638 18.26 22.9488 18.48L22.1088 19.34L22.2838 20.525C22.3288 20.83 22.0138 21.06 21.7338 20.925L20.6588 20.395L19.5838 20.925C19.3088 21.06 18.9938 20.83 19.0338 20.525L19.2088 19.34L18.3688 18.48C18.1538 18.26 18.2738 17.89 18.5788 17.835L19.7588 17.635L20.3138 16.575C20.4588 16.3 20.8488 16.3 20.9888 16.575H21.0038Z"
        fill="#FC9C26"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.1132 16.575L33.6682 17.635L34.8482 17.835C35.1532 17.885 35.2732 18.26 35.0582 18.48L34.2182 19.34L34.3932 20.525C34.4382 20.83 34.1232 21.06 33.8432 20.925L32.7682 20.395L31.6932 20.925C31.4182 21.06 31.1032 20.83 31.1432 20.525L31.3182 19.34L30.4782 18.48C30.2632 18.26 30.3832 17.89 30.6882 17.835L31.8732 17.635L32.4282 16.575C32.5732 16.3 32.9632 16.3 33.1032 16.575H33.1132Z"
        fill="#FC9C26"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.0098 20.9747C24.7348 20.9747 24.5098 21.1997 24.5098 21.4747C24.5098 21.7497 24.7348 21.9747 25.0098 21.9747H28.4298C28.7048 21.9747 28.9298 21.7497 28.9298 21.4747C28.9298 21.1997 28.7048 20.9747 28.4298 20.9747H25.0098Z"
        fill="#599CEF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.9412 25.505C24.7462 25.7 24.7462 26.02 24.9412 26.21C25.0362 26.305 25.1612 26.355 25.2962 26.355C25.4312 26.355 25.5562 26.3 25.6512 26.21C25.8462 26.015 25.8462 25.695 25.6512 25.505C25.5562 25.41 25.4312 25.36 25.2962 25.36C25.1612 25.36 25.0362 25.415 24.9412 25.505Z"
        fill="#F0F3F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.9437 25.505C26.7487 25.7 26.7487 26.02 26.9437 26.21C27.0387 26.305 27.1637 26.355 27.2987 26.355C27.4337 26.355 27.5587 26.3 27.6537 26.21C27.8487 26.015 27.8487 25.695 27.6537 25.505C27.5587 25.41 27.4337 25.36 27.2987 25.36C27.1637 25.36 27.0387 25.415 26.9437 25.505Z"
        fill="#F0F3F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.9462 25.505C28.7512 25.7 28.7512 26.02 28.9462 26.21C29.0412 26.305 29.1662 26.355 29.3012 26.355C29.4362 26.355 29.5612 26.3 29.6562 26.21C29.8512 26.015 29.8512 25.695 29.6562 25.505C29.5612 25.41 29.4362 25.36 29.3012 25.36C29.1662 25.36 29.0412 25.415 28.9462 25.505Z"
        fill="#F0F3F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.2988 27.775C25.0238 27.775 24.7988 28 24.7988 28.275C24.7988 28.55 25.0238 28.775 25.2988 28.775H28.7188C28.9938 28.775 29.2188 28.55 29.2188 28.275C29.2188 28 28.9938 27.775 28.7188 27.775H25.2988Z"
        fill="#F0F3F5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.2988 29.6046C25.0238 29.6046 24.7988 29.8296 24.7988 30.1046C24.7988 30.3796 25.0238 30.6046 25.2988 30.6046H32.1138C32.3888 30.6046 32.6138 30.3796 32.6138 30.1046C32.6138 29.8296 32.3888 29.6046 32.1138 29.6046H25.2988Z"
        fill="#F0F3F5"
      />
    </svg>
  );
};
