import React from "react";
import styled from "styled-components";

function BookOrderIcon({ hideBg = false, width, style, ...props }) {
  const IconWrapper = styled.div`
    width: ${width ? width : "auto"};
    border-radius: 60px;
    background: ${hideBg === true ? "unset" : "#cfc6ff"};
    svg {
      padding: 20%;
      width: 100%;
      height: 100%;
    }
  `;

  return (
    <IconWrapper style={style}>
      <svg xmlns="http://www.w3.org/2000/svg" width="72" height="73" viewBox="0 0 72 73" fill="none">
        <g clipPath="url(#clip0_24176_34296)">
          <path
            d="M44.127 7.19098C40.6212 1.11598 31.3456 4.75535 28.8607 10.2664H22.2837C19.5879 10.2819 17.0159 12.4377 16.3774 15.021L2.60884 71.7182H0.140869L14.4692 12.726C15.1287 10.0372 17.8104 7.79145 20.6117 7.7816H27.4559C29.1209 4.50504 32.4607 1.94848 36.0031 1.40988C40.0854 0.65754 43.8781 3.28582 44.127 7.19098Z"
            fill="#FED2A4"
          />
          <path
            d="M55.5601 12.2229C53.3621 21.6082 43.1907 63.2065 41.146 71.7186H36.833L39.3488 61.3573C39.5837 60.394 38.9818 59.5868 37.9918 59.5826H9.18616C8.20038 59.5826 7.2146 60.3786 6.97835 61.3573L4.45554 71.7186H2.60913L16.3777 15.0214C17.0162 12.4381 19.5882 10.2823 22.284 10.2668H28.861C30.6849 6.71183 34.4593 4.06949 38.4108 4.03715C40.9407 4.01324 43.929 5.51793 44.1666 7.7834H51.721C54.1918 7.75949 55.9383 9.65793 55.5601 12.2229Z"
            fill="#FFE5C2"
          />
          <path
            d="M24.9645 12.0962C23.5456 12.0962 22.1098 13.2479 21.7625 14.6668L12.529 52.6904C12.1901 54.1093 13.0578 55.261 14.4767 55.261H11.0229C9.60403 55.261 8.73637 54.1093 9.07528 52.6904L18.3087 14.6668C18.6561 13.2479 20.0904 12.0962 21.5107 12.0962H24.9645Z"
            fill="#E2E2E2"
          />
          <path
            d="M50.6875 14.6668L41.454 52.6904C41.1067 54.1093 39.6794 55.261 38.252 55.261H14.4765C13.0576 55.261 12.19 54.1093 12.5289 52.6904L21.7623 14.6668C22.1097 13.2479 23.544 12.0962 24.9644 12.0962H48.7398C50.1587 12.0962 51.0264 13.2479 50.6875 14.6668Z"
            fill="#F2F3F4"
          />
          <path
            d="M43.2282 26.2977C43.1325 26.2512 22.7067 26.2977 22.5914 26.2822C21.7083 26.2752 20.7942 27.1372 20.873 28.0569C19.0252 27.763 20.1825 25.2472 21.6464 25.3358H42.1566C42.7796 25.3358 43.1972 25.738 43.2282 26.2977Z"
            fill="#428981"
          />
          <path
            d="M43.1965 26.6916C43.0151 27.4481 42.2501 28.0641 41.4935 28.0641C41.3768 28.0556 20.9735 28.0795 20.8723 28.0556C20.7949 27.1359 21.7076 26.2739 22.5907 26.2809C22.7088 26.2964 43.1276 26.25 43.2274 26.2964C43.2429 26.4244 43.2274 26.5495 43.1965 26.6916Z"
            fill="#50A49D"
          />
          <path
            d="M38.2375 18.1204C33.1553 16.6551 27.5795 20.4618 25.9356 25.3359H22.6787C24.6446 19.3213 32.4465 14.5738 38.2375 18.1204Z"
            fill="#D1303D"
          />
          <path
            d="M41.1308 25.3359H25.9348C27.5773 20.4633 33.1545 16.6551 38.2367 18.1205C40.4515 19.5787 41.6033 22.2365 41.1308 25.3359Z"
            fill="#DD4558"
          />
          <path
            d="M35.1063 15.6199C34.3033 15.5595 33.4765 16.167 33.1741 16.9362C32.9055 16.9601 32.6454 16.991 32.3782 17.0388C32.5427 15.8773 34.098 14.8802 35.1063 15.6199Z"
            fill="#428981"
          />
          <path
            d="M35.4993 16.9295C35.4908 16.9604 35.4838 17.0082 35.4683 17.0406C34.7385 16.9084 33.9341 16.8718 33.1733 16.9379C33.6529 15.654 35.8508 14.7934 35.4993 16.9295Z"
            fill="#50A49D"
          />
          <path
            d="M36.7273 33.7524H23.9669C22.9404 33.7482 22.9319 32.1802 23.9669 32.176H36.7273C37.7538 32.1788 37.7608 33.7482 36.7273 33.7524Z"
            fill="#33A4DB"
          />
          <path
            d="M40.6521 37.9712H17.4096C16.3816 37.9557 16.3662 36.4117 17.4096 36.3948H40.6521C41.6801 36.4088 41.6941 37.9529 40.6521 37.9712Z"
            fill="#33A4DB"
          />
          <path
            d="M39.6276 42.1885H16.3837C15.3558 42.173 15.3403 40.6289 16.3837 40.6121H39.6262C40.6556 40.6275 40.6697 42.1702 39.6276 42.1885Z"
            fill="#33A4DB"
          />
          <path
            d="M38.6025 46.407H15.36C14.3321 46.3915 14.3166 44.8474 15.36 44.8306H38.6025C39.6305 44.8446 39.6446 46.3887 38.6025 46.407Z"
            fill="#33A4DB"
          />
          <path
            d="M37.5772 50.6247H14.3346C13.3067 50.6093 13.2912 49.0652 14.3346 49.0483H37.5772C38.6065 49.0624 38.6206 50.6065 37.5772 50.6247Z"
            fill="#33A4DB"
          />
          <path
            d="M35.1712 71.715H30.8569L27.908 59.5776H34.0083C33.0183 59.5776 32.415 60.375 32.6526 61.3551L35.1712 71.715Z"
            fill="#EA9B58"
          />
          <path
            d="M58.6682 17.3865L48.4251 59.5825H44.0952L55.4662 12.7262C55.9077 10.9361 55.3396 9.36668 54.126 8.49902C55.7657 9.36668 57.0988 10.9361 57.5319 12.7262L58.6682 17.3865Z"
            fill="#EA9B58"
          />
          <path
            d="M71.8605 71.7181H67.539L65.0233 61.3569C64.787 60.3795 63.7928 59.5822 62.8154 59.5822H48.4253L58.6684 17.3862L69.2983 61.1741C69.3011 61.1881 71.8548 71.6956 71.8605 71.7181Z"
            fill="#F6A96C"
          />
        </g>
        <defs>
          <clipPath id="clip0_24176_34296">
            <rect width="72" height="72" fill="white" transform="translate(0.000244141 0.5)" />
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  );
}

export default BookOrderIcon;
